import { FC, memo } from 'react'

import { ReactComponent as StarIcon } from 'assets/images/common/star.svg'

import style from './style.module.scss'

type PropsType = { count?: number; rating: number }

const UIRating: FC<PropsType> = ({ count = 5, rating }) => {
  const stars = [...Array(count).fill(1)].map((_, index) =>
    index + 1 <= rating ? style.active : style.inactive,
  )

  return (
    <div className={style.starWrapper}>
      {stars.map((className, index) => (
        <StarIcon key={index} className={className} />
      ))}
    </div>
  )
}

export default memo(UIRating)
