import { FileName, FullMedia, UploadMedia } from 'entities/common'
import { POST, DELETE } from 'utils/http'

const basePath = '/image'

export const uploadFile = async (
  fileName: FileName,
  nameUpload: string,
  file: UploadMedia,
) => {
  const formData = new FormData()

  formData.append(fileName, file as unknown as Blob)
  formData.append('name', nameUpload)

  const { data }: { data: FullMedia } = await POST(basePath, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}

export const deleteFile = (id: number) => DELETE(`${basePath}/${id}`)
