import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'

import { DateClickArg } from '@fullcalendar/interaction'
import { EventClickArg, DateSelectArg } from '@fullcalendar/react'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as PlusCircle } from 'assets/images/common/redesign/plusCircle.svg'
import {
  createNotActiveEvent,
  getDisableDate,
  createEvent,
} from 'entities/manager/appointments'
import useResponsive from 'hooks/useResponsive'
import AppointmentsCalendar from 'modules/manager/appointments/Calendar'
import AppointmentsMonthEvent from 'modules/manager/appointments/MonthEvent'
import { addAlert } from 'store/Alert'
import {
  getAppointmentRequest,
  setQuery,
  resetError,
} from 'store/Manager/Appointments/Appointments.action'
import { IRootState } from 'store/Root.reducer'
import UIAppointmentsSidebar from 'ui/AppointmentsSidebar'
import UICalendarHeader from 'ui/CalendarHeader'
import { ModeType } from 'ui/CalendarHeader/index.type'
import { FORMAT_DATE } from 'utils'
import { DateHelper } from 'utils/helpers'

import styles from './style.module.scss'

const ViewAppointments: FC = () => {
  const {
    isGetLoading,
    appointmens,
    schedule,
    appointmensMobile,
    errorMessage,
    appointmentsError,
  } = useSelector((state: IRootState) => state.appointments)

  const [dayEl, setDayEl] = useState<HTMLElement>()

  const [, setClikDate] = useState<Date | null>(null)
  const [eventData, setEventData] = useState<EventClickArg | undefined | null>(
    null,
  )
  const [eventDate, setEventDate] = useState<Date>(new Date())
  const [headerDate, setHeaderDate] = useState<Date>(new Date())
  const [selectDate, setSelectDate] = useState({ start: '', end: '' })

  const [isDrawer, setIsDrawer] = useState(false)
  const [mode, setMode] = useState(ModeType.DAY)
  const [isAddAppointment, setIsAddAppointment] = useState(false)

  const calendarRef = useRef(null)
  const { t } = useTranslation()
  const { isMobile, isTablet } = useResponsive()
  const dispatch = useDispatch()

  const showDrawer = useCallback(
    (date: Date) => {
      const query = `from=${DateHelper.toFormat(
        date,
        FORMAT_DATE,
      )}&to=${DateHelper.toFormat(date, FORMAT_DATE)}`

      if (mode === ModeType.MONTH && (isMobile || isTablet)) {
        setIsDrawer(false)
        dispatch(getAppointmentRequest({ query, type: 'mobile' }))
        dispatch(setQuery(query))
      } else {
        setIsDrawer(true)
      }
    },
    [dispatch, isMobile, isTablet, mode],
  )

  const handleEventClick = (e: EventClickArg) => {
    setEventData(e)
    setClikDate(e.event.end)
    setIsAddAppointment(false)

    if (!e.event.id.includes('item')) {
      showDrawer(e.event.end || new Date())
    }
  }

  const handleClick = (e: DateClickArg) => {
    setEventDate(e.date)
    setClikDate(e.date)
    setIsAddAppointment(true)

    setDayEl(e.dayEl)

    dayEl?.classList.remove('activMobileDate')

    e.dayEl.classList.add('activMobileDate')

    if (
      new Date(e.date).valueOf() >= new Date().valueOf() &&
      mode !== ModeType.MONTH
    ) {
      showDrawer(e.date)
    } else if (
      new Date(new Date(e.date)).valueOf() >=
        new Date(new Date().toDateString()).valueOf() &&
      mode === ModeType.MONTH
    ) {
      showDrawer(e.date)
    } else if (mode === ModeType.MONTH && (isMobile || isTablet)) {
      showDrawer(e.date)
    }
  }

  const handleSelect = (e: DateSelectArg) => {
    if (mode === ModeType.DAY || mode === ModeType.WEEK) {
      showDrawer(e.start)
      setSelectDate({
        start: e.start as unknown as string,
        end: e.end as unknown as string,
      })
    }
  }

  const handleSelectAllow = (selectInfo: { end: Date; start: Date }) => {
    if (
      mode === ModeType.MONTH &&
      (isMobile || isTablet) &&
      selectInfo.end.getTime() / 1000 - selectInfo.start.getTime() / 1000 <=
        86400
    ) {
      return true
    }

    if (!(mode === ModeType.MONTH && (isMobile || isTablet))) {
      return selectInfo.start.valueOf() > new Date().valueOf()
    }

    return false
  }

  useEffect(() => {
    if (appointmentsError?.length) {
      setIsDrawer(true)
    }
  }, [appointmentsError])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(resetError())
    }
  }, [errorMessage, dispatch])

  const renderEvent = useCallback(() => {
    if (mode === ModeType.MONTH && (isMobile || isTablet)) {
      return createEvent(appointmens)
    }

    return [...createNotActiveEvent(schedule), ...createEvent(appointmens)]
  }, [appointmens, isMobile, isTablet, mode, schedule])

  useEffect(() => {
    if (mode === ModeType.DAY) window.scrollTo(0, 860)
  }, [headerDate, mode])

  return (
    <div className={styles.wrapper}>
      <UICalendarHeader
        calendarRef={calendarRef}
        btnIcon={<PlusCircle />}
        btnLabel={t('MANAGER.BUTTON.ADD_APPOINTMENT')}
        btnHandler={() => {
          setIsDrawer(true)
          setIsAddAppointment(true)
        }}
        setMode={setMode}
        isShift={false}
        mode={mode}
        setHeaderDate={setHeaderDate}
      />

      <div className={styles.calendarWrapp}>
        <Spin spinning={isGetLoading}>
          <AppointmentsCalendar
            calendarRef={calendarRef}
            onClick={(e) => handleClick(e)}
            onEventClick={(e) => handleEventClick(e)}
            monthDisableDate={getDisableDate(schedule)}
            events={renderEvent()}
            {...((isMobile || isTablet) && {
              selectable: false,
            })}
            onSelect={(e) => handleSelect(e)}
            selectAllow={(selectInfo) => handleSelectAllow(selectInfo)}
            setMode={setMode}
            mode={mode}
            headerDate={headerDate}
          />
        </Spin>
      </div>

      {mode === ModeType.MONTH && (isMobile || isTablet) && (
        <AppointmentsMonthEvent
          events={createEvent(appointmensMobile)}
          setEventDate={setEventDate}
          setEventData={setEventData}
          setIsDrawer={setIsDrawer}
          setIsAddAppointment={setIsAddAppointment}
        />
      )}

      {isDrawer && (
        <UIAppointmentsSidebar
          eventData={eventData}
          setIsDrawer={setIsDrawer}
          eventDate={eventDate}
          setEventData={setEventData}
          setEventDate={setEventDate}
          isAddAppointment={isAddAppointment}
          selectDate={selectDate}
          setSelectDate={setSelectDate}
          mode={mode}
          headerDate={headerDate}
        />
      )}
    </div>
  )
}

export default memo(ViewAppointments)
