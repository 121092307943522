import { FC, useEffect } from 'react'

import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getAdminPagesRequest } from 'store/Admin/Pages/Pages.action'
import { IRootState } from 'store/Root.reducer'
import { UICustomTable } from 'ui'
import { ADMIN_PAGES_EDIT } from 'utils'
// import { getFromQueryString, getQueryString } from 'utils/helpers/query'

import style from './style.module.scss'
import { DateHelper } from 'utils/helpers'

const ViewAdminPages: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const location = useLocation()
  const dispatch = useDispatch()
  const {
    data,
    isLoading,
    // meta: { perPage, total },
  } = useSelector((store: IRootState) => store.adminPages)

  const handleClick = (id: number, type: string) => () => {
    navigate(ADMIN_PAGES_EDIT.replace(':id', String(id)).replace(':type', type))
  }

  const columns = [
    { title: t('COMMON.ID') },
    { title: t('COMMON.NAME') },
    { title: t('COMMON.UPDATED') },
  ]

  // TODO remove if API not return meta
  // useEffect(() => {
  // const queries = getFromQueryString(location.search)

  // navigate(
  // `${location.pathname}${getQueryString({
  // ...queries,
  // page: 1,
  // })}`,
  // )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   if (location.search) {
  //     dispatch(getAdminPagesAction(location.search))
  //   }
  // }, [location.search, dispatch])

  useEffect(() => {
    dispatch(getAdminPagesRequest(''))
  }, [dispatch])

  return (
    <Content className={style.adminPages}>
      <h1>{t('COMMON.PAGES')}</h1>

      <UICustomTable
        columns={columns}
        isLoading={isLoading}
        className={style.table}
        // perPage={perPage}
        // total={total}
      >
        {data?.map(({ updated_at, id, name, slug }) => (
          <tr key={id} onClick={handleClick(Number(id), String(slug))}>
            <td>{id}</td>
            <td>{name}</td>
            <td>{DateHelper.toFormat(new Date(updated_at), 'MMM dd, yyyy')}</td>
          </tr>
        ))}
      </UICustomTable>
    </Content>
  )
}

export default ViewAdminPages
