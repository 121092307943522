import { call, fork, put, takeLatest } from 'redux-saga/effects'
import { StringHelper } from 'utils/helpers'
import { getRequestErrors } from 'utils/helpers/requests'
import {
  getAdminCategoryFailure,
  getAdminCategorySuccess,
  getAdminSettingsFailure,
  getAdminSettingsSuccess,
  getAdminSubCategoryFailure,
  getAdminSubCategorySuccess,
  updateAdminSettingsSuccess,
  updateAdminSettingsFailure,
} from './Seo.action'
import {
  GET_ADMIN_SEO_CATEGORY_REQUEST,
  GET_ADMIN_SEO_SETTINGS_REQUEST,
  GET_ADMIN_SEO_SUBCATEGORY_REQUEST,
  UPDATE_ADMIN_SEO_SETTINGS_REQUEST,
} from './Seo.constant'
import {
  IGetAdminCategoryRequest,
  IGetAdminCategoryResponse,
  IGetAdminSubCategoryRequest,
  IGetAdminSubCategoryResponse,
  IUpdateAdminSettingsRequest,
  SeoSettingType,
} from './Seo.type'

import { AdminSeoHttp } from 'services/http'

function* workerGetCategory({ payload }: IGetAdminCategoryRequest) {
  try {
    const { data }: { data: IGetAdminCategoryResponse } = yield call(
      AdminSeoHttp.getSeoCategory,
      payload,
    )

    yield put(getAdminCategorySuccess(data.data))
  } catch (error) {
    yield getRequestErrors(
      getAdminCategoryFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchGetCategory() {
  yield takeLatest(GET_ADMIN_SEO_CATEGORY_REQUEST, workerGetCategory)
}

function* workerGetSubCategory({ payload }: IGetAdminSubCategoryRequest) {
  try {
    const { data }: { data: IGetAdminSubCategoryResponse } = yield call(
      AdminSeoHttp.getSeoSubCategory,
      payload,
    )

    yield put(getAdminSubCategorySuccess(data.data))
  } catch (error) {
    yield getRequestErrors(
      getAdminSubCategoryFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchGetSubCategory() {
  yield takeLatest(GET_ADMIN_SEO_SUBCATEGORY_REQUEST, workerGetSubCategory)
}

function* workerGetAdminSettings() {
  try {
    const { data }: { data: SeoSettingType } = yield call(
      AdminSeoHttp.getSeoSettings,
    )

    yield put(getAdminSettingsSuccess(data))
  } catch (error) {
    yield getRequestErrors(
      getAdminSettingsFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchGetAdminSettings() {
  yield takeLatest(GET_ADMIN_SEO_SETTINGS_REQUEST, workerGetAdminSettings)
}

function* workerUpdateAdminSettings({ payload }: IUpdateAdminSettingsRequest) {
  try {
    yield call(AdminSeoHttp.updateSeoSettings, payload)

    yield put(updateAdminSettingsSuccess())
  } catch (error) {
    yield getRequestErrors(
      updateAdminSettingsFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchUpdateAdminSettings() {
  yield takeLatest(UPDATE_ADMIN_SEO_SETTINGS_REQUEST, workerUpdateAdminSettings)
}

export const adminSeoWatchers = [
  fork(watchGetCategory),
  fork(watchGetSubCategory),
  fork(watchGetAdminSettings),
  fork(watchUpdateAdminSettings),
]
