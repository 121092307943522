import { FC, useEffect } from 'react'

import 'antd/dist/antd.css'

import { Layout } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  AdminCategories,
  AdminSubcategories,
} from 'modules/admin/Detail/categories'
import {
  clearErrors,
  getCategoriesRequest,
  getSubcategoriesRequest,
} from 'store/Admin'
import { addAlert } from 'store/Alert'
import { clearMediaStore } from 'store/Media/Media.action'
import { IRootState } from 'store/Root.reducer'

import styles from './style.module.scss'

const { Content } = Layout

const ViewCategories: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { activeCategory, errorMessage } = useSelector(
    (state: IRootState) => state.adminCategories,
  )

  const { errorMessage: mediaErrorMessage } = useSelector(
    (state: IRootState) => state.media,
  )

  useEffect(() => {
    dispatch(getCategoriesRequest())
  }, [dispatch])

  useEffect(() => {
    if (activeCategory) {
      dispatch(getSubcategoriesRequest(activeCategory))
    }
  }, [dispatch, activeCategory])

  useEffect(() => {
    if (errorMessage || mediaErrorMessage) {
      dispatch(
        addAlert({
          message: errorMessage || mediaErrorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(clearErrors())
      dispatch(clearMediaStore())
    }
  }, [errorMessage, dispatch, mediaErrorMessage])

  return (
    <Content>
      <div className={styles.title}>
        <b>{t('ADMIN.CATEGORY.ADD_SERVICES')}</b>
      </div>

      <div className={styles.wrapper}>
        <AdminCategories />

        <AdminSubcategories />
      </div>
    </Content>
  )
}

export default ViewCategories
