import { getRequestErrors } from 'utils/helpers/requests'
import { put, takeLatest, fork, call, ForkEffect } from 'redux-saga/effects'
import {
  createBasketServiceFailure,
  createBasketServiceSuccess,
  createSalonOrderFailure,
  createSalonOrderSuccess,
  deleteBasketServiceFailure,
  deleteBasketServiceSuccess,
  getBasketCalculateTimeFailure,
  getBasketCalculateTimeSuccess,
  getBasketFailure,
  getBasketRequest,
  getBasketSuccess,
  getBasketTimeFailure,
  getBasketTimeSuccess,
  getBasketTotalFailure,
  getBasketTotalRequest,
  getBasketTotalSuccess,
  updateBasketServiceFailure,
  updateBasketServiceSuccess,
  setIsTimeRequest,
} from './Basket.action'
import {
  CREATE_BASKET_SERVICE_REQUEST,
  CREATE_SALON_ORDER_REQUEST,
  DELETE_BASKET_SERVICE_REQUEST,
  GET_BASKET_CALCULATE_TIME_REQUEST,
  GET_BASKET_REQUEST,
  GET_BASKET_TIME_REQUEST,
  GET_BASKET_TOTAL_REQUEST,
  UPDATE_BASKET_SERVICE_REQUEST,
} from './Basket.constant'
import { StringHelper } from 'utils/helpers'
import { BasketHttp } from 'services/http'
import {
  IGetBasketTimeRequest,
  IBasketResponse,
  ICreateBasketServiceRequest,
  IGetBasketCalculateTimeRequest,
  IUpdateBasketServiceRequest,
  IDeleteBasketServiceRequest,
  IBasketResponseItem,
  IBasketTotal,
  ICreateSalonOrderRequest,
} from './Basket.type'

function* workerGetBasket() {
  try {
    const { data }: { data: { data: IBasketResponse } } = yield call(
      BasketHttp.getBasket,
    )

    yield put(getBasketSuccess(data.data))
  } catch (error) {
    yield getRequestErrors(
      getBasketFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchGetBasket() {
  yield takeLatest(GET_BASKET_REQUEST, workerGetBasket)
}

function* workerGetBasketTime({ payload }: IGetBasketTimeRequest) {
  try {
    const { data }: { data: { data: { [key: string]: string[] } } } =
      yield call(BasketHttp.getBasketTime, payload)

    yield put(getBasketTimeSuccess(data.data))
  } catch (error) {
    yield getRequestErrors(
      getBasketTimeFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchBasketTime() {
  yield takeLatest(GET_BASKET_TIME_REQUEST, workerGetBasketTime)
}

function* workerGetBasketCalculateTime({
  payload,
}: IGetBasketCalculateTimeRequest) {
  try {
    const { data }: { data: { data: IBasketResponse } } = yield call(
      BasketHttp.getBasketCalculateTime,
      payload,
    )

    yield put(
      getBasketCalculateTimeSuccess({ data: data.data, isTimeRequest: true }),
    )
  } catch (error) {
    yield getRequestErrors(
      getBasketCalculateTimeFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchBasketCalculateTime() {
  yield takeLatest(
    GET_BASKET_CALCULATE_TIME_REQUEST,
    workerGetBasketCalculateTime,
  )
}

function* workerCreateBasketService({ payload }: ICreateBasketServiceRequest) {
  try {
    const { data }: { data: { data: IBasketResponseItem } } = yield call(
      BasketHttp.createBasketService,
      payload,
    )

    yield put(createBasketServiceSuccess(data.data))
    yield put(getBasketTotalRequest())
    yield put(setIsTimeRequest(true))
  } catch (error) {
    yield getRequestErrors(
      createBasketServiceFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchCreateBasketService() {
  yield takeLatest(CREATE_BASKET_SERVICE_REQUEST, workerCreateBasketService)
}

function* workerUpdateBasketService({ payload }: IUpdateBasketServiceRequest) {
  try {
    const { data }: { data: { data: IBasketResponseItem } } = yield call(
      BasketHttp.updateBasketService,
      payload,
    )

    yield put(updateBasketServiceSuccess(data.data))
    yield put(getBasketTotalRequest())
    yield put(setIsTimeRequest(true))
  } catch (error) {
    yield getRequestErrors(
      updateBasketServiceFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchUpdateBasketService() {
  yield takeLatest(UPDATE_BASKET_SERVICE_REQUEST, workerUpdateBasketService)
}

function* workerDeleteBasketService({ payload }: IDeleteBasketServiceRequest) {
  try {
    yield call(BasketHttp.deleteBasketService, payload)

    yield put(deleteBasketServiceSuccess())
    yield put(getBasketRequest())
    yield put(getBasketTotalRequest())
    yield put(setIsTimeRequest(true))
  } catch (error) {
    yield getRequestErrors(
      deleteBasketServiceFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchUpdateDeleteBasketService() {
  yield takeLatest(DELETE_BASKET_SERVICE_REQUEST, workerDeleteBasketService)
}

function* workerGetBasketTotal() {
  try {
    const { data }: { data: { data: IBasketTotal } } = yield call(
      BasketHttp.getTotal,
    )

    yield put(getBasketTotalSuccess(data.data))
  } catch (error) {
    yield getRequestErrors(
      getBasketTotalFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchGetBasketTotal() {
  yield takeLatest(GET_BASKET_TOTAL_REQUEST, workerGetBasketTotal)
}

function* workerCreateSalonOrderTotal({ payload }: ICreateSalonOrderRequest) {
  try {
    yield call(BasketHttp.createSalonOrder, payload)

    yield put(createSalonOrderSuccess())
  } catch (error) {
    yield getRequestErrors(
      createSalonOrderFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchCreateSalonOrder() {
  yield takeLatest(CREATE_SALON_ORDER_REQUEST, workerCreateSalonOrderTotal)
}

export const basketWatchers: ForkEffect[] = [
  fork(watchGetBasket),
  fork(watchBasketTime),
  fork(watchBasketCalculateTime),
  fork(watchCreateBasketService),
  fork(watchUpdateBasketService),
  fork(watchUpdateDeleteBasketService),
  fork(watchGetBasketTotal),
  fork(watchCreateSalonOrder),
]
