import { FC, useState } from 'react'

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { ReactComponent as PlusIcon } from 'assets/images/common/redesign/plusCircle.svg'
import { reorder } from 'entities/common'
import { CategoryType } from 'entities/view'
import {
  setNewSubcategoryRequest,
  setSubcategories,
  sortSubcategoriesRequest,
} from 'store/Admin'
import { IRootState } from 'store/Root.reducer'
import { UIButton } from 'ui'
import UITextInput from 'ui/TextInput'

import style from './style.module.scss'
import AdminSubcategory from '../Subcategory'

const AdminUISubcategories: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { subcategories, activeCategory } = useSelector(
    (state: IRootState) => state.adminCategories,
  )
  const [value, setValue] = useState('')

  const onAddSubcategories = () => {
    dispatch(
      setNewSubcategoryRequest({
        name: value,
        id: activeCategory || 0,
      }),
    )

    setValue('')
  }

  const handleDrag = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      subcategories,
      result.source.index,
      result.destination.index,
    ) as CategoryType[]

    dispatch(sortSubcategoriesRequest(items.map((e) => e.id)))
    dispatch(setSubcategories(items))
  }

  return (
    <div className={style.wrapper}>
      <div className={style.name}>
        <b>{t('ADMIN.SUBCATEGORY.NAME')}</b>
      </div>

      <div className={style.searchWrapper}>
        <UITextInput
          value={value}
          placeholder={t('ADMIN.SUBCATEGORY.NAME')}
          onChange={(e) => setValue(e.target.value)}
          maxLength={149}
          className={style.searchInput}
        />

        <UIButton
          handler={onAddSubcategories}
          label={t('ADMIN.SUB_CATEGORY.ADD_BUTTON')}
          icon={<PlusIcon />}
          disabled={!value.trim()}
          type="text"
        />
      </div>

      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {subcategories.map(({ name, active, id }, index) => (
                <Draggable key={id} draggableId={String(id)} index={index}>
                  {(prov) => (
                    <div
                      ref={prov.innerRef}
                      {...prov.draggableProps}
                      {...prov.dragHandleProps}
                    >
                      <AdminSubcategory
                        name={name}
                        active={Boolean(active)}
                        id={id}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default AdminUISubcategories
