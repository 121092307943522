/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  useState,
  useCallback,
  SyntheticEvent,
  useEffect,
  useMemo,
} from 'react'

import { Spin } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// import { ReactComponent as PlusIcon } from 'assets/images/common/redesign/plusCircle.svg'
import {
  AddSubscriptionBlock,
  AdminSettingsFreeTrialBlock,
  AdminSettingsInfoBlock,
  AdminSettingsPassword,
} from 'modules/admin/Settings'
import {
  clearErrors,
  getPlanSubscriptionsRequest,
  updateSettingsRequest,
} from 'store/Admin/Settings/Settings.action'
import { addAlert } from 'store/Alert'
import { IRootState } from 'store/Root.reducer'
import { UIButton } from 'ui'

import {
  AdminSettingsBlockSubscriptionsType,
  AdminSettingsBlockType,
  SETTINGS_PASSWORDS_INITIAL_STATE,
  // SETTINGS_SUBSCRIPTIONS_INITIAL_STATE,
} from './index.type'
import style from './style.module.scss'

// commented for future (many subs)
const ViewAdminSetting = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    isLoading,
    errorMessage,
    errors,
    subscriptions,
    settings: {
      reminder_subscription_plan_deprecated_status,
      reminder_subscription_plan_deprecated_time,
      subscription_plan_free_trial_details,
      subscription_plan_free_trial_days,
      subscription_plan_free_trial_description,
      subscription_plan_free_trial_name,
      subscription_plan_free_trial_status,
    },
  } = useSelector((state: IRootState) => state.adminSettings)
  const [passwordState, setPasswordState] = useState(
    SETTINGS_PASSWORDS_INITIAL_STATE,
  )
  const [firstBlockState, setFirstBlockState] = useState<
    AdminSettingsBlockType & AdminSettingsBlockSubscriptionsType
  >({
    isChecked: !!subscription_plan_free_trial_status,
    value: String(subscription_plan_free_trial_days),
    name: subscription_plan_free_trial_name,
    description: subscription_plan_free_trial_description,
    details: subscription_plan_free_trial_details,
  })
  const [secondBlockState, setSecondBlockState] =
    useState<AdminSettingsBlockType>({
      isChecked: !!reminder_subscription_plan_deprecated_status,
      value: String(reminder_subscription_plan_deprecated_time),
    })
  const [subscriptionsState, setSubscriptionsState] =
    useState<AdminSettingsBlockSubscriptionsType[]>(subscriptions)

  const subscriptionErrors = useMemo(() => {
    const newErrors: { [key: string]: { [field: string]: string[] } } = {}

    if (errors) {
      const keys = Object.keys(errors)

      keys.forEach((key: string) => {
        if (key.includes('subscription_plans')) {
          const [_, subIndex, fieldName] = key.split('.')

          newErrors[subIndex] = {
            ...newErrors[subIndex],
            [fieldName]: errors[key],
          }
        }
      })
    }

    return newErrors
  }, [errors])

  const handleSave = () => {
    if (!getBtnDisabled()) {
      dispatch(
        updateSettingsRequest({
          ...(passwordState.password && passwordState),
          subscription_plans: subscriptionsState
            .filter((item) => !item?.isRemoved)
            .map((item, index) => ({
              ...item,
              price_type: index,
            })),
          settings: {
            reminder_subscription_plan_deprecated_status: Number(
              secondBlockState.isChecked,
            ),
            reminder_subscription_plan_deprecated_time: Number(
              secondBlockState.value,
            ),
            subscription_plan_free_trial_days: Number(firstBlockState.value),
            subscription_plan_free_trial_status: Number(
              firstBlockState.isChecked,
            ),
            subscription_plan_free_trial_description:
              firstBlockState.description,
            subscription_plan_free_trial_details: firstBlockState.details,
            subscription_plan_free_trial_name: firstBlockState.name,
          },
        }),
      )
    }
  }

  // const handleRemoveClick = useCallback(
  //   (index: number) => () => {
  //     setSubscriptionsState((prevState) => {
  //       const newState = [...prevState]

  //       newState[index].isRemoved = !newState[index]?.isRemoved

  //       return newState
  //     })
  //   },
  //   [],
  // )

  const handleChecked = useCallback(
    (value: boolean) =>
      setSecondBlockState((prevState) => ({ ...prevState, isChecked: value })),
    [],
  )

  const handleChange = useCallback((event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement

    setSecondBlockState((prevState) => ({
      ...prevState,
      value: Number(value) < 0 ? '0' : value,
    }))
  }, [])

  const handleChangeSubscriptions = useCallback(
    (index: number) => (value: AdminSettingsBlockSubscriptionsType) => {
      setSubscriptionsState((prevState) => {
        const newState = [...prevState]

        newState[index] = value

        return newState
      })
    },
    [],
  )

  // const handleAddSub = () => {
  //   setSubscriptionsState((prevState) => [
  //     SETTINGS_SUBSCRIPTIONS_INITIAL_STATE,
  //     ...prevState,
  //   ])
  // }

  function getBtnDisabled() {
    if (
      passwordState.password ||
      passwordState.password_confirmation ||
      passwordState.password_old
    ) {
      return !(
        passwordState.password &&
        passwordState.password_confirmation &&
        passwordState.password_old
      )
    }

    return false
  }

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(clearErrors())
    }
  }, [errorMessage, dispatch])

  useEffect(() => {
    dispatch(getPlanSubscriptionsRequest())
  }, [dispatch])

  useEffect(() => {
    if (subscriptions) setSubscriptionsState(subscriptions)
  }, [subscriptions])

  useEffect(() => {
    setFirstBlockState({
      isChecked: !!subscription_plan_free_trial_status,
      value: String(subscription_plan_free_trial_days),
      description: subscription_plan_free_trial_description,
      details: subscription_plan_free_trial_details,
      name: subscription_plan_free_trial_name,
    })
  }, [
    subscription_plan_free_trial_status,
    subscription_plan_free_trial_days,
    subscription_plan_free_trial_details,
    subscription_plan_free_trial_description,
    subscription_plan_free_trial_name,
  ])

  useEffect(() => {
    setSecondBlockState({
      isChecked: !!reminder_subscription_plan_deprecated_status,
      value: String(reminder_subscription_plan_deprecated_time),
    })
  }, [
    reminder_subscription_plan_deprecated_status,
    reminder_subscription_plan_deprecated_time,
  ])

  return (
    <Content className={style.settingsPage}>
      <h1>{t('COMMON.SETTINGS')}</h1>

      <div className={style.contentWrapper}>
        <Spin spinning={isLoading}>
          <AdminSettingsPassword
            errors={errors}
            passwordState={passwordState}
            setPasswordState={setPasswordState}
          />

          <AdminSettingsFreeTrialBlock
            firstBlockState={firstBlockState}
            setFirstBlockState={setFirstBlockState}
          />

          <AdminSettingsInfoBlock
            description={t('ADMIN.SETTINGS.DESCRIPTION_2')}
            isChecked={secondBlockState.isChecked}
            onChange={handleChange}
            onChecked={handleChecked}
            title={t('ADMIN.SETTINGS.TITLE_2')}
            value={secondBlockState.value}
          />

          <div className={style.list}>
            {/* <UIButton
              handler={handleAddSub}
              label={t('COMMON.ADD_SUBSCRIPTION')}
              icon={<PlusIcon />}
              type="text"
            /> */}

            {subscriptionsState.map((item, index) => (
              <AddSubscriptionBlock
                key={item.id || `id_${index}`}
                // onRemove={handleRemoveClick(index)}
                onChange={handleChangeSubscriptions(index)}
                errors={subscriptionErrors[index]}
                {...item}
              />
            ))}
          </div>

          <UIButton
            label={t('COMMON.SAVE')}
            handler={handleSave}
            disabled={getBtnDisabled()}
            className={style.saveButton}
          />
        </Spin>
      </div>
    </Content>
  )
}

export default ViewAdminSetting
