import { Dispatch, SetStateAction } from 'react'

import i18n from 'lib/i18n/i18n'
import BlogArticlecContent from 'modules/admin/Blog/Articles/Content'
import BlogArticlesSeo from 'modules/admin/Blog/Articles/Seo'

import { ValuesType } from './index.type'

export const tabs = (
  values: ValuesType,
  setValues: Dispatch<SetStateAction<ValuesType>>,
) => [
  {
    name: `${i18n.t('ADMIN.BLOG.CONTENT')}`,
    element: <BlogArticlecContent values={values} setValues={setValues} />,
  },
  {
    name: i18n.t('ADMIN.BLOG.SEO'),
    element: <BlogArticlesSeo values={values} setValues={setValues} />,
  },
]

export const INITIAL_VALUES = {
  name: '',
  category: '',
  cnc: '',
  title: '',
  h1: '',
  description: '',
  keywords: '',
  added: '',
  announcement: '',
  timeAdded: '',
  dateAdded: '',
}
