import { FC, memo, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { UICustomCard, UISelect } from 'ui'
import Switch from 'ui/Switch'
import { TIMEZONES } from 'utils'

import { PropsType } from './index.type'
import ProfileSalonBusinessHoursList from './List'
import styles from './style.module.scss'

const ProfileSalonBusinessHours: FC<PropsType> = ({
  customize,
  setCustomize,
  values,
  setValues,
  setIsHourError,
}) => {
  const { t } = useTranslation()

  const handleChangeOption = (value: string) => {
    setValues((prevState) => ({
      ...prevState,
      timezone: Number(value),
    }))
  }

  const timezoneOptions = useMemo(
    () =>
      TIMEZONES.map((item, index) => ({
        label: item,
        value: index,
      })),
    [],
  )

  return (
    <UICustomCard
      title={
        <>
          <span>4. </span>
          {t('MANAGER.PROFILE_SALON.ITEM_4.0')}
        </>
      }
      titleRight={
        <div className={styles.wrapperUnified}>
          <div className={styles.unified}>
            {t('MANAGER.PROFILE_SALON.UNIFIED')}
          </div>

          <Switch
            checked={customize}
            className={styles.switch}
            onChange={(e) => setCustomize(e)}
          />
          <div className={styles.customizeTitle}>
            {t('MANAGER.PROFILE_SALON.CUSTOMIZE')}{' '}
          </div>
        </div>
      }
    >
      <UISelect
        options={timezoneOptions}
        value={typeof values.timezone === 'number' ? values.timezone : ''}
        label={t('COMMON.TIME_ZONE')}
        onChange={handleChangeOption}
        className={styles['timzone-select']}
      />

      <ProfileSalonBusinessHoursList
        values={values}
        setValues={setValues}
        setIsHourError={setIsHourError}
        customize={customize}
        setCustomize={setCustomize}
      />
    </UICustomCard>
  )
}

export default memo(ProfileSalonBusinessHours)
