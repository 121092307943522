export const INITIAL_VALUES = {
  notification: false,
  notification_duration: null,
  feedback: false,
  feedback_duration: null,
  status: 0,
  answer: '',
  url: '',
  timeslot_duration: 5,
}
