import {
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState,
  useCallback,
} from 'react'

import { EventClickArg } from '@fullcalendar/react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { ReactComponent as DeleteSVG } from 'assets/images/common/redesign/close.svg'
import { ValidationErrorType } from 'entities/manager/appointments'
import useResponsive from 'hooks/useResponsive'
import {
  resetAvailableTime,
  resetValidationError,
} from 'store/Manager/Appointments/Appointments.action'
import { IRootState } from 'store/Root.reducer'
import { FORMAT_DATE_WITH_YEAR } from 'utils'
import { DateHelper } from 'utils/helpers'

import styles from './style.module.scss'
import { ValuesType } from '../index.type'

type PropsType = {
  setEventData?: Dispatch<EventClickArg | null>
  setEventDate?: Dispatch<SetStateAction<Date>>
  setIsBack: (arg: boolean) => void
  setIsDrawer: (arg: boolean) => void
  setValues: Dispatch<SetStateAction<ValuesType>>
}

const AppointmentSidearConflicts: FC<PropsType> = ({
  setIsBack,
  setIsDrawer,
  setValues,
  setEventData,
  setEventDate,
}) => {
  const { appointmentsError } = useSelector(
    (state: IRootState) => state.appointments,
  )

  const [activeError, setActiveError] = useState<number | null>(null)

  const { t } = useTranslation()
  const { isMobile, isTablet } = useResponsive()
  const dispatch = useDispatch()

  const headerErrorClick = useCallback(
    (item: ValidationErrorType) => {
      setActiveError(item.id)
      if (isMobile || isTablet) {
        setIsBack(true)
      }

      setValues({
        from: `${item?.from}T00:00:00`,
        staff_id: item?.staff?.id,
        staffName: item?.staff?.name,
        service_id: item?.service?.id,
        finished_at: item?.finished_at.slice(0, 5),
        started_at: item?.started_at.slice(0, 5),
        id: item?.id,
        client_id: item?.client?.id,
        price: item.service?.price?.price,
        serviceDuration: item?.service?.duration,
        contact_phone: item?.contact?.phone.slice(
          3,
          item.contact?.phone.length,
        ),
        contact_name: item?.contact?.name,
      })
      dispatch(resetAvailableTime())
    },
    [isMobile, isTablet, setValues, dispatch, setIsBack],
  )

  const renderErrorBlock = useMemo(
    () =>
      appointmentsError.map((item) => (
        <div
          className={classNames(styles.item, {
            [styles.active]: activeError === item.id,
          })}
          key={item?.id}
          onClick={() => headerErrorClick(item)}
        >
          <div className={styles.dateWrap}>
            <p className={styles.titleWrapp}>
              <span className={styles.label}>{t('MANAGER.STAFF.DATE')}</span>
              <span className={styles.title}>
                {DateHelper.toFormat(
                  `${item?.from}T00:00:00`,
                  FORMAT_DATE_WITH_YEAR,
                )}
              </span>
            </p>
            <p className={styles.titleWrapp}>
              <span className={styles.label}>{t('MANAGER.STAFF.TIME')}</span>
              <span className={styles.title}>
                {item?.started_at.slice(0, 5)} - {item?.finished_at.slice(0, 5)}
              </span>
            </p>
          </div>
          <p className={styles.titleWrapp}>
            <span className={styles.label}>{t('COMMON.MASTER')}</span>
            {item?.staff?.name}
          </p>
          <p className={styles.titleWrapp}>
            <span className={styles.label}>{t('COMMON.CLIENTS')}</span>
            <span className={styles.title}>
              {item?.client?.name || item?.contact?.name}
            </span>
          </p>
          <p className={styles.titleWrapp}>
            <span className={styles.label}>{t('COMMON.PROCEDURE')}</span>
            <span className={styles.title}> {item?.service?.name}</span>
          </p>
        </div>
      )),
    [activeError, headerErrorClick, t, appointmentsError],
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainTitle}>
        <h2>{t('MANAGER.STAFF.CONFLICT_APPOINTMENT')}</h2>
        {(isTablet || isMobile) && (
          <span
            onClick={() => {
              setIsDrawer(false)
              dispatch(resetValidationError())
              if (setEventData && setEventDate) {
                setEventData(null)
                setEventDate(new Date())
              }
            }}
          >
            <DeleteSVG />
          </span>
        )}
      </div>
      <div className={styles.list}>{renderErrorBlock}</div>
    </div>
  )
}

export default AppointmentSidearConflicts
