import { FullMedia } from 'entities/common'
import { ServiceType, StaffType } from 'entities/manager/service'
import { CategoryType, SubCategoryType } from 'entities/view'
import { ValuesType } from 'modules/manager/services/EditService/index.type'
import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  GET_SERVICE_BY_CATEGORY_FAILURE,
  GET_SERVICE_BY_CATEGORY_REQUEST,
  GET_SERVICE_BY_CATEGORY_SUCCESS,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_ONE_SERVICE_REQUEST,
  GET_ONE_SERVICE_SUCCESS,
  GET_ONE_SERVICE_FAILURE,
  GET_SUB_CATEGORY_REQUEST,
  GET_SUB_CATEGORY_SUCCESS,
  GET_SUB_CATEGORY_FAILURE,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  GET_STAFF_FAILURE,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  SET_ACTIVE_CATEGORY,
  UPDATE_SERVICE_FAILURE,
  SET_IMAGE_LIST,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAILURE,
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_FAILURE,
  RESET_STORE,
  RESET_ERROR,
  GET_ALL_SERVICE_REQUEST,
  GET_ALL_SERVICE_SUCCESS,
  GET_ALL_SERVICE_FAILURE,
  GET_FULL_SERVICE_SUCCESS,
  RESET_CATEGORY,
  GET_FULL_CATEGORIES_SUCCESS,
  SET_CATEGORIES_WITHOUT_SUCCESS,
  GET_SERVICE_WITHOUT_CATEGORIES_REQUEST,
  GET_SERVICE_WITHOUT_CATEGORIES_SUCCESS,
  GET_SERVICE_WITHOUT_CATEGORIES_FAILURE,
  SET_MOBILE_TITLE_OPEN,
} from './Services.constant'

export const getServicesByCategoryRequest = (id: number, search?: string) => ({
  payload: { id, search },
  type: GET_SERVICE_BY_CATEGORY_REQUEST,
})

export const getServicesByCategorySuccess = (payload: ServiceType[]) => ({
  type: GET_SERVICE_BY_CATEGORY_SUCCESS,
  payload,
})

export const getServicesByCategoryFailure = () => ({
  type: GET_SERVICE_BY_CATEGORY_FAILURE,
})

export const getCategoriesRequest = (payload: string) => ({
  payload,
  type: GET_CATEGORIES_REQUEST,
})

export const getCategoriesSuccess = (payload: CategoryType[]) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload,
})

export const getFullCategoriesSuccess = (payload: CategoryType[]) => ({
  type: GET_FULL_CATEGORIES_SUCCESS,
  payload,
})

export const setCategoriesSuccess = (payload: boolean) => ({
  type: SET_CATEGORIES_WITHOUT_SUCCESS,
  payload,
})

export const getCategoriesFailure = () => ({
  type: GET_CATEGORIES_FAILURE,
})

export const getOneServiceRequest = (payload: number) => ({
  type: GET_ONE_SERVICE_REQUEST,
  payload,
})

export const getOneServiceSuccess = (payload: ServiceType) => ({
  type: GET_ONE_SERVICE_SUCCESS,
  payload,
})

export const getOneServiceFailure = () => ({
  type: GET_ONE_SERVICE_FAILURE,
})

export const getSubCategoryRequest = (payload: number) => ({
  type: GET_SUB_CATEGORY_REQUEST,
  payload,
})

export const getSubCategorySuccess = (payload: SubCategoryType[]) => ({
  type: GET_SUB_CATEGORY_SUCCESS,
  payload,
})

export const getSubCategoryFailure = () => ({
  type: GET_SUB_CATEGORY_FAILURE,
})

export const getStaffRequest = (payload?: number) => ({
  type: GET_STAFF_REQUEST,
  payload,
})

export const getStaffSuccess = (payload: StaffType[]) => ({
  type: GET_STAFF_SUCCESS,
  payload,
})

export const getStaffFailure = () => ({
  type: GET_STAFF_FAILURE,
})

export const updateServiceRequest = (payload: Partial<ValuesType>) => ({
  type: UPDATE_SERVICE_REQUEST,
  payload,
})

export const updateServiceSuccess = () => ({
  type: UPDATE_SERVICE_SUCCESS,
})

export const updateServiceFailure = (payload: ResponseFailureType) => ({
  payload,
  type: UPDATE_SERVICE_FAILURE,
})

export const setActiveCategory = (payload: number | null) => ({
  payload,
  type: SET_ACTIVE_CATEGORY,
})

export const setImageList = (payload: FullMedia[] | null) => ({
  payload,
  type: SET_IMAGE_LIST,
})

export const createServiceRequest = (payload: Partial<ValuesType>) => ({
  payload,
  type: CREATE_SERVICE_REQUEST,
})

export const createServiceSuccess = () => ({
  type: CREATE_SERVICE_SUCCESS,
})

export const createServiceFailure = (payload: ResponseFailureType) => ({
  type: CREATE_SERVICE_FAILURE,
  payload,
})

export const deleteServiceRequest = (payload?: number) => ({
  payload,
  type: DELETE_SERVICE_REQUEST,
})

export const deleteServiceSuccess = () => ({
  type: DELETE_SERVICE_SUCCESS,
})

export const deleteServiceFailure = (payload: ResponseFailureType) => ({
  payload,
  type: DELETE_SERVICE_FAILURE,
})

export const getAllServiceRequest = (search?: string) => ({
  type: GET_ALL_SERVICE_REQUEST,
  payload: search || '',
})

export const getAllServiceSuccess = (payload: ServiceType[]) => ({
  payload,
  type: GET_ALL_SERVICE_SUCCESS,
})

export const getAllServiceFailure = () => ({
  type: GET_ALL_SERVICE_FAILURE,
})

export const getFullServiceSuccess = (payload: ServiceType[]) => ({
  payload,
  type: GET_FULL_SERVICE_SUCCESS,
})

export const getServiceWithoutCategoryRequest = () => ({
  type: GET_SERVICE_WITHOUT_CATEGORIES_REQUEST,
})

export const getServiceWithoutCategorySuccess = (payload: ServiceType[]) => ({
  type: GET_SERVICE_WITHOUT_CATEGORIES_SUCCESS,
  payload,
})

export const getSecriceWithoutCategoryFailure = () => ({
  type: GET_SERVICE_WITHOUT_CATEGORIES_FAILURE,
})

export const setMobileTitleOpen = (payload: boolean) => ({
  payload,
  type: SET_MOBILE_TITLE_OPEN,
})

export const resetStore = () => ({
  type: RESET_STORE,
})

export const resetCategory = () => ({
  type: RESET_CATEGORY,
})

export const resetError = () => ({
  type: RESET_ERROR,
})
