import { FC, memo } from 'react'

import { useTranslation } from 'react-i18next'

import style from './style.module.scss'

type PropsType = { text: string }

const AdminSalonSectionSeven: FC<PropsType> = ({ text }) => {
  const { t } = useTranslation()

  return (
    <section className={style.seven}>
      <h2>7. {t('ADMIN.PROFILE_SALON.SECTION_7.TITLE')}</h2>

      <p>{text}</p>
    </section>
  )
}

export default memo(AdminSalonSectionSeven)
