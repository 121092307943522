import React, { FC, useCallback, useEffect } from 'react'

import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { ReactComponent as CloseCircleIcon } from 'assets/images/common/closeCircle.svg'
import { ReactComponent as ExclamationCircleIcon } from 'assets/images/common/exclamation-circle.svg'
import { NotificationType } from 'entities/notification'
import { removeAlert } from 'store/Alert'
import { ALERT_TIMEOUT } from 'utils'

import style from './style.module.scss'

const CustomAlert: FC<NotificationType & { index: number }> = ({
  id,
  message,
  duration = ALERT_TIMEOUT,
  type = 'success',
  index,
}) => {
  const dispatch = useDispatch()

  const classes = classNames(style.customAlert, {
    [style.success]: type === 'success',
    [style.warning]: type === 'warning',
    [style.error]: type === 'error',
    [style.info]: type === 'info',
  })

  const handleClose = useCallback(() => {
    dispatch(removeAlert(id))
  }, [dispatch, id])

  useEffect(() => {
    setTimeout(() => {
      handleClose()
    }, ALERT_TIMEOUT)
  }, [duration, handleClose])

  return (
    <div
      className={classes}
      style={{
        top: index * 50,
        zIndex: index,
      }}
    >
      <ExclamationCircleIcon className={style.exclamation} />
      {Array.isArray(message) ? (
        <div className={style.getError}>
          {message.map((text, messageIndex) => (
            <div key={messageIndex}>{text}</div>
          ))}
        </div>
      ) : (
        <p>{message}</p>
      )}

      <CloseCircleIcon className={style.close} onClick={handleClose} />
    </div>
  )
}

export default React.memo(CustomAlert)
