import { FC } from 'react'

import { ReactComponent as NotificationSVG } from 'assets/images/common/redesign/notificationCircle.svg'

import styles from './style.module.scss'

type PropsType = {
  text: string
}

const DocumentNotification: FC<PropsType> = ({ text }) => {
  return (
    <div className={styles.wrapper}>
      <div>
        <NotificationSVG />
      </div>
      {text}
    </div>
  )
}

export default DocumentNotification
