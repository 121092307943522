import {
  CLEAR_SUPPORT_MANAGER_ERRORS,
  SEND_SUPPORT_MANAGER_MESSAGE_REQUEST,
  SEND_SUPPORT_MANAGER_MESSAGE_FAILURE,
  SEND_SUPPORT_MANAGER_MESSAGE_SUCCESS,
} from './Support.constant'
import { IManagerSupportState, SupportActionTypes } from './Support.type'

const initialState: IManagerSupportState = {
  errorMessage: null,
  errors: null,
  isLoading: false,
  isSended: false,
}

export default function supportManagerReducer(
  state: IManagerSupportState = initialState,
  action: SupportActionTypes,
): IManagerSupportState {
  switch (action.type) {
    case SEND_SUPPORT_MANAGER_MESSAGE_REQUEST:
      return { ...state, isLoading: true, isSended: false }
    case SEND_SUPPORT_MANAGER_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSended: true,
      }
    case SEND_SUPPORT_MANAGER_MESSAGE_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    case CLEAR_SUPPORT_MANAGER_ERRORS:
      return {
        ...state,
        errorMessage: null,
        errors: null,
        isSended: false,
      }
    default:
      return { ...state }
  }
}
