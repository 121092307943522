import { useLayoutEffect, useState } from 'react'

export default function useWindowSize() {
  const [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    const updateSize = () => setSize([window.outerWidth, window.outerHeight])

    window.addEventListener('resize', updateSize)
    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return { width: size[0], height: size[1] }
}
