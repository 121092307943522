import { FC, memo } from 'react'

import cn from 'classnames'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AdminNotificationStatus } from 'entities/common'
import { AdminNotificationsDataType } from 'store/Admin/Notifications/Notifications.type'
import { UIAvatar } from 'ui'
import { ADMIN_SALONS_PROFILE } from 'utils'

import styles from './style.module.scss'

const NotificationTableRow: FC<AdminNotificationsDataType> = ({
  created_at,
  type,
  is_read,
  data,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleRowClick = () => {
    navigate(ADMIN_SALONS_PROFILE.replace(':id', String(data?.user?.id)))
  }

  const getAdminNotificationType = (): string => {
    switch (type) {
      case AdminNotificationStatus.ADDED:
        return t('ADMIN.NOTIFICATION.ADDED_TYPE')
      case AdminNotificationStatus.UPDATED:
        return t('ADMIN.NOTIFICATION.UPDATED_TYPE')
      default:
        return '-'
    }
  }

  return (
    <tr className={styles.wrapper} onClick={handleRowClick}>
      <td>
        <div className={cn(styles.unread, { [styles.active]: !is_read })}></div>
      </td>

      <td>
        <div className={styles.avatar}>
          <UIAvatar size={50} src={data?.user?.avatar?.path} />

          <p>{data?.user?.name}</p>
        </div>
      </td>

      <td>
        <p className={styles.type}>{getAdminNotificationType()}</p>
      </td>

      <td className={styles.date}>
        {format(new Date(created_at), 'dd.MM.yyyy HH:mm')}
      </td>
    </tr>
  )
}

export default memo(NotificationTableRow)
