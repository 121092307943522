/* eslint-disable no-unsafe-optional-chaining */
import { FC, useCallback } from 'react'

import { Popover } from 'antd'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PencilSVG } from 'assets/images/common/redesign/pencil.svg'
import { UIButton } from 'ui'

import { PropsType } from './index.type'
import { ExtendedPropsType } from '../index.type'
import styles from '../style.module.scss'

const StaffCalendarPopover: FC<PropsType> = ({
  eventInfo,
  openEditWorkingHours,
  setStaffId,
  openAddTimeOff,
}) => {
  const { t } = useTranslation()

  const extendedProps = eventInfo.resource?.extendedProps

  const renderBtn = useCallback(
    (id: number) => {
      return (
        <div className={styles.content}>
          <UIButton
            type="text"
            handler={() => {
              openEditWorkingHours(id)
              setStaffId(id)
            }}
            label={t('MANAGER.STAFF.EDIT_HOURS')}
          />

          <UIButton
            type="text"
            className={styles.button}
            handler={() => {
              setStaffId(id)
              openAddTimeOff(id)
            }}
            label={t('MANAGER.STAFF.ADD_TIME_OFF')}
          />
        </div>
      )
    },
    [openAddTimeOff, openEditWorkingHours, setStaffId, t],
  )

  const renderTimeWork = useCallback(
    (data: ExtendedPropsType) => {
      const renderTime = (title: string, name: 'week' | 'month') => {
        return (
          <p>
            {`${title} ${
              data?.duration?.[name].work ? data?.duration?.[name].work : ''
            }`}
          </p>
        )
      }

      return (
        <div className={styles.time}>
          {renderTime(t('MANAGER.STAFF.WEEK'), 'week')}
          {renderTime(t('MANAGER.STAFF.MONTH'), 'month')}
        </div>
      )
    },
    [t],
  )

  return (
    <div className={styles.item}>
      <div className={styles.nameWrap}>
        <Popover
          content={() => renderBtn(+eventInfo?.resource.id)}
          trigger="click"
        >
          <div className={styles.name}>{extendedProps?.name}</div>

          <PencilSVG className={styles.edit} />
        </Popover>
      </div>
      <div className={styles.wrapperTime}>
        {renderTimeWork(extendedProps as ExtendedPropsType)}
      </div>
    </div>
  )
}

export default StaffCalendarPopover
