import { useState, FC, SyntheticEvent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as SearchSVG } from 'assets/images/common/redesign/search.svg'
import useDebounce from 'hooks/useDebounce'
import UITextInput from 'ui/TextInput'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const FeedbackSearch: FC<PropsType> = ({ searchValue, onSearchChange }) => {
  const { t } = useTranslation()
  const [state, setState] = useState(searchValue)
  const debouncedValue = useDebounce(state, 500)

  const handleChange = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement

    setState(value)
  }

  useEffect(() => {
    onSearchChange(debouncedValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  useEffect(() => {
    setState(searchValue)
  }, [searchValue])

  return (
    <div className={styles.wrapper}>
      <UITextInput
        isSearch
        icon={<SearchSVG />}
        value={state}
        onChange={handleChange}
        placeholder={t('COMMON.SEARCH')}
      />
    </div>
  )
}

export default FeedbackSearch
