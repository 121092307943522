import { Dispatch, FC, SetStateAction, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import useResponsive from 'hooks/useResponsive'
import { UIButton } from 'ui'

import styles from './style.module.scss'
import { BaseFormat } from '../index.type'
import { useDispatch } from 'react-redux'
import { getExportContactsRequest } from 'store/Manager/Clients/Clients.action'

type PropsType = {
  setTypeTab: Dispatch<SetStateAction<BaseFormat>>
  typeTab: BaseFormat
}

const ClientSidebarExport: FC<PropsType> = ({ setTypeTab, typeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isMobile, isTablet } = useResponsive()

  useEffect(() => {
    dispatch(getExportContactsRequest(typeTab))
  }, [dispatch, typeTab])

  return (
    <div className={styles.wrapper}>
      <p>{t('MANAGER.CLIENTS.EXPORT_INFO')}</p>
      <div className={styles.btn}>
        <UIButton
          label={
            isMobile || isTablet
              ? t('MANAGER.CLIENTS.XLS')
              : t('MANAGER.CLIENTS.XLS_FORMAT')
          }
          handler={() => setTypeTab(BaseFormat.XLS)}
          type={typeTab === BaseFormat.XLS ? 'primary' : 'outline'}
        />
        <UIButton
          label={
            isMobile || isTablet
              ? t('MANAGER.CLIENTS.CSV')
              : t('MANAGER.CLIENTS.CSV_FORMAT')
          }
          handler={() => setTypeTab(BaseFormat.CSV)}
          type={typeTab === BaseFormat.CSV ? 'primary' : 'outline'}
        />
      </div>
    </div>
  )
}

export default ClientSidebarExport
