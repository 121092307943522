import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_CUSTOMER_ERROR,
  GET_ADMIN_CURRENT_CUSTOMER_REQUEST,
  GET_ADMIN_CURRENT_CUSTOMER_FAILURE,
  GET_ADMIN_CURRENT_CUSTOMER_SUCCESS,
  GET_ADMIN_CUSTOMERS_REQUEST,
  GET_ADMIN_CUSTOMERS_FAILURE,
  GET_ADMIN_CUSTOMERS_SUCCESS,
  SET_ADMIN_CURRENT_CUSTOMER_REQUEST,
  SET_ADMIN_CURRENT_CUSTOMER_FAILURE,
  SET_ADMIN_CURRENT_CUSTOMER_SUCCESS,
} from './Customer.constant'
import {
  AdminCustomerActionsTypes,
  AdminCustomerState,
  IAdminCustomerResponseData,
} from './Customer.type'

const initialState: AdminCustomerState = {
  currentCustomer: {} as IAdminCustomerResponseData,
  errors: {},
  errorMessage: null,
  isLoading: false,
  isLockLoading: false,
  data: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
}

export default function adminCustomerReducer(
  state = initialState,
  action: AdminCustomerActionsTypes,
): AdminCustomerState {
  switch (action.type) {
    case GET_ADMIN_CUSTOMERS_REQUEST:
    case GET_ADMIN_CURRENT_CUSTOMER_REQUEST:
      return { ...state, isLoading: true }
    case SET_ADMIN_CURRENT_CUSTOMER_REQUEST:
      return { ...state, isLockLoading: true }
    case GET_ADMIN_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload?.data,
        meta: {
          page: action.payload?.meta?.current_page,
          perPage: action.payload?.meta?.per_page,
          total: action.payload?.meta?.total,
        },
      }
    case GET_ADMIN_CURRENT_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentCustomer: action.payload,
      }
    case GET_ADMIN_CUSTOMERS_FAILURE:
    case GET_ADMIN_CURRENT_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case SET_ADMIN_CURRENT_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLockLoading: false,
        currentCustomer: {
          ...state.currentCustomer,
          active: action.payload,
        },
      }
    case SET_ADMIN_CURRENT_CUSTOMER_FAILURE:
      return {
        ...state,
        isLockLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case CLEAR_CUSTOMER_ERROR:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    default:
      return { ...state }
  }
}
