import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import i18n from 'lib/i18n/i18n'
import { ServicesHttp } from 'services/http'
import { addAlert } from 'store/Alert'
import { AlertHelper } from 'utils/helpers'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  getServicesByCategorySuccess,
  getServicesByCategoryFailure,
  getCategoriesSuccess,
  getCategoriesFailure,
  getOneServiceSuccess,
  getSubCategorySuccess,
  getSubCategoryFailure,
  getStaffSuccess,
  getStaffFailure,
  updateServiceFailure,
  deleteServiceFailure,
  createServiceFailure,
  createServiceSuccess,
  updateServiceSuccess,
  deleteServiceSuccess,
  getAllServiceSuccess,
  getAllServiceFailure,
  getFullServiceSuccess,
  getFullCategoriesSuccess,
  setCategoriesSuccess,
  getSecriceWithoutCategoryFailure,
  getServiceWithoutCategorySuccess,
} from './Services.action'
import {
  GET_SERVICE_BY_CATEGORY_REQUEST,
  GET_CATEGORIES_REQUEST,
  GET_ONE_SERVICE_REQUEST,
  GET_SUB_CATEGORY_REQUEST,
  GET_STAFF_REQUEST,
  UPDATE_SERVICE_REQUEST,
  DELETE_SERVICE_REQUEST,
  CREATE_SERVICE_REQUEST,
  GET_ALL_SERVICE_REQUEST,
  GET_SERVICE_WITHOUT_CATEGORIES_REQUEST,
} from './Services.constant'
import {
  IGetServicesResponse,
  IGetServicesByCategoryRequest,
  IGetOneServicesRequest,
  IGetOneServiceResponse,
  IGetSubCategoryResponse,
  IGetSubCategoryRequest,
  IGetStaffRequest,
  IGetStaffResponse,
  IUpdateServiceRequest,
  IDeleteServiceRequest,
  ICreateServiceRequest,
  IGetCategoriesResponse,
  IGetCategoriesRequest,
  IGetFontCategoriesResponse,
  IGetAllServiceResponse,
  IGetAllServiceRequest,
} from './Services.type'
import { ValuesToRequestType } from '../../../modules/manager/services/EditService/index.type'

function* workerGetServices({ payload }: IGetServicesByCategoryRequest) {
  try {
    const { data }: { data: IGetServicesResponse } = yield call(
      ServicesHttp.getServices,
      payload.id,
      payload.search || '',
    )

    yield put(getServicesByCategorySuccess(data.data))
    yield put(getAllServiceSuccess(data.data))
  } catch (error) {
    yield put(getServicesByCategoryFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetServices() {
  yield takeLatest(GET_SERVICE_BY_CATEGORY_REQUEST, workerGetServices)
}

function* workerGetCategories({ payload }: IGetCategoriesRequest) {
  try {
    const { data } = yield call(ServicesHttp.getCategory, payload)

    const {
      data: { without },
    } = data as IGetFontCategoriesResponse

    yield put(setCategoriesSuccess(without))

    if (payload === 'profile') {
      const {
        data: { items },
      } = data as IGetFontCategoriesResponse

      yield put(getCategoriesSuccess(items))
    } else {
      const res = data as IGetCategoriesResponse

      yield put(getFullCategoriesSuccess(res.data))
    }
  } catch (error) {
    yield put(getCategoriesFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetCategories() {
  yield takeLatest(GET_CATEGORIES_REQUEST, workerGetCategories)
}

function* workerGetOneService({ payload }: IGetOneServicesRequest) {
  try {
    const { data }: { data: IGetOneServiceResponse } = yield call(
      ServicesHttp.getOneService,
      payload,
    )

    yield put(getOneServiceSuccess(data.data))
  } catch (error) {
    yield put(getCategoriesFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetOneService() {
  yield takeLatest(GET_ONE_SERVICE_REQUEST, workerGetOneService)
}

function* workerGetSubCategory({ payload }: IGetSubCategoryRequest) {
  try {
    const { data }: { data: IGetSubCategoryResponse } = yield call(
      ServicesHttp.getSubCategory,
      payload,
    )

    yield put(getSubCategorySuccess(data.data))
  } catch (error) {
    yield put(getSubCategoryFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetSubCategory() {
  yield takeLatest(GET_SUB_CATEGORY_REQUEST, workerGetSubCategory)
}

function* workerGetStaff({ payload }: IGetStaffRequest) {
  try {
    const { data }: { data: IGetStaffResponse } = yield call(
      ServicesHttp.getStaff,
      payload,
    )

    yield put(getStaffSuccess(data.data))
  } catch (error) {
    yield put(getStaffFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetStaff() {
  yield takeLatest(GET_STAFF_REQUEST, workerGetStaff)
}

function* workerUpdateService({ payload }: IUpdateServiceRequest) {
  try {
    const { id, category, ...newPayload } = payload
    const dataToRequest: ValuesToRequestType = {
      ...newPayload,
      category_id: Number(payload.category?.id),
      sections: payload.sections?.map((i) => +i.id),
    }

    if (id) {
      yield call(ServicesHttp.updateService, dataToRequest, Number(payload.id))
    }
    yield put(updateServiceSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(updateServiceFailure, error as string)
  }
}

function* watchUpdateService() {
  yield takeLatest(UPDATE_SERVICE_REQUEST, workerUpdateService)
}

function* workerDeleteService({ payload }: IDeleteServiceRequest) {
  try {
    yield call(ServicesHttp.deleteService, payload)
    yield put(deleteServiceSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_DELETE'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(deleteServiceFailure, error as string)
  }
}

function* watchDeleteService() {
  yield takeLatest(DELETE_SERVICE_REQUEST, workerDeleteService)
}

function* workerCreateService({ payload }: ICreateServiceRequest) {
  try {
    const { id, category, ...newPayload } = payload
    const dataToRequest: ValuesToRequestType = {
      ...newPayload,
      category_id: Number(payload.category?.id),
      sections: payload.sections?.map((i) => +i.id),
    }

    yield call(ServicesHttp.createService, dataToRequest)

    yield put(createServiceSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_SAVED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(createServiceFailure, error as string)
  }
}

function* watchCreateService() {
  yield takeLatest(CREATE_SERVICE_REQUEST, workerCreateService)
}

function* workerGetAllService({ payload }: IGetAllServiceRequest) {
  try {
    const { data }: { data: IGetAllServiceResponse } = yield call(
      ServicesHttp.getAllServices,
      payload,
    )

    yield put(getAllServiceSuccess(data.data))
    yield put(getFullServiceSuccess(data.data))
  } catch (error) {
    yield put(getAllServiceFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetAllService() {
  yield takeLatest(GET_ALL_SERVICE_REQUEST, workerGetAllService)
}

function* workerGetServiceWithoutCategories() {
  try {
    const { data }: { data: IGetServicesResponse } = yield call(
      ServicesHttp.getWithoutCategory,
    )

    yield put(getServiceWithoutCategorySuccess(data.data))
  } catch (error) {
    yield put(getSecriceWithoutCategoryFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetServiceWithoutCategories() {
  yield takeLatest(
    GET_SERVICE_WITHOUT_CATEGORIES_REQUEST,
    workerGetServiceWithoutCategories,
  )
}

export const servicesWatchers: ForkEffect[] = [
  fork(watchGetServices),
  fork(watchGetCategories),
  fork(watchGetOneService),
  fork(watchGetSubCategory),
  fork(watchGetStaff),
  fork(watchUpdateService),
  fork(watchDeleteService),
  fork(watchCreateService),
  fork(watchGetAllService),
  fork(watchGetServiceWithoutCategories),
]
