import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_MANAGER_NOTIFICATION,
  GET_MANAGER_NOTIFICATIONS_FAILURE,
  GET_MANAGER_NOTIFICATIONS_REQUEST,
  GET_MANAGER_NOTIFICATIONS_SUCCESS,
  CLEAR_MANAGER_NOTIFICATION_ERROR,
  SET_MANAGER_NOTIFICATIONS,
} from './Notifications.constant'
import {
  INotificationState,
  NotificationActionTypes,
} from './Notifications.type'

const initialState: INotificationState = {
  notification: {},
  errors: {},
  errorMessage: null,
  isLoading: false,
}

export default function notificationReducer(
  state = initialState,
  action: NotificationActionTypes,
): INotificationState {
  switch (action.type) {
    case GET_MANAGER_NOTIFICATIONS_REQUEST:
      return { ...state, isLoading: true }
    case GET_MANAGER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: true,
        notification: action.payload,
      }
    case GET_MANAGER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }

    case SET_MANAGER_NOTIFICATIONS: {
      const el = state.notification[action.payload]

      return {
        ...state,
        notification: {
          ...state.notification,
          [action.payload]: el && typeof el === 'number' ? el + 1 : 1,
        },
      }
    }

    case CLEAR_MANAGER_NOTIFICATION:
      return {
        ...initialState,
      }
    case CLEAR_MANAGER_NOTIFICATION_ERROR:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    default:
      return { ...state }
  }
}
