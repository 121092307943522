import { ValuesToRequestType } from 'modules/manager/services/EditService/index.type'
import { getQueryString } from 'utils/helpers/query'
import { GET, POST, DELETE } from 'utils/http'

import { QUERY_PARAMS } from '../../../utils/constants'
import axios, { CancelTokenSource } from 'axios'

let axiosSource: CancelTokenSource | null = null

const basePath = '/service'

export const getServices = async (id: number, search: string) => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(
    `${basePath}/category/${id}${getQueryString({
      [QUERY_PARAMS.SEARCH]: search,
    })}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  )

  return data
}

export const getAllServices = async (search = '') => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(
    `${basePath}${getQueryString({
      [QUERY_PARAMS.SEARCH]: search,
    })}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  )

  return data
}

export const getCategory = (url: string) => GET(`/${url}/category`)

export const getWithoutCategory = () => GET('service/without-category')

export const getOneService = (id: number) => GET(`${basePath}/${id}`)

export const getSubCategory = (id: number) => GET(`category/${id}`)

export const getStaff = (id?: number) => GET(id ? `staff/${id}` : '/staff')

export const updateService = (payload: ValuesToRequestType, id: number) => {
  return POST(`${basePath}/${id}`, payload)
}

export const createService = (payload: ValuesToRequestType) => {
  return POST(`${basePath}`, payload)
}

export const deleteService = (id?: number) => DELETE(`${basePath}/${id}`)
