import { ReactComponent as ArrowIcon } from 'assets/images/common/redesign/arrowBottom.svg'
import i18n from 'lib/i18n/i18n'

export const columnsArticles = [
  { title: '' },
  { title: i18n.t('ADMIN.BLOG.IMAGE') },
  { title: i18n.t('COMMON.NAME'), icon: <ArrowIcon /> },
  { title: i18n.t('ADMIN.BLOG.CNC'), icon: <ArrowIcon /> },
  { title: i18n.t('ADMIN.BLOG.CATEGORY'), icon: <ArrowIcon /> },
  { title: i18n.t('COMMON.ADDED'), icon: <ArrowIcon /> },
  { title: i18n.t('COMMON.UPDATED'), icon: <ArrowIcon /> },
]

export const dataArticles = [
  {
    id: 1,
    image:
      'https://i.pinimg.com/originals/d5/2d/4d/d52d4d4e53edcec846d6681f06253ebb.jpg',
    name: 'How to send the best promotional texts this Black Friday',
    type: 'chshs-sdfs-sdsd-sds-d-s-sdxcwchshs-sdfs-sdsd-sa',
    category: 'Case studies Case studies',
    added: 'Wednesday 10:30',
    updated: '27 days ago',
  },
  {
    id: 2,
    image:
      'https://img1.akspic.ru/attachments/originals/3/8/0/2/0/102083-nacionalnyj_park-gora-pejzazhi_gor-fjord-lofoten-2560x1440.jpg',
    name: 'How to send the best promotional texts this Black Friday',
    type: 'chshs-sdfs-sdsd-sds-d-s-sdxcwchshs-sdfs-sdsd-sa',
    category: 'Case studies Case studies',
    added: 'Wednesday 10:30',
    updated: '27 days ago',
  },
  {
    id: 3,
    image: 'https://pbs.twimg.com/media/Eu6U3LuWYAIvRNj.jpg',
    name: 'How to send the best promotional texts this Black Friday',
    type: 'chshs-sdfs-sdsd-sds-d-s-sdxcwchshs-sdfs-sdsd-sa',
    category: 'Case studies Case studies',
    added: 'Wednesday 10:30',
    updated: '27 days ago',
  },
  {
    id: 4,
    image:
      'https://i.pinimg.com/originals/ae/89/e3/ae89e34032214aa0887ef96203f970dc.jpg',
    name: 'How to send the best promotional texts this Black Friday',
    type: 'chshs-sdfs-sdsd-sds-d-s-sdxcwchshs-sdfs-sdsd-sa',
    category: 'Case studies Case studies',
    added: 'Wednesday 10:30',
    updated: '27 days ago',
  },
]

export const columnsCategories = [
  { title: '' },
  { title: i18n.t('COMMON.NAME') },
  { title: i18n.t('ADMIN.BLOG.CREATE'), icon: <ArrowIcon /> },
  { title: i18n.t('COMMON.UPDATED'), icon: <ArrowIcon /> },
]

export const dataCategories = [
  {
    id: 1,
    name: 'How to send the best promotional texts this Black Friday',
    create: 'Wednesday 10:30',
    updated: '27 days ago',
  },
  {
    id: 2,
    name: 'How to send the best promotional texts this Black Friday',
    create: 'Wednesday 10:30',
    updated: '27 days ago',
  },
  {
    id: 3,
    name: 'How to send the best promotional texts this Black Friday',
    create: 'Wednesday 10:30',
    updated: '27 days ago',
  },
  {
    id: 4,
    name: 'How to send the best promotional texts this Black Friday',
    create: 'Wednesday 10:30',
    updated: '27 days ago',
  },
  {
    id: 5,
    name: 'How to send the best promotional texts this Black Friday',
    create: 'Wednesday 10:30',
    updated: '27 days ago',
  },
]
