import React, { FC, useEffect } from 'react'

import { redirectFromType } from 'entities/auth'
import { IUserInfo } from 'entities/unauthorized/Auth'
import { USER } from 'utils'
import { getCookie } from 'utils/helpers/cookie'

type PropsType = { path: string }

const Redirect: FC<PropsType> = ({ path }) => {
  useEffect(() => {
    const user = JSON.parse(getCookie(USER) || 'null')

    if (user) {
      redirectFromType((user as IUserInfo)?.type)

      return
    }

    if (path) {
      window.location.replace(path)
    }
  }, [path])

  return <></>
}

export default React.memo(Redirect)
