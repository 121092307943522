import { ResponseFailureType } from 'store/Auth/Auth.type'
import { ArrayHelper } from 'utils/helpers'

import {
  CLEAR_FEEDBACKS_ERRORS,
  EDIT_FEEDBACKS_REQUEST,
  EDIT_FEEDBACKS_FAILURE,
  EDIT_FEEDBACKS_SUCCESS,
  GET_FEEDBACKS_REQUEST,
  GET_FEEDBACKS_FAILURE,
  GET_FEEDBACKS_SUCCESS,
  SET_FEEDBACKS_REQUEST,
  SET_FEEDBACKS_FAILURE,
  SET_FEEDBACKS_SUCCESS,
} from './Feedbacks.constant'
import {
  FeedbacksActionTypes,
  IFeedbacksResponseData,
  IFeedbacksState,
} from './Feedbakcs.type'

const initialState: IFeedbacksState = {
  data: null,
  meta: {
    total: 0,
    page: 0,
    perPage: 0,
  },
  isLoading: false,
  isModalLoading: false,
  errors: {},
  errorMessage: null,
}

export default function feedbacksReducer(
  state = initialState,
  action: FeedbacksActionTypes,
): IFeedbacksState {
  switch (action.type) {
    case GET_FEEDBACKS_REQUEST:
      return { ...state, isLoading: true }
    case GET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        meta: {
          total: action.payload?.meta?.total || 0,
          perPage: action.payload?.meta?.per_page || 0,
          page: action.payload?.meta?.current_page || 0,
        },
      }
    case GET_FEEDBACKS_FAILURE:
      return {
        ...state,
        ...(action.payload as unknown as ResponseFailureType),
        isLoading: false,
      }
    case SET_FEEDBACKS_REQUEST:
    case EDIT_FEEDBACKS_REQUEST:
      return { ...state, isModalLoading: true }
    case SET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        isModalLoading: false,
        data: ArrayHelper.setKeyFromArray<IFeedbacksResponseData[]>(
          state.data || [],
          action.payload.review_id,
          'answer',
          action.payload,
        ),
      }
    case EDIT_FEEDBACKS_SUCCESS:
      return {
        ...state,
        isModalLoading: false,
        data: ArrayHelper.setKeyFromArray<IFeedbacksResponseData[]>(
          state.data || [],
          Number(action.payload.review_id),
          'answer',
          {
            answer_id: action.payload.answer_id,
            comment: action.payload.comment,
            created_at: action.payload.created_at,
          },
        ),
      }
    case SET_FEEDBACKS_FAILURE:
    case EDIT_FEEDBACKS_FAILURE:
      return {
        ...state,
        isModalLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case CLEAR_FEEDBACKS_ERRORS:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    default:
      return { ...state }
  }
}
