import { FC, memo } from 'react'

import { Spin } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line import/no-cycle
import { UICustomPagination } from 'ui'

import { PropsType } from './index.type'
import style from './style.module.scss'

const UIManagerTable: FC<PropsType> = ({
  columns,
  children,
  className = '',
  isLoading,
  perPage,
  total,
}) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(style.managerTable, className)}>
      <table>
        <thead>
          <tr>
            {columns.map(({ title, className: tableClass = '' }, index) => (
              <th key={index} className={tableClass}>
                {title}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={columns.length} className={style.loader}>
                <Spin size="large" />
              </td>
            </tr>
          )}

          {!isLoading && !children && (
            <tr>
              <td colSpan={columns.length} className={style.emptyTable}>
                {t('COMMON.EMPTY_TABLE')}
              </td>
            </tr>
          )}

          {!isLoading && children}
        </tbody>
      </table>

      {!!total && (
        <UICustomPagination
          perPage={perPage}
          total={total}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

export default memo(UIManagerTable)
