import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { CategoryType } from 'entities/view'
import i18n from 'lib/i18n/i18n'
import {
  editCategoryApi,
  editSubategoryApi,
  getCategoriesApi,
  getSubcategoriesApi,
  setCategoryApi,
  setSubcategoryApi,
  sortCategoriesApi,
  sortSubcategoriesApi,
} from 'services/http'
import { addAlert } from 'store/Alert'
import { AlertHelper, StringHelper } from 'utils/helpers'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  setActiveCategory,
  setCategories,
  setSubcategories,
  setNewCategory,
  setNewSubcategory,
  editCategorySuccess,
  setNewCategoryFailure,
  setNewSubcategoryRequestFailure,
  editCategoryFailure,
  editSubcategoryFailure,
  sortSubcategoriesFailure,
  sortCategoriesFailure,
  getCategoriesFailure,
} from './Categories.action'
import {
  EDIT_ADMIN_CATEGORY_REQUEST,
  EDIT_ADMIN_SUBCATEGORY,
  GET_ADMIN_CATEGORIES_REQUEST,
  GET_ADMIN_SUBCATEGORIES_REQUEST,
  SET_ADMIN_NEW_CATEGORY_REQUEST,
  SET_ADMIN_NEW_SUBCATEGORY_REQUEST,
  SORT_ADMIN_CATEGORIES_REQUEST,
  SORT_ADMIN_SUBCATEGORIES_REQUEST,
} from './Categories.constant'
import {
  IEditCategoryRequest,
  IEditCategoryResponse,
  IEditSubCategoryRequest,
  IGetCategoriesResponse,
  IGetSubcategories,
  IGetSubCategoriesResponse,
  INewCategoryRequest,
  INewSubcategoryRequest,
  ISetCategoryResponse,
  ISetSubCategoryResponse,
  ISortCategoriesRequest,
  ISortSubcategoriesRequest,
} from './Categories.type'

function* workerGetCategories(): unknown {
  try {
    const { data }: { data: IGetCategoriesResponse } = yield call(
      getCategoriesApi,
    )

    yield put(setCategories(data.data))

    if (data?.data?.length) {
      yield put(setActiveCategory(data.data[0].id))
    }
  } catch (error) {
    yield getRequestErrors(
      getCategoriesFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchGetCategories() {
  yield takeLatest(GET_ADMIN_CATEGORIES_REQUEST, workerGetCategories)
}

function* workerGetSubcategories({ payload }: IGetSubcategories): unknown {
  try {
    const { data }: { data: IGetSubCategoriesResponse } = yield call(
      getSubcategoriesApi,
      payload,
    )

    yield put(setSubcategories(data.data as unknown as CategoryType[]))
  } catch {
    yield put(AlertHelper.getError())
  }
}

function* watchGetSubategories() {
  yield takeLatest(GET_ADMIN_SUBCATEGORIES_REQUEST, workerGetSubcategories)
}

function* workerSetNewCategory({ payload }: INewCategoryRequest): unknown {
  try {
    const { data }: { data: ISetCategoryResponse } = yield call(
      setCategoryApi,
      payload,
    )

    yield put(setNewCategory(data.data))
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_SAVED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error: unknown) {
    yield getRequestErrors(
      setNewCategoryFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchSetNewCategory() {
  yield takeLatest(SET_ADMIN_NEW_CATEGORY_REQUEST, workerSetNewCategory)
}

function* workerEditCategory({ payload }: IEditCategoryRequest): unknown {
  try {
    const { data }: { data: IEditCategoryResponse } = yield call(
      editCategoryApi,
      payload,
    )

    yield put(editCategorySuccess(data.data))
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(
      editCategoryFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchEditCategory() {
  yield takeLatest(EDIT_ADMIN_CATEGORY_REQUEST, workerEditCategory)
}

function* workerSortCategories({ payload }: ISortCategoriesRequest): unknown {
  try {
    yield call(sortCategoriesApi, payload)
  } catch (error) {
    yield getRequestErrors(
      sortCategoriesFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchSortCategories() {
  yield takeLatest(SORT_ADMIN_CATEGORIES_REQUEST, workerSortCategories)
}

function* workerSetNewSubcategory({
  payload,
}: INewSubcategoryRequest): unknown {
  try {
    const { data }: { data: ISetSubCategoryResponse } = yield call(
      setSubcategoryApi,
      payload,
    )

    yield put(setNewSubcategory(data.data))
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_SAVED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error: unknown) {
    yield getRequestErrors(
      setNewSubcategoryRequestFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchSetNewSubcategory() {
  yield takeLatest(SET_ADMIN_NEW_SUBCATEGORY_REQUEST, workerSetNewSubcategory)
}

function* workerEditSubcategory({ payload }: IEditSubCategoryRequest): unknown {
  try {
    yield call(editSubategoryApi, payload)
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(
      editSubcategoryFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchEditSubcategory() {
  yield takeLatest(EDIT_ADMIN_SUBCATEGORY, workerEditSubcategory)
}

function* workerSortSubcategories({
  payload,
}: ISortSubcategoriesRequest): unknown {
  try {
    yield call(sortSubcategoriesApi, payload)
  } catch (error) {
    yield getRequestErrors(
      sortSubcategoriesFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchSortSubcategories() {
  yield takeLatest(SORT_ADMIN_SUBCATEGORIES_REQUEST, workerSortSubcategories)
}

export const adminCategoriesWatchers = [
  fork(watchGetCategories),
  fork(watchSetNewCategory),
  fork(watchGetSubategories),
  fork(watchEditCategory),
  fork(watchSetNewSubcategory),
  fork(watchEditSubcategory),
  fork(watchSortCategories),
  fork(watchSortSubcategories),
]
