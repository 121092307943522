import { FC, memo } from 'react'

import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

import image from 'assets/images/logo/logo.svg'
import { IAdminSalonResponseDataProfile } from 'store/Admin/Salons/Salons.type'
import { UIPhoneInput } from 'ui'

import style from './style.module.scss'

type PropsType = { profile: IAdminSalonResponseDataProfile }

const AdminSalonSectionOne: FC<PropsType> = ({
  profile: { email = '', name = '', logo, phones } = {},
}) => {
  const { t } = useTranslation()

  return (
    <section className={style.first}>
      <h2>1. {t('ADMIN.PROFILE_SALON.SECTION_1.TITLE')}</h2>

      <div className={style.info}>
        <Form layout="vertical" disabled={true} className={style.form}>
          <p>{t('COMMON.GENERAL_INFO')}</p>

          <Form.Item label={`${t('COMMON.SALON_NAME')}`}>
            <Input value={name} />
          </Form.Item>

          <Form.Item label={t('COMMON.EMAIL')}>
            <Input value={email} />
          </Form.Item>

          {phones?.map(({ id, number }) => (
            <UIPhoneInput
              key={id}
              label={t('COMMON.PHONE_NUMBER')}
              value={number?.slice(3)}
              onChange={() => {}}
              disabled={true}
              className={style.phone}
            />
          ))}
        </Form>

        <div className={style.imgWrapper}>
          <p>{t('COMMON.LOGO')}</p>

          <img src={logo?.path || image} alt={t('COMMON.LOGO')} />
        </div>
      </div>
    </section>
  )
}

export default memo(AdminSalonSectionOne)
