import { FC, memo, useEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'assets/images/common/arrow.svg'
import { QUERY_PARAMS } from 'utils'
import { getFromQueryString, getQueryString } from 'utils/helpers/query'

import style from './style.module.scss'

type PropTypes = { isLoading?: boolean; perPage?: number; total?: number }

const UICustomPagination: FC<PropTypes> = ({
  perPage: propsPerPage,
  total: propsTotal,
  isLoading = false,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [{ page, perPage, total }, setState] = useState({
    page: 1,
    perPage: propsPerPage || 15,
    total: propsTotal || 0,
  })

  const offset = page * perPage

  const handleClick = (isNext: boolean) => {
    const { [QUERY_PARAMS.PAGE]: searchPage, ...queries } = getFromQueryString(
      location.search,
    )

    navigate(
      `${location.pathname}${getQueryString({
        ...queries,
        page: isNext ? page + 1 : page - 1,
      })}`,
    )
  }

  const handleNextClick = () => {
    if (page >= Math.ceil(total / perPage) || isLoading) return

    handleClick(true)
  }

  const handlePrevClick = () => {
    if (page <= 1 || isLoading) return

    handleClick(false)
  }

  useEffect(() => {
    const queryObject = getFromQueryString(location.search)

    setState((prevState) => ({
      ...prevState,
      page: Number(queryObject?.page) || page,
    }))
  }, [location.search, page])

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...(propsPerPage !== prevState.perPage && { perPage: propsPerPage }),
      ...(propsTotal !== prevState.total && { total: propsTotal }),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsPerPage, propsTotal])

  return (
    <div className={style.pagination}>
      <span className={style.paginationText}>
        {offset - perPage + 1}-{offset > total ? total : offset} of {total}
      </span>

      <ArrowIcon className={style.paginationLeft} onClick={handlePrevClick} />
      <ArrowIcon className={style.paginationRight} onClick={handleNextClick} />
    </div>
  )
}

export default memo(UICustomPagination)
