export const GET_MANAGER_CLIENTS = '@clients/GET_MANAGER_CLIENTS'
export const GET_MANAGER_CLIENTS_SUCCESS =
  '@clients/GET_MANAGER_CLIENTS_SUCCESS'
export const GET_MANAGER_CLIENTS_FAILURE =
  '@clients/GET_MANAGER_CLIENTS_FAILURE'

export const GET_MANAGER_USERS = '@clients/GET_MANAGER_USERS'
export const GET_MANAGER_USERS_SUCCESS = '@clients/GET_MANAGER_USERS_SUCCESS'
export const GET_MANAGER_USERS_FAILURE = '@clients/GET_MANAGER_USERS_FAILURE'

export const GET_EXPORT_CONTACTS_REQUEST =
  '@clients/GET_EXPORT_CONTACTS_REQUEST'
export const GET_EXPORT_CONTACTS_SUCCESS =
  '@clients/GET_EXPORT_CONTACTS_SUCCESS'
export const GET_EXPORT_CONTACTS_FAILURE =
  '@clients/GET_EXPORT_CONTACTS_FAILURE'

export const ADD_IMPORT_CONTACTS_REQUEST =
  '@clients/ADD_IMPORT_CONTACTS_REQUEST'
export const ADD_IMPORT_CONTACTS_SUCCESS =
  '@clients/ADD_IMPORT_CONTACTS_SUCCESS'
export const ADD_IMPORT_CONTACTS_FAILURE =
  '@clients/ADD_IMPORT_CONTACTS_FAILURE'

export const GET_ERROR_IMPORT_REQUEST = '@clients/GET_ERROR_IMPORT_REQUEST'
export const GET_ERROR_IMPORT_SUCCESS = '@clients/GET_ERROR_IMPORT_SUCCESS'
export const GET_ERROR_IMPORT_FAILURE = '@clients/GET_ERROR_IMPORT_FAILURE'

export const CLEAR_CLIENTS_ERROR = '@clients/CLEAR_CLIENTS_ERROR'
export const CLEAR_CLIENTS_BASE = '@clients/CLEAR_CLIENTS_BASE'
