export const PERSONAL_SORT_REQUEST = '@sort/PERSONAL_SORT_REQUEST'
export const PERSONAL_SORT_SUCCESS = '@sort/PERSONAL_SORT_SUCCESS'
export const PERSONAL_SORT_FAILURE = '@sort/PERSONAL_SORT_FAILURE'

export const SORT_REQUEST = '@sort/SORT_REQUEST'
export const SORT_SUCCESS = '@sort/SORT_SUCCESS'
export const SORT_FAILURE = '@sort/PSORT_FAILURE'

export const SORT_STAFF_SERVICE_REQUEST = '@sort/SORT_STAFF_SERVICE_REQUEST'
export const SORT_STAFF_SERVICE_SUCCESS = '@sort/SORT_STAFF_SERVICE_SUCCESS'
export const SORT_STAFF_SERVICE_FAILURE = '@sort/SORT_STAFF_SERVICE_FAILURE'
