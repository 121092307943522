import { useTranslation } from 'react-i18next'
import WidgetModalStaffEditHourRow from '../Row'
import { DayTextType } from '../index.type'
import { PropsType } from './index.type'
import { FC } from 'react'

const WidgetModalStaffEditHourList: FC<PropsType> = ({
  values,
  setValues,
  setIsDisable,
  isCustomize,
}) => {
  const { t } = useTranslation()

  const weekArrayNotCustomize = [
    {
      label: t('MANAGER.PROFILE_SALON.MONDAY_FRIDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'monday',
    },
  ]

  const weekArray = [
    {
      label: t('MANAGER.PROFILE_SALON.MONDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'monday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.TUESDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'tuesday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.WEDNESDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'wednesday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.THURSDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'thursday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.FRIDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'friday',
    },
  ]

  const weekArrayWeekends = [
    {
      label: t('MANAGER.PROFILE_SALON.SATURDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'saturday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.SUNDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'sunday',
    },
  ]

  return (
    <>
      {!isCustomize &&
        weekArrayNotCustomize.map((item, index) => {
          return (
            <WidgetModalStaffEditHourRow
              day={index}
              text={item.label as unknown as DayTextType}
              formName={item.name as unknown as DayTextType}
              key={index}
              values={values}
              isCustomize
              setValues={setValues}
              setIsDisable={setIsDisable}
            />
          )
        })}

      {isCustomize &&
        weekArray.map((item, index) => {
          return (
            <WidgetModalStaffEditHourRow
              day={index}
              text={item.label as unknown as DayTextType}
              formName={item.name as unknown as DayTextType}
              key={index}
              values={values}
              setValues={setValues}
              setIsDisable={setIsDisable}
            />
          )
        })}

      {weekArrayWeekends.map((item, index) => {
        return (
          <WidgetModalStaffEditHourRow
            day={index}
            text={item.label as unknown as DayTextType}
            formName={item.name as unknown as DayTextType}
            key={index}
            values={values}
            setValues={setValues}
            setIsDisable={setIsDisable}
          />
        )
      })}
    </>
  )
}

export default WidgetModalStaffEditHourList
