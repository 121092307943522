import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { resetCategory, resetStore } from 'store/Manager/Services'
import { UIButton, UICustomModal } from 'ui'

import styles from './style.module.scss'

type PropsType = {
  handler: () => void
}

const WidgetModalDeleteStaff: FC<PropsType> = ({ handler }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(resetCategory())
    dispatch(resetStore())
    handler()
  }

  return (
    <UICustomModal isBack={false}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{t('MANAGER.STAFF.MODAL.NOTIFY')}</div>
        <UIButton handler={handleDelete} label={t('COMMON.DELETE')} />
      </div>
    </UICustomModal>
  )
}

export default WidgetModalDeleteStaff
