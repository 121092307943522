import { FC, ReactElement } from 'react'

import { RcFile } from 'antd/lib/upload'
import ImgCrop from 'antd-img-crop'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { FileName, UploadMedia } from 'entities/common'
import { clearMediaStore, uploadMediaRequest } from 'store/Media/Media.action'

import styles from './style.module.scss'

type PropsType = {
  children: ReactElement<any, any>
  heightRatio: number
  isArray?: boolean
  isCircle?: boolean
  linkType?: string
  name?: string
  widthRatio: number
}

const WidgetModalImageEditor: FC<PropsType> = ({
  widthRatio = 1,
  heightRatio = 1,
  isArray,
  linkType,
  children,
  isCircle,
  name,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleRequest = (file: string | boolean | void | File | Blob) => {
    dispatch(
      uploadMediaRequest({
        file: file as unknown as UploadMedia,
        nameUpload: name || (file as unknown as RcFile).name,
        fileName: FileName.IMAGE,
        ...(isArray && { isArray: true }),
        ...(linkType && { linkType }),
      }),
    )
    dispatch(clearMediaStore())
  }

  return (
    <ImgCrop
      onModalOk={handleRequest}
      aspect={widthRatio / heightRatio}
      modalClassName={classNames(styles.modalWrap, {
        [styles.wrappCircle]: isCircle,
      })}
      modalTitle={t('COMMON.IMAGE_EDIT')}
      modalCancel={t('COMMON.CANCEL')}
      modalOk={t('COMMON.SAVE')}
    >
      {children}
    </ImgCrop>
  )
}

export default WidgetModalImageEditor
