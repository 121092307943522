export const GET_ADMIN_NOTIFICATIONS_REQUEST =
  '@notification/GET_ADMIN_NOTIFICATIONS_REQUEST'
export const GET_ADMIN_NOTIFICATIONS_SUCCESS =
  '@notification/GET_ADMIN_NOTIFICATIONS_SUCCESS'
export const GET_ADMIN_NOTIFICATIONS_FAILURE =
  '@notification/GET_ADMIN_NOTIFICATIONS_FAILURE'

export const GET_ADMIN_SIDEBAR_NOTIFICATIONS_REQUEST =
  '@notification/GET_ADMIN_SIDEBAR_NOTIFICATIONS_REQUEST'
export const GET_ADMIN_SIDEBAR_NOTIFICATIONS_SUCCESS =
  '@notification/GET_ADMIN_SIDEBAR_NOTIFICATIONS_SUCCESS'
export const GET_ADMIN_SIDEBAR_NOTIFICATIONS_FAILURE =
  '@notification/GET_ADMIN_SIDEBAR_NOTIFICATIONS_FAILURE'

export const CLEAR_NOTIFICATION_ERROR = '@notification/CLEAR_NOTIFICATION_ERROR'

export const SET_ADMIN_NOTIFICATIONS = '@notification/SET_ADMIN_NOTIFICATIONS'
