import { Dispatch, FC, SetStateAction } from 'react'

import { StaffType } from 'entities/manager/service'
import { UICheckbox } from 'ui'

import styles from './style.module.scss'
import { ValuesType } from '../../EditService/index.type'

type PropsType = {
  fullStaff?: StaffType[]
  serviceStaff?: StaffType[]
  setIsChecked: Dispatch<SetStateAction<boolean>>
  setValue: (value: SetStateAction<Partial<ValuesType>>) => void
  values: Partial<ValuesType>
}

const ServiceStaffCheckBox: FC<PropsType> = ({
  fullStaff,
  values,
  setValue,
  setIsChecked,
}) => {
  const handlerChange = (staffId: number) => {
    setIsChecked(false)

    if (values?.staff?.find((id) => id === staffId)) {
      setValue((prevState) => ({
        ...prevState,
        staff: values?.staff?.filter((id) => id !== staffId),
      }))
    } else {
      setValue((prevState) => ({
        ...prevState,
        staff: [...(values.staff || []), staffId],
      }))
    }
  }

  return (
    <>
      <div className={styles.wrapper}>
        {fullStaff?.map((item, index) => (
          <div key={index} className={styles.checkboxWrapper}>
            <UICheckbox
              checked={!!values.staff?.find((id) => id === item.id)}
              onChange={() => handlerChange(item.id)}
              className={styles.checkbox}
            >
              <div className={styles.block}>
                <div className={styles.title}>{item.name}</div>
                <div className={styles.description}>{item.position}</div>
              </div>
            </UICheckbox>
          </div>
        ))}
      </div>
    </>
  )
}

export default ServiceStaffCheckBox
