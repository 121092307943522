import cn from 'classnames'
import { downloadFile } from 'modules/manager/client/Sidebar/constant'
import { FC, useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getErrorImportRequest } from 'store/Manager/Clients/Clients.action'

import { hideModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UICustomModal } from 'ui'

import styles from './style.module.scss'

type PropsType = {
  className?: string
  notifyTitle: string
  status?: string
}

const WidgetsModalNotifyClinets: FC<PropsType> = ({ className, status }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { errorFile } = useSelector((store: IRootState) => store.managerClients)

  const handleClick = () => {
    if (status === 'has_validation') {
      downloadFile(
        errorFile?.data || '',
        errorFile?.headers['content-type'] || '',
      )
      dispatch(hideModal())
    }

    dispatch(hideModal())
  }

  const renderTitle = useMemo(() => {
    switch (status) {
      case 'has_validation':
        return t('MANAGER.CLIENT.BASE_ERROR')

      default:
        return t('MANAGER.CLIENT.BASE_SUCCESSFULL')
    }
  }, [status, t])

  const renderBtnTitle = useMemo(() => {
    switch (status) {
      case 'has_validation':
        return t('MANAGER.CLIENT.SEE_ERRORS')

      default:
        return t('COMMON.OK')
    }
  }, [status, t])

  useEffect(() => {
    if (status === 'has_validation') {
      dispatch(getErrorImportRequest())
    }
  }, [dispatch, status])

  return (
    <UICustomModal
      isBack={false}
      isClose={false}
      className={styles.notifyModal}
    >
      <div className={cn(styles.notifyWrapper, className)}>
        <div className={styles.notifyTitle}>{renderTitle}</div>

        <UIButton handler={handleClick} label={renderBtnTitle} />
      </div>
    </UICustomModal>
  )
}

export default WidgetsModalNotifyClinets
