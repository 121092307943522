/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { StringHelper } from 'utils/helpers'
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { SidebarType } from 'entities/admin'
import { MenuNotificationName } from 'modules/manager/constants/sidebar'
import { IManagerMenuType } from 'store/Manager/Notifications/Notifications.type'
import { TemplateBodyType } from 'views/admin/Pages/index.type'

export default (() => {
  const filterRepeat = (arr: number[]) =>
    arr.filter(
      (item: number, index: number) => arr.indexOf(item as never) === index,
    )

  function setKeyFromArray<T>(
    data: { [key: string]: any }[],
    payload: number,
    key: string,
    value?: any,
  ): T {
    const newData = [...data]
    const index = newData.findIndex((item) => item?.id === payload)

    if (index !== -1) {
      newData[index][key] = value || !newData[index]?.[key]
    }

    return newData as unknown as T
  }

  function setAllKeysFromArray<T extends { id?: string | number }>(
    data: T[],
    payload: number[],
    key: string,
    value: any,
  ): T[] {
    const newData: T[] = [...data]

    payload.forEach((itemId) => {
      const index: number = newData.findIndex(
        (item) => Number(item?.id) === Number(itemId),
      )

      if (index !== -1) {
        newData[index] = { ...newData[index], [key]: value }
      }
    })

    return newData
  }

  const getSelectOption = <T extends { id: number; name: string }>(
    array?: T[],
  ) => {
    return (array || []).map((i) => ({
      value: i.id,
      label: i.name,
    }))
  }

  const getNotification = (
    arraySidebar: SidebarType[],
    notification: IManagerMenuType,
  ) => {
    const arrKeyNotification = Object.keys(notification)

    return arraySidebar?.map((itemMenu) => {
      if (
        itemMenu.notificationName &&
        arrKeyNotification.find((key) => key === itemMenu.notificationName)
      ) {
        return {
          ...itemMenu,
          notification: notification[itemMenu.notificationName],
          ...(itemMenu.notificationName === MenuNotificationName.FEEDBACKS && {
            notificationCount: notification[
              itemMenu.notificationName
            ] as unknown as number,
          }),
          ...(itemMenu.notificationName === MenuNotificationName.SERVICES && {
            notificationCount: notification[
              itemMenu.notificationName
            ] as unknown as number,
          }),
          ...(itemMenu.notificationName === MenuNotificationName.ORDERS && {
            notificationCount: notification[
              itemMenu.notificationName
            ] as unknown as number,
          }),
        }
      }

      return itemMenu
    })
  }

  const getPageContent = (content: TemplateBodyType, isEditor = false) => {
    let newStringObj = {}
    const newArrayObj = {}

    const getActionKey = (key: string) => {
      const keyArr = key.split('_')

      return `${keyArr?.[0]}_${keyArr[keyArr.length - 1]}`
    }

    if (content) {
      Object.keys(content)?.forEach((item) => {
        const action = getActionKey(item)

        if (action === 'section_description' || item === 'description') {
          if (!Array.isArray(content[item])) {
            const newValue = isEditor
              ? StringHelper.draftToHtmlEditor(content[item] as string)
              : StringHelper.convertFromHTMLEditor(content[item] as string)

            newStringObj = {
              ...newStringObj,
              [item]: newValue,
            }
          }
        }

        if (action === 'section_text') {
          if (Array.isArray(content[item])) {
            // @ts-ignore
            newArrayObj[item] = content?.[item]?.map((i) => {
              if (i.description) {
                const newDescription = isEditor
                  ? StringHelper.draftToHtmlEditor(i.description as string)
                  : StringHelper.convertFromHTMLEditor(i.description as string)

                return {
                  ...i,
                  description: newDescription,
                }
              }

              return i
            })
          }
        }
      })
    }

    return { ...content, ...newStringObj, ...newArrayObj }
  }

  return {
    filterRepeat,
    setKeyFromArray,
    setAllKeysFromArray,
    getSelectOption,
    getNotification,
    getPageContent,
  }
})()
