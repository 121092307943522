import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import { ICustomer, ResponseType } from 'entities/common'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  addImportContactsFailure,
  addImportContactsSuccess,
  getErrorImportFailure,
  getErrorImportSuccess,
  getExportContactsFailure,
  getExportContactsSuccess,
  getManagerClientsFailureAction,
  getManagerClientsSuccessAction,
  getManagerUsersFailureAction,
  getManagerUsersSuccessAction,
} from './Clients.action'
import {
  ADD_IMPORT_CONTACTS_REQUEST,
  GET_ERROR_IMPORT_REQUEST,
  GET_EXPORT_CONTACTS_REQUEST,
  GET_MANAGER_CLIENTS,
  GET_MANAGER_USERS,
} from './Clients.constant'
import {
  IAddImportContactsRequest,
  IGetExportContactsRequest,
  IGetManagerClientsAction,
  IGetManagerUsersAction,
  IManagerClientsUsersData,
  ResponseFileType,
} from './Clients.type'
import {
  getErrorImportApi,
  getExportContactsApi,
  getManagerClientsApi,
  getManagerUsersApi,
  uploadDocumentApi,
} from '../../../services/http/manager/Clients'
import { showModal } from 'store/Modal'
import { ModalType } from 'entities/modal'

function* workerGetManagerClients({ payload }: IGetManagerClientsAction) {
  try {
    const response: { data: ResponseType<ICustomer[]> } = yield call(
      getManagerClientsApi,
      payload,
    )

    yield put(getManagerClientsSuccessAction(response.data))
  } catch (error) {
    yield getRequestErrors(getManagerClientsFailureAction, error as string)
  }
}

function* watchGetManagerClients() {
  yield takeLatest(GET_MANAGER_CLIENTS, workerGetManagerClients)
}

function* workerGetManagerUsers({ payload }: IGetManagerUsersAction) {
  try {
    const response: { data: ResponseType<IManagerClientsUsersData[]> } =
      yield call(getManagerUsersApi, payload)

    yield put(getManagerUsersSuccessAction(response.data))
  } catch (error) {
    yield getRequestErrors(getManagerUsersFailureAction, error as string)
  }
}

function* watchGetManagerUsers() {
  yield takeLatest(GET_MANAGER_USERS, workerGetManagerUsers)
}

function* workerGetExportContacts({ payload }: IGetExportContactsRequest) {
  try {
    const response: ResponseFileType = yield call(getExportContactsApi, payload)

    yield put(getExportContactsSuccess(response))
  } catch (error) {
    yield getRequestErrors(getExportContactsFailure, error as string)
  }
}

function* watchGetExportContacts() {
  yield takeLatest(GET_EXPORT_CONTACTS_REQUEST, workerGetExportContacts)
}

function* workerAddImportContacts({ payload }: IAddImportContactsRequest) {
  const { file, fileName, nameUpload } = payload

  try {
    const { data }: { data: { [key: string]: string } } = yield call(
      uploadDocumentApi,
      fileName,
      nameUpload,
      file,
    )

    yield put(addImportContactsSuccess(data))
    yield put(
      showModal({
        modalType: ModalType.NOTIFY_CLIENTS,
        modalProps: { status: Object.keys(data)?.[0] },
      }),
    )
  } catch (error) {
    yield getRequestErrors(addImportContactsFailure, error as string)
  }
}

function* watchAddImportContacts() {
  yield takeLatest(ADD_IMPORT_CONTACTS_REQUEST, workerAddImportContacts)
}

function* workerGetErrorImport() {
  try {
    const response: ResponseFileType = yield call(getErrorImportApi)

    yield put(getErrorImportSuccess(response))
  } catch (error) {
    yield getRequestErrors(getErrorImportFailure, error as string)
  }
}

function* watchGetErrorImport() {
  yield takeLatest(GET_ERROR_IMPORT_REQUEST, workerGetErrorImport)
}

export const ManagerClientsWatchers: ForkEffect[] = [
  fork(watchGetManagerClients),
  fork(watchGetManagerUsers),
  fork(watchGetExportContacts),
  fork(watchAddImportContacts),
  fork(watchGetErrorImport),
]
