export const GET_ADMIN_CUSTOMERS_REQUEST =
  '@customer/GET_ADMIN_CUSTOMERS_REQUEST'
export const GET_ADMIN_CUSTOMERS_SUCCESS =
  '@customer/GET_ADMIN_CUSTOMERS_SUCCESS'
export const GET_ADMIN_CUSTOMERS_FAILURE =
  '@customer/GET_ADMIN_CUSTOMERS_FAILURE'

export const GET_ADMIN_CURRENT_CUSTOMER_REQUEST =
  '@customer/GET_ADMIN_CURRENT_CUSTOMER_REQUEST'
export const GET_ADMIN_CURRENT_CUSTOMER_SUCCESS =
  '@customer/GET_ADMIN_CURRENT_CUSTOMER_SUCCESS'
export const GET_ADMIN_CURRENT_CUSTOMER_FAILURE =
  '@customer/GET_ADMIN_CURRENT_CUSTOMER_FAILURE'

export const SET_ADMIN_CURRENT_CUSTOMER_REQUEST =
  '@customer/SET_ADMIN_CURRENT_CUSTOMER_REQUEST'
export const SET_ADMIN_CURRENT_CUSTOMER_SUCCESS =
  '@customer/SET_ADMIN_CURRENT_CUSTOMER_SUCCESS'
export const SET_ADMIN_CURRENT_CUSTOMER_FAILURE =
  '@customer/SET_ADMIN_CURRENT_CUSTOMER_FAILURE'

export const CLEAR_CUSTOMER_ERROR = '@customer/CLEAR_CUSTOMER_ERROR'
