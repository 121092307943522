import {
  ADD_ALERT,
  ADD_ALERT_TO_START,
  CLEAR_ALERTS,
  REMOVE_ALERT,
} from './Alert.constant'
import { AlertState, AlertActionsTypes } from './Alert.type'

const initialState: AlertState = {
  placement: 'center',
  alerts: [],
}

export default function alertReducer(
  state = initialState,
  action: AlertActionsTypes,
): AlertState {
  switch (action.type) {
    case ADD_ALERT:
      // eslint-disable-next-line no-case-declarations
      const { placement = 'center', ...alert } = action.payload

      return {
        placement,
        alerts: [alert],
      } as AlertState
    case ADD_ALERT_TO_START:
      // eslint-disable-next-line no-case-declarations
      const { placement: alertsPlacement = 'center', ...alerts } =
        action.payload

      return {
        placement: alertsPlacement,
        alerts: [alerts, ...state.alerts],
      } as AlertState
    case REMOVE_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter((item) => action.payload !== item.id),
      }
    case CLEAR_ALERTS:
      return initialState
    default:
      return state
  }
}
