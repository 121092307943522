import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  DELETE_STAFF_FAILURE,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  GET_ONE_STAFF_FAILURE,
  GET_ONE_STAFF_REQUEST,
  GET_ONE_STAFF_SUCCESS,
  GET_STAFF_FAILURE,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  GET_STAFF_TIME_FAILURE,
  GET_STAFF_TIME_REQUEST,
  GET_STAFF_TIME_SUCCESS,
  RESET_STORE,
  SET_IMAGE_LIST,
  UPDATE_STAFF_FAILURE,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
  SET_ACTIVE_STAFF,
  ADD_STAFF_REQUEST,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_FAILURE,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
  CLEAR_ERRORS,
  UPDATE_STAFF_TIME_REQUEST,
  UPDATE_STAFF_TIME_SUCCESS,
  UPDATE_STAFF_TIME_FAILURE,
  GET_STAFF_SUBCATEGORY_REQUEST,
  GET_STAFF_SUBCATEGORY_SUCCESS,
  SET_STAFF_STEP,
  GET_STAFF_SUBCATEGORY_FAILURE,
  RESET_STAFF,
  GET_STAFF_SERVICES_BY_SUBCATEGORY_REQUEST,
  GET_STAFF_SERVICES_BY_SUBCATEGORY_SUCCESS,
  GET_STAFF_SERVICES_BY_SUBCATEGORY_FAILURE,
  CLEAR_STAFFS,
  GET_ONE_STAFF_TIME_SUCCESS,
  RESET_STAFF_TIME,
  SET_IS_START_STAFF,
  SET_MOBILE_TITLE_OPEN,
} from './Staff.constant'
import { IStaffState, StaffActionsTypes } from './Staff.type'

const initialState: IStaffState = {
  staffs: null,
  staff: null,
  stafTime: null,
  staffSchedule: {},
  isLoading: false,
  isServiceLoading: false,
  isTimeLoading: false,
  isStaffAction: false,
  activeStaff: null,
  idxStaff: 0,
  services: [],
  errors: {},
  errorMessage: null,
  isStaffAdd: false,
  isStaffUpdate: false,
  addStaffStep: 1,
  staffSubCategory: [],
  isStaffServiceUpdate: false,
  isStaffModalUpdate: false,
  isStaffs: false,
  isStartStaff: false,
  isMobileTitleOpen: false,
}

export default function staffReducer(
  state = initialState,
  action: StaffActionsTypes,
): IStaffState {
  switch (action.type) {
    case GET_STAFF_REQUEST:
      return {
        ...state,
        isLoading: true,
        isStaffs: false,
      }
    case GET_SERVICES_REQUEST:
    case UPDATE_STAFF_TIME_REQUEST:
    case GET_STAFF_SUBCATEGORY_REQUEST:
    case GET_STAFF_SERVICES_BY_SUBCATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_STAFF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staffs: action.payload,
        isStaffs: true,
      }
    case GET_STAFF_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staffSubCategory: action.payload,
      }
    case GET_STAFF_SERVICES_BY_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        services: action.payload,
      }
    case GET_STAFF_SUBCATEGORY_FAILURE:
    case GET_STAFF_SERVICES_BY_SUBCATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_STAFF_FAILURE:
    case GET_SERVICES_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_ONE_STAFF_REQUEST:
      return {
        ...state,
        isServiceLoading: true,
      }
    case GET_ONE_STAFF_SUCCESS:
      return {
        ...state,
        isServiceLoading: false,
        staff: action.payload,
      }
    case GET_ONE_STAFF_TIME_SUCCESS:
      return {
        ...state,
        isServiceLoading: false,
        staffSchedule: action.payload,
      }
    case GET_ONE_STAFF_FAILURE:
      return {
        ...state,
        isServiceLoading: false,
      }
    case GET_STAFF_TIME_REQUEST:
      return {
        ...state,
        isTimeLoading: true,
      }
    case GET_STAFF_TIME_SUCCESS:
      return {
        ...state,
        stafTime: action.payload,
        isTimeLoading: false,
      }
    case GET_STAFF_TIME_FAILURE:
      return {
        ...state,
        isTimeLoading: false,
      }
    case DELETE_STAFF_REQUEST:
      return {
        ...state,
        isLoading: true,
        isStaffAdd: false,
      }
    case UPDATE_STAFF_REQUEST:
      return {
        ...state,
        isLoading: true,
        isStaffServiceUpdate: false,
        isStaffModalUpdate: false,
      }
    case ADD_STAFF_REQUEST:
      return {
        ...state,
        isLoading: true,
        isStaffAction: false,
      }
    case ADD_STAFF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isStaffAdd: true,
      }
    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isStaffAdd: true,
      }
    case UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        ...(action.payload.type === 'service' && {
          isStaffServiceUpdate: true,
        }),
        ...(action.payload.type === 'modal' && { isStaffModalUpdate: true }),
        services: state?.services?.filter(
          (item) => item.id !== action.payload.servicesId,
        ),
        isLoading: false,
        isStaffAdd: true,
        isStaffAction: true,
      }
    case UPDATE_STAFF_TIME_SUCCESS:
      return {
        ...state,
        isStaffAction: true,

        isLoading: false,
      }
    case DELETE_STAFF_FAILURE:
    case UPDATE_STAFF_FAILURE:
    case ADD_STAFF_FAILURE:
    case UPDATE_STAFF_TIME_FAILURE:
      return {
        ...state,
        isLoading: false,
        isStaffAction: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
        isLoading: false,
      }
    case RESET_STORE:
      return {
        ...state,
        isStaffAction: false,
        isStaffServiceUpdate: false,
        isStaffModalUpdate: false,
        isStaffAdd: false,
      }
    case SET_IMAGE_LIST:
      return {
        ...state,
        staff: {
          ...state.staff,
          images: action.payload,
          description: state.staff?.description || '',
          id: state.staff?.id || 0,
          name: state.staff?.name || '',
        },
      }
    case SET_ACTIVE_STAFF: {
      const { id, idx } = action.payload

      return {
        ...state,
        activeStaff: id,
        idxStaff: idx,
      }
    }
    case SET_STAFF_STEP:
      return {
        ...state,
        addStaffStep: action.payload,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    case RESET_STAFF: {
      return {
        ...state,
        staff: null,
        staffSubCategory: [],
      }
    }
    case CLEAR_STAFFS:
      return {
        ...state,
        isStaffs: false,
        services: [],
      }
    case RESET_STAFF_TIME:
      return {
        ...state,
        stafTime: null,
      }
    case SET_IS_START_STAFF:
      return {
        ...state,
        isStartStaff: action.payload,
      }
    case SET_MOBILE_TITLE_OPEN:
      return {
        ...state,
        isMobileTitleOpen: action.payload,
      }
    default:
      return state
  }
}
