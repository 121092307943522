import React from 'react'

import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { IRootState } from 'store/Root.reducer'
import { ALERT_TIMEOUT } from 'utils'

import style from './style.module.scss'
import CustomAlert from './ui/CustomAlert'

const AlertsList = () => {
  const { alerts, placement } = useSelector((store: IRootState) => store.alert)

  const classes = classNames(style.alerts, {
    [style.alertsCenter]: placement === 'center',
    [style.alertsLeft]: placement === 'left',
    [style.alertsRight]: placement === 'right',
  })

  return (
    <div className={classes}>
      <div className={style.alertsWrapper}>
        {!!alerts.length &&
          alerts.map(
            (
              { id, message, type = 'success', duration = ALERT_TIMEOUT },
              index,
            ) => (
              <CustomAlert
                key={id}
                id={id}
                type={type}
                duration={duration}
                message={message}
                index={index}
              />
            ),
          )}
      </div>
    </div>
  )
}

export default React.memo(AlertsList)
