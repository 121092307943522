export const INITIAL_VALUES = {
  description: '',
  keywords: '',
  title: '',
}

export enum InputNames {
  DESCRIPTION = 'description',
  KEYWORDS = 'keywords',
  TITLE = 'title',
}

export const INPUTS = [
  {
    title: 'ADMIN.SEO.PARAM.TITLE',
    name: InputNames.TITLE,
  },
  {
    title: 'ADMIN.SEO.PARAM.DESCRIPTION',
    name: InputNames.DESCRIPTION,
  },
  {
    title: 'ADMIN.SEO.PARAM.KEYWORDS',
    name: InputNames.KEYWORDS,
  },
]
