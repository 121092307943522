import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  GET_ONE_SCHEDULE_FAILURE,
  GET_ONE_SCHEDULE_REQUEST,
  GET_ONE_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAILURE,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SHIFT_FAILURE,
  UPDATE_SHIFT_REQUEST,
  UPDATE_SHIFT_SUCCESS,
  RESET_STORE,
  ADD_TIME_SHIFT_REQUEST,
  ADD_TIME_SHIFT_SUCCESS,
  ADD_TIME_SHIFT_FAILURE,
  COPY_SHIFT_REQUEST,
  COPY_SHIFT_SUCCESS,
  COPY_SHIFT_FAILURE,
  CLEAR_ERRORS,
} from './Shift.constant'
import { IShiftState, ShiftActionsTypes } from './Shifts.type'

const initialState: IShiftState = {
  shifts: [],
  shift: null,
  isLoading: false,
  errors: {},
  errorMessage: null,
  isUpdate: false,
  isShiftAction: false,
}

export default function shiftReducer(
  state = initialState,
  action: ShiftActionsTypes,
): IShiftState {
  switch (action.type) {
    case GET_SCHEDULE_REQUEST:
    case GET_ONE_SCHEDULE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        shifts: action.payload,
        isLoading: false,
      }
    case GET_SCHEDULE_FAILURE:
    case GET_ONE_SCHEDULE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_ONE_SCHEDULE_SUCCESS:
      return {
        ...state,
        shift: action.payload,
        isLoading: false,
      }
    case UPDATE_SCHEDULE_REQUEST:
      return {
        ...state,
        isUpdate: false,
        isLoading: true,
        isShiftAction: false,
      }
    case UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        isUpdate: true,
        isLoading: false,
        isShiftAction: true,
      }
    case UPDATE_SCHEDULE_FAILURE:
      return {
        ...state,
        isUpdate: false,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
        isShiftAction: false,
      }

    case UPDATE_SHIFT_REQUEST:
    case ADD_TIME_SHIFT_REQUEST:
    case COPY_SHIFT_REQUEST:
      return {
        ...state,
        isShiftAction: false,
        isLoading: true,
      }
    case UPDATE_SHIFT_SUCCESS:
    case ADD_TIME_SHIFT_SUCCESS:
    case COPY_SHIFT_SUCCESS:
      return {
        ...state,
        isShiftAction: true,
        isLoading: false,
      }
    case UPDATE_SHIFT_FAILURE:
    case ADD_TIME_SHIFT_FAILURE:
    case COPY_SHIFT_FAILURE:
      return {
        ...state,
        isShiftAction: false,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case RESET_STORE:
      return {
        ...state,
        isShiftAction: false,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    default:
      return state
  }
}
