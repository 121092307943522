import { FC } from 'react'
import { format } from 'date-fns'
import classNames from 'classnames'
import styles from '../style.module.scss'

const UIAppointmendarSidebarAddTimeCard: FC<{
  item: string
  onSelect: (date: Date | null) => () => void
  selectedTime: Date | null
}> = ({ item, selectedTime, onSelect }) => {
  const [hour, minute] = item.split(':')
  const isSelectedTime = selectedTime ? format(selectedTime, 'HH:mm:ss') : null
  const date = new Date(2022, 1, 1, Number(hour), Number(minute))

  return (
    <div
      className={classNames(styles.hoursCard, {
        [styles.hoursCardActive]: isSelectedTime === item,
      })}
      onClick={onSelect(isSelectedTime === item ? null : date)}
    >
      {item.slice(0, 5)}
    </div>
  )
}

export default UIAppointmendarSidebarAddTimeCard
