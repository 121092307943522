import { call, fork, put, takeLatest } from 'redux-saga/effects'

import {
  editFeedbackApi,
  getFeedbacksApi,
  setFeedbackApi,
} from 'services/http/manager'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  editFeedbacksFailure,
  editFeedbacksSuccess,
  getFeedbacksFailure,
  getFeedbacksSuccess,
  setFeedbacksFailure,
  setFeedbacksSuccess,
} from './Feedbacks.action'
import {
  EDIT_FEEDBACKS_REQUEST,
  GET_FEEDBACKS_REQUEST,
  SET_FEEDBACKS_REQUEST,
} from './Feedbacks.constant'
import {
  IEditFeedbacksRequest,
  IFeedbacksResponse,
  IGetFeedbacksRequest,
  ISetFeedbacksRequest,
} from './Feedbakcs.type'

function* workerGetFeedbacks({ payload }: IGetFeedbacksRequest) {
  try {
    const response: { data: IFeedbacksResponse[] } = yield call(
      getFeedbacksApi,
      payload,
    )

    yield put(getFeedbacksSuccess(response.data))
  } catch (error) {
    yield getRequestErrors(getFeedbacksFailure, error as string)
  }
}

function* watchGetFeedbacks() {
  yield takeLatest(GET_FEEDBACKS_REQUEST, workerGetFeedbacks)
}

function* workerSetFeedbacks({ payload }: ISetFeedbacksRequest) {
  try {
    yield call(setFeedbackApi, payload)

    yield put(
      setFeedbacksSuccess({
        ...payload,
        created_at: new Date().toISOString(),
      }),
    )
  } catch (error) {
    yield getRequestErrors(setFeedbacksFailure, error as string)
  }
}

function* watchSetFeedbacks() {
  yield takeLatest(SET_FEEDBACKS_REQUEST, workerSetFeedbacks)
}

function* workerEditFeedbacks({ payload }: IEditFeedbacksRequest) {
  try {
    yield call(editFeedbackApi, {
      answer_id: payload.answer_id,
      comment: payload.comment,
    })

    yield put(
      editFeedbacksSuccess({
        ...payload,
        created_at: new Date().toISOString(),
      }),
    )
  } catch (error) {
    yield getRequestErrors(editFeedbacksFailure, error as string)
  }
}

function* watchEditFeedbacks() {
  yield takeLatest(EDIT_FEEDBACKS_REQUEST, workerEditFeedbacks)
}

export const feedbackWatchers = [
  fork(watchGetFeedbacks),
  fork(watchSetFeedbacks),
  fork(watchEditFeedbacks),
]
