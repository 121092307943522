import { FC, memo, useMemo, SyntheticEvent } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ReactComponent as DeleteSVG } from 'assets/images/common/redesign/close.svg'
import { ReactComponent as DeleteCircleSVG } from 'assets/images/common/redesign/deleteCircle.svg'
import { ReactComponent as OkCircleSVG } from 'assets/images/common/redesign/okCircle.svg'
import { ReactComponent as SubscribeSVG } from 'assets/images/common/redesign/subscribe.svg'
import { ReactComponent as QuetSVG } from 'assets/images/common/redesign/quiet.svg'
import {
  cancelManagerPlanRequest,
  subscribeManagerPlanRequest,
} from 'store/Manager/Subscription/Subscription.action'
import { AdminSettingsBlockSubscriptionsType } from 'views/admin/Settings/index.type'

import styles from './style.module.scss'
import { DateHelper } from 'utils/helpers'
import { FORMAT_DATE_CUSTOM, FORMAT_DATE_MONTH_DAY } from 'utils'

const SubscribeHeader: FC<AdminSettingsBlockSubscriptionsType> = ({
  id,
  name,
  price,
  subscribe,
  freeDuration,
  isHideAction,
  freeSubscribe,
  active,
  isHideSubscribe,
  finished_at,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSubscribe = (e: SyntheticEvent) => {
    e.stopPropagation()

    if (id) dispatch(subscribeManagerPlanRequest(id))
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.stopPropagation()

    if (subscribe?.id) dispatch(cancelManagerPlanRequest(subscribe?.id))
  }

  const duration = useMemo(() => {
    if (price === t('COMMON.MANUAL')) return '---'

    if (freeDuration?.start && freeDuration?.end) {
      return (
        <>
          {DateHelper.toFormat(
            new Date(freeDuration?.start),
            FORMAT_DATE_MONTH_DAY,
          )}{' '}
          -{' '}
          {DateHelper.toFormat(new Date(freeDuration?.end), FORMAT_DATE_CUSTOM)}
        </>
      )
    }

    const startDate = DateHelper.toFormat(
      new Date(subscribe?.subscribe_at || new Date()),
      'MMM d',
    )

    const endDate = DateHelper.toFormat(
      new Date(subscribe?.finished_at || new Date()),
      FORMAT_DATE_CUSTOM,
    )

    return subscribe?.finished_at ? `${startDate} - ${endDate}` : '---'
  }, [
    freeDuration?.end,
    freeDuration?.start,
    price,
    subscribe?.finished_at,
    subscribe?.subscribe_at,
    t,
  ])

  const renderStatusHTML = (
    type: 'active' | 'inactive' | 'quiet' | 'canceled' | '',
  ) => {
    switch (type) {
      case 'active':
        return (
          <span className={styles.statusActive}>
            <OkCircleSVG /> {t('MANAGER.SUBSCRIBTION_ACTIVE')}
          </span>
        )
      case 'inactive':
        return (
          <span className={styles.statusInactive}>
            <DeleteCircleSVG /> {t('COMMON.INACTIVE')}
          </span>
        )

      case 'quiet':
        return (
          <span className={styles.statusQuiet}>
            <QuetSVG /> {t('COMMON.QUIET')}
          </span>
        )

      case 'canceled':
        return (
          <span className={styles.statusInactive}>
            <DeleteCircleSVG /> {t('COMMON.CANCELLED')}
          </span>
        )

      default:
        return ''
    }
  }

  const renderStatus = () => {
    if (freeSubscribe?.id || freeSubscribe?.manual) {
      return renderStatusHTML('active')
    }

    if (!subscribe) {
      return renderStatusHTML('inactive')
    }

    if (subscribe.status === 1) {
      return renderStatusHTML('active')
    }

    if (subscribe.status === 2) {
      return renderStatusHTML('quiet')
    }

    if (subscribe.status === 99) {
      return renderStatusHTML('canceled')
    }

    if (active === 1) {
      return renderStatusHTML('active')
    }

    return ''
  }

  return (
    <div className={styles.headerWrapp}>
      <p className={styles.plan}>
        <span className={styles.hint}>{t('MANAGER.SUBSCRIPTION.PLAN')}</span>
        {name}
      </p>

      <p className={styles.status}>
        <span className={styles.hint}>{t('MANAGER.SUBSCRIPTION.STATUS')}</span>

        {renderStatus()}
      </p>

      <p className={styles.price}>
        <span className={styles.hint}>{t('MANAGER.SUBSCRIPTION.PRICE')}</span>
        {price}
      </p>

      <p className={styles.date}>
        <span className={styles.hint}>
          {t('MANAGER.SUBSCRIPTION.DURATION')}
        </span>

        {finished_at || duration}
      </p>

      <p className={styles.action}>
        {subscribe && !isHideAction && !(subscribe.status === 99) && (
          <span
            className={styles.actionCancel}
            onClick={(e) => handleCancel(e)}
          >
            <DeleteSVG />
            {t('COMMON.CANCEL')}
          </span>
        )}

        {((!subscribe && !isHideAction) ||
          (subscribe?.status === 99 && !isHideSubscribe)) && (
          <span
            className={styles.actionSubcribe}
            onClick={(e) => handleSubscribe(e)}
          >
            <SubscribeSVG /> {t('COMMON.SUBSCRIBE')}
          </span>
        )}
      </p>
    </div>
  )
}

export default memo(SubscribeHeader)
