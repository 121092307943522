import { FC, useEffect, useMemo, useState, memo } from 'react'

import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowLeft } from 'assets/images/common/arrowLeft.svg'
import FaqTextBlock from 'modules/admin/Faq/FaqTextBlock'
import { FaqTextBlockState } from 'modules/admin/Faq/FaqTextBlock/index.type'
import {
  addAdminFaqRequest,
  clearAdminFaqErrors,
  editAdminFaqRequest,
  getAdminCurrentFaqRequest,
} from 'store/Admin/Faq/Faq.action'
import { AdminFaqBodyType } from 'store/Admin/Faq/Faq.type'
import { addAlert } from 'store/Alert'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UISwitch } from 'ui'
import { ADMIN_HELP_FOR_BUSINESS, ADMIN_HELP_FOR_CUSTOMER } from 'utils'

import style from './style.module.scss'
import { StringHelper } from 'utils/helpers'

type PropTypes = { isManager: boolean }

const ViewAdminFaqAnswer: FC<PropTypes> = ({ isManager }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { isLoading, isSaved, errors, currentData, errorMessage } = useSelector(
    (store: IRootState) => store.adminFaq,
  )
  const [state, setState] = useState<FaqTextBlockState>({
    answer: '',
    question: '',
  })
  const [publish, setPublish] = useState(false)
  const id: null | string = useMemo(() => {
    const splittedPath = location.pathname.split('/')

    return JSON.parse(splittedPath[splittedPath.length - 1]) as string
  }, [location.pathname])

  const handleGoBack = () => {
    navigate(isManager ? ADMIN_HELP_FOR_BUSINESS : ADMIN_HELP_FOR_CUSTOMER)
  }

  const handleChange = (value: boolean) => {
    setPublish(value)
  }

  const handleSave = () => {
    const { answer, question } = state

    const body: AdminFaqBodyType = {
      answer: StringHelper.draftToHtmlEditor(answer as string),
      question: StringHelper.draftToHtmlEditor(question as string),
      active: Number(publish) as 0 | 1,
      type: isManager ? 1 : 2,
    }

    dispatch(
      id ? editAdminFaqRequest(body, Number(id)) : addAdminFaqRequest(body),
    )
  }

  useEffect(() => {
    if (isSaved) {
      dispatch(
        addAlert({
          message: t('COMMON.SUCCESSFULLY_SAVED'),
          type: 'success',
        }),
      )
    }

    return () => {
      dispatch(clearAdminFaqErrors())
    }
  }, [isSaved, dispatch, t])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
        }),
      )
    }

    return () => {
      dispatch(clearAdminFaqErrors())
    }
  }, [dispatch, errorMessage])

  useEffect(() => {
    if (id) {
      dispatch(getAdminCurrentFaqRequest(Number(id)))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (currentData?.active || currentData?.answer || currentData?.question) {
      setState({
        answer: StringHelper.convertFromHTMLEditor(currentData?.answer) || '',
        question:
          StringHelper.convertFromHTMLEditor(currentData?.question) || '',
      })

      setPublish(!!currentData?.active)
    }
  }, [currentData?.active, currentData?.answer, currentData?.question])

  return (
    <Content className={style.faqAnswer}>
      <div className={style.titleWrapper}>
        <ArrowLeft onClick={handleGoBack} className={style.arrow} />

        <h1>
          {isManager
            ? t('ADMIN.HELP_FOR_BUSINESS.ANSWER_TITLE')
            : t('ADMIN.HELP_FOR_CUSTOMERS.ANSWER_TITLE')}
        </h1>
      </div>

      <FaqTextBlock
        answerTitle={t('COMMON.QUESTION')}
        questionTitle={t('COMMON.ASK')}
        setState={setState}
        state={state}
        className={style.faqTextBlock}
        disabled={isLoading}
        answerError={errors?.answer?.[0]}
        questionError={errors?.question?.[0]}
      />

      <div className={style.switch}>
        <UISwitch checked={publish} onChange={handleChange} />

        <p className="mb-0 title-1 fw-700">{t('COMMON.PUBLISH')}</p>
      </div>

      <UIButton
        label={id ? t('COMMON.EDIT') : t('COMMON.SAVE')}
        handler={handleSave}
        disabled={isLoading}
        className={style.button}
      />
    </Content>
  )
}

export default memo(ViewAdminFaqAnswer)
