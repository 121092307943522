import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  GET_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  RESET_ERROR,
  UPADATE_SETTINGS_FAILURE,
  UPADATE_SETTINGS_REQUEST,
  UPADATE_SETTINGS_SUCCESS,
  SYNC_GOOGLE_CALENDAR_FAILURE,
  SYNC_GOOGLE_CALENDAR_REQUEST,
  SYNC_GOOGLE_CALENDAR_SUCCESS,
  DESYNC_GOOGLE_CALENDAR_REQUEST,
  DESYNC_GOOGLE_CALENDAR_SUCCESS,
  DESYNC_GOOGLE_CALENDAR_FAILURE,
} from './Settings.constant'
import {
  IGetSettingsResponse,
  ManagerSettingsUpdateType,
} from './Settings.type'

export const getSettingsRequest = () => ({
  type: GET_SETTINGS_REQUEST,
})

export const getSettingsSuccess = (payload: IGetSettingsResponse) => ({
  type: GET_SETTINGS_SUCCESS,
  payload,
})

export const getSettingsFailure = (payload: ResponseFailureType) => ({
  payload,
  type: GET_SETTINGS_FAILURE,
})

export const updateSettingsRequest = (payload: ManagerSettingsUpdateType) => ({
  type: UPADATE_SETTINGS_REQUEST,
  payload,
})

export const updateSettingsSuccess = () => ({
  type: UPADATE_SETTINGS_SUCCESS,
})

export const updateSettingsFailure = (payload: ResponseFailureType) => ({
  payload,
  type: UPADATE_SETTINGS_FAILURE,
})

export const resetError = () => ({
  type: RESET_ERROR,
})

export const syncGoogleCalendarRequest = (payload: { code: string }) => ({
  payload,
  type: SYNC_GOOGLE_CALENDAR_REQUEST,
})

export const syncGoogleCalendarSuccess = () => ({
  type: SYNC_GOOGLE_CALENDAR_SUCCESS,
})

export const syncGoogleCalendarFailure = (payload: ResponseFailureType) => ({
  payload,
  type: SYNC_GOOGLE_CALENDAR_FAILURE,
})

export const desyncGoogleCalendarRequest = () => ({
  type: DESYNC_GOOGLE_CALENDAR_REQUEST,
})

export const desyncGoogleCalendarSuccess = () => ({
  type: DESYNC_GOOGLE_CALENDAR_SUCCESS,
})

export const desyncGoogleCalendarFailure = (payload: ResponseFailureType) => ({
  payload,
  type: DESYNC_GOOGLE_CALENDAR_FAILURE,
})
