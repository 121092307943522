import { FullMedia } from 'entities/common'
import { ServiceType } from 'entities/manager/service'
import { StaffTimeType, StaffType, TimeWeekType } from 'entities/manager/staff'
import { CategoryType } from 'entities/view'
import { ResponseFailureType } from 'store/Auth/Auth.type'
import { StaffValuesType } from 'views/manager/Staff/EditStaff/index.type'

import {
  ADD_STAFF_FAILURE,
  ADD_STAFF_REQUEST,
  ADD_STAFF_SUCCESS,
  CLEAR_ERRORS,
  DELETE_STAFF_FAILURE,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  GET_ONE_STAFF_FAILURE,
  GET_ONE_STAFF_REQUEST,
  GET_ONE_STAFF_SUCCESS,
  GET_SERVICES_FAILURE,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_STAFF_FAILURE,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  GET_STAFF_TIME_FAILURE,
  GET_STAFF_TIME_REQUEST,
  GET_STAFF_TIME_SUCCESS,
  RESET_STORE,
  SET_ACTIVE_STAFF,
  SET_IMAGE_LIST,
  UPDATE_STAFF_FAILURE,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_TIME_FAILURE,
  UPDATE_STAFF_TIME_REQUEST,
  UPDATE_STAFF_TIME_SUCCESS,
  SET_STAFF_STEP,
  GET_STAFF_SUBCATEGORY_REQUEST,
  GET_STAFF_SUBCATEGORY_SUCCESS,
  GET_STAFF_SUBCATEGORY_FAILURE,
  RESET_STAFF,
  GET_STAFF_SERVICES_BY_SUBCATEGORY_REQUEST,
  GET_STAFF_SERVICES_BY_SUBCATEGORY_SUCCESS,
  GET_STAFF_SERVICES_BY_SUBCATEGORY_FAILURE,
  CLEAR_STAFFS,
  GET_ONE_STAFF_TIME_SUCCESS,
  RESET_STAFF_TIME,
  SET_IS_START_STAFF,
  SET_MOBILE_TITLE_OPEN,
} from './Staff.constant'

export const getStaffRequest = (search = '') => ({
  type: GET_STAFF_REQUEST,
  payload: search,
})

export const getStaffSuccess = (payload: StaffType[]) => ({
  type: GET_STAFF_SUCCESS,
  payload,
})

export const getStaffFailure = () => ({
  type: GET_STAFF_FAILURE,
})

export const getOneStaffRequest = (payload: number) => ({
  payload,
  type: GET_ONE_STAFF_REQUEST,
})

export const getOneStaffSuccess = (payload: StaffType) => ({
  type: GET_ONE_STAFF_SUCCESS,
  payload,
})

export const getOneStaffTimeSuccess = (payload: TimeWeekType[]) => ({
  type: GET_ONE_STAFF_TIME_SUCCESS,
  payload,
})

export const getOneStaffFailure = () => ({
  type: GET_ONE_STAFF_FAILURE,
})

export const getStaffTimeRequest = (payload: number) => ({
  payload,
  type: GET_STAFF_TIME_REQUEST,
})

export const getStaffTimeSuccess = (payload: StaffType) => ({
  type: GET_STAFF_TIME_SUCCESS,
  payload,
})

export const getStaffTimeFailure = () => ({
  type: GET_STAFF_TIME_FAILURE,
})

export const deleteStaffRequest = (payload: number) => ({
  payload,
  type: DELETE_STAFF_REQUEST,
})

export const deleteStaffSuccess = () => ({
  type: DELETE_STAFF_SUCCESS,
})

export const deleteStaffFailure = (payload: ResponseFailureType) => ({
  type: DELETE_STAFF_FAILURE,
  payload,
})

export const updateStaffRequest = (payload: {
  body: StaffValuesType
  id: number
  isServicesList?: boolean
  servicesId?: number
  type?: string
}) => ({
  payload,
  type: UPDATE_STAFF_REQUEST,
})

export const updateStaffSuccess = (type: string, servicesId: number) => ({
  payload: { type, servicesId },
  type: UPDATE_STAFF_SUCCESS,
})

export const updateStaffFailure = (payload: ResponseFailureType) => ({
  type: UPDATE_STAFF_FAILURE,
  payload,
})

export const addStaffRequest = (payload: StaffValuesType) => ({
  payload,
  type: ADD_STAFF_REQUEST,
})

export const addStaffSuccess = () => ({
  type: ADD_STAFF_SUCCESS,
})

export const addStaffFailure = (payload: ResponseFailureType) => ({
  type: ADD_STAFF_FAILURE,
  payload,
})

export const getServicesRequest = () => ({
  type: GET_SERVICES_REQUEST,
})

export const getServicesSuccess = (payload: ServiceType[]) => ({
  payload,
  type: GET_SERVICES_SUCCESS,
})

export const getServicesFailure = () => ({
  type: GET_SERVICES_FAILURE,
})

export const updateStaffTimeRequest = (payload: {
  body: StaffTimeType
  id: number
}) => ({
  payload,
  type: UPDATE_STAFF_TIME_REQUEST,
})

export const updateStaffTimeSuccess = () => ({
  type: UPDATE_STAFF_TIME_SUCCESS,
})

export const updateStaffTimeFailure = (payload: ResponseFailureType) => ({
  type: UPDATE_STAFF_TIME_FAILURE,
  payload,
})

export const getStaffSubCategoryRequest = (payload: number) => ({
  payload,
  type: GET_STAFF_SUBCATEGORY_REQUEST,
})

export const getStaffSubCategorySuccess = (payload: CategoryType[]) => ({
  type: GET_STAFF_SUBCATEGORY_SUCCESS,
  payload,
})

export const getStaffSubCategoryFailure = () => ({
  type: GET_STAFF_SUBCATEGORY_FAILURE,
})

export const getStaffServicesBySubCategoryRequest = (payload: {
  sectionId: number
  staffId: number
}) => ({
  payload,
  type: GET_STAFF_SERVICES_BY_SUBCATEGORY_REQUEST,
})

export const getStaffServicesBySubCategorySuccess = (
  payload: ServiceType[],
) => ({
  type: GET_STAFF_SERVICES_BY_SUBCATEGORY_SUCCESS,
  payload,
})

export const getStaffServicesBySubCategoryFailure = () => ({
  type: GET_STAFF_SERVICES_BY_SUBCATEGORY_FAILURE,
})

export const resetStore = () => ({
  type: RESET_STORE,
})

export const setImageList = (payload: FullMedia[] | null) => ({
  payload,
  type: SET_IMAGE_LIST,
})

export const setActiveStaff = (payload: {
  id?: number | null
  idx?: number
}) => ({
  payload,
  type: SET_ACTIVE_STAFF,
})

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
})

export function setStaffStep(payload: number) {
  return {
    type: SET_STAFF_STEP,
    payload,
  }
}

export const resetStaff = () => ({
  type: RESET_STAFF,
})

export const clearStaffs = () => ({
  type: CLEAR_STAFFS,
})

export const setIsStartStaff = (payload: boolean) => ({
  payload,
  type: SET_IS_START_STAFF,
})

export const resetStaffTime = () => ({
  type: RESET_STAFF_TIME,
})

export const setMobileTitleOpen = (payload: boolean) => ({
  payload,
  type: SET_MOBILE_TITLE_OPEN,
})
