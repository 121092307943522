import { FC } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { StringHelper } from 'utils/helpers'

import { PropsType } from './index.type'
import styles from '../style.module.scss'

const StaffHoursRow: FC<PropsType> = ({
  name,
  workStart,
  workEnd,
  breakStart,
  breakEnd,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.timeWrapper}>
      <div>
        <div className={styles.text}>{name}</div>
        <div className={styles.time}>
          {StringHelper.renderTime(workStart, workEnd)}
        </div>
      </div>
      <div>
        <div className={cn(styles.text, styles.textBreak)}>
          {t('MANAGER.STAFF.BREAK')}
        </div>
        <div className={styles.time}>
          {StringHelper.renderTime(breakStart, breakEnd)}
        </div>
      </div>
    </div>
  )
}

export default StaffHoursRow
