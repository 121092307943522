/* eslint-disable no-nested-ternary */
import { FC, memo, ReactNode } from 'react'

import classNames from 'classnames'

import style from './style.module.scss'

type PropsType = {
  children: ReactNode
  className?: string
  count?: number
  dot?: boolean
  height?: number
  offset?: [number, number]
  text?: string | ReactNode
  width?: number
}

const UICustomBadge: FC<PropsType> = ({
  children,
  height,
  offset,
  width,
  count,
  text,
  dot,
  className,
}) => {
  const styles = {
    width,
    height,
    marginTop: offset ? offset[0] : height ? height / 2 : '',
    right: offset ? offset[1] : width ? width / 2 : '',
  }
  const isShow = !!dot || !!count || !!text
  const showCount = !!count && count > 99 ? '99+' : count

  return (
    <div className={classNames(style.badgeWrapper, className)}>
      {isShow && (
        <sup className={style.badge} style={styles}>
          {!!showCount && showCount}

          {!showCount && !!text && text}
        </sup>
      )}
      {children}
    </div>
  )
}

export default memo(UICustomBadge)
