import { FC, MouseEvent, useEffect, useMemo, useState } from 'react'

import { Spin, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { ReactComponent as DeleteSVG } from 'assets/images/common/redesign/close.svg'
import { UIButton } from 'ui'

import ClientSidebarExport from './Export'
import ClientSidebarImport from './Import'
import { BaseFormat, PropsType } from './index.type'
import styles from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  addImportContactsRequest,
  clearClientsBase,
  getExportContactsRequest,
} from 'store/Manager/Clients/Clients.action'
import { IRootState } from 'store/Root.reducer'
import { DocumentsType } from 'store/Manager/Documents/Documents.type'
import { FileName, UploadMedia } from 'entities/common'
import { downloadFile } from './constant'

const ClientSidebar: FC<PropsType> = ({ setIsDrawer }) => {
  const dispatch = useDispatch()

  const { exportFile, isSidebarLoading } = useSelector(
    (store: IRootState) => store.managerClients,
  )

  const [typeTab, setTypeTab] = useState(BaseFormat.XLS)
  const [activeTab, setActiveTab] = useState('1')
  const [fileList, setFileList] = useState<DocumentsType[]>([])

  const { t } = useTranslation()

  const handleCLose = (e: MouseEvent<HTMLElement>) => {
    if ((e.target as Element)?.classList.contains(styles.outsideWrapp)) {
      setIsDrawer(false)
    }
  }

  const onTabChange = (key: string) => {
    setActiveTab(key)

    if (key === '1') {
      dispatch(getExportContactsRequest(typeTab))
    }
  }

  const onExportFile = () => {
    if (activeTab === '1') {
      downloadFile(
        exportFile?.data || '',
        exportFile?.headers['content-type'] || '',
      )
    }

    if (activeTab === '2' && fileList.length) {
      dispatch(
        addImportContactsRequest({
          file: fileList?.[0] as unknown as UploadMedia,
          nameUpload: fileList?.[0]?.name || '',
          fileName: FileName.FILE,
        }),
      )

      setFileList([])
    }
  }

  const getBtnDisable = useMemo(() => {
    if (activeTab === '1') return isSidebarLoading

    return !fileList.length
  }, [activeTab, fileList.length, isSidebarLoading])

  useEffect(() => {
    return () => {
      dispatch(clearClientsBase())
    }
  }, [dispatch])

  return (
    <div className={styles.outsideWrapp} onClick={(e) => handleCLose(e)}>
      <div className={styles.drawerWrapp}>
        <div className={cn(styles.content)}>
          <div className={styles.header}>
            <div>
              <h2>{t('MANAGER.CLIENTS.BASE')}</h2>
            </div>
            <div className={styles.close} onClick={() => setIsDrawer(false)}>
              <DeleteSVG />
            </div>
          </div>
          <div className={styles.tabs}>
            <Spin spinning={isSidebarLoading}>
              <Tabs
                className={styles.tab}
                activeKey={activeTab}
                defaultActiveKey="1"
                onChange={onTabChange}
              >
                <TabPane tab={t('MANAGER.CLIENTS.DATA_EXPORT')} key="1">
                  <ClientSidebarExport
                    setTypeTab={setTypeTab}
                    typeTab={typeTab}
                  />
                </TabPane>
                <TabPane tab={t('MANAGER.CLIENTS.DATA_IMPORT')} key="2">
                  <ClientSidebarImport
                    setFileList={setFileList}
                    fileList={fileList}
                  />
                </TabPane>
              </Tabs>
            </Spin>
          </div>
          <div className={styles.footer}>
            <UIButton
              label={
                activeTab === '1'
                  ? t('MANAGER.CLIENTS.EXPORT')
                  : t('MANAGER.CLIENTS.IMPORT')
              }
              handler={onExportFile}
              disabled={getBtnDisable}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientSidebar
