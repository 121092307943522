import { FC, memo, useCallback, useEffect, useState } from 'react'

import 'antd/dist/antd.css'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ServiceType } from 'entities/manager/service'
import { ModalType } from 'entities/modal'
import { CategoryType } from 'entities/view'
import useResponsive from 'hooks/useResponsive'
import LayoutManagerSidebar from 'layouts/Manager/Sidebar'
import {
  getOneStaffRequest,
  getStaffServicesBySubCategoryRequest,
  getStaffSubCategoryRequest,
  resetStore,
  setIsStartStaff,
  updateStaffRequest,
  setMobileTitleOpen,
} from 'store/Manager/Staff'
import { showModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { sortStaffServiceRequest } from 'store/Sort/Sort.action'
import { UIButton, UICategoriesCards, UIServicesCards } from 'ui'
import UIBack from 'ui/Back'
import { MANAGER_SERVICES_EDIT, MANAGER_STAFF } from 'utils'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const StaffAddService: FC<PropsType> = ({ isAdd }) => {
  const {
    staff,
    services: servicesList,
    isLoading,
    staffSubCategory,
    isStaffServiceUpdate,
    isStaffModalUpdate,
    isStaffAdd,
    isMobileTitleOpen,
  } = useSelector((state: IRootState) => state.staff)

  const navigate = useNavigate()
  const [active, setActive] = useState(0)
  const [idSubCategory, setIdSubCategory] = useState<number>()
  const [isUpdateSubCategory, setIsUpdateSubCategory] = useState(false)
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [services, setServices] = useState<ServiceType[]>([])
  const [values, setValues] = useState<number[]>([])
  const [activeId, setActiveId] = useState<number | null>(null)
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [isSorting, setIsSorting] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id: idStaff } = useParams()
  const { isMobile, isTablet } = useResponsive()

  const handleClick = useCallback(() => navigate(MANAGER_STAFF), [navigate])

  const handlerDelete = (id: number) => {
    if (staff) {
      dispatch(
        updateStaffRequest({
          body: {
            name: staff?.name,
            description: staff?.description,
            position: staff?.position,
            phone: staff?.phone,
            avatar: staff?.avatar?.id,
            color: staff?.color,
            services: values.filter((i) => i !== id),
          },
          id: staff?.id,
          type: 'modal',
          isServicesList: servicesList?.length === 1,
          servicesId: id,
        }),
      )
    }
  }

  const handlerOpenMenuMobile = useCallback(() => {
    if (isMobile || isTablet) {
      setIsMobileOpen(true)
    }
  }, [isMobile, isTablet])

  const handleCategoryClick = (id: number) => {
    setActiveId(id)
    if (staff) {
      setIdSubCategory(id)
      dispatch(
        getStaffServicesBySubCategoryRequest({
          staffId: staff?.id,
          sectionId: id,
        }),
      )
      dispatch(setIsStartStaff(false))
    }
    handlerOpenMenuMobile()
  }

  const handleSortCategory = (ids: number[]) => {
    if (staff) {
      dispatch(
        sortStaffServiceRequest({
          ids,
          name: '/sorting/section_with_staff',
          staffId: staff?.id,
        }),
      )
      setIsSorting(true)
    }
  }

  const handleSortService = (ids: number[]) => {
    if (staff) {
      dispatch(
        sortStaffServiceRequest({
          ids,
          name: '/sorting/service_with_staff_section',
          staffId: staff?.id,
          sectionId: idSubCategory,
        }),
      )
    }
  }

  const handleAddService = () => {
    dispatch(
      showModal({
        modalType: ModalType.MANAGER_ADD_SERVICE,
        modalProps: { setValues, values },
      }),
    )
  }

  useEffect(() => {
    if (isUpdateSubCategory && staff && isStaffAdd) {
      dispatch(getStaffSubCategoryRequest(staff?.id))
    } else if (staff && !isStaffModalUpdate) {
      dispatch(getStaffSubCategoryRequest(staff?.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isStaffAdd, isUpdateSubCategory, isStaffModalUpdate])

  useEffect(() => {
    if (staffSubCategory.length && staff) {
      setIdSubCategory(staffSubCategory[0]?.id)

      dispatch(
        getStaffServicesBySubCategoryRequest({
          staffId: staff?.id,
          sectionId: staffSubCategory[0]?.id,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, staffSubCategory, staff?.id])

  useEffect(() => {
    setCategories(staffSubCategory)
  }, [staffSubCategory])

  useEffect(() => {
    if (servicesList && staff?.services) {
      setServices(servicesList)
      setValues(staff?.services?.map((i) => i.id))
    }
  }, [staff, servicesList])

  useEffect(() => {
    if (staff && (isStaffServiceUpdate || isStaffModalUpdate)) {
      dispatch(resetStore())
      dispatch(getOneStaffRequest(staff.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isStaffModalUpdate, isStaffServiceUpdate])

  useEffect(() => {
    if (isStaffServiceUpdate) {
      handleClick()
      setIsUpdateSubCategory(true)

      dispatch(resetStore())
    }
  }, [dispatch, handleClick, isStaffServiceUpdate])

  useEffect(() => {
    if (categories.length && !isSorting) {
      setActiveId(categories[0].id)
    }
  }, [categories, isSorting])

  useEffect(() => {
    if (!staff && idStaff) {
      dispatch(getOneStaffRequest(+idStaff))
      dispatch(getStaffSubCategoryRequest(+idStaff))
    }
  }, [dispatch, idStaff, staff])

  return (
    <LayoutManagerSidebar
      titleHeader={
        <p className={styles.headerTitle}>
          {isStaffAdd ? (
            <>
              <span>4.</span> {t('MANAGER.SERVICES.TITLE')}
            </>
          ) : (
            t('MANAGER.SERVICES.TITLE')
          )}
        </p>
      }
      mobileActive={isMobileOpen || isMobileTitleOpen}
      titleSidebar={t('MANAGER.SERVICES.SELECT_SUBCATEGORIES')}
      titleBtnHeader={t('MANAGER.BUTTON.ADD_SERVICES')}
      handleHeader={handleAddService}
      {...(!isAdd && {
        handleBack: handleClick,
        titleCenter: `${t('MANAGER.STAFF.TITLE')} - ${staff?.name}`,
      })}
      sidebarContent={
        <div className={styles.categoriesCards}>
          <UICategoriesCards
            active={active}
            setActive={setActive}
            categories={categories}
            setCategories={setCategories}
            handler={handleCategoryClick}
            handlerSort={handleSortCategory}
            activeId={activeId}
          />

          <div className={styles.btn}>
            <UIButton label={t('COMMON.DONE')} handler={handleClick} />
          </div>
        </div>
      }
    >
      {(isMobileOpen || isMobileTitleOpen) && (
        <UIBack
          type="title"
          title={categories?.find((item) => item.id === activeId)?.name}
          setIsMobileOpen={() => {
            setIsMobileOpen(false)
            dispatch(setMobileTitleOpen(false))
          }}
        />
      )}

      <div className={styles.servicesCard}>
        <Spin spinning={isLoading}>
          <UIServicesCards
            onClick={(id) =>
              navigate(MANAGER_SERVICES_EDIT.replace(':id', String(id)))
            }
            services={services}
            setServices={setServices}
            handlerDelete={handlerDelete}
            handlerSort={handleSortService}
            remove
          />
        </Spin>
      </div>
    </LayoutManagerSidebar>
  )
}

export default memo(StaffAddService)
