/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import i18n from 'lib/i18n/i18n'
import { FORMAT_TIME_HOUR_WITH_MIN, STANDART_DATE } from 'utils'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import { EditorState as EditorStateType } from 'react-draft-wysiwyg'

// eslint-disable-next-line import/no-cycle
import DateHelper from './date'
import getType from './getType'

export default (() => {
  const validateEmail = (value: string) => {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  const renderTime = (startTime?: string | null, endTime?: string | null) => {
    if (!startTime && !endTime) return null

    return `
      ${DateHelper.toFormat(
        new Date(`${STANDART_DATE} ${startTime}`),
        FORMAT_TIME_HOUR_WITH_MIN,
      )} -
      ${DateHelper.toFormat(
        new Date(`${STANDART_DATE} ${endTime}`),
        FORMAT_TIME_HOUR_WITH_MIN,
      )}
    `
  }

  const replaceByTypes = (
    string: string,
    param: { [key: string]: string | number | undefined },
    types: string[] = ['id'],
  ) => {
    let result = string

    for (let index = 0; index < types.length; index++) {
      result = result.replace(`:${types[index]}`, String(param?.[types[index]]))
    }

    return result
  }

  const getError = (error: { [key: string]: string[] }) => {
    if (
      getType(error) !== 'string' &&
      !error.appointments &&
      Object.keys(error)?.length
    ) {
      return error[Object.keys(error)?.[0]]?.[0]
    }

    if (error.appointments) {
      return error
    }

    return `${i18n.t('ERROR.ALERT_GET_ONE')} ${i18n.t('ERROR.ALERT_GET_TWO')}`
  }

  const getFisrtMessage = (error: string | string[]) => {
    if (Array.isArray(error)) {
      return error?.[0]
    }

    return error
  }

  const draftToHtmlEditor = (value: string) => {
    return draftToHtml(
      convertToRaw((value as unknown as EditorStateType)?.getCurrentContent()),
    )
  }

  const convertFromHTMLEditor = (value: string) => {
    const contentBlock = htmlToDraft(value)
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks as any,
    )

    const editorState = EditorState.createWithContent(contentState)

    return editorState
  }

  return {
    validateEmail,
    renderTime,
    replaceByTypes,
    getError,
    getFisrtMessage,
    draftToHtmlEditor,
    convertFromHTMLEditor,
  }
})()
