import * as React from 'react'

import { PickerTimeProps } from 'antd/es/date-picker/generatePicker'

import UIAntDatePicker from '../DatePicker'

import styles from './style.module.scss'
import { DateHelper } from 'utils/helpers'
import cn from 'classnames'

export type UIAntTimePickerProps = Omit<PickerTimeProps<Date>, 'picker'> & {
  error?: string
}

const UIAntTimePicker = React.forwardRef<any, UIAntTimePickerProps>(
  (props, ref) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [valueSecond, setValueSecond] = React.useState('')

    const handleSelect = (date: Date) => {
      const dateSeconds = DateHelper.toFormat(date, 'mm')
      const dateHours = DateHelper.toFormat(date, 'HH')

      setValueSecond(dateSeconds)

      if (props.onSelect) {
        props.onSelect(date)
      }

      if (
        (valueSecond && dateSeconds !== valueSecond) ||
        (valueSecond && dateSeconds === dateHours)
      ) {
        setIsOpen(false)
      }
    }

    return (
      <div className={cn('wrapperInput', styles.wrapper)}>
        <UIAntDatePicker
          {...props}
          picker="time"
          mode={undefined}
          ref={ref}
          open={isOpen}
          popupClassName="popupTimePicker"
          onOpenChange={(open) => setIsOpen(open)}
          onSelect={(date) => handleSelect(date)}
        />

        {props.error && (
          <div className={cn('wrapperInputError', styles.error)}>
            {props.error}
          </div>
        )}

        <div className={'wrapperInputErrorTooltip'}>{props?.error}</div>
      </div>
    )
  },
)

UIAntTimePicker.displayName = 'UIAntTimePicker'

export default UIAntTimePicker
