import { FC, memo } from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as InfoSVG } from 'assets/images/common/information.svg'
import { UICustomCard, UITextArea } from 'ui'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const ProfileSalonAbout: FC<PropsType> = ({ values, setValues, error }) => {
  const { t } = useTranslation()

  const handleChange = (value: string) => {
    setValues((prevState) => ({ ...prevState, about: value }))
  }

  return (
    <UICustomCard
      className={styles.tooltipWrapp}
      title={
        <>
          <span>5. </span> {t('MANAGER.PROFILE_SALON.ITEM_5.0')}
          <div className={styles.tooltip}>
            <InfoSVG />
            <div className={styles.tooltipContent}>
              <div>{t('MANAGER.PROFILE_SALON.ABOUT_1')} </div>
              <div>&bull; {t('MANAGER.PROFILE_SALON.ABOUT_2')}</div>
              <div>&bull; {t('MANAGER.PROFILE_SALON.ABOUT_3')}</div>
              <div>&bull; {t('MANAGER.PROFILE_SALON.ABOUT_4')}</div>
              <div>&bull; {t('MANAGER.PROFILE_SALON.ABOUT_5')}</div>
              <div>&bull; {t('MANAGER.PROFILE_SALON.ABOUT_6')}</div>
              <div>&bull; {t('MANAGER.PROFILE_SALON.ABOUT_7')}</div>
              <div>&bull; {t('MANAGER.PROFILE_SALON.ABOUT_8')}</div>
              <div>&bull; {t('MANAGER.PROFILE_SALON.ABOUT_9')}</div>
              <div>&bull; {t('MANAGER.PROFILE_SALON.ABOUT_10')}</div>
            </div>
          </div>
        </>
      }
    >
      <div className={styles.aboutText}>
        <UITextArea
          error={error}
          value={values.about?.trimStart()}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </UICustomCard>
  )
}

export default memo(ProfileSalonAbout)
