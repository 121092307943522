import { Dispatch, FC, memo, SetStateAction, SyntheticEvent } from 'react'

import { useTranslation } from 'react-i18next'

import {
  AdminSettingsBlockSubscriptionsType,
  AdminSettingsBlockType,
} from 'views/admin/Settings/index.type'

// eslint-disable-next-line import/no-cycle
import styles from './style.module.scss'
import { AdminSettingsInfoBlock, AddSubscriptionBlock } from '../index'
// eslint-disable-next-line import/no-cycle

type PropTypes = {
  firstBlockState: AdminSettingsBlockType & AdminSettingsBlockSubscriptionsType
  setFirstBlockState: Dispatch<
    SetStateAction<AdminSettingsBlockType & AdminSettingsBlockSubscriptionsType>
  >
}

const FreeTrialBlock: FC<PropTypes> = ({
  firstBlockState,
  setFirstBlockState,
}) => {
  const { t } = useTranslation()

  const handleCustomChange = (key: string, value: string | boolean) => {
    setFirstBlockState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleChecked = () => {
    handleCustomChange('isChecked', !firstBlockState.isChecked)
  }

  const handleChange = (event: SyntheticEvent) => {
    handleCustomChange('value', (event.target as HTMLInputElement).value)
  }

  const handleChangeSubs = (value: AdminSettingsBlockSubscriptionsType) => {
    const { description, details, name } = value

    setFirstBlockState((prevState) => ({
      ...prevState,
      name,
      details,
      description,
    }))
  }

  return (
    <div className={styles.wrapper}>
      <AdminSettingsInfoBlock
        description={t('ADMIN.SETTINGS.DESCRIPTION_1')}
        isChecked={firstBlockState.isChecked}
        onChange={handleChange}
        onChecked={handleChecked}
        title={t('ADMIN.SETTINGS.TITLE_1')}
        value={firstBlockState.value}
      />

      <AddSubscriptionBlock
        isTrial
        errors={undefined}
        onChange={handleChangeSubs}
        name={firstBlockState.name}
        details={firstBlockState.details}
        description={firstBlockState.description}
      />
    </div>
  )
}

export default memo(FreeTrialBlock)
