import {
  ADD_IMPORT_CONTACTS_FAILURE,
  ADD_IMPORT_CONTACTS_REQUEST,
  ADD_IMPORT_CONTACTS_SUCCESS,
  CLEAR_CLIENTS_ERROR,
  GET_ERROR_IMPORT_FAILURE,
  GET_ERROR_IMPORT_REQUEST,
  GET_ERROR_IMPORT_SUCCESS,
  GET_EXPORT_CONTACTS_FAILURE,
  GET_EXPORT_CONTACTS_REQUEST,
  GET_EXPORT_CONTACTS_SUCCESS,
  GET_MANAGER_CLIENTS,
  GET_MANAGER_CLIENTS_FAILURE,
  GET_MANAGER_CLIENTS_SUCCESS,
  GET_MANAGER_USERS,
  GET_MANAGER_USERS_FAILURE,
  GET_MANAGER_USERS_SUCCESS,
  CLEAR_CLIENTS_BASE,
} from './Clients.constant'
import { IManagerClientState, ManagerClientsActionTypes } from './Clients.type'

const initialState: IManagerClientState = {
  isLoading: false,
  isSidebarLoading: false,
  errorMessage: null,
  data: [],
  meta: {
    page: 1,
    perPage: 0,
    total: 0,
  },
  status: null,
  exportFile: null,
  errorFile: null,
}

export default function managerClientsReducer(
  state = initialState,
  action: ManagerClientsActionTypes,
): IManagerClientState {
  switch (action.type) {
    case GET_MANAGER_CLIENTS:
    case GET_MANAGER_USERS:
      return { ...state, isLoading: true }

    case GET_EXPORT_CONTACTS_REQUEST:
    case GET_ERROR_IMPORT_REQUEST:
    case ADD_IMPORT_CONTACTS_REQUEST:
      return { ...state, isSidebarLoading: true }

    case GET_MANAGER_CLIENTS_SUCCESS:
    case GET_MANAGER_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        meta: {
          page: action.payload.meta.current_page,
          perPage: action.payload.meta.per_page,
          total: action.payload.meta.total,
        },
        isLoading: false,
      }

    case GET_MANAGER_CLIENTS_FAILURE:
    case GET_MANAGER_USERS_FAILURE:
      return { ...state, ...action.payload, isLoading: false }

    case GET_ERROR_IMPORT_FAILURE:
    case GET_EXPORT_CONTACTS_FAILURE:
    case ADD_IMPORT_CONTACTS_FAILURE:
      return { ...state, ...action.payload, isSidebarLoading: false }

    case GET_EXPORT_CONTACTS_SUCCESS:
      return { ...state, isSidebarLoading: false, exportFile: action.payload }

    case GET_ERROR_IMPORT_SUCCESS:
      return { ...state, isSidebarLoading: false, errorFile: action.payload }

    case ADD_IMPORT_CONTACTS_SUCCESS:
      return { ...state, isSidebarLoading: false, status: action.payload }

    case CLEAR_CLIENTS_BASE:
      return {
        ...state,
        status: null,
        errorFile: null,
        exportFile: null,
      }

    case CLEAR_CLIENTS_ERROR:
      return {
        ...state,
        errorMessage: null,
      }
    default:
      return { ...state }
  }
}
