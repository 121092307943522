export const INITIAL_VALUES = {
  query: '',
  from: '',
  staff_id: null,
  service_id: null,
  staffName: '',
  started_at: '',
  finished_at: '',
  client_id: null,
  clientName: '',
  contact_name: '',
  contact_phone: '',
  serviceDuration: '',
  price: '',
  id: null,
  time: {
    from: '',
    to: '',
  },
}
