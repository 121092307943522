import {
  LoginType,
  RegisterType,
  RegisterRequestType,
  ResetPasswordType,
  SocialLoginType,
} from 'entities/unauthorized/Auth'
import { POST } from 'utils/http'

const basePath = '/auth'

export const loginApi = (body: LoginType) => POST(`${basePath}/login`, body)

export const socialLoginApi = (body: SocialLoginType) =>
  POST(`${basePath}/social/${body.provider}`, body)

export const registerApi = (type: RegisterRequestType, body: RegisterType) =>
  POST(`${basePath}/register/${type}`, body)

export const verifyApi = (token: string) =>
  POST(`${basePath}/verify`, { token })

export const forgotPasswordApi = (email: string) =>
  POST(`${basePath}/forgot-password`, { email })

export const resetPasswordApi = (body: ResetPasswordType) =>
  POST(`${basePath}/reset-password`, body)

export const logoutApi = () => POST(`${basePath}/logout`)
