export enum SalonTypes {
  BASIC = 'Basic',
  FREE = 'Free',
  NOT_PAID = 'Not paid',
  PREMIUM = 'Premium',
}

export enum SalonStatus {
  CONFIRMED = 'Confirmed',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
}
