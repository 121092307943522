import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import {
  getCancelledOrderHistoryApi,
  getOrderHistoryApi,
} from 'services/http/manager/OrderHistory'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  getOrderHistorySuccess,
  getOrderHistoryFailure,
  getCancelledOrderHistorySuccess,
  getCancelledOrderHistoryFailure,
} from './Orders.action'
import {
  GET_CANCELLED_ORDER_HISTORY_REQUEST,
  GET_ORDER_HISTORY_REQUEST,
} from './Orders.constant'
import { IGetOrderHistoryRequest, IOrderHistoryResponse } from './Orders.type'

function* workerGetOrderHistory({ payload }: IGetOrderHistoryRequest) {
  try {
    const response: { data: IOrderHistoryResponse } = yield call(
      getOrderHistoryApi,
      payload,
    )

    yield put(getOrderHistorySuccess(response.data))
  } catch (error) {
    yield getRequestErrors(getOrderHistoryFailure, error as string)
  }
}

function* watchGetOrderHistory() {
  yield takeLatest(GET_ORDER_HISTORY_REQUEST, workerGetOrderHistory)
}

function* workerGetCancelledOrderHistory({ payload }: IGetOrderHistoryRequest) {
  try {
    const response: { data: IOrderHistoryResponse } = yield call(
      getCancelledOrderHistoryApi,
      payload,
    )

    yield put(getCancelledOrderHistorySuccess(response.data))
  } catch (error) {
    yield getRequestErrors(getCancelledOrderHistoryFailure, error as string)
  }
}

function* watchGetCancelledOrderHistory() {
  yield takeLatest(
    GET_CANCELLED_ORDER_HISTORY_REQUEST,
    workerGetCancelledOrderHistory,
  )
}

export const orderHistoryWatchers: ForkEffect[] = [
  fork(watchGetOrderHistory),
  fork(watchGetCancelledOrderHistory),
]
