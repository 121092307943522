export const UPLOAD_MEDIA_REQUEST = '@media/UPLOAD_MEDIA_REQUEST'
export const UPLOAD_MEDIA_SUCCESS = '@media/UPLOAD_MEDIA_SUCCESS'
export const UPLOAD_MEDIA_FAILURE = '@media/UPLOAD_MEDIA_FAILURE'

export const DELETE_MEDIA_REQUEST = '@media/DELETE_MEDIA_REQUEST'
export const DELETE_MEDIA_SUCCESS = '@media/DELETE_MEDIA_SUCCESS'
export const DELETE_MEDIA_FAILURE = '@media/DELETE_MEDIA_FAILURE'

export const UPLOAD_ARRAY_MEDIA_SUCCESS = '@media/UPLOAD_ARRAY_MEDIA_SUCCESS'

export const CLEAR_MEDIA_STOR = '@media/CLEAR_MEDIA_STOR'
export const RESET_LINK_TYPE = '@media/RESET_LINK_TYPE'

export const SET_IMAGE_LIST = '@media/SET_IMAGE_LIST'

export const UPLOAD_LINK_CATEGORY = 'UPLOAD_LINK_CATEGORY'
export const UPLOAD_LINK_SUBCATEGORY = 'UPLOAD_LINK_SUBCATEGORY'
export const UPLOAD_LINK_STAFF_LOGO = 'UPLOAD_LINK_STAFF_LOGO'
export const UPLOAD_LINK_STAFF_PORTFILIO = 'UPLOAD_LINK_STAFF_PORTFILIO'

export const CLEAR_ERRORS = '@staff/CLEAR_ERRORS'
