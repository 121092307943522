// eslint-disable @typescript-eslint/no-unsafe-return
import { useEffect, useMemo } from 'react'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import useResponsive from 'hooks/useResponsive'
import { addAlert } from 'store/Alert'
import {
  clearManagerSubsctiption,
  getManagerSubscriptionRequest,
  updateManagerHistorySubscriptionRequest,
} from 'store/Manager/Subscription/Subscription.action'
import { IRootState } from 'store/Root.reducer'
import { UITooltip } from 'ui'
import { AdminSettingsBlockSubscriptionsType } from 'views/admin/Settings/index.type'

import { HistoryContent, HistoryHeader } from './History'
import styles from './style.module.scss'
import SubscribeContent from './SubscribeContent'
import SubscribeHeader from './SubscribeHeader'
import SubscriptionUIAccordion from '../ui/Accordion'
import { useNavigate, useSearchParams } from 'react-router-dom'

const SubscriptionInfo = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isMobile } = useResponsive()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const subscriptionId = searchParams.get('subscription_id')
  const baToken = searchParams.get('ba_token')
  const token = searchParams.get('token')

  const {
    errorMessage,
    isLoading,
    settings,
    subscribe,
    subscriptions,
    currentSubscriptionLoading,
  } = useSelector((store: IRootState) => store.managerSubscription)

  const subscriptionsFreeTrial: AdminSettingsBlockSubscriptionsType[] = useMemo(
    () => [
      {
        freeSubscribe: subscribe,
        isHideAction: true,
        freeDuration: {
          start: subscribe?.subscribe_at || '',
          end: subscribe?.finished_at || '',
        },
        finished_at: subscribe?.finished_at,
        description: settings.subscription_plan_free_trial_description,
        details: settings.subscription_plan_free_trial_details,
        name: settings.subscription_plan_free_trial_name,
        price:
          subscribe && subscribe?.subscribe_at && subscribe?.finished_at
            ? t('MANAGER.SUBSCRIPTION.TRIAL_DAYS', {
                count: settings.subscription_plan_free_trial_days,
              })
            : t('COMMON.MANUAL'),
        subscribe,
      },
    ],
    [
      subscribe,
      settings.subscription_plan_free_trial_description,
      settings.subscription_plan_free_trial_details,
      settings.subscription_plan_free_trial_name,
      settings.subscription_plan_free_trial_days,
      t,
    ],
  )

  const subscriptionsPay: AdminSettingsBlockSubscriptionsType[] = useMemo(
    () => [
      ...subscriptions.map(({ price, price_type, ...item }) => ({
        ...item,
        price: `$${price}/${(price_type === 0
          ? t('COMMON.MONTH')
          : t('COMMON.YEAR')
        ).toLowerCase()}`,
      })),
    ],
    [t, subscriptions],
  )

  useEffect(() => {
    if (!subscriptionId && !baToken) {
      dispatch(getManagerSubscriptionRequest())
    }
  }, [baToken, dispatch, subscriptionId])

  useEffect(() => {
    if (subscriptionId || baToken || token) {
      dispatch(
        updateManagerHistorySubscriptionRequest({
          subscription_id: subscriptionId || '',
          ba_token: baToken || '',
          token: token || '',
        }),
      )
    }
  }, [baToken, dispatch, navigate, searchParams, subscriptionId, token])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
        }),
      )
    }

    return () => {
      dispatch(clearManagerSubsctiption())
    }
  }, [dispatch, errorMessage])

  return (
    <Spin spinning={isLoading}>
      <div className={styles.wrapper}>
        <p className={styles.title}>
          {t('MANAGER.SUBSCRIPTION')}
          <UITooltip
            content={t('MANAGER.SUBSCRIPTION.TOOLTIP')}
            placement={isMobile ? 'bottom' : 'right'}
          />
        </p>

        {(subscribe
          ? [...subscriptionsFreeTrial, ...subscriptionsPay]
          : subscriptionsPay
        )?.map((item, index) => (
          <Spin spinning={item?.id === currentSubscriptionLoading} key={index}>
            <div className={styles.accordionItemWrapp}>
              <SubscriptionUIAccordion
                isDefaultOpen={index === 0 && !!item?.details?.length}
                topHeader={
                  <SubscribeHeader
                    {...item}
                    isHideSubscribe={
                      !!subscriptions.find(
                        (i) => i.subscribe?.status !== 99 && i.subscribe,
                      )
                    }
                  />
                }
                content={
                  <SubscribeContent
                    isFreeTrial={index === 0}
                    reminder_subscription_plan_deprecated_time={
                      settings.reminder_subscription_plan_deprecated_time
                    }
                    subscription_plan_free_trial_days={
                      settings.subscription_plan_free_trial_days
                    }
                    {...item}
                  />
                }
              />
            </div>
          </Spin>
        ))}

        <p className={styles.title}>{t('MANAGER.SUBSCRIPTION.HISTORY')}</p>
        <SubscriptionUIAccordion
          topHeader={<HistoryHeader />}
          content={<HistoryContent />}
        />
      </div>
    </Spin>
  )
}

export default SubscriptionInfo
