import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as EditSVG } from 'assets/images/common/redesign/edit.svg'
import { ServiceType } from 'entities/manager/service'
import { UIButton, UIServicesCards } from 'ui'
import { MANAGER_STAFF_SERVICES } from 'utils'

import styles from './style.module.scss'

type PropsType = {
  services: ServiceType[]
  setServices: (value: ServiceType[]) => void
  staffId?: number
}

const ServicesUIServices: FC<PropsType> = ({
  services,
  setServices,
  staffId,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handlerEditService = () => {
    navigate(MANAGER_STAFF_SERVICES.replace(':id', String(staffId)))
  }

  return (
    <div>
      <div className={styles.edit}>
        <UIButton
          type="text"
          label={t('MANAGER.STAFF.EDIT_SERVICES')}
          handler={handlerEditService}
          icon={<EditSVG />}
          isGreen
        />
      </div>

      <UIServicesCards
        services={services}
        setServices={setServices}
        isNotMove
      />
    </div>
  )
}

export default ServicesUIServices
