/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, memo, useEffect, useState } from 'react'

import classNames from 'classnames'
import GoogleMapReact from 'google-map-react'
import PlacesAutocomplete from 'react-google-autocomplete'
import { useTranslation } from 'react-i18next'

import pinImg from 'assets/images/common/pin.png'
import { ReactComponent as SearchSVG } from 'assets/images/common/redesign/search.svg'
import { ReactComponent as TooltipSVG } from 'assets/images/common/redesign/tooltip.svg'
import { GOOGLE_KEY, VALIDATION_CLASS } from 'utils'
import { SalonDataType } from 'views/manager/ProfileSalon/index.type'

import styles from './style.module.scss'
import { StringHelper } from 'utils/helpers'

type PropsType = {
  error?: string[]
  setValues?: (value: React.SetStateAction<SalonDataType>) => void
  values?: SalonDataType
}

const INITIAL_LAT = 19.49835602
const INITIAL_LNG = -99.01502627

const getLacationValues = (location?: google.maps.LatLng) => {
  const initialValues = {
    locationLat: INITIAL_LAT,
    locationLng: INITIAL_LNG,
  }

  if (!location) return initialValues
  const { lat, lng } = location

  const locationLat = typeof lat === 'number' ? lat : lat()
  const locationLng = typeof lng === 'number' ? lng : lng()

  return {
    locationLat,
    locationLng,
  }
}

const UIGoogleMap: FC<PropsType> = ({ values, setValues, error }) => {
  const { t } = useTranslation()
  const [geometry, setGeometry] =
    useState<null | google.maps.places.PlaceGeometry>(null)
  const [addressName, setAddressName] = useState<string>('')
  const [gmapsLoaded, setGmapsLoaded] = useState(false)
  const [zoom, setZoom] = useState(16)

  const { locationLat, locationLng } = getLacationValues(
    values?.address?.geometry?.location,
  )

  const handleAddCity = (obj: google.maps.places.PlaceResult) => {
    if (setValues) {
      setValues((prev) => ({ ...prev, address: obj }))
    }

    if (obj.geometry) {
      setAddressName(obj.formatted_address || '')
      setGeometry(obj.geometry)
      setZoom(16)
    }
  }

  const renderAdress = () => {
    if (values?.address?.formatted_address)
      return values?.address?.formatted_address
    if (addressName) return addressName

    return (
      <>
        <TooltipSVG />
        {t('COMMON.FOR_EXAMPLE')}:{' '}
        {
          'Av Alfonso Reyes 374, Zona Tampiquito, Lomas del Valle, 66250 San Pedro Garza García, N.L., Mexico '
        }
      </>
    )
  }

  useEffect(() => {
    // @ts-ignore
    window.initMap = () => setGmapsLoaded(true)
    const gmapScriptEl = document.createElement('script')

    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}&libraries=places&callback=initMap&language=es`

    // @ts-ignore
    document
      .querySelector('body')
      .insertAdjacentElement('beforeend', gmapScriptEl)

    return () => {
      setGmapsLoaded(false)
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <div className={styles.mapInput}>
          <p>
            {t('MANAGER.PROFILE_SALON.STREET_ADDRESS')}{' '}
            <span>({t('COMMON.NECESSARY')})</span>
          </p>

          <div className={styles.inputWrapp}>
            {gmapsLoaded && (
              <>
                <PlacesAutocomplete
                  className={classNames('ant-input', {
                    [styles['map-error-search']]: error,
                    [VALIDATION_CLASS]: error,
                  })}
                  onPlaceSelected={(places) => handleAddCity(places)}
                  apiKey={GOOGLE_KEY}
                  options={{
                    types: ['geocode', 'establishment'],
                  }}
                  placeholder={t('MANAGER.ORDERS.SEARCH')}
                />

                {error && (
                  <p className="text-error desc-1">
                    {StringHelper.getFisrtMessage(error)}
                  </p>
                )}
              </>
            )}

            <span className={styles.icon}>
              <SearchSVG />
            </span>
          </div>
        </div>

        <div className={styles.infoText}>{renderAdress()}</div>
      </div>

      <div className={styles.map}>
        {gmapsLoaded && (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: GOOGLE_KEY as string,
            }}
            defaultCenter={{
              lat: locationLat,
              lng: locationLng,
            }}
            zoom={zoom}
            center={{
              lat: geometry?.location?.lat() || locationLat,
              lng: geometry?.location?.lng() || locationLng,
            }}
            yesIWantToUseGoogleMapApiInternals
          >
            {locationLat && locationLng && (
              <img
                className={styles.marker}
                src={pinImg}
                /* eslint-disable */
                // @ts-ignore
                lat={locationLat}
                lng={locationLng}
                /* eslint-disable */
              />
            )}
          </GoogleMapReact>
        )}
      </div>
    </div>
  )
}

export default memo(UIGoogleMap)
