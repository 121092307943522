import {
  ADD_VALIDATION_ERROR,
  CLEAR_VALIDATION_ERROR,
} from './Validation.constant'
import { IValidationState, ValidationActionsTypes } from './Validation.type'

const INITIAL_STATE: IValidationState = {
  isError: false,
}

export default function validationReducer(
  state = INITIAL_STATE,
  action: ValidationActionsTypes,
): IValidationState {
  switch (action.type) {
    case ADD_VALIDATION_ERROR:
      return { ...state, isError: true }
    case CLEAR_VALIDATION_ERROR:
      return { ...state, isError: false }
    default:
      return { ...state }
  }
}
