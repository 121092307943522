import { FC, useCallback, memo } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { ICustomer } from 'entities/common'
import { IManagerClientsUsersData } from 'store/Manager/Clients/Clients.type'
import { UIAvatar } from 'ui'

import styles from './style.module.scss'
import plugAvatar from '../../../../assets/images/common/redesign/plugAvatarDefault.svg'

type PropsType = (ICustomer | IManagerClientsUsersData) & {
  setIsDrawer: (arg: boolean) => void
}

const ClientTableMobile: FC<PropsType> = ({
  setIsDrawer,
  id,
  name,
  phone,
  ...props
}) => {
  const { t } = useTranslation()

  const handleRowClick = useCallback(() => {
    setIsDrawer(true)
  }, [setIsDrawer])

  return (
    <div className={styles.listWrapp}>
      <div className={styles.wrapper} onClick={handleRowClick}>
        <div className={classNames(styles.avatar, styles.row)}>
          <span className={styles.subTitle}>{t('MANAGER.CLIENTS.NAME')}</span>
          <div>
            <UIAvatar
              size={30}
              src={(props as ICustomer)?.profile?.avatar?.path || plugAvatar}
            />

            <p> {name}</p>
          </div>
        </div>

        <div className={styles.row}>
          <span className={styles.subTitle}>{t('MANAGER.CLIENTS.EMAIL')}</span>

          {(props as ICustomer)?.email || '---'}
        </div>

        <div className={styles.row}>
          <span className={styles.subTitle}>{t('MANAGER.CLIENTS.PHONE')}</span>

          {phone || (props as ICustomer)?.profile?.phone || '---'}
        </div>
      </div>
    </div>
  )
}

export default memo(ClientTableMobile)
