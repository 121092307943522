import { RoutesType } from 'entities/view'
import LayoutAdmin from 'layouts/Admin'
import LayoutManager from 'layouts/Manager'
import {
  ADMIN_BLOG,
  ADMIN_BLOG_ADD,
  ADMIN_BLOG_ADD_CATEGORY,
  ADMIN_BLOG_EDIT,
  ADMIN_BLOG_EDIT_CATEGORY,
  ADMIN_CATEGORIES,
  ADMIN_CUSTOMERS,
  ADMIN_CUSTOMER_PROFILE,
  ADMIN_HELP_FOR_BUSINESS,
  ADMIN_HELP_FOR_BUSINESS_ANSWER_PAGE,
  ADMIN_HELP_FOR_CUSTOMER,
  ADMIN_HELP_FOR_CUSTOMER_ANSWER_PAGE,
  ADMIN_NOTIFICATION,
  ADMIN_PAGES,
  ADMIN_PAGES_EDIT,
  ADMIN_SALONS,
  ADMIN_SALONS_PROFILE,
  ADMIN_SEO,
  ADMIN_SEO_EDIT_CATEGORY,
  ADMIN_SEO_EDIT_SUBCATEGORY,
  ADMIN_SETTINGS,
  CLIENT_LINK,
  MANAGER_APPOINTMENTS,
  MANAGER_CLIENTS,
  MANAGER_DOCUMENT,
  MANAGER_FEEDBACKS,
  MANAGER_ORDERS,
  MANAGER_PROFILE_SALON,
  MANAGER_SERVICES,
  MANAGER_SERVICES_ADD,
  MANAGER_SERVICES_EDIT,
  MANAGER_SETTING,
  MANAGER_SHIFTS,
  MANAGER_STAFF,
  MANAGER_STAFF_ADD,
  MANAGER_STAFF_EDIT,
  MANAGER_STAFF_SERVICES,
  MANAGER_SUBSCRIPTION,
  MANAGER_SUPPORT,
  NOT_FOUND,
} from 'utils'
import ViewCategories from 'views/admin/Categories'
import ViewAppointments from 'views/manager/Appointments'
import ViewManagerClient from 'views/manager/Client'
import ViewManagerFeedBack from 'views/manager/Feedback'
import ViewProfileSalon from 'views/manager/ProfileSalon'
import ViewServices from 'views/manager/Services/Services'
import ViewManagerSetting from 'views/manager/Setting'
import ViewAddStaff from 'views/manager/Staff/AddStaff'
import ViewEditStaff from 'views/manager/Staff/EditStaff'
import ViewStaff from 'views/manager/Staff/Staff'
import ViewStaffServices from 'views/manager/Staff/StaffServices'
import ViewManagerSupport from 'views/manager/Support'
import ViewNotFound from 'views/NotFound'
import Redirect from 'widgets/Redirect'

import ViewAdminBlog from './admin/Blog'
import ViewAdminBlogArticle from './admin/Blog/Article'
import ViewAdminBlogCategory from './admin/Blog/Category'
import ViewAdminCustomer from './admin/Customer'
import ViewAdminCustomerProfile from './admin/Customer/Profile'
import ViewAdminFaq from './admin/Faq'
import ViewAdminFaqAnswer from './admin/Faq/Answer'
import ViewAdminNotification from './admin/Notification'
import ViewAdminPages from './admin/Pages'
import ViewAdminPagesEdit from './admin/Pages/Edit'
import ViewAdminSalons from './admin/Salons'
import ViewAdminSalonProfile from './admin/Salons/Profile'
import ViewAdminSeo from './admin/Seo'
import ViewAdminSeoEdit from './admin/Seo/Edit'
import ViewAdminSetting from './admin/Settings'
import ViewManagerDocument from './manager/Document'
import ViewManagerOrders from './manager/Orders'
import ViewAddServices from './manager/Services/AddService'
import ViewEditServices from './manager/Services/EditService'
import ViewManagerShifts from './manager/Shifts'
import ViewManagerSubscription from './manager/Subscription'

const clientRoutes: RoutesType[] = [
  {
    path: '/',
    element: <Redirect path={CLIENT_LINK} />,
    name: 'ViewHome',
  },
  {
    path: NOT_FOUND,
    element: <ViewNotFound />,
    name: 'ViewNotFound',
  },
]

const managerRoutes: RoutesType[] = [
  {
    path: MANAGER_PROFILE_SALON,
    element: <ViewProfileSalon />,
    name: 'ViewProfileSalon',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_SERVICES,
    element: <ViewServices />,
    name: 'ViewServices',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_SERVICES_EDIT,
    element: <ViewEditServices />,
    name: 'ViewEditServices',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_SERVICES_ADD,
    element: <ViewAddServices />,
    name: 'ViewEditServices',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_STAFF,
    element: <ViewStaff />,
    name: 'ViewStaff',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_STAFF_SERVICES,
    element: <ViewStaffServices />,
    name: 'ViewStaffServices',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_STAFF_ADD,
    element: <ViewAddStaff />,
    name: 'ViewAddStaff',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_STAFF_EDIT,
    element: <ViewEditStaff />,
    name: 'ViewAddStaff',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_APPOINTMENTS,
    element: <ViewAppointments />,
    name: 'ViewAppointments',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_CLIENTS,
    element: <ViewManagerClient />,
    name: 'ViewManagerClient',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_SETTING,
    element: <ViewManagerSetting />,
    name: 'ViewManagerSetting',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_SUPPORT,
    element: <ViewManagerSupport />,
    name: 'ViewManagerSupport',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_FEEDBACKS,
    element: <ViewManagerFeedBack />,
    name: 'ViewManagerFeedBack',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_SUBSCRIPTION,
    element: <ViewManagerSubscription />,
    name: 'ViewManagerSubscription',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_ORDERS,
    element: <ViewManagerOrders />,
    name: 'ViewManagerOrders',
    Layout: LayoutManager,
  },
  {
    path: MANAGER_DOCUMENT,
    element: <ViewManagerDocument />,
    name: 'ViewManagerDocument',
    Layout: LayoutManager,
  },
  // backlog
  // {
  //   path: MANAGER_REPORTS,
  //   element: <ViewManagerReports />,
  //   name: 'ViewManagerReports',
  //   Layout: LayoutManager,
  // },
  {
    path: MANAGER_SHIFTS,
    element: <ViewManagerShifts />,
    name: 'ViewManagerShifts',
    Layout: LayoutManager,
  },
]

const adminRoutes: RoutesType[] = [
  {
    path: ADMIN_CUSTOMERS,
    element: <ViewAdminCustomer />,
    name: 'ViewAdminCustomer',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_CUSTOMER_PROFILE,
    element: <ViewAdminCustomerProfile />,
    name: 'ViewAdminCustomerProfile',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_SALONS,
    element: <ViewAdminSalons />,
    name: 'ViewAdminSalons',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_SALONS_PROFILE,
    element: <ViewAdminSalonProfile />,
    name: 'ViewAdminSalonsProfile',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_CATEGORIES,
    element: <ViewCategories />,
    name: 'ViewCategories',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_PAGES,
    element: <ViewAdminPages />,
    name: 'ViewAdminPages',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_PAGES_EDIT,
    element: <ViewAdminPagesEdit />,
    name: 'ViewAdminPagesEdit',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_SETTINGS,
    element: <ViewAdminSetting />,
    name: 'ViewAdminSetting',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_HELP_FOR_BUSINESS,
    element: <ViewAdminFaq isManager={true} />,
    name: 'ViewAdminHelpForBusiness',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_HELP_FOR_BUSINESS_ANSWER_PAGE,
    element: <ViewAdminFaqAnswer isManager={true} />,
    name: 'ViewAdminHelpForBusinessAnswer',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_HELP_FOR_CUSTOMER,
    element: <ViewAdminFaq isManager={false} />,
    name: 'ViewAdminHelpForCustomer',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_HELP_FOR_CUSTOMER_ANSWER_PAGE,
    element: <ViewAdminFaqAnswer isManager={false} />,
    name: 'ViewAdminHelpForCustomerAnswer',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_SEO,
    element: <ViewAdminSeo />,
    name: 'ViewAdminSeo',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_SEO_EDIT_CATEGORY,
    element: <ViewAdminSeoEdit />,
    name: 'ViewAdminSeoEditCategory',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_SEO_EDIT_SUBCATEGORY,
    element: <ViewAdminSeoEdit />,
    name: 'ViewAdminSeoEditSubcategory',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_BLOG,
    element: <ViewAdminBlog />,
    name: 'ViewAdminBlog',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_BLOG_ADD,
    element: <ViewAdminBlogArticle />,
    name: 'ViewAdminBlogArticle',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_BLOG_EDIT,
    element: <ViewAdminBlogArticle />,
    name: 'ViewAdminBlogArticle',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_BLOG_ADD_CATEGORY,
    element: <ViewAdminBlogCategory />,
    name: 'ViewAdminBlogCategory',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_BLOG_EDIT_CATEGORY,
    element: <ViewAdminBlogCategory />,
    name: 'ViewAdminBlogCategory',
    Layout: LayoutAdmin,
  },
  {
    path: ADMIN_NOTIFICATION,
    element: <ViewAdminNotification />,
    name: 'ViewAdminNotification',
    Layout: LayoutAdmin,
  },
]

const routes: RoutesType[] = [
  {
    path: '*',
    element: <ViewNotFound />,
    name: 'ViewNotFound',
  },
]

const fullRoutes = [
  ...clientRoutes,
  ...managerRoutes,
  ...adminRoutes,
  ...routes,
]

export default fullRoutes
