/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios'

import { ErrorResponseType, HttpStatus } from 'entities/common'
import i18n from 'lib/i18n/i18n'
import {
  COOKIE_ACCESS_TOKEN,
  API_URL,
  COOKIE_REFRESH_TOKEN,
} from 'utils/constants'
import { getCookie } from 'utils/helpers/cookie'

import { CLIENT_LINK, MANAGER_SUBSCRIPTION, NOT_FOUND } from './link'

const defaultConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true,
}

const http = Axios.create(defaultConfig)
const refreshTokenService = Axios.create(defaultConfig)

let refreshTokenPromise: Promise<any>
let isRefreshFinished = true

http.interceptors.response.use(
  undefined,
  (error: AxiosError<ErrorResponseType>) => {
    if (error.response?.status === HttpStatus.Unauthorized) {
      if (getCookie(COOKIE_REFRESH_TOKEN)) {
        const originalRequest = error.config

        if (isRefreshFinished) {
          isRefreshFinished = false

          refreshTokenPromise = refreshTokenService({
            method: 'GET',
            url: '/auth/refresh-token',
            headers: {
              ...defaultConfig.headers,
              Authorization: `Bearer ${getCookie(COOKIE_REFRESH_TOKEN)}`,
            },
          })
            .then(() => {
              isRefreshFinished = true
              ;(
                originalRequest.headers as AxiosRequestHeaders
              ).Authorization = `Bearer ${getCookie(COOKIE_ACCESS_TOKEN)}`

              return http(originalRequest)
            })
            .catch((err: any) => {
              isRefreshFinished = true

              if (err.response?.status === HttpStatus.Unauthorized) {
                window.location.href = CLIENT_LINK
              } else {
                throw err
              }
            })

          return refreshTokenPromise
        }

        return refreshTokenPromise.then(() => {
          return http(originalRequest)
        })
      }
      if (error.response?.status === HttpStatus.Unauthorized) {
        window.location.href = CLIENT_LINK

        return Promise.reject()
      }
    } else if (error.response?.status === HttpStatus.Forbidden) {
      window.location.href = CLIENT_LINK
    } else if (error.response?.status === HttpStatus.NotFound) {
      window.location.replace(NOT_FOUND)
    } else if (error.response?.status === HttpStatus.ExpiredSubscribe) {
      error.response.data.message = String(
        i18n.t('exception.subscription_plan_expired'),
      )

      window.location.replace(MANAGER_SUBSCRIPTION)
      localStorage.setItem('subscription', String(true))
    } else if (
      error.response?.status === HttpStatus.ManyRequests ||
      error.response?.status === HttpStatus.InternalServerError ||
      error.response?.status === HttpStatus.LargeRequest
    ) {
      if (error.response?.status === HttpStatus.InternalServerError) {
        error.response.data.message = String(i18n.t('COMMON.ERROR_500'))
      }

      return Promise.reject(error.response?.data?.message)
    } else if (error.response?.status === HttpStatus.UnprocessableEntity) {
      if (error.response?.data?.exception) {
        const { exception } = error.response.data

        return Promise.reject(exception)
      }

      if (error.response?.data?.errors) {
        const { message, errors } = error.response.data

        if (Object.keys(errors).length) {
          return Promise.reject(errors)
        }

        return Promise.reject(message)
      }

      if (error.response?.data?.appointments) {
        const { appointments } = error.response.data

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ appointments })
      }
    }

    return Promise.reject(error.response?.data.message || error.toString())
  },
)

http.interceptors.request.use(
  async (config) => {
    if (config.url === '/auth/logout') return config
    const originalRequest = { ...config }

    if (!getCookie(COOKIE_ACCESS_TOKEN) && getCookie(COOKIE_REFRESH_TOKEN)) {
      if (isRefreshFinished) {
        isRefreshFinished = false

        refreshTokenPromise = refreshTokenService({
          method: 'GET',
          url: '/auth/refresh-token',
          headers: {
            ...defaultConfig.headers,
            Authorization: `Bearer ${getCookie(COOKIE_REFRESH_TOKEN)}`,
          },
        })
          .then(() => {
            isRefreshFinished = true
            ;(
              originalRequest.headers as AxiosRequestHeaders
            ).Authorization = `Bearer ${getCookie(COOKIE_ACCESS_TOKEN)}`

            return originalRequest
          })
          .catch((err: any) => {
            isRefreshFinished = true

            if (err.response?.status === HttpStatus.Unauthorized) {
              window.location.href = CLIENT_LINK
            } else {
              throw err
            }
          })
      }

      return refreshTokenPromise.then(() => {
        ;(
          originalRequest.headers as AxiosRequestHeaders
        ).Authorization = `Bearer ${getCookie(COOKIE_ACCESS_TOKEN)}`

        return originalRequest
      })
    }

    return originalRequest
  },
  (error) => Promise.reject(error),
)

function GET(url: string, config: AxiosRequestConfig = {}) {
  return http.get(url, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${getCookie(COOKIE_ACCESS_TOKEN) || ''}`,
    },
  })
}

function POST<T>(url: string, body?: T, config: AxiosRequestConfig = {}) {
  return http.post(url, body, {
    ...config,
    headers: {
      Authorization: `Bearer ${getCookie(COOKIE_ACCESS_TOKEN) || ''}`,
      'Content-Type': 'application/json',
      ...config?.headers,
    },
  })
}

function PUT<T>(url: string, body: T, config: AxiosRequestConfig = {}) {
  return http.put(url, body, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${getCookie(COOKIE_ACCESS_TOKEN) || ''}`,
    },
  })
}

function PATCH<T>(url: string, body: T, config: AxiosRequestConfig = {}) {
  return http.patch(url, body, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${getCookie(COOKIE_ACCESS_TOKEN) || ''}`,
    },
  })
}

function DELETE(url: string, config: AxiosRequestConfig = {}) {
  return http.delete(url, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${getCookie(COOKIE_ACCESS_TOKEN) || ''}`,
    },
  })
}

export { GET, POST, PUT, PATCH, DELETE }

export default http
