import { SalonPhoneRequestType } from 'store/Manager/Salon/Salon.type'
import { DELETE, GET, POST } from 'utils/http'
import {
  SalonDataRequestType,
  SalonPhoneType,
} from 'views/manager/ProfileSalon/index.type'

const basePath = '/profile'

export const getProfile = () => GET(`${basePath}`)

export const updateProfile = (body: SalonDataRequestType) =>
  POST(`${basePath}/salon`, body)

export const deleteProfilePhone = (id: number) =>
  DELETE(`${basePath}/phone/${id}`)

export const deleteProfileImage = (id: number) =>
  DELETE(`${basePath}/image/${id}`)

export const getPhone = () => GET('/phone')

export const createPhone = (payload: SalonPhoneRequestType) =>
  POST('/phone', payload)

export const deletePhone = (payload: number) => DELETE(`/phone/${payload}`)

export const updatePhone = (payload: SalonPhoneType) =>
  POST(`/phone/${payload.id}`, { number: payload.number })
