import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'

import 'antd/dist/antd.css'

import { Spin } from 'antd'
import { ColorResult } from 'react-color'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ModalType } from 'entities/modal'
import useResponsive from 'hooks/useResponsive'
import LayoutManagerCenter from 'layouts/Manager/Center'
import { addAlert } from 'store/Alert'
import {
  clearErrors,
  deleteStaffRequest,
  getOneStaffRequest,
  resetStore,
  setActiveStaff,
  setIsStartStaff,
  updateStaffRequest,
  setMobileTitleOpen,
} from 'store/Manager/Staff'
import { clearMediaStore } from 'store/Media/Media.action'
import { hideModal, showModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { UICustomCard, UIPhoneInput, UITextArea, UIUpload } from 'ui'
import UISketchPicker from 'ui/SketchPicker'
import UITextInput from 'ui/TextInput'
import { MANAGER_STAFF } from 'utils'

import { INITIAL_VALUES } from './constant'
import { StaffValuesType } from './index.type'
import styles from './style.module.scss'

const ViewEditStaff: FC = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile, isTablet } = useResponsive()

  const {
    staff,
    isStaffAdd,
    isLoading,
    errorMessage,
    fileData,
    errorMessage: mediaErrorMessage,
    isloading: isLoadingMedia,
    errors,
  } = useSelector((state: IRootState) => ({
    ...state.staff,
    fileData: state.media.fileData,
    errorMessage: state.media.errorMessage,
    isloading: state.media.isloading,
  }))

  const [color, setColor] = useState('')
  const [number, setNumber] = useState<string>('')

  const [value, setValue] = useState<StaffValuesType>(INITIAL_VALUES)

  const handleBack = useCallback(() => {
    navigate(MANAGER_STAFF)

    if (isMobile || isTablet) {
      dispatch(setMobileTitleOpen(true))
    }
  }, [dispatch, isMobile, isTablet, navigate])

  const handleClick = useCallback(() => {
    if (staff) {
      dispatch(
        updateStaffRequest({
          body: { ...value, phone: `52-${number}` },
          id: staff?.id,
        }),
      )
      dispatch(clearMediaStore())
      dispatch(setIsStartStaff(false))
    }
  }, [dispatch, number, staff, value])

  const onChangeInput = (
    values: string,
    name: 'name' | 'position' | 'description',
  ) => {
    setValue({ ...value, [name]: values })
  }

  useEffect(() => {
    if (isStaffAdd && !errorMessage) {
      dispatch(hideModal())
      navigate(MANAGER_STAFF)
      dispatch(resetStore())
    }
  }, [dispatch, errorMessage, isStaffAdd, navigate])

  useEffect(() => {
    if (staff) {
      setValue({
        name: staff.name,
        description: staff.description,
        position: staff.position,
        avatar: staff.avatar?.id,
        color: staff.color,
        phone: staff.phone,
      })

      if (staff?.phone) {
        setNumber(staff?.phone?.slice(3, staff?.phone?.length))
      }

      setColor(staff?.color || '')
    }
  }, [staff])

  useEffect(() => {
    if (fileData) {
      setValue({
        ...value,
        avatar: fileData?.id,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData])

  useEffect(() => {
    if (errorMessage || mediaErrorMessage) {
      dispatch(
        addAlert({
          message: errorMessage || mediaErrorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(clearErrors())
      dispatch(clearMediaStore())
    }
  }, [errorMessage, dispatch, mediaErrorMessage])

  useEffect(() => {
    if (!staff && id) {
      dispatch(getOneStaffRequest(+id))
    }
  }, [dispatch, id, staff])

  const handleDelete = () => {
    if (staff) {
      dispatch(
        showModal({
          modalType: ModalType.MANAGER_DELETE_STAFF,
          modalProps: {
            handler: () => {
              dispatch(resetStore())
              dispatch(setActiveStaff({ id: null }))
              dispatch(deleteStaffRequest(staff?.id))
            },
          },
        }),
      )
    }
  }

  const handleColorChange = (clr: ColorResult) => {
    setColor(clr.hex)
    setValue({ ...value, color: clr.hex })
  }

  return (
    <LayoutManagerCenter
      isLoading={isLoading}
      title={t('MANAGER.STAFF.EDIT_STAFF_MEMBER')}
      handleBack={handleBack}
      isDelete
      handleSave={handleClick}
      handleDelete={handleDelete}
      isHeader
    >
      <div className={styles.wrapper}>
        <Spin spinning={isLoading}>
          <UICustomCard
            title={
              <>
                <span>1. </span> {t('MANAGER.STAFF.ITEM_1')}
              </>
            }
          >
            <div className={styles.infoWrapp}>
              <div className={styles.avatar}>
                <p>{t('MANAGER.STAFF.PHOTO')}</p>
                <UIUpload
                  className={styles.uploadAvatar}
                  pathImg={staff?.avatar?.path}
                  setValues={setValue}
                  values={value}
                  fileData={fileData}
                  isCircle={true}
                  isLoading={isLoadingMedia}
                  widthRatio={1}
                  heightRatio={1}
                />
              </div>

              <div className={styles.inputWrapp}>
                <UITextInput
                  onChange={(e) => onChangeInput(e.target.value, 'name')}
                  text={t('MANAGER.STAFF.NAME')}
                  placeholder={t('MANAGER.STAFF.NAME')}
                  necessaryLabel={`(${t('COMMON.NECESSARY')})`}
                  value={value.name}
                  error={errors.name}
                />

                <UIPhoneInput
                  label={t('COMMON.PHONE_NUMBER')}
                  necessaryLabel={`(${t('COMMON.NECESSARY')})`}
                  value={number}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setNumber(e.target.value)
                  }
                />

                <UITextInput
                  onChange={(e) => onChangeInput(e.target.value, 'position')}
                  text={t('MANAGER.STAFF.POSITION')}
                  placeholder={t('MANAGER.STAFF.POSITION')}
                  necessaryLabel={`(${t('COMMON.NECESSARY')})`}
                  value={value.position}
                  error={errors.position}
                />
              </div>

              <div className={styles.texareaWrapp}>
                <UITextArea
                  onChange={(e) => onChangeInput(e.target.value, 'description')}
                  label={t('MANAGER.STAFF.STAFFET')}
                  placeholder={t('MANAGER.STAFF.STAFFET_PLACEHOLDER')}
                  value={value.description}
                />
              </div>
            </div>
          </UICustomCard>

          <UICustomCard
            title={
              <>
                <span>2. </span> {t('MANAGER.STAFF.ITEM_4')}
              </>
            }
          >
            <div className={styles.wrappColor}>
              <p>
                {t('MANAGER.STAFF.COLOR_DESCRIPTION')}{' '}
                <span className={styles.necessaryLabel}>{`(${t(
                  'COMMON.NECESSARY',
                )})`}</span>
              </p>

              <UISketchPicker
                color={color}
                error={errors?.color}
                onChange={(clr) => handleColorChange(clr)}
              />
            </div>
          </UICustomCard>
        </Spin>
      </div>
    </LayoutManagerCenter>
  )
}

export default ViewEditStaff
