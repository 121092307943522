import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Tabs } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as ArrowSVG } from 'assets/images/common/redesign/arrowBottom.svg'
import { SelectOption } from 'entities/common'
import { ServiceType } from 'entities/manager/service'
import { StaffType } from 'entities/manager/staff'
import {
  updateAppointmentAvailableRequest,
  updateAppointmentSearchRequest,
} from 'store/Manager/Appointments/Appointments.action'
import { MethodSearch } from 'store/Manager/Appointments/Appointments.type'
import { IRootState } from 'store/Root.reducer'
import { UIDatePicker, UIPhoneInput, UISelect } from 'ui'
import { ModeType } from 'ui/CalendarHeader/index.type'
import UITextInput from 'ui/TextInput'
import {
  FORMAT_DATE,
  FORMAT_DATE_WEEK_DAY_MONTH,
  FORMAT_TIME_HOUR_WITH_MIN,
  STANDART_DATE,
} from 'utils'
import { DateHelper } from 'utils/helpers'

import { ExtendedPropsType, PropsType } from './index.type'
import { SelectOptionType } from '../index.type'
import styles from '../style.module.scss'
import UIAppointmendarSidebarFooter from '../Footer'

const getTimeWithRounding = (date: Date = new Date()) => {
  const minute = DateHelper.toFormat(date, 'mm')
  const hour = Number(DateHelper.toFormat(date, 'HH'))
  const formattedDate = DateHelper.toFormat(date, 'yyyy-MM-dd')
  const minutes = `${minute.slice(0, 1)}${
    Number(minute.slice(1)) > 0 && Number(minute.slice(1)) <= 5 ? 5 : 0
  }`
  const time = `${Number(minute) >= 55 ? hour + 1 : hour}:${minutes}`

  return new Date(`${formattedDate} ${time}`)
}

// TODO refactor
const UIAppointmendarSidebarContent: FC<PropsType> = ({
  eventDate,
  eventData,
  setValues,
  values,
  selectDate,
  mode,
  headerDate,
  setTabMode,
  isAddAppointment,
  setSelectDate,
  isBtnDisabled,
  handleSave,
  handleDelete,
}) => {
  const {
    isServiceLoading,
    isClientLoading,
    isStaffLoading,
    isLoading,
    staffList,
    clientList,
    serviceList,
    availableTime,
    service,
    errors,
  } = useSelector((state: IRootState) => state.appointments)

  const validateErrors = errors as { [field: string]: string[] }

  const [activeTab, setActiveTab] = useState('0')
  const [isStartTimeEdit, setIsStartTimeEdit] = useState(false)
  const [localStaffList, setlocalStaffList] = useState<StaffType[]>([])
  const [localServiceList, setLocalServiceList] = useState<ServiceType[]>([])

  useEffect(() => {
    if (staffList) {
      setlocalStaffList(staffList)
    }
  }, [staffList])

  useEffect(() => {
    if (serviceList) {
      setLocalServiceList(serviceList)
    }
  }, [serviceList])

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const extendedProps = eventData?.event?.extendedProps as ExtendedPropsType

  const endTime = useMemo(() => {
    const durationAdd = ((service && [service]) || serviceList)?.find(
      (item) => item?.id === values.service_id,
    )?.duration

    const durationEdit = extendedProps ? extendedProps?.serviceDuration : ''

    return values.started_at
      ? DateHelper.toFormat(
          new Date(`${STANDART_DATE} ${values.started_at}:00`).valueOf() +
            (new Date(
              `${STANDART_DATE} ${
                isAddAppointment ? durationAdd : durationEdit
              }`,
            ).valueOf() -
              new Date(`${STANDART_DATE} 00:00:00`).valueOf()),
          FORMAT_TIME_HOUR_WITH_MIN,
        )
      : ''
  }, [
    extendedProps,
    isAddAppointment,
    service,
    serviceList,
    values.service_id,
    values.started_at,
  ])

  const handleClientChange = (value: string) => {
    dispatch(
      updateAppointmentSearchRequest({
        body: {
          search: value,
          from: DateHelper.toFormat(eventDate, FORMAT_DATE),
        },
        method: MethodSearch.CLIENT,
      }),
    )
  }

  const handleServiceChange = (value: string) => {
    dispatch(
      updateAppointmentSearchRequest({
        body: {
          search: value,
          from: DateHelper.toFormat(eventDate, FORMAT_DATE),
          ...(values.staff_id && { staff_id: values.staff_id }),
          ...((values.time?.from || values.time?.to) && {
            time: {
              ...(values.time.to && { to: values.time.to }),
              ...(values.time.from && { from: values.time.from }),
            },
          }),
        },
        method: MethodSearch.SERVICE,
      }),
    )
  }

  const handleStaffChange = (value: string) => {
    dispatch(
      updateAppointmentSearchRequest({
        body: {
          search: value,
          from: DateHelper.toFormat(values?.from, FORMAT_DATE),
          ...(values.service_id && { service_id: values.service_id }),
          ...((values.started_at || values.time?.to) && {
            time: {
              from: values.started_at || '',
              ...((values.started_at &&
                values.service_id &&
                values.started_at !== values.finished_at && { to: endTime }) ||
                (values.finished_at &&
                  values.started_at !== values.finished_at && {
                    to: values.finished_at,
                  })),
            },
          }),
        },
        method: MethodSearch.STAFF,
      }),
    )
  }

  const handleFocusTime = () => {
    dispatch(
      updateAppointmentAvailableRequest({
        ...(values.service_id && { service_id: values.service_id }),
        ...(values.staff_id && { staff_id: values.staff_id }),
        from: DateHelper.toFormat(values.from, FORMAT_DATE),
      }),
    )
  }

  const handleTabChange = (key: string) => {
    setActiveTab(key)
    if (setTabMode) {
      setTabMode(key)
    }
  }

  const disabledDate = (date: Date) => {
    const ONE_DAY = 86300000

    return date && date.valueOf() <= new Date().valueOf() - ONE_DAY
  }

  const getContentDate = useCallback(() => {
    if (mode === ModeType.DAY) {
      return headerDate
    }

    return (
      (eventData?.event?.end as unknown as string) ||
      (eventDate as unknown as string)
    )
  }, [eventData?.event?.end, eventDate, headerDate, mode])

  const onDateChange = (date: string) => {
    setValues((prevState) => ({
      ...prevState,
      started_at: '',
      finished_at: '',
      time: { to: '', from: '' },
      from: date,
    }))

    if (setSelectDate) {
      setSelectDate({ start: '', end: '' })
    }
  }

  const itemsList = [
    {
      key: '1',
      label: t('MANAGER.STAFF.ADD_CLIENT'),
      children: (
        <>
          <div className={styles.wrappPicker}>
            <UIDatePicker
              format={FORMAT_DATE_WEEK_DAY_MONTH}
              value={
                (values.from && new Date(values.from)) ||
                eventData?.event?.start ||
                eventDate ||
                new Date()
              }
              onChange={(date) => onDateChange(date as string)}
              className={styles.datePicker}
              disabledDate={disabledDate}
            />
            <ArrowSVG />
          </div>
          <UISelect
            placeholder={t('MANAGER.STAFF.FULL_NAME_PLACEHOLDER')}
            label={t('MANAGER.STAFF.CLIENT_NAME')}
            showSearch
            classNameWrapper={styles.mb20}
            className={styles.select}
            value={values.clientName || undefined}
            onFocus={(e) => {
              handleClientChange(
                (e as unknown as ChangeEvent<HTMLInputElement>).target.value,
              )
            }}
            onSearch={(e) => handleClientChange(e)}
            options={clientList?.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={(value, obj) => {
              setValues({
                ...values,
                client_id: value,
                clientName: (obj as SelectOption).label,
              })
            }}
            isLoading={isClientLoading}
            filterOption={(inputValue: string, option: SelectOptionType) =>
              option?.label
                ?.toLowerCase()
                ?.includes(inputValue?.toLowerCase()) as boolean
            }
            error={validateErrors.client_id}
          />
        </>
      ),
    },
    {
      key: '2',
      label: t('MANAGER.STAFF.ADD_CONTACT'),
      children: (
        <>
          <div className={styles.wrappPicker}>
            <UIDatePicker
              format={FORMAT_DATE_WEEK_DAY_MONTH}
              className={styles.datePicker}
              value={
                (values.from && new Date(values.from)) ||
                eventData?.event?.start ||
                eventDate ||
                new Date()
              }
              onChange={(date) => onDateChange(date as string)}
              disabledDate={disabledDate}
            />
            <ArrowSVG />
          </div>

          <UITextInput
            text={t('MANAGER.STAFF.FULL_NAME')}
            placeholder={t('MANAGER.STAFF.FULL_NAME_PLACEHOLDER')}
            className={styles.mb20}
            value={values.contact_name?.trimStart()}
            onChange={(e) =>
              setValues({ ...values, contact_name: e.target.value })
            }
            error={validateErrors?.contact_name}
          />

          <UIPhoneInput
            label={t('COMMON.PHONE_NUMBER')}
            necessaryLabel={`(${t('COMMON.NECESSARY')})`}
            className={styles.mb20}
            value={values.contact_phone}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, contact_phone: e.target.value })
            }
            error={validateErrors?.contact_phone}
          />
        </>
      ),
    },
  ]

  useEffect(() => {
    if (extendedProps?.startTime || extendedProps?.endTime) {
      setValues((prevState) => ({
        ...prevState,
        from:
          (eventData?.event?.end as unknown as string) ||
          (eventDate as unknown as string),
        started_at: extendedProps?.startTime,
        finished_at: extendedProps?.endTime,
        contact_name: extendedProps?.contactName,
        contact_phone: extendedProps?.contactPhone?.slice(3),
        ...(extendedProps?.staff?.value && {
          staff_id: Number(extendedProps?.staff?.value),
          staffName: extendedProps?.staff?.label,
        }),
        ...(extendedProps?.service?.value && {
          service_id: Number(extendedProps?.service?.value),
          serviceName: extendedProps?.service?.label,
        }),
        ...(extendedProps?.price?.price && {
          price: extendedProps?.price?.price,
        }),
        ...(extendedProps?.client_id && {
          client_id: extendedProps?.client_id,
          clientName: extendedProps?.clientName,
        }),
        ...(eventData?.event?.id && {
          id: +(eventData?.event?.id || 0),
        }),
      }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendedProps, eventData?.event, eventDate])

  useEffect(() => {
    if (
      (!extendedProps?.startTime || !extendedProps?.endTime) &&
      (selectDate?.start || selectDate?.end || eventDate)
    ) {
      const startedDate = getTimeWithRounding(
        new Date(selectDate?.start || new Date()),
      )
      const endedDate = getTimeWithRounding(
        new Date(selectDate?.end || new Date()),
      )

      const startedAt = selectDate?.start
        ? DateHelper.toFormat(startedDate, FORMAT_TIME_HOUR_WITH_MIN)
        : ''

      const finishedAt = selectDate?.end
        ? DateHelper.toFormat(endedDate, FORMAT_TIME_HOUR_WITH_MIN)
        : ''

      setValues((prevState) => ({
        ...prevState,
        from: getContentDate() as string,
        started_at: startedAt,
        finished_at: finishedAt,
        time: {
          from: startedAt,
          to: finishedAt,
        },
      }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendedProps, eventDate, eventData?.event?.end])

  useEffect(() => {
    if (extendedProps?.client_id) {
      setActiveTab('1')
      if (setTabMode) {
        setTabMode('1')
      }
    } else {
      setActiveTab('2')
      if (setTabMode) {
        setTabMode('2')
      }
    }
  }, [extendedProps?.client_id, setTabMode])

  return (
    <>
      <Tabs
        className={styles.tab}
        defaultActiveKey="1"
        onChange={(key) => handleTabChange(key)}
        activeKey={activeTab}
        items={itemsList}
      />
      <UISelect
        placeholder={t('MANAGER.APPOINTMENT.CHOOSE_SERVICE')}
        label={t('MANAGER.BUTTON.ADD_SERVICE')}
        showSearch
        className={cn(styles.mb20, styles.select)}
        onFocus={(e) => {
          handleServiceChange(
            (e as unknown as ChangeEvent<HTMLInputElement>).target.value,
          )
        }}
        value={values.serviceName || undefined}
        onSearch={(e) => handleServiceChange(e)}
        options={localServiceList?.map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        onChange={(value, obj) => {
          setValues({
            ...values,
            service_id: value,
            serviceName: (obj as SelectOption).label,
          })
        }}
        onBlur={() => setLocalServiceList([])}
        isLoading={isServiceLoading}
        filterOption={(inputValue: string, option: SelectOptionType) =>
          option?.label
            ?.toLowerCase()
            ?.includes(inputValue?.toLowerCase()) as boolean
        }
      />

      <div className={styles.timepick}>
        <UISelect
          placeholder={t('COMMON.SELECT')}
          label={t('COMMON.START_TIME')}
          value={
            values.started_at ||
            (selectDate?.start &&
              DateHelper.toFormat(
                DateHelper.addMinutes(selectDate?.start, 10),
                FORMAT_TIME_HOUR_WITH_MIN,
              ))
          }
          onFocus={handleFocusTime}
          isLoading={isLoading}
          options={availableTime.map((item, index) => ({
            value: index,
            label: item.slice(0, 5),
          }))}
          onChange={(_, obj) => {
            setValues({
              ...values,
              started_at: (obj as SelectOption).label,
              time: {
                ...values.time,
                from: (obj as SelectOption).label,
                to: '',
              },
              finished_at: '',
            })
            setIsStartTimeEdit(true)
          }}
          error={errors?.started_at as unknown as string}
        />

        <span className={styles.dash}></span>

        <UISelect
          placeholder={t('COMMON.SELECT')}
          label={t('COMMON.END_TIME')}
          {...(values.service_id
            ? {
                value: endTime,
              }
            : {
                value: isStartTimeEdit
                  ? ''
                  : selectDate?.end &&
                    DateHelper.toFormat(
                      selectDate?.end,
                      FORMAT_TIME_HOUR_WITH_MIN,
                    ),
              })}
          disabled
        />
      </div>

      <UISelect
        placeholder={t('MANAGER.APPOINTMENT.CHOOSE_MASTER')}
        label={t('MANAGER.BUTTON.ADD_MASTER')}
        showSearch
        className={styles.select}
        value={values.staffName || undefined}
        onSearch={(e) => handleStaffChange(e)}
        onFocus={(e) => {
          handleStaffChange(
            (e as unknown as ChangeEvent<HTMLInputElement>).target.value,
          )
        }}
        options={localStaffList?.map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        onChange={(value, obj) => {
          setValues({
            ...values,
            staff_id: value,
            staffName: (obj as SelectOption).label,
          })
        }}
        onBlur={() => setlocalStaffList([])}
        isLoading={isStaffLoading}
        filterOption={(inputValue: string, option: SelectOptionType) =>
          option?.label
            ?.toLowerCase()
            ?.includes(inputValue?.toLowerCase()) as boolean
        }
      />

      <UIAppointmendarSidebarFooter
        total={
          values?.price ||
          serviceList?.find((item) => item.id === values.service_id)?.price
            .price
        }
        handleSave={handleSave}
        handleDelete={handleDelete}
        isAddAppointment={isAddAppointment}
        isBtnDisabled={isBtnDisabled}
      />
    </>
  )
}

export default memo(UIAppointmendarSidebarContent)
