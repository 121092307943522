import { FC, memo, SyntheticEvent, ReactNode } from 'react'

import { OnUploadFileChange } from 'ui/UploadFile/index.type'

import { BusinessTemplateStateType } from '../../index.type'
import TemplateField from '../TemplateField'
import {
  InputTypes,
  TemplateFieldsPropsTypeChange,
  TYPE_FILE,
} from '../TemplateField/index.type'

type PropsType = {
  array: BusinessTemplateStateType[]
  className: string
  onChange: (event: SyntheticEvent) => void
  onFileChange: OnUploadFileChange
  title: string
}

const getInputs = (
  array: BusinessTemplateStateType[],
  onChange: (event: SyntheticEvent) => void,
  onFileChange: OnUploadFileChange,
): ReactNode =>
  array.map(
    ({ type, label, name, widthRatio, heightRatio, ...props }, index) => (
      <TemplateField
        key={index}
        type={type as InputTypes}
        onChange={
          (type === TYPE_FILE
            ? onFileChange
            : onChange) as TemplateFieldsPropsTypeChange
        }
        label={label as string}
        name={name as string}
        widthRatio={widthRatio as number}
        heightRatio={heightRatio as number}
        {...props}
      />
    ),
  )

const BusinessTemplateBlock: FC<PropsType> = ({
  className,
  onChange,
  onFileChange,
  array,
  title,
}) => (
  <div className={className}>
    <h2>{title}</h2>

    {getInputs(array, onChange, onFileChange)}
  </div>
)

export default memo(BusinessTemplateBlock)
