import { v4 as uuidv4 } from 'uuid'

import { NotificationPlacement, NotificationType } from 'entities/notification'

import {
  ADD_ALERT,
  ADD_ALERT_TO_START,
  REMOVE_ALERT,
  CLEAR_ALERTS,
} from './Alert.constant'

export const addAlert = (
  alert: Omit<NotificationType, 'id'> & { placement?: NotificationPlacement },
) => ({
  type: ADD_ALERT,
  payload: { ...alert, id: uuidv4() },
})

export const addAlertToStart = (
  alert: Omit<NotificationType, 'id'> & { placement?: NotificationPlacement },
) => ({
  type: ADD_ALERT_TO_START,
  payload: { ...alert, id: uuidv4() },
})

export const removeAlert = (id: string) => ({
  type: REMOVE_ALERT,
  payload: id,
})

export const clearAlerts = () => ({
  type: CLEAR_ALERTS,
})
