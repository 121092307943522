/* eslint-disable @typescript-eslint/no-unsafe-call */
import Echo from 'laravel-echo'
import io from 'socket.io-client'

import { API_URL } from 'utils'

declare global {
  interface Window {
    Echo: Echo
    io: any
  }
}

window.io = io

export function createSocketConnection(token: string) {
  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: 'socket.io',
      host: API_URL,
      transports: ['websocket', 'polling', 'flashsocket'],
      auth: {
        headers: {
          Authorization: `Bearer ${token || ''}`,
          withCredentials: true,
        },
      },
    })
  }
}
