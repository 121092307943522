import { Collapse, Spin } from 'antd'
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from './style.module.scss'
import { format } from 'date-fns'
import classNames from 'classnames'
import { getBasketCalculateTimeRequest } from 'store/Manager/Basket/Basket.action'
import UIAppointmendarSidebarAddTimeCard from './Card'
import { DateHelper } from 'utils/helpers'
import { FORMAT_DATE } from 'utils'

type PropsType = {
  basketTime?: { [key: string]: string[] } | null
  isLoading?: boolean
  selectTime: string | Date | null
  setSelectTime: Dispatch<SetStateAction<string | Date | null>>
  tabDate?: string | Date
}

type HourCardsType = {
  afternoon: string[]
  evening: string[]
  morning: string[]
}

const UIAppointmendarSidebarAddTime: FC<PropsType> = ({
  basketTime,
  tabDate,
  setSelectTime,
  selectTime,
  isLoading,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [open, setOpen] = useState<string | undefined>(undefined)

  const handleChange = (key: string | string[]) => {
    if (typeof key === 'string') setOpen(key)
    else {
      setOpen(!key.length ? undefined : key[Number(key.length) - 1])
    }
  }

  const handleSelectTime = useCallback(
    (time: Date | null) => () => {
      setSelectTime(time)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const hourCards: HourCardsType = useMemo(() => {
    const initialValue: HourCardsType = {
      afternoon: [],
      evening: [],
      morning: [],
    }

    if (!tabDate) return initialValue

    const times: string[] = (basketTime as { [key: string]: string[] })?.[
      format(new Date(tabDate), 'yyyy-MM-dd')
    ]

    if (times) {
      return times.reduce((prev, curr) => {
        const [hour] = curr.split(':')
        const returnValue: HourCardsType = { ...prev }

        if (Number(hour) < 12) {
          returnValue.morning = [...prev.morning, curr]
        } else if (Number(hour) < 18) {
          returnValue.afternoon = [...prev.afternoon, curr]
        } else {
          returnValue.evening = [...prev.evening, curr]
        }

        return returnValue
      }, initialValue)
    }

    return initialValue
  }, [tabDate, basketTime])

  const panels = useMemo(
    () => [
      { title: t('COMMON.MORNING'), key: '1', array: hourCards.morning },
      { title: t('COMMON.AFTERNOON'), key: '2', array: hourCards.afternoon },
      { title: t('COMMON.EVENING'), key: '3', array: hourCards.evening },
    ],
    [t, hourCards],
  )

  useEffect(() => {
    const objToRequest = {
      date_from: DateHelper.toFormat(tabDate, FORMAT_DATE),
      time_from: DateHelper.toFormat(selectTime || new Date(), 'HH:mm'),
    }

    if (selectTime) {
      dispatch(getBasketCalculateTimeRequest(objToRequest))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTime, dispatch])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedAvailableTime = useMemo(() => basketTime, [basketTime])

  const isHasTime = useMemo(
    () =>
      !!selectedAvailableTime?.[
        tabDate ? format(new Date(tabDate), 'yyyy-MM-dd') : ''
      ]?.length,
    [selectedAvailableTime, tabDate],
  )

  useEffect(() => {
    if (isHasTime) {
      const { afternoon, evening, morning } = hourCards

      if (morning?.length) {
        setOpen('1')
      } else if (afternoon?.length) {
        setOpen('2')
      } else if (evening?.length) {
        setOpen('3')
      }
    }
  }, [isHasTime, hourCards])

  return (
    <Spin spinning={isLoading}>
      <div className={styles.wrapper}>
        <h4>{t('COMMON.SELECT_START_TIME_FOR_APPOINTMENT')}</h4>
        {tabDate && (
          <Collapse activeKey={open} onChange={handleChange} ghost>
            {panels.map(({ array, key, title }) => (
              <Collapse.Panel header={title} key={key} className={styles.title}>
                <div
                  className={classNames(styles.cardWrapper, {
                    [styles.cardWrapperNotTime]:
                      !array || (array && !array.length),
                  })}
                >
                  {array &&
                    !!array.length &&
                    array.map((item, index) => (
                      <UIAppointmendarSidebarAddTimeCard
                        key={index}
                        item={item}
                        selectedTime={selectTime as Date}
                        onSelect={handleSelectTime}
                      />
                    ))}
                  {!array ||
                    (array && !array.length && (
                      <p>{t('COMMON.NO_FREE_PLACES_OR_DATE')}</p>
                    ))}
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
        )}
      </div>
    </Spin>
  )
}

export default UIAppointmendarSidebarAddTime
