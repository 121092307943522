import { FC, ReactNode } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { MANAGER_SHIFTS, MANAGER_STAFF } from 'utils'

import styles from './style.module.scss'

type PropsType = {
  button: ReactNode
}

const StaffHeader: FC<PropsType> = ({ button }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <div
      className={cn(styles.header, {
        [styles.staffHeader]: pathname.includes(MANAGER_STAFF),
      })}
    >
      <div className={styles.tabs}>
        <p
          onClick={() => navigate(MANAGER_STAFF)}
          className={cn({ [styles.active]: pathname.includes(MANAGER_STAFF) })}
        >
          {t('MANAGER.SIDEBAR.STAFF')}
        </p>
        <p
          onClick={() => navigate(MANAGER_SHIFTS)}
          className={cn({ [styles.active]: pathname.includes(MANAGER_SHIFTS) })}
        >
          {t('MANAGER.STAFF.SHIFTS')}
        </p>
      </div>
      <div className={styles.btn}>{button}</div>
    </div>
  )
}

export default StaffHeader
