import { FC, memo } from 'react'

import { Select } from 'antd'
import cn from 'classnames'

import { VALIDATION_CLASS } from 'utils'

import { PropsType } from './index.type'
import styles from './style.module.scss'
import { StringHelper } from 'utils/helpers'

const UISelect: FC<PropsType> = ({
  disabled,
  options,
  placeholder,
  onChange,
  defaultValue,
  value,
  onFocus,
  className,
  mode,
  label,
  necessaryLabel,
  showSearch,
  isLoading,
  onSearch,
  filterOption,
  onBlur,
  error,
  classNameWrapper,
}) => {
  return (
    <div className={cn(styles.select, classNameWrapper)}>
      {label && (
        <div className={styles.label}>
          {label}
          {'  '}
          {necessaryLabel && (
            <span className={styles.necessaryLabel}>{necessaryLabel}</span>
          )}
        </div>
      )}

      <Select
        disabled={disabled}
        onChange={onChange}
        className={cn(styles.select, className, { [VALIDATION_CLASS]: error })}
        defaultValue={defaultValue}
        onFocus={onFocus}
        placeholder={placeholder}
        value={value}
        options={options}
        onBlur={onBlur}
        mode={mode}
        showSearch={showSearch}
        loading={isLoading}
        onSearch={onSearch}
        filterOption={filterOption}
        status={error ? 'error' : undefined}
      />

      {error && (
        <div className={cn(styles.error, 'text-error desc-1')}>
          {StringHelper.getFisrtMessage(error)}
        </div>
      )}
    </div>
  )
}

export default memo(UISelect)
