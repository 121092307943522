import { Params } from 'react-router-dom'

import {
  MANAGER_APPOINTMENTS,
  MANAGER_CLIENTS,
  MANAGER_DOCUMENT,
  MANAGER_FEEDBACKS,
  MANAGER_ORDERS,
  MANAGER_PROFILE_SALON,
  MANAGER_REPORTS,
  MANAGER_SERVICES,
  MANAGER_SERVICES_ADD,
  MANAGER_SERVICES_EDIT,
  MANAGER_SETTING,
  MANAGER_SHIFTS,
  MANAGER_STAFF,
  MANAGER_STAFF_ADD,
  MANAGER_STAFF_EDIT,
  MANAGER_STAFF_SERVICES,
  MANAGER_SUBSCRIPTION,
  MANAGER_SUPPORT,
} from 'utils'
// eslint-disable-next-line import/no-cycle
import { StringHelper } from 'utils/helpers'

type DatesType = 'startDate' | 'startTime' | 'endTime' | 'endDate'

export type FullDateType = Record<DatesType, Date | null>

export type PhoneNumberType = {
  id?: number
  number: string
}

export type PhoneType = {
  id?: number
  number: string
  prefix: string
}

export const getDefaultManagerSelectedKeys = (
  pathname: string,
  param: Readonly<Params<string>>,
) => {
  switch (pathname) {
    case MANAGER_PROFILE_SALON:
      return ['MANAGER.SIDEBAR.SALONS']
    case MANAGER_SERVICES:
      return ['MANAGER.SIDEBAR.SERVICES']
    case StringHelper.replaceByTypes(MANAGER_SERVICES_EDIT, param):
      return ['MANAGER.SIDEBAR.SERVICES']
    case MANAGER_SERVICES_ADD:
      return ['MANAGER.SIDEBAR.SERVICES']
    case MANAGER_STAFF:
    case MANAGER_SHIFTS:
      return ['MANAGER.SIDEBAR.STAFF']
    case StringHelper.replaceByTypes(MANAGER_STAFF_SERVICES, param):
      return ['MANAGER.SIDEBAR.STAFF']
    case MANAGER_STAFF_ADD:
      return ['MANAGER.SIDEBAR.STAFF']
    case StringHelper.replaceByTypes(MANAGER_STAFF_EDIT, param):
      return ['MANAGER.SIDEBAR.STAFF']
    case MANAGER_CLIENTS:
      return ['MANAGER.SIDEBAR.CLIENTS']
    case MANAGER_SETTING:
      return ['MANAGER.SIDEBAR.SETTINGS']
    case MANAGER_SUPPORT:
      return ['MANAGER.SIDEBAR.SUPPORT']
    case MANAGER_APPOINTMENTS:
      return ['MANAGER.SIDEBAR.CALENDAR']
    case MANAGER_FEEDBACKS:
      return ['MANAGER.SIDEBAR.FEEDBACKS']
    case MANAGER_SUBSCRIPTION:
      return ['MANAGER.SIDEBAR.SUBSCRIPTION']
    case MANAGER_ORDERS:
      return ['MANAGER.SIDEBAR.ORDERS']
    case MANAGER_DOCUMENT:
      return ['MANAGER.SIDEBAR.DOCUMENTS']
    case MANAGER_REPORTS:
      return ['MANAGER.SIDEBAR.REPORTS']

    default:
      return ['MANAGER.SIDEBAR.SALONS']
  }
}
