export const SET_VALIDATION_ERROR = '@appointments/SET_VALIDATION_ERROR'

export const RESET_VALIDATION_ERROR = '@appointments/RESET_VALIDATION_ERROR'
export const RESET_AVAILABLE_TIME = '@appointments/RESET_AVAILABLE_TIME'

export const UPDATE_APPOINTMENT_SEARCH_REQUEST =
  '@appointments/UPDATE_APPOINTMENT_SEARCH_REQUEST'
export const UPDATE_SEARCH_CLIENT_SUCCESS =
  '@appointments/UPDATE_SEARCH_CLIENT_SUCCESS'
export const UPDATE_SEARCH_CONTACT_SUCCESS =
  '@appointments/UPDATE_SEARCH_CONTACT_SUCCESS'
export const UPDATE_SEARCH_SERVICE_SUCCESS =
  '@appointments/UPDATE_SEARCH_SERVICE_SUCCESS'
export const UPDATE_SEARCH_STAFF_SUCCESS =
  '@appointments/UPDATE_SEARCH_STAFF_SUCCESS'

export const UPDATE_APPOINTMENT_SEARCH_FAILURE =
  '@appointments/UPDATE_APPOINTMENT_SEARCH_FAILURE'

export const UPDATE_APPOINTMENT_AVAILABLE_REQUEST =
  '@appointments/UPDATE_APPOINTMENT_AVAILABLE_REQUEST'
export const UPDATE_APPOINTMENT_AVAILABLE_SUCCESS =
  '@appointments/UPDATE_APPOINTMENT_AVAILABLE_SUCCESS'
export const UPDATE_APPOINTMENT_AVAILABLE_FAILURE =
  '@appointments/UPDATE_APPOINTMENT_AVAILABLE_FAILURE'

export const UPDATE_APPOINTMENT_REQUEST =
  '@appointments/UPDATE_APPOINTMENT_REQUEST'
export const UPDATE_APPOINTMENT_SUCCESS =
  '@appointments/UPDATE_APPOINTMENT_SUCCESS'
export const UPDATE_APPOINTMENT_FAILURE =
  '@appointments/UPDATE_APPOINTMENT_FAILURE'

export const GET_APPOINTMENT_REQUEST = '@appointments/GET_APPOINTMENT_REQUEST'
export const GET_APPOINTMENT_SUCCESS = '@appointments/GET_APPOINTMENT_SUCCESS'
export const GET_APPOINTMENT_SCHEDULE_SUCCESS =
  '@appointments/GET_APPOINTMENT_SCHEDULE_SUCCESS'
export const GET_APPOINTMENT_MOBILE_SUCCESS =
  '@appointments/GET_APPOINTMENT_MOBILE_SUCCESS'
export const GET_APPOINTMENT_FAILURE = '@appointments/GET_APPOINTMENT_FAILURE'

export const CREATE_APPOINTMENT_REQUEST =
  '@appointments/CREATE_APPOINTMENT_REQUEST'
export const CREATE_APPOINTMENT_SUCCESS =
  '@appointments/CREATE_APPOINTMENT_SUCCESS'
export const CREATE_APPOINTMENT_FAILURE =
  '@appointments/CREATE_APPOINTMENT_FAILURE'

export const DELETE_APPOINTMENT_REQUEST =
  '@appointments/DELETE_APPOINTMENT_REQUEST'
export const DELETE_APPOINTMENT_SUCCESS =
  '@appointments/DELETE_APPOINTMENT_SUCCESS'
export const DELETE_APPOINTMENT_FAILURE =
  '@appointments/DELETE_APPOINTMENT_FAILURE'

export const GET_ONE_SERVICE_REQUEST = '@appointments/GET_ONE_SERVICE_REQUEST'
export const GET_ONE_SERVICE_SUCCESS = '@appointments/GET_ONE_SERVICE_SUCCESS'
export const GET_ONE_SERVICE_FAILURE = '@appointments/GET_ONE_SERVICE_FAILURE'

export const CLEAR_STORE = '@appointments/CLEAR_STORE'

export const SET_QUERY = '@appointments/SET_QUERY'

export const SET_REPEAT_REQUEST = '@appointments/SET_REPEAT_REQUEST'

export const RESET_ERROR = 'RESET_ERROR'
