export const GET_CATEGORIES_REQUEST = '@services/GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES_SUCCESS = '@services/GET_CATEGORIES_SUCCESS'
export const SET_CATEGORIES_WITHOUT_SUCCESS =
  '@services/SET_CATEGORIES_WITHOUT_SUCCESS'
export const GET_FULL_CATEGORIES_SUCCESS =
  '@services/GET_FULL_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAILURE = '@services/GET_CATEGORIES_FAILURE'

export const GET_SERVICE_BY_CATEGORY_REQUEST =
  '@services/GET_SERVICE_BY_CATEGORY_REQUEST'
export const GET_SERVICE_BY_CATEGORY_SUCCESS =
  '@services/GET_SERVICER_BY_CATEGORY_SUCCESS'
export const GET_SERVICE_BY_CATEGORY_FAILURE =
  '@services/GET_SERVICER_BY_CATEGORY_FAILURE'

export const UPDATE_SERVICE_REQUEST = '@services/UPDATE_SERVICE_REQUEST'
export const UPDATE_SERVICE_SUCCESS = '@services/UPDATE_SERVICE_SUCCESS'
export const UPDATE_SERVICE_FAILURE = '@services/UPDATE_SERVICE_FAILURE'

export const GET_ONE_SERVICE_REQUEST = '@services/GET_ONE_SERVICE_REQUEST'
export const GET_ONE_SERVICE_SUCCESS = '@services/GET_ONE_SERVICE_SUCCESS'
export const GET_ONE_SERVICE_FAILURE = '@services/GET_ONE_SERVICE_FAILURE'

export const GET_SUB_CATEGORY_REQUEST = '@services/GET_SUB_CATEGORY_REQUEST'
export const GET_SUB_CATEGORY_SUCCESS = '@services/GET_SUB_CATEGORY_SUCCESS'
export const GET_SUB_CATEGORY_FAILURE = '@services/GET_SUB_CATEGORY_FAILURE'

export const GET_STAFF_REQUEST = '@services/GET_STAFF_REQUEST'
export const GET_STAFF_SUCCESS = '@services/GET_STAFF_SUCCESS'
export const GET_STAFF_FAILURE = '@services/GET_STAFF_FAILURE'

export const DELETE_SERVICE_REQUEST = '@services/DELETE_SERVICE_REQUEST'
export const DELETE_SERVICE_SUCCESS = '@services/DELETE_SERVICE_SUCCESS'
export const DELETE_SERVICE_FAILURE = '@services/DELETE_SERVICE_FAILURE'

export const CREATE_SERVICE_REQUEST = '@services/CREATE_SERVICE_REQUEST'
export const CREATE_SERVICE_SUCCESS = '@services/CREATE_SERVICE_SUCCESS'
export const CREATE_SERVICE_FAILURE = '@services/CREATE_SERVICE_FAILURE'

export const GET_ALL_SERVICE_REQUEST = '@services/GET_ALL_SERVICE_REQUEST'
export const GET_ALL_SERVICE_SUCCESS = '@services/GET_ALL_SERVICE_SUCCESS'
export const GET_FULL_SERVICE_SUCCESS = '@services/GET_FULL_SERVICE_SUCCESS'
export const GET_ALL_SERVICE_FAILURE = '@services/GET_ALL_SERVICE_FAILURE'

export const GET_SERVICE_WITHOUT_CATEGORIES_REQUEST =
  '@services/GET_SERVICE_WITHOUT_CATEGORIES_REQUEST'
export const GET_SERVICE_WITHOUT_CATEGORIES_SUCCESS =
  '@services/GET_SERVICE_WITHOUT_CATEGORIES_SUCCESS'
export const GET_SERVICE_WITHOUT_CATEGORIES_FAILURE =
  '@services/GET_SERVICE_WITHOUT_CATEGORIES_FAILURE'

export const SET_ACTIVE_CATEGORY = '@services/SET_ACTIVE_CATEGORY'

export const SET_IMAGE_LIST = '@services/SET_IMAGE_LIST'

export const SET_MOBILE_TITLE_OPEN = '@services/SET_MOBILE_TITLE_OPEN'

export const RESET_STORE = '@services/RESET_STORE'

export const RESET_ERROR = '@services/RESET_ERROR'

export const RESET_CATEGORY = '@services/RESET_CATEGORY'
