import { FileName, FullMedia, UploadMedia } from 'entities/common'
import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  UPLOAD_MEDIA_REQUEST,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_FAILURE,
  CLEAR_MEDIA_STOR,
  SET_IMAGE_LIST,
  UPLOAD_ARRAY_MEDIA_SUCCESS,
  DELETE_MEDIA_REQUEST,
  DELETE_MEDIA_FAILURE,
  RESET_LINK_TYPE,
  CLEAR_ERRORS,
} from './Media.constant'
import { MediaActionsTypes } from './Media.type'

export function uploadMediaRequest(payload: {
  file: UploadMedia
  fileName: FileName
  isAddCategory?: boolean
  isArray?: boolean
  linkType?: string
  nameUpload: string
}): MediaActionsTypes {
  return {
    type: UPLOAD_MEDIA_REQUEST,
    payload,
  }
}

export function uploadMediaSuccess(payload: {
  data: FullMedia
  name: string
}): MediaActionsTypes {
  return {
    type: UPLOAD_MEDIA_SUCCESS,
    payload,
  }
}

export function uploadArrayMediaSuccess(payload: FullMedia): MediaActionsTypes {
  return {
    type: UPLOAD_ARRAY_MEDIA_SUCCESS,
    payload,
  }
}

export function uploadMediaFailure(payload: ResponseFailureType) {
  return {
    payload,
    type: UPLOAD_MEDIA_FAILURE,
  }
}

export function clearMediaStore(): MediaActionsTypes {
  return {
    type: CLEAR_MEDIA_STOR,
  }
}

export const setImageList = (payload: FullMedia[] | null) => ({
  payload,
  type: SET_IMAGE_LIST,
})

export function deleteMediaRequest(payload: number): MediaActionsTypes {
  return {
    type: DELETE_MEDIA_REQUEST,
    payload,
  }
}

export function deleteMediaFailure() {
  return {
    type: DELETE_MEDIA_FAILURE,
  }
}

export function resetLinkType(): MediaActionsTypes {
  return {
    type: RESET_LINK_TYPE,
  }
}

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
})
