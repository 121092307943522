import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_ORDERS_ERROR,
  GET_ORDER_HISTORY_REQUEST,
  GET_ORDER_HISTORY_FAILURE,
  GET_ORDER_HISTORY_SUCCESS,
  SET_MOBILE_TITLE_OPEN,
  GET_CANCELLED_ORDER_HISTORY_REQUEST,
  GET_CANCELLED_ORDER_HISTORY_SUCCESS,
  GET_CANCELLED_ORDER_HISTORY_FAILURE,
} from './Orders.constant'
import { IOrderHistoryResponse } from './Orders.type'

export const setMobileTitleOpen = (payload: boolean) => ({
  payload,
  type: SET_MOBILE_TITLE_OPEN,
})

export const getOrderHistoryRequest = (query: string) => ({
  type: GET_ORDER_HISTORY_REQUEST,
  payload: query,
})

export const getOrderHistorySuccess = (data: IOrderHistoryResponse) => ({
  type: GET_ORDER_HISTORY_SUCCESS,
  payload: data,
})

export const getOrderHistoryFailure = (error: ResponseFailureType) => ({
  type: GET_ORDER_HISTORY_FAILURE,
  payload: error,
})

export const getCancelledOrderHistoryRequest = (query: string) => ({
  type: GET_CANCELLED_ORDER_HISTORY_REQUEST,
  payload: query,
})

export const getCancelledOrderHistorySuccess = (
  data: IOrderHistoryResponse,
) => ({
  type: GET_CANCELLED_ORDER_HISTORY_SUCCESS,
  payload: data,
})

export const getCancelledOrderHistoryFailure = (
  error: ResponseFailureType,
) => ({
  type: GET_CANCELLED_ORDER_HISTORY_FAILURE,
  payload: error,
})

export const clearOrderError = () => ({
  type: CLEAR_ORDERS_ERROR,
})
