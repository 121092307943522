export enum ManagerSocketEvent {
  APPOINTMENT_BLOKED_BY_CUSTOMER = 'Realtime\\ForManager\\AppointmentBookedByCustomer',
  APPOINTMENT_CANCELED_BY_CUSTOMER = 'Realtime\\ForManager\\AppointmentCanceledByCustomer',
  APPOINTMENT_RESCHEDULE_BY_CUSTOMER = 'Realtime\\ForManager\\AppointmentRescheduledByCustomer',
  CATEGORY_DEACTIVATED = 'Realtime\\ForManager\\CategoryDeactivated',
  DOCUMENT_ACCEPTED = 'Realtime\\ForManager\\DocumentAccepted',
  DOCUMENT_REJECTED = 'Realtime\\ForManager\\DocumentRejected',
  NEW_FEED_BACK = 'Realtime\\ForManager\\NewFeedback',
  SUBSCRIPTION_EXPORED = 'Realtime\\ForManager\\SubscriptionExpired',
}

export enum AdminSocketEvent {
  DOCUMENT_UPLOADED = 'Realtime\\ForAdmin\\DocumentUploaded',
}

export enum PublicSocketEvent {
  PUBLIC_EVENT = 'Realtime\\TestEvent',
}
