import { ResponseFailureType } from 'store/Auth/Auth.type'
import {
  CLEAR_BASKET_ACTION_SUCCESS,
  CLEAR_BASKET_STORE,
  CREATE_BASKET_SERVICE_FAILURE,
  CREATE_BASKET_SERVICE_REQUEST,
  CREATE_BASKET_SERVICE_SUCCESS,
  DELETE_BASKET_SERVICE_REQUEST,
  DELETE_BASKET_SERVICE_SUCCESS,
  GET_BASKET_CALCULATE_TIME_FAILURE,
  GET_BASKET_CALCULATE_TIME_REQUEST,
  GET_BASKET_CALCULATE_TIME_SUCCESS,
  GET_BASKET_FAILURE,
  GET_BASKET_REQUEST,
  GET_BASKET_SUCCESS,
  GET_BASKET_TIME_FAILURE,
  GET_BASKET_TIME_REQUEST,
  GET_BASKET_TIME_SUCCESS,
  GET_BASKET_TOTAL_FAILURE,
  GET_BASKET_TOTAL_REQUEST,
  GET_BASKET_TOTAL_SUCCESS,
  UPDATE_BASKET_SERVICE_FAILURE,
  UPDATE_BASKET_SERVICE_REQUEST,
  UPDATE_BASKET_SERVICE_SUCCESS,
  CREATE_SALON_ORDER_REQUEST,
  CREATE_SALON_ORDER_SUCCESS,
  CREATE_SALON_ORDER_FAILURE,
  SET_IS_TIME_REQUEST,
} from './Basket.constant'
import { BasketActionTypes, IBasketState } from './Basket.type'

const initialState: IBasketState = {
  basket: null,
  isLoading: false,
  errors: {},
  errorMessage: null,
  basketTime: null,
  total: null,
  isActionSuccess: false,
  isSaveSuccess: false,
  isTimeRequest: true,
}

export default function basketReducer(
  state = initialState,
  action: BasketActionTypes,
): IBasketState {
  switch (action.type) {
    case GET_BASKET_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_BASKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        basket: action.payload,
      }

    case GET_BASKET_TIME_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_BASKET_TIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        basketTime: action.payload,
      }

    case GET_BASKET_CALCULATE_TIME_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_BASKET_CALCULATE_TIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        basket: action.payload.data,
        isTimeRequest: false,
      }
    case UPDATE_BASKET_SERVICE_REQUEST:
    case CREATE_BASKET_SERVICE_REQUEST:
    case DELETE_BASKET_SERVICE_REQUEST:
    case GET_BASKET_TOTAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_BASKET_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        basket: {
          ...state.basket,
          items: [...(state.basket?.items || []), action.payload],
        },
        isActionSuccess: true,
      }

    case UPDATE_BASKET_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        basket: {
          ...state.basket,
          items:
            state?.basket?.items?.map((item) => {
              if (item?.id === action?.payload?.id) {
                return action.payload
              }

              return item
            }) || [],
        },
        isActionSuccess: true,
      }

    case DELETE_BASKET_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isActionSuccess: true,
      }

    case GET_BASKET_TOTAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload,
      }

    case GET_BASKET_TOTAL_FAILURE:
    case UPDATE_BASKET_SERVICE_FAILURE:
    case CREATE_BASKET_SERVICE_FAILURE:
    case GET_BASKET_FAILURE:
    case GET_BASKET_TIME_FAILURE:
    case GET_BASKET_CALCULATE_TIME_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
        isActionSuccess: false,
      }

    case CREATE_SALON_ORDER_REQUEST:
      return {
        ...state,
        isSaveSuccess: false,
        isLoading: true,
      }
    case CREATE_SALON_ORDER_SUCCESS:
      return {
        ...state,
        isSaveSuccess: true,
        isLoading: false,
      }
    case CREATE_SALON_ORDER_FAILURE:
      return {
        ...state,
        isSaveSuccess: false,
        isLoading: false,
      }

    case CLEAR_BASKET_ACTION_SUCCESS:
      return {
        ...state,
        isActionSuccess: false,
      }

    case CLEAR_BASKET_STORE: {
      return initialState
    }

    case SET_IS_TIME_REQUEST: {
      return { ...state, isTimeRequest: action.payload }
    }

    default:
      return state
  }
}
