import { useTranslation } from 'react-i18next'

import { ReactComponent as WarningIcon } from 'assets/images/common/notify.svg'

import styles from './style.module.scss'

const TooltipUIPassword = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div>
        <WarningIcon />
      </div>
      <p>{t('VALIDATION.PASSWORD.SHOULD_CORRECT')}</p>
    </div>
  )
}

export default TooltipUIPassword
