import {
  AppointmentSearchValues,
  MethodSearch,
  AppointmentAvailableValues,
  AppointmentValues,
  AppointmentCreateValues,
} from 'store/Manager/Appointments/Appointments.type'
import { POST, GET, DELETE } from 'utils/http'

const basePath = '/appointment'

export const updateSearch = (
  body: AppointmentSearchValues,
  method: MethodSearch,
) => POST(`${basePath}/search/${method}`, body)

export const updateAvailable = (body: AppointmentAvailableValues) =>
  POST(`${basePath}/available-time`, body)

export const updateAppointment = (body: AppointmentValues, id: number) =>
  POST(`${basePath}/${id}`, body)

export const getAppointment = (query: string) => GET(`${basePath}?${query}`)

export const createAppointment = (body: AppointmentCreateValues) =>
  POST(`${basePath}`, body)

export const deleteAppointment = (id: number) => DELETE(`${basePath}/${id}`)
