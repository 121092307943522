import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_ERRORS,
  GET_PLAN_SUBSCRIPTIONS_FAILURE,
  GET_PLAN_SUBSCRIPTIONS_REQUEST,
  GET_PLAN_SUBSCRIPTIONS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
} from './Settings.constant'
import { SettingsActionsTypes, SettingsState } from './Settings.type'

const initialState: SettingsState = {
  isLoading: false,
  errors: {},
  errorMessage: null,
  subscriptions: [],
  settings: {
    reminder_subscription_plan_deprecated_status: 0,
    reminder_subscription_plan_deprecated_time: 0,
    subscription_plan_free_trial_days: 0,
    subscription_plan_free_trial_description: '',
    subscription_plan_free_trial_details: [],
    subscription_plan_free_trial_name: '',
    subscription_plan_free_trial_status: 0,
  },
}

export default function settingsReducer(
  state = initialState,
  action: SettingsActionsTypes,
): SettingsState {
  switch (action.type) {
    case GET_PLAN_SUBSCRIPTIONS_REQUEST:
    case UPDATE_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PLAN_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriptions: action.payload.subscription_plans,
        settings: action.payload.settings,
      }
    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: {},
        errorMessage: null,
      }
    case GET_PLAN_SUBSCRIPTIONS_FAILURE:
    case UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    default:
      return state
  }
}
