import { FC, SyntheticEvent, useMemo, memo } from 'react'

import { useTranslation } from 'react-i18next'

import { UITextArea } from 'ui'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const BlogArticlesSeo: FC<PropsType> = ({
  setValues,
  values: { title, description, h1, keywords },
}) => {
  const { t } = useTranslation()

  const handleChange = (event: SyntheticEvent) => {
    const { name, value } = event.target as HTMLInputElement

    setValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const inputs = useMemo(
    () => [
      { label: t('ADMIN.BLOG.TITLE'), value: title, name: 'title' },
      {
        label: t('ADMIN.BLOG.DESCRIPTION'),
        value: description,
        name: 'description',
      },
      { label: t('ADMIN.BLOG.H1'), value: h1, name: 'h1' },
      {
        label: t('ADMIN.BLOG.KEYWORDS'),
        value: keywords,
        name: 'keywords',
      },
    ],
    [t, description, h1, keywords, title],
  )

  return (
    <div className={styles.wrapper}>
      {inputs.map((item, index) => (
        <UITextArea
          key={index}
          className={styles.bold}
          onChange={handleChange}
          {...item}
        />
      ))}
    </div>
  )
}

export default memo(BlogArticlesSeo)
