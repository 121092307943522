import { memo, FC, useState, useEffect, useMemo } from 'react'

import { UploadFile as TypeUploadFile } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { clearMediaStore } from 'store/Media/Media.action'
import { IRootState } from 'store/Root.reducer'
// eslint-disable-next-line import/no-cycle
import { UIUpload } from 'ui'

import { PropsType } from './index.type'
import style from './style.module.scss'

const UIUploadFile: FC<PropsType> = ({
  name,
  onChange,
  disabled,
  selectedName,
  widthRatio = 1.34,
  heightRatio = 1,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isloading, fileData, fileName } = useSelector(
    (state: IRootState) => state.media,
  )

  const [state, setState] = useState<TypeUploadFile[]>([])

  const handleChange = (file: UploadChangeParam<TypeUploadFile<any>>) => {
    setState((file as unknown as { fileList: TypeUploadFile[] }).fileList)
  }

  const renderName: string = useMemo(() => {
    if (!!state && !!state[0]?.name) return state[0]?.name
    if (selectedName) return String(selectedName)

    return t('COMMON.FILE_NOT_SELECTED')
  }, [state, selectedName, t])

  useEffect(() => {
    if (fileData?.id) {
      onChange(fileName, fileData)
    }

    return () => {
      dispatch(clearMediaStore())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData?.id, fileName])

  const btnUpload = () => {
    return <div className={style.uplodBtn}>{t('COMMON.SELECT_A_FILE')}</div>
  }

  return (
    <div
      className={classNames(style.fileWrapper, {
        [style.disabled]: disabled || isloading,
      })}
    >
      <UIUpload
        isHideBtn={false}
        className={style.upload}
        btnUpload={btnUpload()}
        type="text"
        fileData={fileData}
        isCircle={false}
        widthRatio={widthRatio}
        heightRatio={heightRatio}
        handleChange={handleChange}
        name={name}
      />

      <p>{renderName}</p>
    </div>
  )
}

export default memo(UIUploadFile)
