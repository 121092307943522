import { FC, memo } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Arrow } from 'assets/images/common/redesign/arrowLeft.svg'
import { SORT_VALUES } from 'utils'

import { PropsType, SortStateType } from './index.type'
import style from './style.module.scss'

const UICustomSort: FC<PropsType> = ({ setState, state, sortValues }) => {
  const { t } = useTranslation()

  const handleChange = (key: string) => () => {
    setState((prevState: SortStateType) => {
      const newState: SortStateType = { ...prevState }

      if (newState[key] === null) {
        const keys = Object.keys(state)

        keys.forEach((item) => {
          newState[item] = null
        })

        newState[key] = SORT_VALUES.ASC
      } else {
        newState[key] =
          newState[key] === SORT_VALUES.ASC ? SORT_VALUES.DESC : SORT_VALUES.ASC
      }

      return newState
    })
  }

  return (
    <div className={style.sortWrapper}>
      <p>{t('COMMON.SORT_BY')}:</p>

      <div className={style.sortContainer}>
        {sortValues.map(({ title, key }) => (
          <p
            key={key}
            className={classNames(style.sortItem, {
              [style.active]: !!state[key],
            })}
            onClick={handleChange(key)}
          >
            {title}

            {!!state[key] && (
              <Arrow
                className={classNames(style.arrow, {
                  [style.arrowUp]: state[key] === SORT_VALUES.DESC,
                })}
              />
            )}
          </p>
        ))}
      </div>
    </div>
  )
}

export default memo(UICustomSort)
