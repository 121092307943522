import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useEffect,
  useState,
  useMemo,
} from 'react'

import { Switch } from 'antd'
import { useTranslation } from 'react-i18next'

import UIAntTimePicker from 'ui/Ant/TimePicker'
import { FORMAT_TIME_SHORT, STANDART_DATE } from 'utils'
import { DateHelper, WeekTimeHelper } from 'utils/helpers'
import {
  SalonDataType,
  SalonTime,
  StateTimeType,
} from 'views/manager/ProfileSalon/index.type'

import styles from './style.module.scss'

type PropsType = {
  customize?: boolean
  day: number
  errors?: { [key: string]: string | string[] }
  setIsHourError?: Dispatch<SetStateAction<boolean>>
  setValues?: (value: React.SetStateAction<SalonDataType>) => void
  text: string
  values?: SalonDataType
}

const UIBusinessHours: FC<PropsType> = ({
  text,
  customize = false,
  day,
  values,
  setValues,
  setIsHourError,
  errors,
}) => {
  const { t } = useTranslation()
  const [isBreak, setIsBreak] = useState(false)
  const [isDay, setIsDay] = useState(false)
  const [isWeek, setIsWeek] = useState(false)

  const dayWeek = [1, 2, 3, 4, 5]

  const valueTime = useMemo(() => {
    return values?.times as SalonTime[]
  }, [values?.times])

  const onChange = (time: Date | null, name: string, key: string) => {
    if (customize && time && setValues) {
      const newValues = { ...values, times: { ...valueTime } }

      dayWeek.map((index) => {
        newValues.times[index] = {
          ...valueTime[index],
          [name]: {
            ...((valueTime as unknown as StateTimeType)?.[index]?.[name] || {}),
            [key]: DateHelper.toFormat(time, FORMAT_TIME_SHORT),
          },
        }

        return null
      })
      setValues(newValues as SalonDataType)
    } else if (day && time && setValues && values?.times) {
      setValues((prev) => ({
        ...prev,
        times: {
          ...(prev.times as unknown as StateTimeType),
          [day]: {
            ...(prev.times as unknown as StateTimeType)?.[day],
            [name]: {
              ...((prev.times as unknown as StateTimeType)?.[day]?.[name] ||
                {}),
              [key]: DateHelper.toFormat(time, FORMAT_TIME_SHORT),
            },
          },
        },
      }))
    }

    return null
  }

  const onChangeSwitch = (name: string) => {
    const newValue = {
      ...values,
      times: {
        ...values?.times,
        [day]: {
          ...(values?.times as unknown as StateTimeType)?.[day],
        },
      },
    }

    const newValueTime = (newValue as unknown as StateTimeType).times

    if (isDay) {
      setIsDay(false)
    } else {
      setIsDay(true)
    }

    if (setValues) {
      if (
        newValueTime &&
        (+Object.keys(newValueTime)[day - 1] === day || newValueTime[day])
      ) {
        if (name === 'time' && customize) {
          setIsWeek(!isWeek)
          dayWeek.forEach((index) => delete newValueTime[index])
        } else if (name === 'time' && !customize) {
          setIsWeek(!isWeek)
          setIsBreak(false)
          delete newValueTime[day]?.time
          delete newValueTime[day]?.break
        } else {
          setIsBreak(!isBreak)

          delete newValueTime[day]?.break
        }

        if (
          name === 'break' &&
          customize &&
          !!dayWeek.find((index) => newValueTime[index])
        ) {
          dayWeek.forEach((index) => {
            delete (newValue as unknown as StateTimeType).times[index].break
          })
        }
      }

      setValues(newValue as SalonDataType)
    }
  }

  const timeStart = (valueTime as unknown as StateTimeType)?.[+day]?.time
    ?.start as unknown as Date

  const timeEnd = (valueTime as unknown as StateTimeType)?.[+day]?.time
    ?.end as unknown as Date

  const breakStart = (valueTime as unknown as StateTimeType)?.[+day]?.break
    ?.start as unknown as Date

  const breakEnd = (valueTime as unknown as StateTimeType)?.[+day]?.break
    ?.end as unknown as Date

  useEffect(() => {
    if (
      valueTime &&
      !!valueTime[day] &&
      'break' in (valueTime as unknown as StateTimeType)[day]
    ) {
      setIsBreak(true)
    }
  }, [day, valueTime])

  useEffect(() => {
    if (
      valueTime &&
      !!valueTime[day] &&
      'time' in (valueTime as unknown as StateTimeType)[day]
    ) {
      setIsWeek(true)
      setIsDay(true)
    }
  }, [day, valueTime])

  useEffect(() => {
    if (setIsHourError) {
      setIsHourError(false)

      const time = valueTime as unknown as StateTimeType

      Object.keys(valueTime).forEach((item) => {
        const startT = time[+item]?.time?.start
        const endT = time[+item]?.time?.end
        const startB = time[+item]?.break?.start
        const endB = time[+item]?.break?.end

        if (
          (startT && startB && startT >= startB) ||
          (startB && endB && startB >= endB)
        ) {
          setIsHourError(true)
        } else if (startT && endT && startT >= endT) {
          setIsHourError(true)
        } else if ((startT && !endT) || (endT && !startT)) {
          setIsHourError(true)
        } else if (startB && startT && startB <= startT) {
          setIsHourError(true)
        } else if (endB && endT && endB >= endT) {
          setIsHourError(true)
        } else if ((startB && !endB) || (endB && !startB)) {
          setIsHourError(true)
        }
      })
    }
  }, [setIsHourError, valueTime])

  return (
    <div className={styles.wrapper}>
      <Switch checked={isDay} onChange={() => onChangeSwitch('time')} />

      <p className={styles.text}>{text}</p>
      {isDay && (
        <>
          <UIAntTimePicker
            placeholder={t('COMMON.SELECT')}
            format="HH:mm"
            value={timeStart && new Date(`${STANDART_DATE} ${timeStart}`)}
            clearIcon={false}
            showNow={false}
            inputReadOnly={true}
            disabledTime={() =>
              WeekTimeHelper.onDisabledTime(
                WeekTimeHelper.disabledTimeStart(timeEnd),
                WeekTimeHelper.disabledTimeStart(timeEnd, timeStart, true),
              )
            }
            onSelect={(e: Date | null) => onChange(e, 'time', 'start')}
            minuteStep={5}
            {...(timeStart >= timeEnd && { status: 'error' })}
            error={errors?.[`times.${day}.time.start`]?.[0]}
          />
          <div className={styles.dash}></div>
          <UIAntTimePicker
            placeholder={t('COMMON.SELECT')}
            className={styles.timepicker}
            format="HH:mm"
            clearIcon={false}
            value={timeEnd && new Date(`${STANDART_DATE} ${timeEnd}`)}
            showNow={false}
            inputReadOnly={true}
            onSelect={(e: Date | null) => onChange(e, 'time', 'end')}
            disabledTime={() =>
              WeekTimeHelper.onDisabledTime(
                WeekTimeHelper.disabledTimeEnd(timeStart),
                WeekTimeHelper.disabledTimeEnd(timeStart, timeEnd, true),
              )
            }
            minuteStep={5}
            {...(timeStart >= timeEnd && { status: 'error' })}
            error={errors?.[`times.${day}.time.end`]?.[0]}
          />
        </>
      )}

      <Switch
        checked={
          isBreak &&
          valueTime &&
          !!valueTime[day] &&
          !!(valueTime as unknown as StateTimeType)[day]?.time
        }
        onChange={() => onChangeSwitch('break')}
        disabled={!(timeStart && timeEnd)}
      />
      <div className={styles.addBreak}>
        {t('MANAGER.PROFILE_SALON.ADD_BREAK')}
      </div>

      {isBreak && isDay && timeStart && timeEnd && (
        <UIAntTimePicker
          placeholder={t('COMMON.SELECT')}
          format="HH:mm"
          value={breakStart && new Date(`${STANDART_DATE} ${breakStart}`)}
          clearIcon={false}
          showNow={false}
          inputReadOnly={true}
          onSelect={(e: Date | null) => onChange(e, 'break', 'start')}
          disabledTime={() =>
            WeekTimeHelper.onDisabledTime(
              WeekTimeHelper.disabledBreakStart(
                timeStart,
                timeEnd,
                breakEnd,
                breakStart,
              ),
              WeekTimeHelper.disabledBreakStart(
                timeStart,
                timeEnd,
                breakEnd,
                breakStart,
                true,
              ),
            )
          }
          disabled={!(timeStart && timeEnd)}
          minuteStep={5}
          {...(timeStart >= breakStart && { status: 'error' })}
          error={errors?.[`times.${day}.break.start`]?.[0]}
        />
      )}

      {isBreak && isDay && timeStart && timeEnd && (
        <>
          <div className={styles.dash}></div>
          <UIAntTimePicker
            placeholder={t('COMMON.SELECT')}
            className={styles.breakTimepicker}
            format="HH:mm"
            value={breakEnd && new Date(`${STANDART_DATE} ${breakEnd}`)}
            clearIcon={false}
            showNow={false}
            inputReadOnly={true}
            onSelect={(e: Date | null) => onChange(e, 'break', 'end')}
            disabledTime={() =>
              WeekTimeHelper.onDisabledTime(
                WeekTimeHelper.disabledBreakEnd(
                  timeStart,
                  timeEnd,
                  breakStart,
                  breakEnd,
                ),
                WeekTimeHelper.disabledBreakEnd(
                  timeStart,
                  timeEnd,
                  breakStart,
                  breakEnd,
                  true,
                ),
              )
            }
            disabled={!(timeStart && timeEnd)}
            minuteStep={5}
            {...((breakStart >= breakEnd || breakEnd >= timeEnd) && {
              status: 'error',
            })}
            error={errors?.[`times.${day}.break.end`]?.[0]}
          />
        </>
      )}
    </div>
  )
}

export default memo(UIBusinessHours)
