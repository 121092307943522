import { FC, useCallback, useMemo } from 'react'

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'

import { reorder } from 'entities/common'
import { ServiceType } from 'entities/manager/service'

import ServiceCard from './ServiceCard'
import styles from './style.module.scss'

type PropsType = {
  handler?: (values: number) => void
  handlerDelete?: (value: number) => void
  handlerSort?: (array: number[]) => void
  isNotMove?: boolean
  onClick?: (index: number) => void
  remove?: boolean
  services: ServiceType[]
  setServices: (services: ServiceType[]) => void
}

const UIServicesCards: FC<PropsType> = ({
  services,
  setServices,
  onClick,
  remove,
  handlerDelete,
  handler,
  isNotMove,
  handlerSort,
}) => {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      services,
      result.source.index,
      result.destination.index,
    )

    setServices(items as ServiceType[])

    if (handlerSort) {
      handlerSort((items as ServiceType[]).map((i) => i.id))
    }
  }

  const handlerDeleteChange = useCallback(
    (index: number, id: number) => () => {
      if (handlerDelete) {
        handlerDelete(id)
      }
    },
    [handlerDelete],
  )

  const handleClick = useCallback(
    (id: number) => {
      if (onClick && id) {
        onClick(id)
      }
      if (handler) {
        handler(id)
      }
    },
    [handler, onClick],
  )

  const renderServices = useMemo(
    () =>
      services.map((item, index) => {
        return (
          <Draggable
            key={item.id}
            draggableId={String(item.id)}
            index={index}
            isDragDisabled={isNotMove}
          >
            {(prov) => (
              <div
                ref={prov.innerRef}
                {...prov.draggableProps}
                {...prov.dragHandleProps}
                onClick={() => handleClick(item.id)}
              >
                <div className={styles.wrapper}>
                  <ServiceCard
                    remove={remove}
                    description={item.description}
                    price={item.price.price}
                    oldPrice={item.price.old_price}
                    time={item.duration}
                    title={item.name}
                    onDelete={handlerDeleteChange(index, item.id)}
                  />
                </div>
              </div>
            )}
          </Draggable>
        )
      }),
    [handleClick, handlerDeleteChange, isNotMove, remove, services],
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {renderServices}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default UIServicesCards
