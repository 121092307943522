import { FileName, UploadMedia } from 'entities/common'
import { POST, DELETE, GET } from 'utils/http'

const basePath = '/document'

export const uploadDocument = async (
  fileName: FileName,
  nameUpload: string,
  file: UploadMedia,
) => {
  const formData = new FormData()

  formData.append(fileName, file as unknown as Blob)
  formData.append('name', nameUpload)

  const { data }: { data: { id: number; path: string } } = await POST(
    basePath,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )

  return data
}

export const deleteDocument = (id: number) => DELETE(`${basePath}/${id}`)

export const addDocument = (body: { ids: number[] }) =>
  POST(`${basePath}/save`, body)

export const getDocument = () => GET(`${basePath}`)
