import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_SUPPORT_MANAGER_ERRORS,
  SEND_SUPPORT_MANAGER_MESSAGE_REQUEST,
  SEND_SUPPORT_MANAGER_MESSAGE_FAILURE,
  SEND_SUPPORT_MANAGER_MESSAGE_SUCCESS,
} from './Support.constant'

export const sendSupportRequest = (message: string) => ({
  type: SEND_SUPPORT_MANAGER_MESSAGE_REQUEST,
  payload: message,
})

export const sendSupportSuccess = () => ({
  type: SEND_SUPPORT_MANAGER_MESSAGE_SUCCESS,
})

export const sendSupportFailure = (error: ResponseFailureType) => ({
  type: SEND_SUPPORT_MANAGER_MESSAGE_FAILURE,
  payload: error,
})

export const clearSupportManagerErrors = () => ({
  type: CLEAR_SUPPORT_MANAGER_ERRORS,
})
