import { FC, memo } from 'react'

import classNames from 'classnames'

import style from './style.module.scss'

type PropsType = { isOpen: boolean; onClick: () => void }

const UIBurger: FC<PropsType> = ({ isOpen, onClick }) => (
  <button
    className={classNames(style.burger, { [style.open]: isOpen })}
    onClick={onClick}
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
)

export default memo(UIBurger)
