import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { redirectFromType } from 'entities/auth'
import {
  ForgotPasswordResponseType,
  ILoginResponse,
  LoginType,
} from 'entities/unauthorized/Auth'
import {
  loginApi,
  registerApi,
  verifyApi,
  forgotPasswordApi,
  resetPasswordApi,
  socialLoginApi,
  logoutApi,
} from 'services/http'
import { USER } from 'utils'
import getType from 'utils/helpers/getType'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  authLoginSuccess,
  authLoginFailure,
  authRegisterSuccess,
  authRegisterFailure,
  authVerifyFailure,
  authVerifySuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  logoutFailure,
  logoutSuccess,
} from './Auth.action'
import {
  AUTH_LOGIN_REQUEST,
  AUTH_REGISTER_REQUEST,
  AUTH_SOCIAL_LOGIN_REQUEST,
  AUTH_VERIFY_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  LOGOUT_REQUEST,
  RESET_PASSWORD_REQUEST,
} from './Auth.constant'
import {
  IAuthLoginRequest,
  IAuthRegisterRequest,
  IAuthSocialLoginRequest,
  IAuthVerifyRequest,
  IResetPasswordRequest,
} from './Auth.type'

function saveToStorage(
  response: { data: ILoginResponse },
  payload?: LoginType,
) {
  const {
    data: {
      user,
      user: { email },
    },
  } = response

  localStorage.setItem(USER, JSON.stringify(user))

  if (payload?.remember) {
    localStorage.setItem('email', email)
  } else {
    localStorage.removeItem('email')
  }
}

function* workerAuthLogin({ payload }: IAuthLoginRequest): unknown {
  try {
    const response: { data: ILoginResponse } = yield call(loginApi, payload)

    saveToStorage(response, payload)

    yield put(authLoginSuccess(response.data))

    redirectFromType(response.data.user.type)
  } catch (error) {
    yield getRequestErrors(authLoginFailure, error as string)
  }
}

function* watchAuthLogin() {
  yield takeLatest(AUTH_LOGIN_REQUEST, workerAuthLogin)
}

function* workerAuthSocialLogin({ payload }: IAuthSocialLoginRequest): unknown {
  try {
    if (payload.token) {
      const response: { data: ILoginResponse } = yield call(
        socialLoginApi,
        payload,
      )

      saveToStorage(response)

      yield put(authLoginSuccess(response.data))

      redirectFromType(response.data.user.type)
    }
  } catch (error) {
    yield getRequestErrors(authLoginFailure, error as string)
  }
}

function* watchAuthSocailLogin() {
  yield takeLatest(AUTH_SOCIAL_LOGIN_REQUEST, workerAuthSocialLogin)
}

function* workerAuthRegister({
  payload: { type, body },
}: IAuthRegisterRequest): unknown {
  try {
    yield call(registerApi, type, body)

    yield put(authRegisterSuccess())
  } catch (error) {
    yield put(
      authRegisterFailure(
        getType(error) === 'string'
          ? { errorMessage: error as string }
          : { registerErrors: error as { [key: string]: string[] } },
      ),
    )
  }
}

function* watchAuthRegister() {
  yield takeLatest(AUTH_REGISTER_REQUEST, workerAuthRegister)
}

function* workerAuthVerify({ payload }: IAuthVerifyRequest) {
  try {
    yield call(verifyApi, payload)

    yield put(authVerifySuccess())
    window.location.replace('/')
  } catch (error) {
    yield getRequestErrors(authVerifyFailure, error as string)
  }
}

function* watchAuthVerify() {
  yield takeLatest(AUTH_VERIFY_REQUEST, workerAuthVerify)
}

function* workerForgotPassword({ payload }: IAuthVerifyRequest) {
  try {
    const response: ForgotPasswordResponseType = yield call(
      forgotPasswordApi,
      payload,
    )

    if (response.data.status) {
      yield put(forgotPasswordSuccess())
    }
  } catch (error) {
    yield getRequestErrors(forgotPasswordFailure, error as string)
  }
}

function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, workerForgotPassword)
}

function* workerResetPassword({ payload }: IResetPasswordRequest) {
  try {
    const response: ForgotPasswordResponseType = yield call(
      resetPasswordApi,
      payload,
    )

    if (response.data.status) {
      yield put(resetPasswordSuccess())
    }
  } catch (error) {
    yield getRequestErrors(resetPasswordFailure, error as string)
  }
}

function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD_REQUEST, workerResetPassword)
}

function* workerLogout() {
  try {
    yield call(logoutApi)

    yield put(logoutSuccess())
    localStorage.removeItem(USER)
    window.location.assign('https://beautybooking.mx/');
  } catch (error) {
    yield getRequestErrors(logoutFailure, error as string)
  }
}

function* watchLogout() {
  yield takeLatest(LOGOUT_REQUEST, workerLogout)
}

export const authWatchers = [
  fork(watchAuthLogin),
  fork(watchAuthSocailLogin),
  fork(watchAuthRegister),
  fork(watchAuthVerify),
  fork(watchForgotPassword),
  fork(watchResetPassword),
  fork(watchLogout),
]
