import { FC, memo } from 'react'

import { Switch, SwitchProps } from 'antd'
import classNames from 'classnames'

import style from './style.module.scss'

type PropsType = SwitchProps

const UISwitch: FC<PropsType> = ({ className, ...props }) => (
  <Switch className={classNames(style.switcher, className)} {...props} />
)

export default memo(UISwitch)
