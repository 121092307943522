import {
  EditCategoryType,
  SetCategoryType,
  SetSubCategoryType,
} from 'entities/view'
import { GET, POST } from 'utils/http'

const basePath = '/category'

export const getCategoriesApi = () => GET(`${basePath}`)

export const editCategoryApi = (payload: EditCategoryType) =>
  POST(`${basePath}/${payload.id}`, {
    active: payload.active,
    image: payload.image,
    name: payload.name,
    slug: payload.slug,
    ...(payload.seo && { seo: payload.seo }),
  })

export const setCategoryApi = (payload: SetCategoryType) =>
  POST(`${basePath}`, payload)

export const sortCategoriesApi = (payload: number[]) =>
  POST(`${basePath}/sort`, { ids: payload })

export const getSubcategoriesApi = (id: number) => GET(`${basePath}/${id}`)

export const editSubategoryApi = (payload: SetCategoryType) =>
  POST(`${basePath}/section/${payload.id}`, payload)

export const setSubcategoryApi = (payload: SetSubCategoryType) =>
  POST(`${basePath}/${payload.id}/section`, {
    name: payload.name,
    image: payload.image,
    slug: payload.slug,
    ...(payload.seo && { seo: payload.seo }),
  })

export const sortSubcategoriesApi = (payload: number[]) =>
  POST('section/sort', { ids: payload })
