import { Dispatch, FC, ReactElement, SetStateAction } from 'react'

import { Spin, UploadFile } from 'antd'
import { RcFile } from 'antd/lib/upload'
import Dragger from 'antd/lib/upload/Dragger'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ReactComponent as UploadSVG } from 'assets/images/common/redesign/upload.svg'
import DocumentCard from 'modules/manager/document/Card'
import { filterDocumentList } from 'store/Manager/Documents/Documents.action'
import { DocumentsType } from 'store/Manager/Documents/Documents.type'

import styles from './style.module.scss'

type PropsType = {
  className?: string
  fileList: DocumentsType[]
  handleChange?: (arg: number) => void
  handleRequest: (arg: UploadFile) => void
  isLoading?: boolean
  setFileList: Dispatch<SetStateAction<DocumentsType[]>>
}

const UIDragUpload: FC<PropsType> = ({
  fileList,
  setFileList,
  isLoading,
  handleRequest,
  handleChange,
  className,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleDelete = (fileId: number) => {
    setFileList(fileList.filter((item) => item.id !== fileId))
    dispatch(filterDocumentList(fileId))
  }

  const renderItem = (originNode: ReactElement, file: UploadFile) => {
    return (
      <DocumentCard
        name={file.name}
        handlerDelete={() =>
          handleDelete((file as unknown as DocumentsType).id)
        }
      />
    )
  }

  return (
    <div className={cn(styles.wrapper, className)}>
      <Dragger
        fileList={fileList as unknown as UploadFile[]}
        customRequest={(file) => handleRequest(file.file as RcFile)}
        itemRender={(originNode: ReactElement, file: UploadFile) =>
          renderItem(originNode, file)
        }
        onChange={(value) => {
          if (handleChange) {
            handleChange(value.fileList.length)
          }
        }}
        multiple={true}
      >
        <Spin spinning={isLoading}>
          <div className={styles.uploadBtnWrapp}>
            <UploadSVG />
            <p className={styles.drag}>{t('MANAGER.DOCUMENT.DRAG')}</p>
            <p className={styles.browsefile}>
              {t('MANAGER.DOCUMENT.BROWSE_FILE')}
            </p>
          </div>
        </Spin>
      </Dragger>
    </div>
  )
}

export default UIDragUpload
