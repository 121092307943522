import React, { FC } from 'react'

import { Routes, Route } from 'react-router-dom'

import routes from 'views'

const RoutesComponent: FC = () => (
  <Routes>
    {routes.map(({ element, name, path, Layout }) => {
      return (
        <Route
          path={path}
          element={Layout ? <Layout>{element}</Layout> : element}
          key={name}
        />
      )
    })}
  </Routes>
)

export default React.memo(RoutesComponent)
