export const AUTH_LOGIN_REQUEST = '@auth/AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_SUCCESS = '@auth/AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = '@auth/AUTH_LOGIN_FAILURE'

export const AUTH_REGISTER_REQUEST = '@auth/AUTH_REGISTER_REQUEST'
export const AUTH_REGISTER_SUCCESS = '@auth/AUTH_REGISTER_SUCCESS'
export const AUTH_REGISTER_FAILURE = '@auth/AUTH_REGISTER_FAILURE'

export const AUTH_SOCIAL_LOGIN_REQUEST = '@auth/AUTH_SOCIAL_LOGIN_REQUEST'

export const AUTH_VERIFY_REQUEST = '@auth/AUTH_VERIFY_REQUEST'
export const AUTH_VERIFY_SUCCESS = '@auth/AUTH_VERIFY_SUCCESS'
export const AUTH_VERIFY_FAILURE = '@auth/AUTH_VERIFY_FAILURE'

export const FORGOT_PASSWORD_REQUEST = '@auth/FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = '@auth/FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = '@auth/FORGOT_PASSWORD_FAILURE'

export const RESET_PASSWORD_REQUEST = '@auth/RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = '@auth/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = '@auth/RESET_PASSWORD_FAILURE'

export const LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = '@auth/LOGOUT_FAILURE'

export const RESET_FORGOT_STATE = '@auth/RESET_FORGOT_STATE'

export const CLEAR_ERRORS = '@auth/CLEAR_ERRORS'
export const CLEAR_REGESTERED = '@auth/CLEAR_REGESTERED'

export const CANCELING_REDIRECT = '@auth/CANCELING_REDIRECT'
