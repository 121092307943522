import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

type PropsType = {
  className?: string
}

const UITag: FC<PropsType> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <p className={classNames(styles.notification, className)}>
      {t('COMMON.NEW')}
    </p>
  )
}

export default UITag
