import { call, fork, put, takeLatest } from 'redux-saga/effects'

import i18n from 'lib/i18n/i18n'
import { SalonHttp } from 'services/http'
import { addAlert } from 'store/Alert'
import { AlertHelper } from 'utils/helpers'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  createPhoneFailure,
  createPhoneSuccess,
  getProfileFailure,
  getProfileSuccess,
  deletePhoneFailure,
  deletePhoneSuccess,
  updatePhoneFailure,
  updatePhoneSuccess,
  updateProfileFailure,
  updateProfileSuccess,
} from './Salon.action'
import {
  CREATE_MANAGER_PHONE_REQUEST,
  DELETE_MANAGER_PHONE_REQUEST,
  GET_MANAGER_PHONE_REQUEST,
  GET_MANAGER_PROFILE_REQUEST,
  UPDATE_MANAGER_PHONE_REQUEST,
  UPDATE_MANAGER_PROFILE_REQUEST,
} from './Salon.constant'
import {
  ICreatePhoneRequest,
  IDeletePhoneRequest,
  IGetProfileResponse,
  IUpdatePhoneRequest,
  IUpdateProfileRequest,
  SalonPhoneResponseType,
} from './Salon.type'

function* workerUpdateProfile({ payload }: IUpdateProfileRequest): unknown {
  try {
    yield call(SalonHttp.updateProfile, payload)

    yield put(updateProfileSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(updateProfileFailure, error as string)
  }
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_MANAGER_PROFILE_REQUEST, workerUpdateProfile)
}

function* workerGetProfile() {
  try {
    const { data }: { data: IGetProfileResponse } = yield call(
      SalonHttp.getProfile,
    )

    yield put(getProfileSuccess({ data: data.data, message: data.message }))
  } catch (error) {
    yield put(getProfileFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetProfile() {
  yield takeLatest(GET_MANAGER_PROFILE_REQUEST, workerGetProfile)
}

function* workerGetPhone() {
  try {
    const { data }: { data: IGetProfileResponse } = yield call(
      SalonHttp.getPhone,
    )

    yield put(getProfileSuccess({ data: data.data, message: data.message }))
  } catch {
    yield put(getProfileFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetPhone() {
  yield takeLatest(GET_MANAGER_PHONE_REQUEST, workerGetPhone)
}

function* workerCreatePhone({ payload }: ICreatePhoneRequest) {
  try {
    const { data }: { data: SalonPhoneResponseType } = yield call(
      SalonHttp.createPhone,
      payload,
    )

    yield put(createPhoneSuccess(data.data))
  } catch (error) {
    yield getRequestErrors(createPhoneFailure, error as string)
  }
}

function* watchCreatePhone() {
  yield takeLatest(CREATE_MANAGER_PHONE_REQUEST, workerCreatePhone)
}

function* workerDeletePhone({ payload }: IDeletePhoneRequest) {
  try {
    const { data }: { data: number } = yield call(
      SalonHttp.deletePhone,
      payload,
    )

    yield put(deletePhoneSuccess(data))
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_DELETE'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(deletePhoneFailure, error as string)
  }
}

function* watchDeletePhone() {
  yield takeLatest(DELETE_MANAGER_PHONE_REQUEST, workerDeletePhone)
}

function* workerUpdatePhone({ payload }: IUpdatePhoneRequest) {
  try {
    const { data }: { data: SalonPhoneResponseType } = yield call(
      SalonHttp.updatePhone,
      payload,
    )

    yield put(updatePhoneSuccess(data.data))
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(updatePhoneFailure, error as string)
  }
}

function* watchDUpdatePhone() {
  yield takeLatest(UPDATE_MANAGER_PHONE_REQUEST, workerUpdatePhone)
}

export const salonWatchers = [
  fork(watchUpdateProfile),
  fork(watchGetProfile),
  fork(watchGetPhone),
  fork(watchCreatePhone),
  fork(watchDeletePhone),
  fork(watchDUpdatePhone),
]
