import { FullMedia } from 'entities/common'
import { SeoNames } from 'utils'

export const INITIAL_CUSTOMER_STATE_SECTION_TWO = {
  section_2_title: '',
  section_2_description: '',
}

export const INITIAL_CUSTOMER_STATE_SECTION_FOUR = {
  section_4_title: '',
  section_4_description: '',
  section_4_image_1: undefined,
  section_4_image_2: undefined,
}

export const INITIAL_CUSTOMER_STATE_SECTION_FIVE = {
  section_5_title: '',
  section_5_description: '',
  section_5_image_1: undefined,
  section_5_image_2: undefined,
  section_5_image_3: undefined,
}

export const INITIAL_CUSTOMER_STATE_SECTION_SIX = {
  section_6_title: '',
  section_6_subtitle: '',
  section_6_description: [],
  section_6_image: undefined,
}

export const INITAL_SEO_STATE = {
  [SeoNames.DESCRIPTION]: '',
  [SeoNames.KEYWORDS]: '',
  [SeoNames.TITLE]: '',
}

export type CustomerTemplateStateType = {
  [key: string]: string | string[] | undefined | File | number | FullMedia
}
