import { Spin } from 'antd'
import useLazyLoading from 'hooks/useLazyLoading'
import { UIEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getManagerHistorySubscriptionRequest } from 'store/Manager/Subscription/Subscription.action'
import { IRootState } from 'store/Root.reducer'
import { FORMAT_DATE_CUSTOM } from 'utils'
import { DateHelper } from 'utils/helpers'
import styles from './style.module.scss'

export const HistoryHeader = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.headerWrapp}>
      <p>{t('COMMON.SUBSCRIPTION_HISTORY')}</p>
    </div>
  )
}

export const HistoryContent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const subscriptionId = searchParams.get('subscription_id')
  const baToken = searchParams.get('ba_token')

  const { history, isHistoryLoading } = useSelector(
    (store: IRootState) => store.managerSubscription,
  )

  const [page, setPage] = useState(0)

  const [onScroll, containerRef] = useLazyLoading({
    onIntersection: () => {
      if (history && page <= history?.meta.per_page) {
        setPage(page + 1)
      }
    },
  })

  const renderStatus = (type: number) => {
    switch (type) {
      case 1:
        return <p className={styles.succesful}>{t('MANAGER.PAID_SECCESFUL')}</p>
      case 2:
        return <p className={styles.failed}>{t('MANAGER.PAID_FAILED')}</p>

      default:
        return <p className={styles.succesful}>{t('MANAGER.PAID_SECCESFUL')}</p>
    }
  }

  useEffect(() => {
    if (!subscriptionId && !baToken) {
      dispatch(getManagerHistorySubscriptionRequest())
    }
  }, [baToken, dispatch, subscriptionId])

  useEffect(() => {
    if (page > 0 && history?.meta?.next_cursor) {
      dispatch(
        getManagerHistorySubscriptionRequest(
          `cursor=${history?.meta.next_cursor}`,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page])

  return (
    <Spin spinning={isHistoryLoading}>
      <div
        className={styles.contentWrapp}
        ref={containerRef}
        onScroll={onScroll as UIEventHandler<HTMLDivElement>}
      >
        {history?.data?.map((item) => (
          <div className={styles.item} key={item.id}>
            <p>
              {DateHelper.toFormat(
                new Date(item.created_at),
                FORMAT_DATE_CUSTOM,
              )}
            </p>
            <p>{item.subject}</p>
            <p>${item.sum} </p>
            {renderStatus(item.type)}
          </div>
        ))}
      </div>
    </Spin>
  )
}
