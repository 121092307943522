import { call, fork, put, takeLatest } from 'redux-saga/effects'

import i18n from 'lib/i18n/i18n'
import { SettingsHttp } from 'services/http'
import { addAlert } from 'store/Alert'
import { getRequestErrors } from 'utils/helpers/requests'
import { AdminSettingsBlockSubscriptionsType } from 'views/admin/Settings/index.type'

import {
  getPlanSubscriptionsFailure,
  getPlanSubscriptionsRequest,
  getPlanSubscriptionsSuccess,
  updateSettingsFailure,
  updateSettingsSuccess,
} from './Settings.action'
import {
  GET_PLAN_SUBSCRIPTIONS_REQUEST,
  UPDATE_SETTINGS_REQUEST,
} from './Settings.constant'
import {
  IUpdateSettingsRequest,
  AdminSettingsRequestDataTypeSettings,
} from './Settings.type'

function* workerGetPlanSubscriptions() {
  try {
    const response: {
      data: {
        data: AdminSettingsBlockSubscriptionsType[]
        settings: AdminSettingsRequestDataTypeSettings
      }
    } = yield call(SettingsHttp.getPlanSubscriptions)

    yield put(
      getPlanSubscriptionsSuccess({
        subscription_plans: response.data.data,
        settings: response.data.settings,
      }),
    )
  } catch (error) {
    yield getRequestErrors(getPlanSubscriptionsFailure, error as string)
  }
}

function* watchGetPlanSubscriptions() {
  yield takeLatest(GET_PLAN_SUBSCRIPTIONS_REQUEST, workerGetPlanSubscriptions)
}

function* workerUpdateSettings({ payload }: IUpdateSettingsRequest): unknown {
  try {
    yield call(SettingsHttp.updateSetting, payload)

    yield put(updateSettingsSuccess())
    yield put(getPlanSubscriptionsRequest())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(updateSettingsFailure, error as string)
  }
}

function* watchUpdateSettings() {
  yield takeLatest(UPDATE_SETTINGS_REQUEST, workerUpdateSettings)
}

export const adminSettingWatchers = [
  fork(watchUpdateSettings),
  fork(watchGetPlanSubscriptions),
]
