import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_ERRORS,
  GET_PLAN_SUBSCRIPTIONS_FAILURE,
  GET_PLAN_SUBSCRIPTIONS_REQUEST,
  GET_PLAN_SUBSCRIPTIONS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
} from './Settings.constant'
import { AdminSettingsRequestDataType } from './Settings.type'

export const getPlanSubscriptionsRequest = () => ({
  type: GET_PLAN_SUBSCRIPTIONS_REQUEST,
})

export const getPlanSubscriptionsSuccess = (
  data: AdminSettingsRequestDataType,
) => ({
  type: GET_PLAN_SUBSCRIPTIONS_SUCCESS,
  payload: data,
})

export const getPlanSubscriptionsFailure = (error: ResponseFailureType) => ({
  type: GET_PLAN_SUBSCRIPTIONS_FAILURE,
  payload: error,
})

export const updateSettingsRequest = (
  payload: AdminSettingsRequestDataType,
) => ({
  type: UPDATE_SETTINGS_REQUEST,
  payload,
})

export const updateSettingsSuccess = () => ({
  type: UPDATE_SETTINGS_SUCCESS,
})

export const updateSettingsFailure = (payload: ResponseFailureType) => ({
  payload,
  type: UPDATE_SETTINGS_FAILURE,
})

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
})
