import { ChangeEvent, FC, useState } from 'react'

import { useDispatch } from 'react-redux'

import { editSubcategory } from 'store/Admin'

import Input from '../Input'

type PropsType = {
  active: boolean
  id: number
  name: string
}

const AdminUISubcategory: FC<PropsType> = ({ active, name, id }) => {
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [value, setValue] = useState(name)

  const handleSave = () => {
    if (value) {
      dispatch(editSubcategory({ name: value, id }))
    }

    setIsEdit(false)
  }

  const handleActive = () => {
    setIsEdit(true)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleSwitch = (isChecked: boolean) => {
    dispatch(editSubcategory({ active: Number(isChecked) as 0 | 1, id }))
  }

  return (
    <Input
      active={active}
      name={name}
      isEdit={isEdit}
      isActive={isEdit}
      value={value}
      handleSwitch={handleSwitch}
      onChange={handleChange}
      onActive={handleActive}
      onSave={handleSave}
    />
  )
}

export default AdminUISubcategory
