import { FC, memo, SyntheticEvent } from 'react'

import { useNavigate } from 'react-router-dom'

import { UICheckbox } from 'ui'
import { ADMIN_BLOG_EDIT, ADMIN_BLOG_EDIT_CATEGORY } from 'utils'

import { PropsType } from './index.type'
import style from './style.module.scss'

const BlogTableContent: FC<PropsType> = ({
  itemRow,
  block,
  handleChecked,
  selected,
}) => {
  const navigate = useNavigate()

  const handleRowClick = (id: number) => {
    navigate(
      block
        ? ADMIN_BLOG_EDIT.replace(':id', String(id))
        : ADMIN_BLOG_EDIT_CATEGORY.replace(':id', String(id)),
    )
  }

  return (
    <tr key={itemRow.id} onClick={() => handleRowClick(itemRow.id)}>
      <td
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation()
        }}
      >
        <UICheckbox
          checked={!!selected.find((item) => item === itemRow.id)}
          onChange={handleChecked(itemRow.id)}
        >
          {null}
        </UICheckbox>
      </td>

      {block && (
        <>
          <td>
            <img className={style.image} src={itemRow.image} alt="image" />
          </td>
          <td>{itemRow.name}</td>
          <td>{itemRow.type}</td>
          <td>{itemRow.category}</td>
          <td>{itemRow.added}</td>
          <td>{itemRow.updated}</td>
        </>
      )}

      {!block && (
        <>
          <td>{itemRow.name}</td>
          <td>{itemRow.create}</td>
          <td>{itemRow.updated}</td>
        </>
      )}
    </tr>
  )
}

export default memo(BlogTableContent)
