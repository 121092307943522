import { Dispatch, FC, SetStateAction, useMemo, useCallback } from 'react'

import cn from 'classnames'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { reorder } from 'entities/common'
import { CategoryType } from 'entities/view'

import CategoryCard from './CategoryCard'
import styles from './style.module.scss'

type PropsType = {
  active: number
  activeId?: number | null
  categories: CategoryType[]
  handler?: (value: number, idx: number) => void
  handlerSort?: (array: number[]) => void
  idxStaff?: number
  setActive: (active: number) => void
  setCategories: Dispatch<SetStateAction<CategoryType[]>>
}

const UICategoriesCards: FC<PropsType> = ({
  categories,
  setActive,
  setCategories,
  handler,
  handlerSort,
  activeId,
}) => {
  const handleClick = useCallback(
    (id: number, index: number) => {
      if (handler) {
        handler(id, index)
        setActive(index)
      }
    },
    [handler, setActive],
  )

  const renderCategories = useMemo(
    () =>
      categories?.map((categorie, index) => {
        return (
          <Draggable
            key={categorie.id}
            draggableId={String(categorie.id)}
            index={index}
          >
            {(prov) => (
              <div
                ref={prov.innerRef}
                {...prov.draggableProps}
                {...prov.dragHandleProps}
              >
                <div className={cn(styles.wrapper, 'categoryWrapp')}>
                  <CategoryCard
                    color={categorie.color}
                    index={index}
                    name={categorie.name}
                    onClick={() => handleClick(categorie.id, index)}
                    isActive={activeId === categorie.id}
                  />
                </div>
              </div>
            )}
          </Draggable>
        )
      }),
    [categories, activeId, handleClick],
  )

  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (!result.destination) {
          return
        }

        const items = reorder(
          categories,
          result.source.index,
          result.destination.index,
        )

        setActive(result.destination.index)
        setCategories(items as CategoryType[])

        if (handlerSort) {
          handlerSort((items as CategoryType[]).map((i) => i.id))
        }
      }}
    >
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ minWidth: '100%' }}
          >
            {renderCategories}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default UICategoriesCards
