import { FC, memo } from 'react'

import { Form, Input } from 'antd'
import GoogleMapReact from 'google-map-react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as LocationIcon } from 'assets/images/common/adminLocationIcon.svg'
import { SalonsAddressType } from 'entities/common'
import { GOOGLE_KEY } from 'utils'

import style from './style.module.scss'

type PropsType = { address: SalonsAddressType }

const AdminSalonSectionThree: FC<PropsType> = ({ address }) => {
  const { t } = useTranslation()

  return (
    <section className={style.three}>
      <h2>3. {t('ADMIN.PROFILE_SALON.SECTION_3.TITLE')}</h2>

      <Form layout="vertical" disabled={true} className={style.form}>
        <Form.Item label={t('ADMIN.PROFILE_SALON.SECTION_3.INPUT_LABEL')}>
          <Input value={address?.formatted_address || ''} />
        </Form.Item>
      </Form>

      <div className={style.map}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_KEY as string }}
          zoom={10}
          center={{
            lat: address?.geometry?.location?.lat,
            lng: address?.geometry?.location?.lng,
          }}
          options={{
            panControl: false,
            zoomControl: false,
            fullscreenControl: false,
          }}
          yesIWantToUseGoogleMapApiInternals
        >
          <div
            className={style.iconWrapper} /* eslint-disable */
            // @ts-ignore
            lat={address?.geometry?.location?.lat}
            lng={address?.geometry?.location?.lng}
            /* eslint-disable */
          >
            <LocationIcon className={style.icon} />
          </div>
        </GoogleMapReact>
      </div>
    </section>
  )
}

export default memo(AdminSalonSectionThree)
