import { useMemo, useEffect, useState } from 'react'

import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import NotificationTableRow from 'modules/admin/Notification/TableRow'
import {
  clearNotificationError,
  getAdminNotificationsRequest,
} from 'store/Admin/Notifications/Notifications.action'
import { addAlert } from 'store/Alert'
import { IRootState } from 'store/Root.reducer'
import { UICustomTable, UIDatePicker } from 'ui'
import { getFromQueryString, getQueryString } from 'utils/helpers/query'

import styles from './style.module.scss'

const ViewAdminNotification = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    isLoading,
    data,
    errorMessage,
    meta: { perPage, total },
  } = useSelector((store: IRootState) => store.adminNotifications)
  const [date, setDate] = useState<string>('')

  const handleChange = (_: string, dateString: string) => {
    setDate(dateString)
  }

  const headerNode = useMemo(() => {
    return (
      <div className={styles.calendarWrapp}>
        <UIDatePicker onChange={handleChange} />
      </div>
    )
  }, [])

  useEffect(() => {
    const { page } = getFromQueryString(location.search)

    const query = getQueryString({
      page: page || 1,
      ...(!!date && { 'filter[date]': date }),
    })

    dispatch(getAdminNotificationsRequest(query))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, location.search])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
        }),
      )
    }

    return () => {
      dispatch(clearNotificationError())
    }
  }, [errorMessage, dispatch])

  return (
    <Content className={styles.adminSalonsPage}>
      <h1 className={styles.titleWrapper}>{t('ADMIN.NOTIFICATION')}</h1>

      <UICustomTable
        headerChildren={headerNode}
        isLoading={isLoading}
        className={styles.table}
        perPage={perPage}
        total={total}
      >
        {data?.map((item) => (
          <NotificationTableRow {...item} key={item.id} />
        ))}
      </UICustomTable>
    </Content>
  )
}

export default ViewAdminNotification
