import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  UPLOAD_MEDIA_FAILURE,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_REQUEST,
  CLEAR_MEDIA_STOR,
  UPLOAD_ARRAY_MEDIA_SUCCESS,
  RESET_LINK_TYPE,
  CLEAR_ERRORS,
} from './Media.constant'
import { IMediaState, MediaActionsTypes } from './Media.type'

const initialState: IMediaState = {
  fileData: null,
  fileName: '',
  isloading: false,
  errors: {},
  errorMessage: null,
  images: [],
  linkType: null,
}

export default function mediaReducer(
  state = initialState,
  action: MediaActionsTypes,
): IMediaState {
  switch (action.type) {
    case UPLOAD_MEDIA_REQUEST: {
      const { linkType } = action.payload

      return {
        ...state,
        fileData: null,
        fileName: '',
        isloading: true,
        images: [],
        linkType,
      }
    }
    case UPLOAD_MEDIA_SUCCESS: {
      return {
        ...state,
        fileName: action.payload.name,
        fileData: action.payload.data,
        isloading: false,
      }
    }
    case UPLOAD_ARRAY_MEDIA_SUCCESS:
      return {
        ...state,
        isloading: false,
        images: [...state.images, action.payload],
      }
    case UPLOAD_MEDIA_FAILURE:
      return {
        ...state,
        isloading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case CLEAR_MEDIA_STOR:
      return {
        ...state,
        fileData: null,
        images: [],
      }
    case RESET_LINK_TYPE:
      return {
        ...state,
        linkType: null,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    default:
      return state
  }
}
