/* eslint-disable import/no-cycle */
import { ReactComponent as AppointmentSVG } from 'assets/images/common/newSidebar/appointments.svg'
import { ReactComponent as ClientsSVG } from 'assets/images/common/newSidebar/clients.svg'
import { ReactComponent as DocumentsSVG } from 'assets/images/common/newSidebar/documents.svg'
import { ReactComponent as FeedbacksSVG } from 'assets/images/common/newSidebar/feedbacks.svg'
import { ReactComponent as OrdersSVG } from 'assets/images/common/newSidebar/orders.svg'
import { ReactComponent as ProfileSVG } from 'assets/images/common/newSidebar/profile.svg'
// backlog
// import { ReactComponent as ReportsSVG } from 'assets/images/common/newSidebar/reports.svg'
import { ReactComponent as ServicesSVG } from 'assets/images/common/newSidebar/services.svg'
import { ReactComponent as SettingsSVG } from 'assets/images/common/newSidebar/settings.svg'
import { ReactComponent as StaffSVG } from 'assets/images/common/newSidebar/staff.svg'
import { ReactComponent as SubscriptionSVG } from 'assets/images/common/newSidebar/subscription.svg'
import { ReactComponent as SupportSVG } from 'assets/images/common/newSidebar/support.svg'
import { SidebarType } from 'entities/admin'
import {
  MANAGER_APPOINTMENTS,
  MANAGER_CLIENTS,
  MANAGER_DOCUMENT,
  MANAGER_FEEDBACKS,
  MANAGER_ORDERS,
  MANAGER_PROFILE_SALON,
  MANAGER_SERVICES,
  MANAGER_SETTING,
  MANAGER_STAFF,
  MANAGER_SUBSCRIPTION,
  MANAGER_SUPPORT,
  QUERY_PARAMS,
} from 'utils'
import { getQueryString } from 'utils/helpers/query'
import { Mode } from 'views/manager/Client/index.type'

export enum MenuNotificationName {
  APPOINTMENTS = 'appointments',
  CLIENTS = 'clients',
  DOCUMENT = 'document',
  FEEDBACKS = 'feedbacks',
  NOTIFICATION = 'document',
  ORDERS = 'orders',
  SERVICES = 'services',
  STAFF = 'staff',
  SUBSCRIPTION = 'subscription',
}

export const sidebar: SidebarType[] = [
  {
    label: 'MANAGER.SIDEBAR.SALONS',
    Icon: ProfileSVG,
    link: MANAGER_PROFILE_SALON,
  },
  {
    label: 'MANAGER.SIDEBAR.CALENDAR',
    Icon: AppointmentSVG,
    link: MANAGER_APPOINTMENTS,
    notification: false,
    notificationName: MenuNotificationName.APPOINTMENTS,
  },
  {
    label: 'MANAGER.SIDEBAR.SERVICES',
    Icon: ServicesSVG,
    link: MANAGER_SERVICES,
    notification: false,
    notificationName: MenuNotificationName.SERVICES,
  },
  {
    label: 'MANAGER.SIDEBAR.STAFF',
    link: MANAGER_STAFF,
    Icon: StaffSVG,
    notification: false,
    notificationName: MenuNotificationName.STAFF,
  },
  {
    label: 'MANAGER.SIDEBAR.CLIENTS',
    Icon: ClientsSVG,
    link:
      MANAGER_CLIENTS +
      getQueryString({
        [QUERY_PARAMS.PAGE]: 1,
        [QUERY_PARAMS.TAB]: Mode.CLIENT,
      }),
    notification: false,
    notificationName: MenuNotificationName.CLIENTS,
  },
  {
    label: 'MANAGER.SIDEBAR.FEEDBACKS',
    Icon: FeedbacksSVG,
    link: MANAGER_FEEDBACKS,
    notification: false,
    notificationCount: 0,
    notificationName: MenuNotificationName.FEEDBACKS,
  },
  // backlog
  // {
  //   label: 'MANAGER.SIDEBAR.REPORTS',
  //   Icon: ReportsSVG,
  //   link: MANAGER_REPORTS,
  // },
  {
    label: 'MANAGER.SIDEBAR.ORDERS',
    Icon: OrdersSVG,
    link: MANAGER_ORDERS,
    notification: false,
    notificationName: MenuNotificationName.ORDERS,
  },
  {
    label: 'MANAGER.SIDEBAR.SUBSCRIPTION',
    Icon: SubscriptionSVG,
    link: MANAGER_SUBSCRIPTION,
    notification: false,
    notificationName: MenuNotificationName.SUBSCRIPTION,
  },
  {
    label: 'MANAGER.SIDEBAR.DOCUMENTS',
    Icon: DocumentsSVG,
    link: MANAGER_DOCUMENT,
    notification: false,
    notificationName: MenuNotificationName.DOCUMENT,
  },
  {
    label: 'MANAGER.SIDEBAR.SUPPORT',
    Icon: SupportSVG,
    link: MANAGER_SUPPORT,
  },
  {
    label: 'MANAGER.SIDEBAR.SETTINGS',
    Icon: SettingsSVG,
    link: MANAGER_SETTING,
  },
]
