import { FC } from 'react'

import classNames from 'classnames'
import { DisabledTimes } from 'rc-picker/lib/interface'

import i18n from 'lib/i18n/i18n'
import TimePicker from 'ui/Ant/TimePicker'
import { VALIDATION_CLASS } from 'utils'

import styles from './style.module.scss'
import { StringHelper } from 'utils/helpers'

type PropsType = {
  classNameSelect?: string
  clearIcon?: boolean
  defaultValuePicker?: string | null
  disabled?: boolean
  disabledTime?: (date: Date) => DisabledTimes
  error?: string[] | string
  format?: string
  inputReadOnly?: boolean
  label?: string
  necessaryLabel?: string
  onFocus?: () => void
  onSelect?: ((value: Date) => void) | undefined
  options?: { label: string; value: number }[]
  placeholder?: string
  showNow?: boolean
  status?: '' | 'error' | 'warning'
  value?: Date | null
}

const UITimePicker: FC<PropsType> = ({
  label,
  placeholder = i18n.t('COMMON.SELECT'),
  defaultValuePicker,
  necessaryLabel,
  format = 'HH:mm',
  value,
  inputReadOnly = true,
  status,
  showNow = false,
  clearIcon = false,
  disabled,
  disabledTime,
  error,
  onSelect,
}) => {
  return (
    <div
      className={classNames('wrapperInput', styles.wrapper, {
        [VALIDATION_CLASS]: error,
      })}
    >
      {label && (
        <span className={styles.label}>
          {label}
          {'  '}
          <span className={styles.necessaryLabel}>{necessaryLabel}</span>
        </span>
      )}

      <TimePicker
        format={format}
        placeholder={placeholder}
        minuteStep={5}
        className={styles.timePicker}
        showNow={showNow}
        value={value}
        inputReadOnly={inputReadOnly}
        {...(defaultValuePicker && {
          value: new Date(defaultValuePicker as unknown as Date),
        })}
        status={error ? 'error' : status || undefined}
        clearIcon={clearIcon}
        disabled={disabled}
        disabledTime={disabledTime}
        onSelect={onSelect}
      />

      {error && (
        <p
          className={classNames(
            styles.error,
            'text-error desc-1 wrapperInputError',
          )}
        >
          {StringHelper.getFisrtMessage(error)}
        </p>
      )}

      {error && (
        <div className={'wrapperInputErrorTooltip'}>
          {StringHelper.getFisrtMessage(error)}
        </div>
      )}
    </div>
  )
}

export default UITimePicker
