import i18n from 'lib/i18n/i18n'
import { EventsType } from 'modules/manager/staff/Calendar/index.type'
import { FORMAT_DATE } from 'utils'
import { DateHelper } from 'utils/helpers'

import { ShiftScheduleType, ShiftType } from '.'

export const getStaffCalendarWeek = (
  shift: ShiftType,
  calendarDate: string,
) => {
  const weekDay = [0, 1, 2, 3, 4, 5, 6]
  const weekArray: EventsType[] = []
  const scheduleLength = shift.schedule.length
  const scheduleMap = new Map<number, ShiftScheduleType>(
    shift.schedule.map((schedule) => [
      DateHelper.getUnixDate(schedule.date),
      schedule,
    ]),
  )

  const GREY_COLOR = '#f5f5f5'

  weekDay.forEach((item) => {
    const day = DateHelper.addDay(calendarDate, item)
    const schedule = scheduleMap.get(day.valueOf())

    if (schedule) {
      if (schedule.work.from && schedule.work.to) return

      weekArray.push({
        title: schedule.work.label,
        start: schedule.date,
        end: schedule.date,
        resourceId: shift.id,
        backgroundColor: GREY_COLOR,
        extendedProps: {
          shiftId: shift.id,
          date: schedule.date,
          staffName: shift.name,
        },
      })
    }

    if (
      DateHelper.getUnixDate(shift.schedule[scheduleLength - 1].date) <
        new Date(calendarDate).valueOf() ||
      DateHelper.getUnixDate(shift.schedule[0].date) >
        new Date(calendarDate).valueOf()
    ) {
      weekArray.push({
        title: i18n.t('MANAGER.NO_SHIFT'),
        start: `${DateHelper.toFormat(day, FORMAT_DATE)}T00:00:00`,
        end: `${DateHelper.toFormat(day, FORMAT_DATE)}T24:00:00`,
        resourceId: shift.id,
        extendedProps: {
          shiftId: shift.id,
          date: DateHelper.toFormat(day, FORMAT_DATE),
          staffName: shift.name,
        },
      })
    }
  })

  return weekArray
}
