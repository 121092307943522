export const GET_FEEDBACKS_REQUEST = '@feedbacks/GET_FEEDBACKS_REQUEST'
export const GET_FEEDBACKS_SUCCESS = '@feedbacks/GET_FEEDBACKS_SUCCESS'
export const GET_FEEDBACKS_FAILURE = '@feedbacks/GET_FEEDBACKS_FAILURE'

export const SET_FEEDBACKS_REQUEST = '@feedbacks/SET_FEEDBACKS_REQUEST'
export const SET_FEEDBACKS_SUCCESS = '@feedbacks/SET_FEEDBACKS_SUCCESS'
export const SET_FEEDBACKS_FAILURE = '@feedbacks/SET_FEEDBACKS_FAILURE'

export const EDIT_FEEDBACKS_REQUEST = '@feedbacks/EDIT_FEEDBACKS_REQUEST'
export const EDIT_FEEDBACKS_SUCCESS = '@feedbacks/EDIT_FEEDBACKS_SUCCESS'
export const EDIT_FEEDBACKS_FAILURE = '@feedbacks/EDIT_FEEDBACKS_FAILURE'

export const CLEAR_FEEDBACKS_ERRORS = '@feedbacks/CLEAR_FEEDBACKS_ERRORS'
