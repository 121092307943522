import {
  put,
  takeLatest,
  fork,
  call,
  ForkEffect,
  takeEvery,
} from 'redux-saga/effects'

import { FullMedia } from 'entities/common'
import { ModalType } from 'entities/modal'
import i18n from 'lib/i18n/i18n'
import { DocumentHttp } from 'services/http'
import { showModal } from 'store/Modal'
import { StringHelper } from 'utils/helpers'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  addDocumentFailure,
  addDocumentSuccess,
  deleteDocumentFailure,
  getDocumentFailure,
  getDocumentNotificationSuccess,
  getDocumentRequest,
  getDocumentSuccess,
  uploadDocumentFailure,
  uploadDocumentSuccess,
} from './Documents.action'
import {
  ADD_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_REQUEST,
  GET_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_REQUEST,
} from './Documents.constant'
import {
  IAddDocumentRequest,
  IDeleteDocumentRequest,
  IGetDocumentResponse,
  IUploadDocumentRequest,
} from './Documents.type'

function* workerUploadDocument({ payload }: IUploadDocumentRequest) {
  const { file, fileName, nameUpload } = payload

  try {
    const { data }: { data: FullMedia } = yield call(
      DocumentHttp.uploadDocument,
      fileName,
      nameUpload,
      file,
    )

    yield put(uploadDocumentSuccess({ data }))
  } catch (error) {
    yield getRequestErrors(
      uploadDocumentFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchUploadDocuments() {
  yield takeEvery(UPLOAD_DOCUMENT_REQUEST, workerUploadDocument)
}

function* workerDeleteDocuments({ payload }: IDeleteDocumentRequest) {
  try {
    yield call(DocumentHttp.deleteDocument, payload)
  } catch (error) {
    yield getRequestErrors(
      deleteDocumentFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchDeleteDocuments() {
  yield takeLatest(DELETE_DOCUMENT_REQUEST, workerDeleteDocuments)
}

function* workerAddDocuments({ payload }: IAddDocumentRequest) {
  try {
    yield call(DocumentHttp.addDocument, payload)

    yield put(addDocumentSuccess())
    yield put(getDocumentRequest())
    yield put(
      showModal({
        modalType: ModalType.NOTIFY,
        modalProps: { notifyTitle: i18n.t('MANAGER.DOCUMENT.MODAL.THANKS') },
      }),
    )
  } catch (error) {
    yield getRequestErrors(
      addDocumentFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchAddDocuments() {
  yield takeLatest(ADD_DOCUMENT_REQUEST, workerAddDocuments)
}

function* workerGetDocument() {
  try {
    const { data }: { data: IGetDocumentResponse } = yield call(
      DocumentHttp.getDocument,
    )

    yield put(getDocumentSuccess(data.data))
    yield put(getDocumentNotificationSuccess(data.notification))
  } catch (error) {
    yield getRequestErrors(
      getDocumentFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchGetDocument() {
  yield takeLatest(GET_DOCUMENT_REQUEST, workerGetDocument)
}

export const documentWatchers: ForkEffect[] = [
  fork(watchUploadDocuments),
  fork(watchDeleteDocuments),
  fork(watchAddDocuments),
  fork(watchGetDocument),
]
