import i18n from 'lib/i18n/i18n'
import { addAlert } from 'store/Alert'

export default (() => {
  const getError = () =>
    addAlert({
      message: [i18n.t('ERROR.ALERT_GET_ONE'), i18n.t('ERROR.ALERT_GET_TWO')],
      type: 'error',
      placement: 'right',
    })

  return { getError }
})()
