import { Dispatch, MutableRefObject, ReactNode, SetStateAction } from 'react'

export type PropsType = {
  btnHandler?: () => void
  btnIcon?: ReactNode
  btnLabel?: string
  calendarRef: MutableRefObject<null>
  isShift?: boolean
  mode?: ModeType
  setDate?: (arg: Date | null) => void
  setHeaderDate?: Dispatch<SetStateAction<Date>>
  setIsDrawer?: (arg: boolean) => void
  setMode?: Dispatch<SetStateAction<ModeType>>
}

export enum NavigateType {
  NEXT = 'next',
  PREV = 'prev',
  TODAY = 'today',
}

export enum ModeType {
  DAY = 'timeGridDay',
  MONTH = 'dayGridMonth',
  WEEK = 'timeGridWeek',
}

export enum ModeShiftType {
  DAY = 'resourceTimelineDay',
  WEEK = 'customWeek',
}

export type DateType = {
  end: Date
  start: Date
}
