import { FC } from 'react'

import { Checkbox } from 'antd'
import cn from 'classnames'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const UICheckbox: FC<PropsType> = ({
  checked,
  children,
  onChange,
  className,
  disabled,
}) => {
  return (
    <Checkbox
      className={cn(styles.checkbox, className)}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    >
      {children}
    </Checkbox>
  )
}

export default UICheckbox
