/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ConfigProvider } from 'antd'
import AlertsList from 'widgets/AlertsList'
import WidgetModal from 'widgets/Modal'
import Routes from 'widgets/Routes'
import ValidationListener from 'widgets/Validation'
import es_ES from 'antd/lib/locale/es_ES'

// @ts-ignore
es_ES.DatePicker.lang.locale = 'es'

const App = () => {
  return (
    <div className="App">
      <ConfigProvider locale={es_ES}>
        <Routes />
        <AlertsList />
        <WidgetModal />
        <ValidationListener />
      </ConfigProvider>
    </div>
  )
}

export default App
