import { FC } from 'react'

import { Spin } from 'antd'

import { ReactComponent as DeleteSVG } from 'assets/images/common/redesign/delete.svg'
import i18n from 'lib/i18n/i18n'
import { UIButton } from 'ui'
import UIBack from 'ui/Back'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const LayoutManagerCenter: FC<PropsType> = ({
  isHeader,
  children,
  isLoading = false,
  handleBack,
  title,
  handleDelete,
  isDelete,
  handleSave,
  isBtnDisable,
  btnLabel = i18n.t('MANAGER.BUTTON.SAVE'),
}) => {
  return (
    <div className={styles.wrapper}>
      <Spin spinning={isLoading}>
        {isHeader && (
          <div className={styles.header}>
            <UIBack onClick={handleBack} />
            <div className={styles.title}>{title}</div>
            <div>
              {isDelete && (
                <UIButton
                  handler={handleDelete}
                  label={i18n.t('MANAGER.BUTTON.DELETE')}
                  icon={<DeleteSVG />}
                  type="text"
                  isRed
                />
              )}
            </div>
          </div>
        )}

        {children}
        <div className={styles.btn}>
          <UIButton
            disabled={isBtnDisable}
            handler={handleSave}
            label={btnLabel}
          />
        </div>
      </Spin>
    </div>
  )
}

export default LayoutManagerCenter
