import { useEffect, useState, useMemo, SyntheticEvent } from 'react'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as SearchSVG } from 'assets/images/common/redesign/search.svg'
import useDebounce from 'hooks/useDebounce'
import LayoutManagerSidebar from 'layouts/Manager/Sidebar'
import OrderdsHistoryCards from 'modules/manager/orders/HistoryCards'
import OrdersCards from 'modules/manager/orders/OrdersCards'
import { addAlert } from 'store/Alert'
import {
  clearOrderError,
  getOrderHistoryRequest,
  getCancelledOrderHistoryRequest,
  setMobileTitleOpen,
} from 'store/Manager/Orders/Orders.action'
import { IRootState } from 'store/Root.reducer'
import { UICustomPagination } from 'ui'
import UIBack from 'ui/Back'
import UITextInput from 'ui/TextInput'
import { MANAGER_ORDERS, QUERY_PARAMS } from 'utils'
import { getFromQueryString, getQueryString } from 'utils/helpers/query'

import { CategoriesOrder, categoriesOrder } from './constant'
import styles from './style.module.scss'
import useWindowSize from 'hooks/useWindowSize'

const ViewManagerOrders = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { width } = useWindowSize()

  const { data, errorMessage, isLoading, meta } = useSelector(
    (store: IRootState) => store.orders,
  )
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [activeId, setActiveId] = useState('active')
  const [search, setSearch] = useState('')
  const [isTabChange, setIsTabChange] = useState(false)
  const debouncedValue = useDebounce(search, 500)

  const handleSearch = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement

    setSearch(value)

    if (value.length === 0 || value.length === 1) {
      navigate(
        `${MANAGER_ORDERS}${getQueryString({
          ...getFromQueryString(location.search),
          page: 1,
        })}`,
      )
    }
  }

  const handleMobileOpen = () => {
    setIsMobileOpen(false)
    dispatch(setMobileTitleOpen(false))
  }

  const handleChangeStatus = (status: CategoriesOrder) => {
    setIsTabChange(true)
    setActiveId(status as string)

    const { [QUERY_PARAMS.ORDER_HISTORY_STATUS]: orderStatus, ...queries } =
      getFromQueryString(location.search)

    navigate(
      `${location.pathname}${getQueryString({
        ...queries,
        [QUERY_PARAMS.ORDER_HISTORY_STATUS]: status,
      })}`,
    )
  }

  const mobileTitle = useMemo(
    () => categoriesOrder?.find((item) => item.id === activeId)?.name,
    [activeId],
  )

  useEffect(() => {
    const {
      [QUERY_PARAMS.ORDER_HISTORY_STATUS]: status,
      [QUERY_PARAMS.PAGE]: page,
    } = getFromQueryString(location.search)

    const objToRequest = {
      [QUERY_PARAMS.ORDER_HISTORY_STATUS]: status || activeId,
      [QUERY_PARAMS.SEARCH]: debouncedValue,
    }

    let isSearch = false

    const renderObjToRequest = () => {
      if (search) {
        if (+page > 1) {
          isSearch = true
        }

        return {
          ...objToRequest,
          ...(isSearch && { [QUERY_PARAMS.PAGE]: page || 1 }),
        }
      }

      isSearch = false

      return {
        ...objToRequest,
        [QUERY_PARAMS.PAGE]: page || 1,
      }
    }

    if (isTabChange) {
      if (
        status === CategoriesOrder.CANCELLED ||
        activeId === CategoriesOrder.CANCELLED
      ) {
        dispatch(
          getCancelledOrderHistoryRequest(
            getQueryString({ [QUERY_PARAMS.SEARCH]: debouncedValue }),
          ),
        )
      } else {
        dispatch(getOrderHistoryRequest(getQueryString(renderObjToRequest())))
      }
    }

    if (status) {
      setActiveId(status)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.search, debouncedValue, isTabChange])

  useEffect(() => {
    if (width > 1024) {
      setIsTabChange(true)
    }
  }, [width])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
        }),
      )
    }

    return () => {
      dispatch(clearOrderError())
    }
  }, [dispatch, errorMessage])

  return (
    <LayoutManagerSidebar
      titleHeader={t('MANAGER.ORDERS.HISTORY')}
      mobileActive={isMobileOpen}
      headerContentCenter={
        <div className={styles.searchWrapp}>
          <UITextInput
            isSearch
            value={search}
            onChange={handleSearch}
            icon={<SearchSVG />}
            placeholder={t('MANAGER.ORDERS.SEARCH')}
          />
        </div>
      }
      contentCenterClassName={styles.search}
      headerClassName={styles.orderHeader}
      sidebarContent={
        <Spin spinning={false}>
          <OrdersCards
            categories={categoriesOrder}
            handler={handleChangeStatus}
            activeId={activeId}
            setIsMobileOpen={setIsMobileOpen}
          />
        </Spin>
      }
    >
      {isMobileOpen && (
        <UIBack
          type="title"
          title={mobileTitle}
          setIsMobileOpen={handleMobileOpen}
        />
      )}

      <div className={styles.servicesCards}>
        <div className={styles.mobileSearchWrapp}>
          <UITextInput
            isSearch
            icon={<SearchSVG />}
            placeholder={t('MANAGER.ORDERS.SEARCH')}
          />
        </div>

        <Spin spinning={isLoading}>
          {data.map((item) => (
            <OrderdsHistoryCards key={item.id} {...item} />
          ))}
        </Spin>

        {!!meta.total && (
          <UICustomPagination perPage={meta.perPage} total={meta.total} />
        )}
      </div>
    </LayoutManagerSidebar>
  )
}

export default ViewManagerOrders
