import { ResponseFailureType } from 'store/Auth/Auth.type'
import {
  CLEAR_MANAGER_NOTIFICATION,
  CLEAR_MANAGER_NOTIFICATION_ERROR,
  GET_MANAGER_NOTIFICATIONS_FAILURE,
  GET_MANAGER_NOTIFICATIONS_REQUEST,
  GET_MANAGER_NOTIFICATIONS_SUCCESS,
  SET_MANAGER_NOTIFICATIONS,
} from './Notifications.constant'
import { IManagerMenuType } from './Notifications.type'

export const getManagerNotificationsRequest = () => ({
  type: GET_MANAGER_NOTIFICATIONS_REQUEST,
})

export const getManagerNotificationsSuccess = (payload: IManagerMenuType) => ({
  type: GET_MANAGER_NOTIFICATIONS_SUCCESS,
  payload,
})

export const getManagerNotificationsFailure = (
  payload: ResponseFailureType,
) => ({
  type: GET_MANAGER_NOTIFICATIONS_FAILURE,
  payload,
})

export const setManagerNotifications = (payload: keyof IManagerMenuType) => ({
  type: SET_MANAGER_NOTIFICATIONS,
  payload,
})

export const clearManagerNotification = () => ({
  type: CLEAR_MANAGER_NOTIFICATION,
})

export const clearManagerNotificationError = () => ({
  type: CLEAR_MANAGER_NOTIFICATION_ERROR,
})
