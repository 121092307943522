import { FC } from 'react'

import classNames from 'classnames'
import { ColorResult, SketchPicker } from 'react-color'

import { VALIDATION_CLASS } from 'utils'

import styles from './style.module.scss'
import { StringHelper } from 'utils/helpers'

type PropsType = {
  color: string
  error?: string[] | string
  onChange: (color: ColorResult) => void
}

const UISketchPicker: FC<PropsType> = ({ color, onChange, error }) => {
  return (
    <>
      <SketchPicker
        color={color}
        onChange={onChange}
        className={classNames({
          [VALIDATION_CLASS]: error,
          [styles.error]: error,
        })}
      />
      {error && (
        <div className={styles.textError}>
          {StringHelper.getFisrtMessage(error)}
        </div>
      )}
    </>
  )
}

export default UISketchPicker
