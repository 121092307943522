import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  memo,
} from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ServiceType } from 'entities/manager/service'
import { ModalType } from 'entities/modal'
import useResponsive from 'hooks/useResponsive'
import LayoutManagerCenter from 'layouts/Manager/Center'
import { addAlert } from 'store/Alert'
import {
  deleteServiceRequest,
  getStaffRequest,
  getSubCategoryRequest,
  setImageList,
  updateServiceRequest,
  createServiceRequest,
  resetStore,
  getCategoriesRequest,
  getOneServiceRequest,
  setMobileTitleOpen,
  resetError,
} from 'store/Manager/Services'
import { clearErrors, clearMediaStore } from 'store/Media/Media.action'
import { hideModal, showModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { UICustomCard, UITextArea, UIUploadMove } from 'ui'
import { GeneralValueType } from 'ui/UploadMove/index.type'
import {
  FORMAT_TIME_HOUR_WITH_MIN,
  MANAGER_SERVICES,
  STANDART_DATE,
} from 'utils'
import { DateHelper } from 'utils/helpers'

import { ValuesType } from './index.type'
import styles from './style.module.scss'
import ServicesInfo from '../Info'
import ServicesStaff from '../Staff'

type PropsType = {
  existNumber?: null | number
  isAdd?: boolean
  isEdit?: boolean
  services?: ServiceType[]
}

const ServicesEditModal: FC<PropsType> = ({
  existNumber,
  services,
  isAdd,
  isEdit,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    service,
    staff,
    isServiceAction,
    isLoading,
    fullCategories,
    subcategories,
    errorMessage,
    isMediaLoading,
    madiaErrorMessage,
    images,
    errors,
  } = useSelector((state: IRootState) => ({
    ...state.service,
    isMediaLoading: state.media.isloading,
    images: state.media.images,
    madiaErrorMessage: state.media.errorMessage,
  }))

  const { id: serviceId } = useParams()
  const [isChecked, setIsChecked] = useState(false)
  const { description, price, duration, name } =
    services?.[existNumber as number] || {}
  const [values, setValue] = useState<Partial<ValuesType>>({
    description,
    price: price?.price || '0',
    duration,
    name,
    category: null,
    sections: [],
    staff: [],
    id: null,
  })

  const { isMobile, isTablet } = useResponsive()

  useEffect(() => {
    dispatch(getCategoriesRequest('front'))
  }, [dispatch])

  useEffect(() => {
    dispatch(getStaffRequest())
  }, [dispatch])

  const handleSave = () => {
    const replacePrice = (inputName: 'price' | 'discount_price') => {
      return /^\d+[.,]/.test(values[inputName] || '')
        ? values[inputName]
        : values[inputName] || ''.replace(/^0*/, '')
    }

    const newPrice = replacePrice('price')
    const newDiscount = replacePrice('discount_price')

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { discount_price, ...stateValues } = values

    const newValue = {
      ...stateValues,
      price: newPrice,
      ...(newDiscount && { discount_price: newDiscount || '0' }),
    }

    if (isAdd) {
      dispatch(createServiceRequest(newValue))
    } else {
      dispatch(updateServiceRequest(newValue))
    }
  }

  const handleBackClick = useCallback(() => {
    window.history.back()

    if (isMobile || isTablet) {
      dispatch(setMobileTitleOpen(true))
    }
  }, [dispatch, isMobile, isTablet])

  const handleDelete = () => {
    dispatch(
      showModal({
        modalType: ModalType.MANAGER_DELETE_SERVICE,
        modalProps: {
          handler: () => {
            dispatch(deleteServiceRequest(service?.id))
          },
        },
      }),
    )
  }

  useEffect(() => {
    if (service && !isAdd) {
      setValue({
        description: service.description,
        price: service.price?.old_price || service.price?.price,
        discount_price: service.price?.old_price ? service.price?.price : '',
        duration: service.duration
          ? DateHelper.toFormat(
              new Date(`${STANDART_DATE} ${service.duration}`),
              FORMAT_TIME_HOUR_WITH_MIN,
            )
          : null,
        name: service.name || '',
        category: service?.category,
        sections: service?.sections || [],
        id: service.id,
        staff: service?.staff?.map((item) => item.id) || [],
        images: service?.images?.map((i) => i.id),
      })
    }
  }, [isAdd, service])

  useEffect(() => {
    if (values.category?.id && values.category?.id === service?.category?.id) {
      dispatch(getSubCategoryRequest(values.category?.id))
    }
  }, [dispatch, values.category?.id, service?.category?.id])

  useEffect(() => {
    if (isAdd) {
      setValue({
        description,
        price: price?.price,
        duration,
        name: '',
        category: null,
        sections: [],
        id: 1,
        staff: [],
        images: [],
      })
    }
  }, [description, duration, isAdd, price])

  useEffect(() => {
    if (isServiceAction) {
      dispatch(resetStore())
      navigate(MANAGER_SERVICES)
      dispatch(hideModal())
    }
  }, [dispatch, isServiceAction, navigate])

  useLayoutEffect(() => {
    dispatch(clearMediaStore())
  }, [dispatch])

  useEffect(() => {
    if (!service && isEdit && serviceId) {
      dispatch(getOneServiceRequest(+serviceId))
    }
  }, [dispatch, isEdit, service, serviceId])

  useEffect(() => {
    if (errorMessage || madiaErrorMessage) {
      dispatch(
        addAlert({
          message: errorMessage || madiaErrorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(resetError())
      dispatch(clearErrors())
    }
  }, [errorMessage, dispatch, madiaErrorMessage])

  return (
    <LayoutManagerCenter
      isLoading={isLoading}
      title={
        isEdit
          ? t('MANAGER.SERVICES.TITLE_EDIT')
          : t('MANAGER.SERVICES.TITLE_ADD')
      }
      handleBack={handleBackClick}
      isDelete={isServiceAction || !isAdd}
      handleSave={handleSave}
      handleDelete={handleDelete}
      isHeader
    >
      <ServicesInfo
        fullCategories={fullCategories}
        subcategories={subcategories}
        values={values}
        setValue={setValue}
        service={service}
        staff={staff}
        errors={errors}
      />

      <ServicesStaff
        staff={staff}
        values={values}
        setValue={setValue}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        service={service}
      />

      <UICustomCard
        title={
          <>
            <span>3. </span> {t('MANAGER.SERVICES.PHOTO')}
          </>
        }
      >
        <UIUploadMove
          startImage={service?.images}
          images={images}
          setValues={
            setValue as (value: React.SetStateAction<GeneralValueType>) => void
          }
          values={values as GeneralValueType}
          isLoading={isMediaLoading}
          setImageList={setImageList}
          className={styles.uploadMoveWrapp}
          heightRatio={1}
          widthRatio={1.65}
        />
      </UICustomCard>

      <UICustomCard
        title={
          <>
            <span>4. </span> {t('MANAGER.SERVICES.DESCRIPTION')}
          </>
        }
      >
        <UITextArea
          className={styles.aboutText}
          value={values.description}
          onChange={(e) => setValue({ ...values, description: e.target.value })}
          placeholder={t('MANAGER.SERVICES.PLACEHOLDER')}
        />
      </UICustomCard>
    </LayoutManagerCenter>
  )
}

export default memo(ServicesEditModal)
