import { FC, memo, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAdminCurrentSalonToggleSubscriptionRequest } from 'store/Admin/Salons/Salons.action'
import { UIButton, UIDatePicker, UISwitch } from 'ui'
import { FORMAT_DATE } from 'utils'
import { DateHelper } from 'utils/helpers'

import style from './style.module.scss'

type PropTypes = {
  date: string | null
  errors: {
    [key: string]: string[]
  }
  id: number
  isLoading: boolean
  value: number
}

const AdminSalonSectionEight: FC<PropTypes> = ({
  value,
  id,
  isLoading,
  errors,
  date,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selectDate, setSelectDate] = useState<string | null>(null)
  const [switchValue, setSwitchValue] = useState<number | boolean>(value)

  const handleDateSave = () => {
    if (selectDate) {
      dispatch(
        setAdminCurrentSalonToggleSubscriptionRequest(
          id,
          switchValue ? 1 : 0,
          DateHelper.toFormat(selectDate, FORMAT_DATE),
        ),
      )
    }
  }

  const disabledDate = (val: Date) => {
    return val && val.valueOf() <= new Date().valueOf()
  }

  useEffect(() => {
    if (value) {
      setSwitchValue(value)
    }
  }, [value])

  useEffect(() => {
    if (date) {
      setSelectDate(new Date(date) as unknown as string)
    }
  }, [date])

  return (
    <section className={style.eight}>
      <h2>8. {t('ADMIN.PROFILE_SALON.SECTION_8.TITLE')}</h2>

      <div className={style.wrapper}>
        <UISwitch
          checked={!!switchValue}
          onChange={setSwitchValue}
          loading={isLoading}
          disabled={!!date || !!selectDate}
        />

        <UIDatePicker
          disabledDate={disabledDate}
          onChange={(arg) => setSelectDate(arg as string)}
          error={errors?.date?.[0]}
          disable={!switchValue || !!date}
          value={selectDate as unknown as Date}
        />
      </div>

      <UIButton
        className={style.btn}
        handler={handleDateSave}
        disabled={!selectDate || !!date}
        label={t('ADMIN.BUTTON.SAVE')}
      />
    </section>
  )
}

export default memo(AdminSalonSectionEight)
