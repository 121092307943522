import { ADMIN_LINK, CLIENT_LINK, MANAGER_LINK } from 'utils'

import { RegisterRequestType } from '../unauthorized/Auth/index'

export const redirectFromType = (type: RegisterRequestType) => {
  switch (type) {
    case 'admin':
      window.location.replace(ADMIN_LINK)

      return
    case 'salon':
      window.location.replace(MANAGER_LINK)

      return
    case 'customer':
      window.location.replace(CLIENT_LINK)

      return
    default:
      window.location.replace('/')
  }
}
