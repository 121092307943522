import { ResponseFailureType } from 'store/Auth/Auth.type'
import {
  CLEAR_SEO_ERRORS,
  CLEAR_SEO_STORE,
  GET_ADMIN_SEO_CATEGORY_FAILURE,
  GET_ADMIN_SEO_CATEGORY_REQUEST,
  GET_ADMIN_SEO_CATEGORY_SUCCESS,
  GET_ADMIN_SEO_SETTINGS_FAILURE,
  GET_ADMIN_SEO_SETTINGS_REQUEST,
  GET_ADMIN_SEO_SETTINGS_SUCCESS,
  GET_ADMIN_SEO_SUBCATEGORY_FAILURE,
  GET_ADMIN_SEO_SUBCATEGORY_REQUEST,
  GET_ADMIN_SEO_SUBCATEGORY_SUCCESS,
  UPDATE_ADMIN_SEO_SETTINGS_FAILURE,
  UPDATE_ADMIN_SEO_SETTINGS_REQUEST,
  UPDATE_ADMIN_SEO_SETTINGS_SUCCESS,
} from './Seo.constant'
import { SeoCategoryType, SeoSettingType, SeoSubCategoryType } from './Seo.type'

export const getAdminCategoryRequest = (id: number) => ({
  payload: id,
  type: GET_ADMIN_SEO_CATEGORY_REQUEST,
})

export const getAdminCategorySuccess = (payload: SeoCategoryType) => ({
  payload,
  type: GET_ADMIN_SEO_CATEGORY_SUCCESS,
})

export const getAdminCategoryFailure = (payload: ResponseFailureType) => ({
  payload,
  type: GET_ADMIN_SEO_CATEGORY_FAILURE,
})

export const getAdminSubcategoryRequest = (id: number) => ({
  payload: id,
  type: GET_ADMIN_SEO_SUBCATEGORY_REQUEST,
})

export const getAdminSubCategorySuccess = (payload: SeoSubCategoryType) => ({
  payload,
  type: GET_ADMIN_SEO_SUBCATEGORY_SUCCESS,
})

export const getAdminSubCategoryFailure = (payload: ResponseFailureType) => ({
  payload,
  type: GET_ADMIN_SEO_SUBCATEGORY_FAILURE,
})

export const getAdminSettingsRequest = () => ({
  type: GET_ADMIN_SEO_SETTINGS_REQUEST,
})

export const getAdminSettingsSuccess = (payload: SeoSettingType) => ({
  payload,
  type: GET_ADMIN_SEO_SETTINGS_SUCCESS,
})

export const getAdminSettingsFailure = (payload: ResponseFailureType) => ({
  payload,
  type: GET_ADMIN_SEO_SETTINGS_FAILURE,
})

export const updateAdminSettingsRequest = (payload: SeoSettingType) => ({
  payload,
  type: UPDATE_ADMIN_SEO_SETTINGS_REQUEST,
})

export const updateAdminSettingsSuccess = () => ({
  type: UPDATE_ADMIN_SEO_SETTINGS_SUCCESS,
})

export const updateAdminSettingsFailure = (payload: ResponseFailureType) => ({
  payload,
  type: UPDATE_ADMIN_SEO_SETTINGS_FAILURE,
})

export const clearSeoError = () => ({
  type: CLEAR_SEO_ERRORS,
})

export const clearSeoStore = () => ({
  type: CLEAR_SEO_STORE,
})
