import { FC, memo, useMemo } from 'react'

import classNames from 'classnames'

import { PropsType } from './index.type'
import style from './style.module.scss'
import { Editor, EditorState } from 'react-draft-wysiwyg'
import { StringHelper } from 'utils/helpers'

const FaqTextBlock: FC<PropsType> = ({
  questionTitle,
  answerTitle,
  className,
  setState,
  state,
  questionError,
  answerError,
  disabled,
}) => {
  const onEditorStateChange = (data: EditorState, fieldName: string) => {
    setState((prevState) => ({ ...prevState, [fieldName]: data }))
  }

  const blocks = useMemo(
    () => [
      { title: questionTitle, name: 'question', error: questionError },
      { title: answerTitle, name: 'answer', error: answerError },
    ],
    [answerError, answerTitle, questionError, questionTitle],
  )

  return (
    <div className={classNames(style.textBlock, className)}>
      {blocks.map(({ error, name, title }, index) => (
        <div className={classNames({ [style.disable]: disabled })} key={index}>
          <h2>{title}</h2>

          <Editor
            editorState={
              state[name as keyof typeof state] as unknown as EditorState
            }
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(data) => onEditorStateChange(data, name)}
          />

          {error && (
            <p className="text-error desc-1">
              {StringHelper.getFisrtMessage(error)}
            </p>
          )}
        </div>
      ))}
    </div>
  )
}

export default memo(FaqTextBlock)
