import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import i18n from 'lib/i18n/i18n'
import { ServicesHttp, StaffHttp } from 'services/http'
import { addAlert } from 'store/Alert'
import { AlertHelper } from 'utils/helpers'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  getStaffFailure,
  getStaffSuccess,
  getOneStaffSuccess,
  getOneStaffFailure,
  getStaffTimeSuccess,
  getStaffTimeFailure,
  deleteStaffSuccess,
  updateStaffSuccess,
  updateStaffFailure,
  addStaffSuccess,
  addStaffFailure,
  getServicesSuccess,
  getServicesFailure,
  deleteStaffFailure,
  updateStaffTimeSuccess,
  updateStaffTimeFailure,
  setStaffStep,
  getStaffSubCategorySuccess,
  getStaffSubCategoryFailure,
  getStaffServicesBySubCategorySuccess,
  getStaffServicesBySubCategoryFailure,
  getOneStaffTimeSuccess,
  clearStaffs,
} from './Staff.action'
import {
  ADD_STAFF_REQUEST,
  DELETE_STAFF_REQUEST,
  GET_ONE_STAFF_REQUEST,
  GET_SERVICES_REQUEST,
  GET_STAFF_REQUEST,
  GET_STAFF_SUBCATEGORY_REQUEST,
  GET_STAFF_TIME_REQUEST,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_TIME_REQUEST,
  GET_STAFF_SERVICES_BY_SUBCATEGORY_REQUEST,
} from './Staff.constant'
import {
  IDeleteStaffRequest,
  IGetOneStaffRequest,
  IGetOneStaffResponse,
  IGetStaffResponse,
  IGetStaffTimeRequest,
  IGetStaffTimeResponse,
  IUpdateStaffRequest,
  IAddStaffRequest,
  IGetServicesResponse,
  IUpdateStaffTimeRequest,
  IGetStaffSubCategoryRequest,
  IGetStaffSubCategoryResponse,
  IGetStaffServicesBySubCategoryRequest,
  IGetStaffServicesBySubCategoryResponse,
  IGetStaffRequest,
} from './Staff.type'

function* workerGetStaff({ payload }: IGetStaffRequest) {
  try {
    const { data }: { data: IGetStaffResponse } = yield call(
      StaffHttp.getStaff,
      payload,
    )

    yield put(getStaffSuccess(data.data))
  } catch (error) {
    yield put(getStaffFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetStaff() {
  yield takeLatest(GET_STAFF_REQUEST, workerGetStaff)
}

function* workerGetOneStaff({ payload }: IGetOneStaffRequest) {
  try {
    const { data }: { data: IGetOneStaffResponse } = yield call(
      StaffHttp.getOneStaff,
      payload,
    )

    yield put(getOneStaffSuccess(data.data))
    yield put(getOneStaffTimeSuccess(data.schedule))
  } catch (error) {
    yield put(getOneStaffFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetOneStaff() {
  yield takeLatest(GET_ONE_STAFF_REQUEST, workerGetOneStaff)
}

function* workerGetStaffTime({ payload }: IGetStaffTimeRequest) {
  try {
    const { data }: { data: IGetStaffTimeResponse } = yield call(
      StaffHttp.getStaffTime,
      payload,
    )

    yield put(getStaffTimeSuccess(data.data))
  } catch (error) {
    yield put(getStaffTimeFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetStaffTime() {
  yield takeLatest(GET_STAFF_TIME_REQUEST, workerGetStaffTime)
}

function* workerDeleteStaff({ payload }: IDeleteStaffRequest) {
  try {
    yield call(StaffHttp.deleteStaff, payload)

    yield put(deleteStaffSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_DELETE'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(deleteStaffFailure, error as string)
  }
}

function* watchDeleteStaff() {
  yield takeLatest(DELETE_STAFF_REQUEST, workerDeleteStaff)
}

function* workerUpdateStaff({ payload }: IUpdateStaffRequest) {
  try {
    const { id, body, type, isServicesList, servicesId } = payload

    yield call(StaffHttp.updateStaff, body, id)

    yield put(updateStaffSuccess(type, Number(servicesId)))

    if (isServicesList) yield put(clearStaffs())

    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(updateStaffFailure, error as string)
  }
}

function* watchUpdateStaff() {
  yield takeLatest(UPDATE_STAFF_REQUEST, workerUpdateStaff)
}

function* workerAddStaff({ payload }: IAddStaffRequest) {
  try {
    yield call(StaffHttp.addStaff, payload)

    yield put(addStaffSuccess())
    yield put(setStaffStep(2))
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_SAVED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(addStaffFailure, error as string)
  }
}

function* watchAddStaff() {
  yield takeLatest(ADD_STAFF_REQUEST, workerAddStaff)
}

function* workerGetServices() {
  try {
    const { data }: { data: IGetServicesResponse } = yield call(
      ServicesHttp.getAllServices,
    )

    yield put(getServicesSuccess(data.data))
  } catch (error) {
    yield put(getServicesFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetServices() {
  yield takeLatest(GET_SERVICES_REQUEST, workerGetServices)
}

function* workerUpdateStaffTime({ payload }: IUpdateStaffTimeRequest) {
  try {
    const { id, body } = payload

    yield call(StaffHttp.updateStaffTime, body, id)

    yield put(updateStaffTimeSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(updateStaffTimeFailure, error as string)
  }
}

function* watchUpdateStaffTime() {
  yield takeLatest(UPDATE_STAFF_TIME_REQUEST, workerUpdateStaffTime)
}

function* workerGetStaffSubCategory({ payload }: IGetStaffSubCategoryRequest) {
  try {
    const { data }: { data: IGetStaffSubCategoryResponse } = yield call(
      StaffHttp.getStaffSubCategory,
      payload,
    )

    yield put(getStaffSubCategorySuccess(data.data))
  } catch (error) {
    yield put(getStaffSubCategoryFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetStaffSubCategory() {
  yield takeLatest(GET_STAFF_SUBCATEGORY_REQUEST, workerGetStaffSubCategory)
}

function* workerGetStaffServicesBySubCategory({
  payload,
}: IGetStaffServicesBySubCategoryRequest) {
  try {
    const { staffId, sectionId } = payload

    const { data }: { data: IGetStaffServicesBySubCategoryResponse } =
      yield call(StaffHttp.getStaffServiceBySubCategory, staffId, sectionId)

    yield put(getStaffServicesBySubCategorySuccess(data.data))
  } catch (error) {
    yield put(getStaffServicesBySubCategoryFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetStaffServicesBySubCategory() {
  yield takeLatest(
    GET_STAFF_SERVICES_BY_SUBCATEGORY_REQUEST,
    workerGetStaffServicesBySubCategory,
  )
}

export const staffWatchers: ForkEffect[] = [
  fork(watchGetStaff),
  fork(watchGetOneStaff),
  fork(watchGetStaffTime),
  fork(watchDeleteStaff),
  fork(watchUpdateStaff),
  fork(watchAddStaff),
  fork(watchGetServices),
  fork(watchUpdateStaffTime),
  fork(watchGetStaffSubCategory),
  fork(watchGetStaffServicesBySubCategory),
]
