import { memo, useCallback, useEffect, useState } from 'react'

import { UploadFile } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as OKSVG } from 'assets/images/common/redesign/okCircle.svg'
import {
  FileName,
  FullMedia,
  UploadMedia,
  DocumentStatus,
} from 'entities/common'
import { ModalType } from 'entities/modal'
import DocumentCard from 'modules/manager/document/Card'
import { addAlert } from 'store/Alert'
import {
  addDocumentRequest,
  clearDocumentErrors,
  clearDocumentStore,
  getDocumentRequest,
  setLoading,
  uploadDocumentRequest,
} from 'store/Manager/Documents/Documents.action'
import { showModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UICustomCard } from 'ui'
import UIDragUpload from 'ui/DragUpload'

import styles from './style.module.scss'
import DocumentNotification from '../../../modules/manager/document/Notification'

const ViewManagerDocument = () => {
  const { documents, profile, errorMessage, isLoading, notification } =
    useSelector((state: IRootState) => state.document)

  const [fileList, setFileList] = useState<FullMedia[]>([])
  const [countFileList, setCountFileList] = useState<number>(0)

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const handleSave = () => {
    dispatch(addDocumentRequest({ ids: fileList.map((item) => item.id) }))
    dispatch(clearDocumentStore())
  }

  const handleRequest = (file: UploadFile) => {
    dispatch(
      uploadDocumentRequest({
        file: file as unknown as UploadMedia,
        nameUpload: file.name,
        fileName: FileName.IMAGE,
      }),
    )
  }

  const handleChange = (count: number) => {
    setCountFileList(count)
  }

  const renderStatusTitle = useCallback(() => {
    switch (profile?.is_confirmed) {
      case DocumentStatus.PENDING:
        return t('MANAGER.DOCUMENT.INFO_7')
      case DocumentStatus.ACCEPT:
        return t('MANAGER.DOCUMENT.INFO_8')
      case DocumentStatus.REJECTED:
        return t('MANAGER.DOCUMENT.MODAL.FAILED')

      default:
        return ''
    }
  }, [profile?.is_confirmed, t])

  useEffect(() => {
    if (documents) {
      setFileList(documents)
    }
  }, [documents])

  useEffect(() => {
    return () => {
      dispatch(clearDocumentStore())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getDocumentRequest())
  }, [dispatch])

  useEffect(() => {
    if (profile?.is_confirmed !== DocumentStatus.EMPTY && notification) {
      const renderText = () => {
        switch (profile?.is_confirmed) {
          case DocumentStatus.ACCEPT:
            return t('MANAGER.DOCUMENT.MODAL.SUCCESS')
          case DocumentStatus.REJECTED:
            return t('MANAGER.DOCUMENT.MODAL.FAILED')
          case DocumentStatus.PENDING:
            return t('MANAGER.DOCUMENT.INFO_7')

          default:
            return ''
        }
      }

      dispatch(
        showModal({
          modalType: ModalType.NOTIFY,
          modalProps: {
            notifyTitle: renderText(),
          },
        }),
      )
    }
  }, [dispatch, notification, profile?.is_confirmed, t])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(clearDocumentErrors())
    }
  }, [errorMessage, dispatch])

  useEffect(() => {
    if (countFileList === documents.length) {
      dispatch(setLoading(false))
    }
  }, [countFileList, dispatch, documents.length])

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapp}>
        <h1>
          {t('MANAGER.DOCUMENT.CONFIRMATION')}
          {profile?.is_confirmed === DocumentStatus.ACCEPT && <OKSVG />}
        </h1>

        <div className={styles.info}>
          <p>{t('MANAGER.DOCUMENT.INFO_1')}</p>
          <p>- {t('MANAGER.DOCUMENT.INFO_2')}</p>
          <p>- {t('MANAGER.DOCUMENT.INFO_3')}</p>
          <p>- {t('MANAGER.DOCUMENT.INFO_4')}</p>
          <p>- {t('MANAGER.DOCUMENT.INFO_5')}</p>
          <p>- {t('MANAGER.DOCUMENT.INFO_6')}</p>
        </div>

        {profile?.is_confirmed !== DocumentStatus.EMPTY && (
          <DocumentNotification text={renderStatusTitle()} />
        )}

        <div
          className={classNames(styles['documents-list'], {
            'd-none': !profile?.documents.length,
          })}
        >
          {profile?.documents?.map((item, index) => (
            <DocumentCard
              key={index}
              isDownload={!!profile?.documents.length}
              name={item.name}
              path={item.path}
            />
          ))}
        </div>
      </div>

      {profile?.is_confirmed !== DocumentStatus.ACCEPT && (
        <>
          <UICustomCard title={<>{t('MANAGER.DOCUMENT.ADD')}</>}>
            <UIDragUpload
              setFileList={setFileList}
              fileList={fileList}
              isLoading={isLoading}
              handleRequest={handleRequest}
              handleChange={handleChange}
            />
          </UICustomCard>

          <div className={styles.contentWrapp}>
            <UIButton
              disabled={!fileList?.length}
              handler={handleSave}
              className={styles.btn}
              label={t('COMMON.SEND')}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default memo(ViewManagerDocument)
