import { Dispatch, SetStateAction } from 'react'

import { FullMedia } from 'entities/common'
import { TemplateFileType } from 'views/admin/Pages/index.type'

import {
  TYPE_FILE,
  TYPE_INPUT,
  TYPE_TEXTAREA,
} from './ui/TemplateField/index.type'

export const INITIAL_STATE_SECTION_ONE = {
  section_1_title: '',
  section_1_description: '',
  section_1_image: undefined,
}

export const INITIAL_STATE_SECTION_TWO = {
  section_2_title: '',
  section_2_description: '',
  section_2_title_1: '',
  section_2_image_1: undefined,
  section_2_title_2: '',
  section_2_image_2: undefined,
  section_2_title_3: '',
  section_2_image_3: undefined,
  section_2_title_4: '',
  section_2_image_4: undefined,
}

export const DYNAMICAL_ROW = { title: '', description: '' }

export const INITIAL_STATE_SECTION_THREE = {
  section_3_image: undefined as FullMedia | undefined,
  section_3_text: [DYNAMICAL_ROW],
}

export const INITIAL_STATE_SECTION_FOUR = {
  section_4_title: '',
  section_4_description: '',
  section_4_image: undefined,
}

export const INITIAL_STATE_SECTION_FIVE = {
  section_5_title: '',
  section_5_description: '',
  section_5_image: undefined,
}

export const getBusinessTemplateSectionOneInputs = (
  t: (str: string, value?: any) => string,
  section_1_title: string,
  section_1_description: string,
  section_1_image: TemplateFileType,
): BusinessTemplateStateType[] => [
  {
    label: t('COMMON.TITLE'),
    value: section_1_title,
    name: 'section_1_title',
    type: TYPE_INPUT,
  },
  {
    label: t('COMMON.DESCRIPTION'),
    value: section_1_description,
    name: 'section_1_description',
    type: TYPE_TEXTAREA,
  },
  {
    label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 1 }),
    name: 'section_1_image',
    type: TYPE_FILE,
    selectedName: String(section_1_image?.path?.split('/')?.slice(-1)?.[0]),
  },
]

export const getBusinessTemplateSectionTwoInputs = (
  t: (str: string, value?: any) => string,
  section_2_title: string,
  section_2_description: string,
  section_2_title_1: string,
  section_2_image_1: TemplateFileType,
  section_2_title_2: string,
  section_2_image_2: TemplateFileType,
  section_2_title_3: string,
  section_2_image_3: TemplateFileType,
  section_2_title_4: string,
  section_2_image_4: TemplateFileType,
): BusinessTemplateStateType[] => [
  {
    label: t('COMMON.TITLE'),
    value: section_2_title,
    name: 'section_2_title',
    type: TYPE_INPUT,
  },
  {
    label: t('COMMON.DESCRIPTION'),
    value: section_2_description,
    name: 'section_2_description',
    type: TYPE_TEXTAREA,
  },
  {
    label: t('COMMON.TITLE_NUMBER', { number: 1 }),
    value: section_2_title_1,
    name: 'section_2_title_1',
    type: TYPE_INPUT,
  },
  {
    label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 1 }),
    name: 'section_2_image_1',
    type: TYPE_FILE,
    selectedName: String(section_2_image_1?.path?.split('/')?.slice(-1)?.[0]),
  },
  {
    label: t('COMMON.TITLE_NUMBER', { number: 2 }),
    value: section_2_title_2,
    name: 'section_2_title_2',
    type: TYPE_INPUT,
  },
  {
    label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 2 }),
    name: 'section_2_image_2',
    type: TYPE_FILE,
    selectedName: String(section_2_image_2?.path?.split('/')?.slice(-1)?.[0]),
  },
  {
    label: t('COMMON.TITLE_NUMBER', { number: 3 }),
    value: section_2_title_3,
    name: 'section_2_title_3',
    type: TYPE_INPUT,
  },
  {
    label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 3 }),
    name: 'section_2_image_3',
    type: TYPE_FILE,
    selectedName: String(section_2_image_3?.path?.split('/')?.slice(-1)?.[0]),
  },
  {
    label: t('COMMON.TITLE_NUMBER', { number: 4 }),
    value: section_2_title_4,
    name: 'section_2_title_4',
    type: TYPE_INPUT,
  },
  {
    label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 4 }),
    name: 'section_2_image_4',
    type: TYPE_FILE,
    selectedName: String(section_2_image_4?.path?.split('/')?.slice(-1)?.[0]),
  },
]

export const getBusinessTemplateSectionFourInputs = (
  t: (str: string, value?: any) => string,
  section_4_title: string,
  section_4_description: string,
  section_4_image: TemplateFileType,
): BusinessTemplateStateType[] => [
  {
    label: t('COMMON.TITLE'),
    value: section_4_title,
    name: 'section_4_title',
    type: TYPE_INPUT,
  },
  {
    label: t('COMMON.DESCRIPTION'),
    value: section_4_description,
    name: 'section_4_description',
    type: TYPE_TEXTAREA,
  },
  {
    label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 1 }),
    name: 'section_4_image',
    type: TYPE_FILE,
    selectedName: String(section_4_image?.path?.split('/')?.slice(-1)?.[0]),
  },
]

export const getBusinessTemplateSectionFiveInputs = (
  t: (str: string, value?: any) => string,
  section_5_title: string,
  section_5_description: string,
  section_5_image: TemplateFileType,
): BusinessTemplateStateType[] => [
  {
    label: t('COMMON.TITLE'),
    value: section_5_title,
    name: 'section_5_title',
    type: TYPE_INPUT,
  },
  {
    label: t('COMMON.DESCRIPTION'),
    value: section_5_description,
    name: 'section_5_description',
    type: TYPE_TEXTAREA,
  },
  {
    label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 1 }),
    name: 'section_5_image',
    type: TYPE_FILE,
    selectedName: String(section_5_image?.path?.split('/')?.slice(-1)?.[0]),
  },
]

export type BusinessTemplateStateType = {
  [key: string]:
    | string
    | number
    | undefined
    | FullMedia
    | { [key: string]: string }
}

export type BusinessTemplateSetterType = Dispatch<
  SetStateAction<BusinessTemplateStateType>
>
