import {
  ICreateSalonOrderDataRequest,
  IGetBasketCalculateTimeBodyRequest,
} from 'store/Manager/Basket/Basket.type'
import { GET, POST, DELETE } from 'utils/http'

const basePath = '/salon/cart'

export const getBasket = () => GET(`${basePath}`)

export const getBasketTime = (query = '') => GET(`${basePath}/time${query}`)

export const getBasketCalculateTime = (
  body: IGetBasketCalculateTimeBodyRequest,
) => POST(`${basePath}/calculate-time`, body)

export const createBasketService = (body: {
  service_id: number
  staff_id: number
}) => POST(`${basePath}`, body)

export const updateBasketService = (body: {
  id: number
  service_id: number
  staff_id: number
}) =>
  POST(`${basePath}/${body.id}`, {
    service_id: body.service_id,
    staff_id: body.staff_id,
  })

export const deleteBasketService = (id: number) => DELETE(`${basePath}/${id}`)

export const getTotal = () => GET(`${basePath}/total`)

export const createSalonOrder = (body: ICreateSalonOrderDataRequest) =>
  POST('/salon/order', body)
