import { useCallback, useRef } from 'react'

function useLazyLoading({
  onIntersection,
  marginFromBottom = 0,
}: {
  marginFromBottom?: number
  onIntersection: () => void
}) {
  const containerRef = useRef(null)
  const onScroll = useCallback(() => {
    const { scrollHeight, clientHeight, scrollTop } =
      containerRef.current as unknown as Element

    if (scrollHeight - scrollTop - clientHeight - marginFromBottom <= 0) {
      onIntersection()
    }
  }, [marginFromBottom, onIntersection])
  const onScrollBottom = useCallback(() => {
    const { scrollTop } = containerRef.current as unknown as Element

    if (scrollTop === 0) {
      onIntersection()
    }
  }, [onIntersection])

  return [onScroll, containerRef, onScrollBottom]
}
export default useLazyLoading
