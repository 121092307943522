import { FC, SyntheticEvent } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { ReactComponent as MoveSVG } from 'assets/images/common/move.svg'
import { ReactComponent as RemoveSVG } from 'assets/images/common/redesign/remove.svg'
import { ModalType } from 'entities/modal'
import { hideModal, showModal } from 'store/Modal'
import {
  FORMAT_TIME_HOUR_MIN,
  FORMAT_TIME_MIN_SHORT,
  MANAGER_STAFF,
  STANDART_DATE,
} from 'utils'
import { DateHelper } from 'utils/helpers'

import styles from './style.module.scss'

type PropsType = {
  description?: string
  oldPrice?: number | string
  onDelete?: () => void
  price?: number | string
  remove?: boolean
  time?: string
  title?: string
}

const ServiceCard: FC<PropsType> = ({
  description,
  title,
  price,
  time,
  remove,
  oldPrice,
  onDelete,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const renderPrice = () => {
    return (
      <div className={styles.price}>
        {oldPrice ? (
          <div>
            <span className={styles.oldPrice}>${oldPrice}</span>${price}
          </div>
        ) : (
          <div>${price} </div>
        )}
      </div>
    )
  }

  const handleDelete = (event: SyntheticEvent) => {
    event.stopPropagation()

    dispatch(
      showModal({
        modalType: ModalType.MANAGER_DELETE_SERVICE,
        modalProps: {
          handler: () => {
            if (onDelete) {
              onDelete()
              dispatch(hideModal())
            }
          },
        },
      }),
    )
  }

  const isMoveIconDisable = pathname === MANAGER_STAFF

  return (
    <div className={styles.wrapper}>
      {!isMoveIconDisable && <MoveSVG className={styles.icon} />}
      <div className={styles.block}>
        <div>
          <span className={styles.title}>
            <b>{title}</b>
          </span>
          <span className={styles.time}>
            {DateHelper.toFormat(
              new Date(`${STANDART_DATE} ${time}`),
              FORMAT_TIME_HOUR_MIN,
            )}
            {t('MANAGER.SERVICES.TIME.HOUR.SHORT')}{' '}
            {DateHelper.toFormat(
              new Date(`${STANDART_DATE} ${time}`),
              FORMAT_TIME_MIN_SHORT,
            )}
            {t('MANAGER.SERVICES.TIME.MIN.SHORT')}
          </span>
        </div>
        {renderPrice()}
      </div>
      <div className={styles.description}>{description}</div>
      {remove && <RemoveSVG className={styles.remove} onClick={handleDelete} />}
    </div>
  )
}

export default ServiceCard
