import { FC, MouseEvent, useRef } from 'react'

import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as BackSVG } from 'assets/images/common/back.svg'
import { ReactComponent as CloseSVG } from 'assets/images/common/redesign/close.svg'
import { resetValidationError } from 'store/Manager/Appointments/Appointments.action'
import { hideModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const UICustomModal: FC<PropsType> = ({
  className,
  children,
  title,
  footer,
  handlerClose,
  isBack = true,
  isClose = true,
}) => {
  const { appointmentsError } = useSelector(
    (state: IRootState) => state.appointments,
  )

  const dispatch = useDispatch()
  const modalRef = useRef(null)

  const handleClose = () => {
    dispatch(hideModal())
    if (handlerClose) {
      handlerClose()
    }
  }

  const handleCloseOutside = (e: MouseEvent<HTMLElement>) => {
    if ((e.target as Element).classList.contains(styles.modal)) {
      dispatch(hideModal())
      dispatch(resetValidationError())
      if (handlerClose) {
        handlerClose()
        dispatch(resetValidationError())
      }
    }
  }

  return (
    <div
      onClick={handleCloseOutside}
      className={cn(styles.modal, className, {
        [styles.errorWrapp]: appointmentsError.length,
        [styles.wrappBack]: isBack,
      })}
    >
      <div className={styles.modalContent} ref={modalRef}>
        <div className={styles.scrollWrapp}>
          {title && (
            <div className={styles.title}>
              <span
                className={styles.arrow}
                onClick={() => {
                  dispatch(hideModal())
                  if (handlerClose) {
                    handlerClose()
                  }
                }}
              >
                <BackSVG />
              </span>
              {title}
            </div>
          )}

          {isClose && (
            <span className={styles.close} onClick={handleClose}>
              <CloseSVG />
            </span>
          )}

          {children}

          {!!footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </div>
    </div>
  )
}

export default UICustomModal
