export const GET_ADMIN_PAGES_REQUEST = '@pages/GET_ADMIN_PAGES_REQUEST'
export const GET_ADMIN_PAGES_SUCCESS = '@pages/GET_ADMIN_PAGES_SUCCESS'
export const GET_ADMIN_PAGES_FAILURE = '@pages/GET_ADMIN_PAGES_FAILURE'

export const GET_ADMIN_CURRENT_PAGE_REQUEST =
  '@pages/GET_ADMIN_CURRENT_PAGE_REQUEST'
export const GET_ADMIN_CURRENT_PAGE_SUCCESS =
  '@pages/GET_ADMIN_CURRENT_PAGE_SUCCESS'
export const GET_ADMIN_CURRENT_PAGE_FAILURE =
  '@pages/GET_ADMIN_CURRENT_PAGE_FAILURE'

export const SET_ADMIN_CURRENT_PAGE_REQUEST =
  '@pages/SET_ADMIN_CURRENT_PAGE_REQUEST'
export const SET_ADMIN_CURRENT_PAGE_SUCCESS =
  '@pages/SET_ADMIN_CURRENT_PAGE_SUCCESS'
export const SET_ADMIN_CURRENT_PAGE_FAILURE =
  '@pages/SET_ADMIN_CURRENT_PAGE_FAILURE'

export const CLEAR_ADMIN_CURRENT_TEMPLATE =
  '@pages/CLEAR_ADMIN_CURRENT_TEMPLATE'
