import React, { FC, memo, useEffect, useState } from 'react'

import 'antd/dist/antd.css'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as PlusSVG } from 'assets/images/common/redesign/plusCircle.svg'
import { ServiceType } from 'entities/manager/service'
import { CategoryType } from 'entities/view'
import StaffHeader from 'modules/manager/staff/Header'
import StaffMembers from 'modules/manager/staff/Members/index'
import { addAlert } from 'store/Alert'
import {
  clearErrors,
  clearStaffs,
  getOneStaffRequest,
  resetStaff,
  setActiveStaff,
  setIsStartStaff,
} from 'store/Manager/Staff'
import { clearErrors as mediaClearError } from 'store/Media/Media.action'
import { IRootState } from 'store/Root.reducer'
import { UIButton } from 'ui'
import { MANAGER_STAFF_ADD } from 'utils'

import styles from './style.module.scss'

const ViewStaff: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    staffs,
    staff,
    isLoading,
    isServiceLoading,
    isTimeLoading,
    activeStaff,
    idxStaff,
    errorMessage,
    isStaffAction,
    isStartStaff,
    isMobileTitleOpen,
    errorMessage: mediaErrorMessage,
  } = useSelector((state: IRootState) => ({
    ...state.staff,
    errorMessage: state.media.errorMessage,
    isShiftAction: state.shift.isShiftAction,
    isRepeatRequest: state.appointments.isRepeatRequest,
  }))

  const [active, setActive] = useState(0)
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [services, setServices] = useState<ServiceType[]>([])

  const handleBtn = () => {
    navigate(MANAGER_STAFF_ADD)
    dispatch(resetStaff())
    dispatch(clearStaffs())
  }

  useEffect(() => {
    if (!isStartStaff) {
      if (staffs?.length && !activeStaff) {
        dispatch(getOneStaffRequest(staffs[0].id))
        dispatch(setActiveStaff({ id: staffs[0].id }))
        dispatch(setIsStartStaff(true))
      } else if (staffs?.length && activeStaff && !isStartStaff) {
        dispatch(getOneStaffRequest(activeStaff))
        dispatch(setIsStartStaff(true))
      }
    }
  }, [activeStaff, dispatch, staffs, isStartStaff])

  useEffect(() => {
    if (staffs) {
      setCategories(
        staffs?.map((item) => ({
          id: item.id,
          name: item.name,
          color: item.color,
        })),
      )
    }
  }, [staffs])

  useEffect(() => {
    if (staff) {
      setServices(staff?.services as unknown as ServiceType[])
    }
  }, [staff])

  useEffect(() => {
    if (errorMessage || mediaErrorMessage) {
      dispatch(
        addAlert({
          message: errorMessage || mediaErrorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(clearErrors())
      dispatch(mediaClearError())
      dispatch(setIsStartStaff(false))
    }
  }, [errorMessage, dispatch, mediaErrorMessage])

  return (
    <Spin spinning={isLoading}>
      <div className={styles.wrapper}>
        <StaffHeader
          button={
            <UIButton
              handler={handleBtn}
              label={t('MANAGER.STAFF.ADD_NEW_STAFF')}
              icon={<PlusSVG />}
              type="text"
            />
          }
        />

        <StaffMembers
          services={services}
          categories={categories}
          setCategories={setCategories}
          active={active}
          setActive={setActive}
          setServices={setServices}
          staff={staff}
          isServiceLoading={isServiceLoading}
          isTimeLoading={isTimeLoading}
          activeStaff={activeStaff}
          idxStaff={idxStaff}
          isStaffAction={isStaffAction}
          isMobileTitleOpen={isMobileTitleOpen}
        />
      </div>
    </Spin>
  )
}

export default memo(ViewStaff)
