import { FC } from 'react'

import { Avatar, AvatarProps } from 'antd'
import cn from 'classnames'

import styles from './style.module.scss'

type PropsType = AvatarProps

const UIAvatar: FC<PropsType> = ({ className, ...props }) => (
  <Avatar className={cn(styles.avatar, className)} {...props} />
)

export default UIAvatar
