import { FC, useEffect, useMemo, useCallback } from 'react'

import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate, useParams } from 'react-router-dom'

import { ReactComponent as ArrowLeft } from 'assets/images/common/arrowLeft.svg'
import {
  AboutUsTemplate,
  ContactUsTemplate,
  CustomerTemplate,
  TextTemplate,
} from 'modules/admin/Pages'
import {
  clearAdminCurrentTemplate,
  getAdminCurrentPageRequest,
  setAdminCurrentPageRequest,
} from 'store/Admin/Pages/Pages.action'
import { addAlert } from 'store/Alert'
import { IRootState } from 'store/Root.reducer'
import { ADMIN_PAGES } from 'utils'

import style from './style.module.scss'
import {
  TemplateType,
  PAGES_TEMPLATES_TYPE_ABOUT_US,
  PAGES_TEMPLATES_TYPE_CONTACT_US,
  PAGES_TEMPLATES_TYPE_FOR_CUSTOMER,
  PAGES_TEMPLATES_TYPE_PRIVACY_POLICY,
  PAGES_TEMPLATES_TYPE_TERMS_CONDITIONS,
  TemplateBodyType,
  ContentType,
} from '../index.type'

import { ArrayHelper, StringHelper } from 'utils/helpers'

const getTemplate = (
  type: TemplateType,
  handleSave: (body: TemplateBodyType) => void,
) => {
  switch (type) {
    case PAGES_TEMPLATES_TYPE_PRIVACY_POLICY:
    case PAGES_TEMPLATES_TYPE_TERMS_CONDITIONS:
      return <TextTemplate onSave={handleSave} />
    case PAGES_TEMPLATES_TYPE_ABOUT_US:
      return <AboutUsTemplate onSave={handleSave} />
    case PAGES_TEMPLATES_TYPE_CONTACT_US:
      return <ContactUsTemplate onSave={handleSave} />
    case PAGES_TEMPLATES_TYPE_FOR_CUSTOMER:
      return <CustomerTemplate onSave={handleSave} />
    default:
      return null
  }
}

const ViewAdminPagesEdit: FC = () => {
  const { t } = useTranslation()
  const { id, type } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isCurrentPageSaved, currentPage } = useSelector(
    (store: IRootState) => store.adminPages,
  )

  const handleGoBack = () => {
    navigate(ADMIN_PAGES)
  }

  const handleSave = useCallback(
    (body: TemplateBodyType) => {
      const newBody = {
        seo: {},
        content: {},
      }

      const getContent = (item: string) => {
        if ((body as ContentType)?.data) {
          return {
            [item]: (body as ContentType)?.data?.map((el) => {
              if (el?.description) {
                return {
                  ...el,
                  description: StringHelper.draftToHtmlEditor(el?.description),
                }
              }

              return el
            }),
          }
        }

        if (
          (body.section_1_description && body.section_2_description) ||
          body.description
        ) {
          return ArrayHelper.getPageContent(body, true)
        }

        return { [item]: body[item] }
      }

      Object.keys(body).forEach((item) => {
        if (item.includes('seo_')) {
          newBody.seo = {
            ...(newBody.seo || {}),
            [item.slice(4)]: body[item],
          }
        } else {
          newBody.content = {
            ...(newBody.content || {}),
            ...getContent(item),
          }
        }
      })

      dispatch(setAdminCurrentPageRequest(Number(id), newBody))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, dispatch],
  )

  const template = useMemo(
    () => getTemplate(type as TemplateType, handleSave),
    [type, handleSave],
  )

  useEffect(() => {
    return () => {
      dispatch(clearAdminCurrentTemplate())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getAdminCurrentPageRequest(Number(id)))
  }, [dispatch, id])

  useEffect(() => {
    if (isCurrentPageSaved) {
      dispatch(
        addAlert({
          message: t('COMMON.SUCCESSFULLY_SAVED'),
          type: 'success',
          placement: 'center',
        }),
      )
    }
  }, [dispatch, isCurrentPageSaved, t])

  return (
    <Content className={style.editPages}>
      <div className={style.titleWrapper}>
        <ArrowLeft onClick={handleGoBack} className={style.arrow} />

        <h1>{currentPage?.name}</h1>
      </div>

      <section className={style.template}>{template}</section>
    </Content>
  )
}

export default ViewAdminPagesEdit
