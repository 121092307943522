/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useTranslation } from 'react-i18next'
import { TokenResponse, useGoogleLogin } from '@react-oauth/google'
import { UIButton, UICustomCard } from 'ui'
import styles from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as GoogleSVG } from 'assets/images/common/google.svg'
import { IRootState } from 'store/Root.reducer'
import {
  desyncGoogleCalendarRequest,
  syncGoogleCalendarRequest,
} from 'store/Manager/Settings/Settings.action'

const SettingGoogleCalendar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { profile } = useSelector((state: IRootState) => state.salon)

  const handleSuccess = ({ code }: { code: string }) => {
    if (code) {
      dispatch(syncGoogleCalendarRequest({ code }))
    }
  }

  const handleError = (
    response: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>,
  ) => {
    console.log(response.error)
  }

  const login = useGoogleLogin({
    redirect_uri:
      'https://admin.beautybooking.mx/profile-settings/profile-salon',
    onSuccess: handleSuccess,
    onError: handleError,
    flow: 'auth-code',
    include_granted_scopes: true,
    scope: 'https://www.googleapis.com/auth/calendar.events',
  })

  const handleClick = () => {
    login()
  }

  const handleDisconnect = () => {
    dispatch(desyncGoogleCalendarRequest())
  }

  return (
    <UICustomCard
      title={<>{t('MANAGER.GOOGLE_CALENDAR_SYNCHRONIZATION')}</>}
      className={styles.wrapper}
    >
      {!profile?.google_calendar_access && (
        <div className={styles.btn} onClick={handleClick}>
          <GoogleSVG /> <p>{t('MANAGER.GOOGLE_CALENDAR')}</p>
        </div>
      )}

      {profile?.google_calendar_access && (
        <UIButton
          type="outline"
          label={t('MANAGER.CANCEL_SYNC')}
          handler={handleDisconnect}
        />
      )}

      <p className={styles.googleInfo}>
        {t('MANAGER.CALENDAR_INFO')} (
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy"
          target="_blank"
        >
          Google API Services User Data Policy | Google for Developers
        </a>
        )
      </p>
    </UICustomCard>
  )
}

export default SettingGoogleCalendar
