export const GET_SCHEDULE_REQUEST = '@shift/GET_SCHEDULE_REQUEST'
export const GET_SCHEDULE_SUCCESS = '@shift/GET_SCHEDULE_SUCCESS'
export const GET_SCHEDULE_FAILURE = '@shift/GET_SCHEDULE_FAILURE'

export const GET_ONE_SCHEDULE_REQUEST = '@shift/GET_ONE_SCHEDULE_REQUEST'
export const GET_ONE_SCHEDULE_SUCCESS = '@shift/GET_ONE_SCHEDULE_SUCCESS'
export const GET_ONE_SCHEDULE_FAILURE = '@shift/GET_ONE_SCHEDULE_FAILURE'

export const UPDATE_SCHEDULE_REQUEST = '@shift/UPDATE_SCHEDULE_REQUEST'
export const UPDATE_SCHEDULE_SUCCESS = '@shift/UPDATE_SCHEDULE_SUCCESS'
export const UPDATE_SCHEDULE_FAILURE = '@shift/UPDATE_SCHEDULE_FAILURE'

export const UPDATE_SHIFT_REQUEST = '@shift/UPDATE_SHIFT_REQUEST'
export const UPDATE_SHIFT_SUCCESS = '@shift/UPDATE_SHIFT_SUCCESS'
export const UPDATE_SHIFT_FAILURE = '@shift/UPDATE_SHIFT_FAILURE'

export const ADD_TIME_SHIFT_REQUEST = '@shift/ADD_TIME_SHIFT_REQUEST'
export const ADD_TIME_SHIFT_SUCCESS = '@shift/ADD_TIME_SHIFT_SUCCESS'
export const ADD_TIME_SHIFT_FAILURE = '@shift/ADD_TIME_SHIFT_FAILURE'

export const COPY_SHIFT_REQUEST = '@shift/COPY_SHIFT_REQUEST'
export const COPY_SHIFT_SUCCESS = '@shift/COPY_SHIFT_SUCCESS'
export const COPY_SHIFT_FAILURE = '@shift/COPY_SHIFT_FAILURE'

export const RESET_STORE = '@shift/RESET_STORE'

export const CLEAR_ERRORS = '@shift/CLEAR_ERRORS'
