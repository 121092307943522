import {
  FC,
  useState,
  SyntheticEvent,
  useEffect,
  useCallback,
  memo,
  useMemo,
} from 'react'

import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { ReactComponent as ArrowLeft } from 'assets/images/common/arrowLeft.svg'
import TextBlock from 'modules/admin/Faq/TextBlock'
import { UIButton } from 'ui'
import { ADMIN_SEO } from 'utils'

import style from './style.module.scss'
import { IRootState } from 'store/Root.reducer'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearSeoStore,
  getAdminCategoryRequest,
  getAdminSettingsRequest,
  getAdminSubcategoryRequest,
  updateAdminSettingsRequest,
} from 'store/Admin/Seo/Seo.action'
import { Spin } from 'antd'
import { editCategoryRequest, editSubcategory } from 'store/Admin'
import { INITIAL_VALUES, INPUTS } from './constant'
import UITextInput from 'ui/TextInput'
import { ValuesType } from './index.type'

const ViewAdminSeoEdit: FC = () => {
  const params = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [values, setValues] = useState<ValuesType>(INITIAL_VALUES)
  const [urlValues, setUrlValues] = useState('')

  const { subCategory, category, isLoading, categories, setting } = useSelector(
    (state: IRootState) => ({
      ...state.adminSeo,
      categories: state.adminCategories.categories,
    }),
  )

  const renderTitle = useCallback(() => {
    if (subCategory) return subCategory.name
    if (category) return category.name
    if (setting) return t('ADMIN.SEO.COMMON_TEMPLATE')

    return '---'
  }, [category, setting, subCategory, t])

  const handleChange = (event: SyntheticEvent) => {
    const { value, name } = event.target as HTMLTextAreaElement

    setValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleGoBack = () => {
    navigate(ADMIN_SEO)
  }

  const handleSave = () => {
    if (subCategory) {
      dispatch(
        editSubcategory({
          name: subCategory?.name,
          id: subCategory?.id,
          slug: urlValues,
          seo: values,
        }),
      )
    }

    if (category) {
      dispatch(
        editCategoryRequest({
          name: category?.name,
          id: category?.id,
          slug: urlValues,
          image:
            categories?.find((i) => i.id === category.id)?.image?.id || null,
          seo: values,
        }),
      )
    }

    if (setting) {
      dispatch(
        updateAdminSettingsRequest({
          seo_salons_description: values.description,
          seo_salons_keywords: values.keywords,
          seo_salons_title: values.title,
        }),
      )
    }
  }

  const getPrevUrl = useMemo(() => {
    const baseUrl = 'https://client.beautybooking.mx/salons/'

    if (category) return baseUrl

    if (subCategory) return `${baseUrl}${subCategory.url?.split('/')?.[0]}/`

    return baseUrl
  }, [category, subCategory])

  useEffect(() => {
    if (params.subcategory && params.category === 'category') {
      dispatch(getAdminCategoryRequest(Number(params?.subcategory)))
    }

    if (params.subcategory && params.category === 'subcategory') {
      dispatch(getAdminSubcategoryRequest(Number(params?.subcategory)))
    }

    if (params.category === 'common') {
      dispatch(getAdminSettingsRequest())
    }
  }, [dispatch, params.category, params.subcategory])

  useEffect(() => {
    if (subCategory) {
      setValues({
        description: subCategory?.seo?.description || '',
        keywords: subCategory?.seo?.keywords || '',
        title: subCategory?.seo?.title || '',
      })
      setUrlValues(subCategory?.slug || '')
    } else if (category) {
      setValues({
        description: category?.seo?.description || '',
        keywords: category?.seo?.keywords || '',
        title: category?.seo?.title || '',
      })
      setUrlValues(category?.slug || '')
    } else if (setting) {
      setValues({
        description: setting?.seo_salons_description || '',
        keywords: setting?.seo_salons_keywords || '',
        title: setting?.seo_salons_title || '',
      })
    }
  }, [category, params.category, params?.subcategory, setting, subCategory])

  useEffect(() => {
    return () => {
      dispatch(clearSeoStore())
    }
  }, [dispatch])

  return (
    <Spin spinning={isLoading}>
      <Content className={style['edit-seo-page']}>
        <div className={style.titleWrapper}>
          <ArrowLeft onClick={handleGoBack} className={style.arrow} />

          <h1>{renderTitle()}</h1>
        </div>

        {(subCategory || category) && (
          <div className={style.urlWrapper}>
            <p>{getPrevUrl}</p>
            <UITextInput
              placeholder={t('COMMON.URL')}
              text={t('COMMON.URL')}
              value={urlValues}
              onChange={(e) => setUrlValues(e.target.value)}
            />
          </div>
        )}

        <div className={style.wrapper}>
          {INPUTS.map(({ name, title: seoTitle }, index) => (
            <TextBlock
              key={index}
              rows={7}
              name={name}
              title={t(seoTitle)}
              value={values[name]}
              onChange={handleChange}
            />
          ))}
        </div>

        <UIButton
          handler={handleSave}
          label={t('COMMON.SAVE')}
          className={style.button}
        />
      </Content>
    </Spin>
  )
}

export default memo(ViewAdminSeoEdit)
