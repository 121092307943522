import { Dispatch, SetStateAction } from 'react'

import i18n from 'lib/i18n/i18n'
import BlogCategorySeo from 'modules/admin/Blog/Category/Seo'

import { ValuesType } from './index.type'

export const tabs = (
  values: ValuesType,
  setValues: Dispatch<SetStateAction<ValuesType>>,
) => [
  {
    name: i18n.t('ADMIN.BLOG.SEO'),
    element: <BlogCategorySeo values={values} setValues={setValues} />,
  },
]

export const INITIAL_VALUES = {
  name: '',
  title: '',
  h1: '',
  description: '',
  keywords: '',
}
