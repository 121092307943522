/* eslint-disable  */
import { addDays } from 'date-fns'
import dayjs from 'dayjs'
import {
  format as fnsTzFormat,
  utcToZonedTime,
  getTimezoneOffset,
  formatInTimeZone,
} from 'date-fns-tz'
import fnsAddDays from 'date-fns/addDays'
import fnsAddHours from 'date-fns/addHours'
import fnsAddMinutes from 'date-fns/addMinutes'
import fnsAddweek from 'date-fns/addWeeks'
import addMonths from 'date-fns/addMonths'
import addSeconds from 'date-fns/addSeconds'
import fnsAddYears from 'date-fns/addYears'
import fnsDifferenceInDays from 'date-fns/differenceInDays'
import fnsDifferenceInHours from 'date-fns/differenceInHours'
import fnsDifferenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import fnsDifferenceInSeconds from 'date-fns/differenceInSeconds'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import endOfDay from 'date-fns/endOfDay'
import fnsEndOfHour from 'date-fns/endOfHour'
import endOfISOWeek from 'date-fns/endOfISOWeek'
import endOfMonth from 'date-fns/endOfMonth'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import fnsFormatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import formatDuration from 'date-fns/formatDuration'
import intervalToDuration from 'date-fns/intervalToDuration'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import fnsIsSame from 'date-fns/isSameDay'
import fnsParse from 'date-fns/parse'
import fnsParseISO from 'date-fns/parseISO'
import setSeconds from 'date-fns/setSeconds'
import startOfDay from 'date-fns/startOfDay'
import fnsStartOfHour from 'date-fns/startOfHour'
import startOfISOWeek from 'date-fns/startOfISOWeek'
import startOfMonth from 'date-fns/startOfMonth'
import fnsStartOfWeek from 'date-fns/startOfWeek'
import subMinutes from 'date-fns/subMinutes'
import fnsToDate from 'date-fns/toDate'
import { FullDateType } from 'entities/manager'

import { FORMAT_DATE_TIME } from '../constants'

import { AppointmentDateState } from 'ui/AppointmentStartTime/index.type'
import { getI18nLocale } from './getLocale'
import i18n from 'lib/i18n/i18n'

export function getDate(date: AppointmentDateState) {
  if (!date) return new Date()

  return new Date(
    Number(date.year),
    Number(date.month) - 1,
    Number(date.day),
    Number(date.hour),
    Number(date.minute),
  )
}

export function getWeek(date: Date) {
  if (!date) return []

  const startWeek = fnsStartOfWeek(date, { weekStartsOn: 1 })
  const week = [...Array(7)].fill(0).map((_, index) => {
    if (index === 0) return startWeek

    return addDays(startWeek, index)
  })

  return week
}

export function getDays(startDate: Date, count: number) {
  if (!startDate) return []

  const dates = [...Array(count)].fill(0).map((_, index) => {
    if (index === 0) return startDate

    return addDays(startDate, index)
  })

  return dates
}

export default (() => {
  const isSameDay = (
    dateLeft?: Date | number,
    dateRight?: Date | number,
  ): boolean => {
    if (dateLeft && dateRight) {
      return fnsIsSame(dateLeft, dateRight)
    }

    return false
  }

  const parse = (date: DateCtx, formatString = 'yyyy-MM-dd') => {
    return typeof date === 'string'
      ? fnsParse(date, formatString, new Date())
      : date
  }

  const parseISO = (date: DateCtx) => {
    return typeof date === 'string' ? fnsParseISO(date) : date
  }

  const eachDay = (start: Date | number, end: Date | number) =>
    eachDayOfInterval({ start, end })

  const defineds = (intDate?: Date) => {
    const date = intDate || new Date()

    return {
      startOfWeek: startOfISOWeek(date),
      endOfWeek: endOfISOWeek(date),
      startOfLastWeek: startOfISOWeek(fnsAddDays(date, -7)),
      endOfLastWeek: endOfISOWeek(fnsAddDays(date, -7)),
      startOfToday: startOfDay(date),
      endOfToday: endOfDay(date),
      startOfYesterday: startOfDay(fnsAddDays(date, -1)),
      endOfYesterday: endOfDay(fnsAddDays(date, -1)),
      startOfSevenDay: startOfDay(fnsAddDays(date, -7)),
      endOfSevenDay: endOfDay(date),
      startOfThirtyDay: startOfDay(fnsAddDays(date, -30)),
      endOfThirtyDay: endOfDay(date),
      startOfMonth: startOfMonth(date),
      endOfMonth: endOfMonth(date),
      startOfLastMonth: startOfMonth(addMonths(date, -1)),
      endOfLastMonth: endOfMonth(addMonths(date, -1)),
      startOfLastSixMonths: startOfMonth(addMonths(date, -7)),
      endOfLastSixMonths: endOfMonth(addMonths(date, -1)),
    }
  }

  const toFormat = (date?: DateCtx, fmt = 'dd/MM', tz?: string) => {
    return fnsTzFormat(parseISO(date ? new Date(date) : new Date()), fmt, {
      ...(tz && {
        timeZone: tz,
      }),
      locale: getI18nLocale(i18n.language),
    })
  }

  const toFormatTimezone = (date: DateCtx, fmt: string, tz: string) => {
    return formatInTimeZone(date, tz, fmt)
  }

  const dayIsBefore = (date: Date | number, dateToCompare: Date | number) =>
    isBefore(date, dateToCompare)

  const dayIsAfter = (date: Date | number, dateToCompare: Date | number) =>
    isAfter(date, dateToCompare)

  const dayIsBetween = (
    date: Date | number,
    dateAfter: Date | number,
    dateBefore: Date | number,
  ) => isAfter(date, dateAfter) && isBefore(date, dateBefore)

  const dayIsBetweenAndEqual = (
    date: Date | number,
    dateAfter: Date | number,
    dateBefore: Date | number,
  ) =>
    fnsIsSame(date, dateBefore) ||
    (isAfter(date, dateAfter) && isBefore(date, dateBefore)) ||
    fnsIsSame(date, dateAfter)

  const formatDistanceToNowStrict = (
    date?: DateCtx,
    options?: {
      unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
    },
  ) => fnsFormatDistanceToNowStrict(parseISO(date || new Date()), options)

  const millisecondsToNextMinute = () => {
    const now = new Date()
    const next = setSeconds(fnsAddMinutes(now, 1), 0)

    return fnsDifferenceInMilliseconds(next, now)
  }

  const millisecondsToNextSecond = () => {
    const now = new Date()
    const next = addSeconds(now, 1)

    return fnsDifferenceInMilliseconds(next, now)
  }

  const getDifferenceInMonths = (
    dateLeft: Date | number,
    dateRight: Date | number,
    options?: {
      roundingMethod?: string
    },
  ) => differenceInMinutes(dateLeft, dateRight, options)

  const shortEnLocale = {
    formatDistance: (token: any, count: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return count < 10 ? `0${count}` : count
    },
  }

  const getTime = (time: number) => {
    const roundTime = Math.round(time)

    const secondRender = roundTime % 60

    const second = secondRender < 10 ? `0${secondRender}` : secondRender

    const minute = (roundTime - secondRender) / 60

    return `${minute}:${second}`
  }

  const getFullTime = (
    fullDate: FullDateType & { isAll: boolean; isRepeat: boolean },
  ) => {
    const { endDate, endTime, isAll, isRepeat, startDate, startTime } = fullDate

    let days = 0
    let hours = 0
    let minutes = 0
    let fullDifference = ''
    const workDay = 8

    if (!isAll && startTime && endTime) {
      hours = differenceInHours({
        dateStrLeft: endTime,
        dateStrRight: startTime,
      })

      minutes = differenceInMinutes(endTime, startTime) % 60

      if (minutes % 5 !== 0) {
        minutes += 1
      }
    }

    if (startDate && endDate) {
      days =
        differenceInDays({ dateStrLeft: endDate, dateStrRight: startDate }) + 1

      if (isRepeat) {
        if (startTime && endTime && !isAll) {
          hours *= days
          minutes *= days
        } else {
          hours = days * workDay
        }
      } else if (isAll) {
        hours += workDay
      }

      days = 0
    }

    if (startDate && isAll && !endDate) {
      hours += workDay
    }

    while (minutes >= 60) {
      hours += 1
      minutes -= 60
    }

    while (hours >= 24) {
      days += 1
      hours -= 24
    }

    fullDifference = `${days ? days + 'd ' : ''}${hours ? hours + 'h ' : ''}${
      minutes ? minutes + 'm ' : ''
    }`

    return (hours >= 0 ? fullDifference : '') || '0h'
  }

  const fromNowString = (date: DateCtx): string =>
    formatDistanceToNow(new Date(date), { addSuffix: true })

  const toDate = (date: DateCtx): Date => fnsToDate(new Date(date))

  const getDifferenceInMinutes = ({
    dateStrLeft,
    dateStrRight,
  }: {
    dateStrLeft?: DateCtx
    dateStrRight?: DateCtx
  }) =>
    differenceInMinutes(
      dateStrLeft ? new Date(dateStrLeft) : new Date(),
      dateStrRight ? new Date(dateStrRight) : new Date(),
    )

  const addDay = (date: DateCtx, amount: number) =>
    fnsAddDays(parseISO(date), amount)

  const durationTimeString = ({
    dateStrLeft,
    dateStrRight,
  }: {
    dateStrLeft?: string
    dateStrRight?: string
  }) =>
    formatDuration(
      intervalToDuration({
        start: dateStrLeft ? new Date(dateStrLeft) : new Date(),
        end: dateStrRight ? new Date(dateStrRight) : new Date(),
      }),
      {
        format: ['minutes', 'seconds'],
        delimiter: ':',
        zero: true,
        locale: shortEnLocale,
      },
    ).replace(/[a-zа-яё\s]/gi, '')

  const differenceInSeconds = ({
    dateStrLeft,
    dateStrRight,
  }: {
    dateStrLeft?: string
    dateStrRight?: string
  }) =>
    fnsDifferenceInSeconds(
      dateStrLeft ? new Date(dateStrLeft) : new Date(),
      dateStrRight ? new Date(dateStrRight) : new Date(),
    )

  const differenceInMilliseconds = ({
    dateStrLeft,
    dateStrRight,
  }: {
    dateStrLeft?: Date | string
    dateStrRight?: Date | string
  }) =>
    fnsDifferenceInMilliseconds(
      dateStrLeft ? new Date(dateStrLeft) : new Date(),
      dateStrRight ? new Date(dateStrRight) : new Date(),
    )

  const differenceInDays = ({
    dateStrLeft,
    dateStrRight,
  }: {
    dateStrLeft?: Date | string
    dateStrRight?: Date | string
  }) =>
    fnsDifferenceInDays(
      dateStrLeft ? new Date(dateStrLeft) : new Date(),
      dateStrRight ? new Date(dateStrRight) : new Date(),
    )

  const differenceInHours = ({
    dateStrLeft,
    dateStrRight,
  }: {
    dateStrLeft?: Date | string
    dateStrRight?: Date | string
  }) =>
    fnsDifferenceInHours(
      dateStrLeft ? new Date(dateStrLeft) : new Date(),
      dateStrRight ? new Date(dateStrRight) : new Date(),
    )

  const getRangeOfDays = (date: Date, len: number) => {
    const range = []

    for (let i = 0; i < len; i++) {
      range.push(addDays(date, i))
    }

    return range
  }

  const addMinutes = (date: DateCtx, amount: number) =>
    fnsAddMinutes(parseISO(date), amount)
  const addHours = (date: DateCtx, amount: number) =>
    fnsAddHours(parseISO(date), amount)
  const addWeek = (date: DateCtx, amount: number) =>
    fnsAddweek(parseISO(date), amount)
  const addYears = (date: DateCtx, amount: number) =>
    fnsAddYears(parseISO(date), amount)
  const startOfHour = (date: DateCtx) => fnsStartOfHour(parseISO(date))
  const endOfHour = (date: DateCtx) => fnsEndOfHour(parseISO(date))

  const startOfWeek = (date: DateCtx) => {
    return startOfISOWeek(
      typeof date !== 'string' ? date : new Date(date),
    ).toISOString()
  }

  const endOfWeek = (date: DateCtx) => {
    return endOfISOWeek(
      typeof date !== 'string' ? date : new Date(date),
    ).toISOString()
  }

  const startMonth = (date: DateCtx) => {
    return startOfMonth(
      typeof date !== 'string' ? date : new Date(date),
    ).toISOString()
  }

  const endMonth = (date: DateCtx) => {
    return endOfMonth(
      typeof date !== 'string' ? date : new Date(date),
    ).toISOString()
  }

  const getUnixDate = (date: DateCtx) => {
    return new Date(date).valueOf() + new Date().getTimezoneOffset() * 60000
  }

  const formateDateToString = (
    date?: dayjs.ConfigType,
    format: string = FORMAT_DATE_TIME,
  ) => {
    return dayjs(date).format(format)
  }

  return {
    getFullTime,
    getTime,
    fromNowString,
    toDate,
    getDifferenceInMonths,
    isSameDay,
    parse,
    eachDay,
    defineds,
    toFormat,
    dayIsBefore,
    dayIsAfter,
    dayIsBetween,
    dayIsBetweenAndEqual,
    formatDistanceToNowStrict,
    millisecondsToNextMinute,
    millisecondsToNextSecond,
    getDifferenceInMinutes,
    addDay,
    durationTimeString,
    differenceInSeconds,
    differenceInMilliseconds,
    startOfDay,
    endOfDay,
    addHours,
    addMinutes,
    subMinutes,
    startOfWeek,
    endOfWeek,
    startOfHour,
    endOfHour,
    utcToZonedTime,
    getTimezoneOffset,
    getRangeOfDays,
    toFormatTimezone,
    addYears,
    differenceInDays,
    differenceInHours,
    getUnixDate,
    addWeek,
    startMonth,
    endMonth,
    formateDateToString,
  }
})()
