import i18n from 'lib/i18n/i18n'

export enum CategoriesOrder {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
}

export const categoriesOrder = [
  { name: i18n.t('COMMON.ACTIVES'), id: CategoriesOrder.ACTIVE },
  { name: i18n.t('COMMON.COMPLETEDS'), id: CategoriesOrder.COMPLETED },
  { name: i18n.t('COMMON.CANCELLED'), id: CategoriesOrder.CANCELLED },
]
