import { useSocket } from 'hooks/useSocket'
import { MenuNotificationName } from 'modules/manager/constants/sidebar'
import { useDispatch } from 'react-redux'
import { setAdminNotifications } from 'store/Admin/Notifications/Notifications.action'
import { AdminSocketEvent } from '..'

export const AdminDocumentUploadedSocket = () => {
  const dispatch = useDispatch()

  useSocket({
    type: AdminSocketEvent.DOCUMENT_UPLOADED,
    callBack: () => {
      dispatch(setAdminNotifications(MenuNotificationName.NOTIFICATION))
    },
  })
}
