import { ChangeEvent, FC, useEffect, useLayoutEffect, useState } from 'react'

import 'antd/dist/antd.css'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PhoneNumberType, PhoneType } from 'entities/manager'
import {
  ProfileSalonAbout,
  ProfileSalonBusinessHours,
  ProfileSalonImages,
  ProfileSalonPhone,
} from 'modules/manager/profileSalon'
import { addAlert } from 'store/Alert'
import {
  clearFirstMessage,
  getProfileRequest,
  resetError,
  resetPhone,
  setImageList,
  updateProfileRequest,
} from 'store/Manager/Salon'
import { clearErrors, clearMediaStore } from 'store/Media/Media.action'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UICustomCard, UIUpload } from 'ui'
import UIGoogleMap from 'ui/GoogleMap'
import UITextInput from 'ui/TextInput'

import { SalonDataType, SalonTime, StateTimeType } from './index.type'
import styles from './style.module.scss'
import { showModal } from 'store/Modal'
import { ModalType } from 'entities/modal'
import { ReactComponent as FacebookSVG } from 'assets/images/common/facebook.svg'
import { ReactComponent as InstagramSVG } from 'assets/images/common/instagram.svg'

const ViewProfileSalon: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    profile,
    isLoading,
    phoneData,
    errorMessage,
    errors,
    fileData,
    images,
    isMediaLoading,
    mediaErrorMessage,
    message,
  } = useSelector((state: IRootState) => ({
    ...state.salon,
    fileData: state.media.fileData,
    images: state.media.images,
    isMediaLoading: state.media.isloading,
    mediaErrorMessage: state.media.errorMessage,
  }))
  // TODO what is it?
  const [, setIsHourError] = useState(false)
  const [numbers, setNumbers] = useState<PhoneNumberType[]>([])
  const [isShowFirstModal, setIsShowFirstModal] = useState(false)
  const [values, setValues] = useState<SalonDataType>({
    name: '',
    address: null,
    phones: [],
    email: null,
    about: '',
    times: {},
    logo: null,
    timezone: null,
  })
  const [customize, setCustomize] = useState(false)

  useEffect(() => {
    dispatch(getProfileRequest())
  }, [dispatch])

  useEffect(() => {
    if (profile) {
      const phones = profile?.phones?.map((e) => ({
        number: e.number.slice(3, e.number.length),
        id: e.id,
      })) as PhoneType[]

      const newTime = {
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
        6: {},
        7: {},
      } as StateTimeType

      profile?.times?.forEach((item: SalonTime) => {
        newTime[item?.day] = {
          ...newTime[item?.day],
          [item?.type === 1 ? 'time' : 'break']: {
            start: item?.start,
            end: item?.end,
          },
        }
      })

      setValues({
        facebook_link: profile.facebook_link,
        instagram_link: profile.instagram_link,
        name: profile?.name,
        email: profile?.email,
        address: profile?.address,
        about: profile?.about,
        times: newTime,
        logo: profile?.logo,
        phones: phones?.map((i) => i.id || 0),
        images: profile?.images?.map((i) => i.id),
        timezone:
          typeof profile?.timezone === 'number' ? profile?.timezone : null,
      })
      setNumbers(phones)
    }
  }, [profile])

  const handleSave = () => {
    const getTimes = () => {
      const newTimes: StateTimeType = {}

      const valueTime = values?.times as StateTimeType

      if (values?.times) {
        Object.keys(values?.times).forEach((item) => {
          if (valueTime[item]?.time?.start) {
            newTimes[item] = valueTime[item]
          } else {
            newTimes[item] = {}
          }
        })
      }

      return newTimes
    }

    dispatch(
      updateProfileRequest({
        ...(values.facebook_link !== null && {
          facebook_link: values.facebook_link,
        }),
        ...(values.instagram_link !== null && {
          instagram_link: values.instagram_link,
        }),
        name: values.name || '',
        phones: values.phones,
        email: values.email || '',
        logo: values.logo?.id,
        about: values.about || '',
        address: values.address,
        times: getTimes(),
        images: values.images,
        timezone: values.timezone,
        ...(values.password && {
          password: values.password,
          password_confirmation: values.password_confirmation,
          password_old: values.password_old,
        }),
      }),
    )
  }

  const handleEmailChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setValues({
      ...values,
      email: e.target.value === ' ' ? null : e.target.value,
    })
  }

  const handleUrlChange = (val: string, field: string) => {
    setValues({
      ...values,
      [field]: val,
    })
  }

  useEffect(() => {
    if (phoneData && !numbers.map((i) => i.id).includes(phoneData.id)) {
      setNumbers((prevState) => [
        ...prevState,
        {
          id: phoneData.id,
          number: phoneData.number.slice(3, phoneData.number.length),
        },
      ])

      dispatch(resetPhone())
    }
  }, [dispatch, numbers, phoneData, values])

  useEffect(() => {
    if (numbers?.length) {
      setValues((prevState) => ({
        ...prevState,
        phones: numbers?.map((i) => i.id || 0),
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numbers])

  useEffect(() => {
    if (fileData) {
      setValues({
        ...values,
        logo: fileData,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData])

  useEffect(() => {
    const dayWeek = [2, 3, 4, 5]
    const newValues = { ...values, times: { ...values?.times } }
    const valueTime = values?.times as StateTimeType

    dayWeek.forEach((index) => {
      ;(newValues.times as StateTimeType)[index] = {
        time: {
          start: valueTime[1]?.time?.start,
          end: valueTime[1]?.time?.end,
        },
        ...(valueTime[1]?.break && {
          break: {
            start: valueTime[1]?.break?.start,
            end: valueTime[1]?.break?.end,
          },
        }),
      }
    })

    if (!customize) {
      setValues(newValues as SalonDataType)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customize])

  useEffect(() => {
    const times = profile?.times
    const sortObj = {
      timeStart: [] as any[],
      timeEnd: [] as any[],
      breakStart: [] as any[],
      breakEnd: [] as any[],
    }

    if (times) {
      times.forEach((item) => {
        if (item.day <= 5) {
          if (+item.type === 1) {
            sortObj.timeStart = [...sortObj.timeStart, item.start]
            sortObj.timeEnd = [...sortObj.timeEnd, item.end]
          } else {
            sortObj.breakStart = [...sortObj.breakStart, item.start]
            sortObj.breakEnd = [...sortObj.breakEnd, item.end]
          }
        }
      })
    }
    let isFind = true

    let firstEl = ''

    const getDisable = (arr: string[]) => {
      const [first] = arr

      firstEl = first

      if (arr.length <= 5) {
        arr.forEach((item) => {
          if (isFind) {
            isFind = item === firstEl
          }
        })
      } else {
        isFind = false
      }
    }

    getDisable(sortObj.timeStart as string[])
    getDisable(sortObj.timeEnd as string[])
    getDisable(sortObj.breakStart as string[])
    getDisable(sortObj.breakEnd as string[])

    const getShowCustomize = () => {
      const arr: string[] = []

      Object.keys(times || {}).forEach((item) => {
        if (
          times?.[item as unknown as number].type === 1 &&
          times?.[item as unknown as number].day <= 5
        ) {
          arr.push(item)
        }
      })

      return !(arr.length === 5)
    }

    setCustomize(!isFind || getShowCustomize())
  }, [profile?.times])

  useEffect(() => {
    if (errorMessage || mediaErrorMessage) {
      dispatch(
        addAlert({
          message: errorMessage || mediaErrorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(resetError())
      dispatch(clearErrors())
    }
  }, [errorMessage, dispatch, mediaErrorMessage])

  useLayoutEffect(() => {
    dispatch(clearMediaStore())
  }, [dispatch])

  useEffect(() => {
    if (message) {
      setIsShowFirstModal(true)
    }
  }, [message])

  useEffect(() => {
    if (isShowFirstModal) {
      dispatch(
        showModal({
          modalType: ModalType.NOTIFY,
          modalProps: {
            notifyTitle: message,
            className: styles.firstModal,
            isHtml: true,
          },
        }),
      )
    }
  }, [dispatch, isShowFirstModal, message])

  useEffect(() => {
    return () => {
      setIsShowFirstModal(false)
      dispatch(clearFirstMessage())
    }
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <Spin spinning={isLoading}>
        <div className={styles.description}>
          <div className={styles.title}>
            <b>{t('MANAGER.PROFILE_SALON.PROFILE_SALON')}</b>
          </div>

          {t('MANAGER.PROFILE_SALON.DESCRIPTION')}
        </div>

        <UICustomCard
          title={
            <>
              <span>1. </span>
              {t('MANAGER.PROFILE_SALON.ITEM_1.0')}
            </>
          }
        >
          <div className={styles.mainInfoWrapper}>
            <div>
              <div className={styles.wrapperGeneralInfo}>
                <UITextInput
                  className={styles.emailInput}
                  text={t('MANAGER.PROFILE_SALON.SALON_NAME')}
                  necessaryLabel={`(${t('COMMON.NECESSARY')})`}
                  placeholder={t(
                    'MANAGER.PROFILE_SALON.SALON_NAME_PLACEHOLDER',
                  )}
                  onChange={(e) => {
                    setValues({ ...values, name: e.target.value })
                  }}
                  maxLength={50}
                  value={values.name}
                  error={errors?.name}
                />

                <UITextInput
                  text={t('MANAGER.PROFILE_SALON.EMAIL')}
                  placeholder={t('MANAGER.PROFILE_SALON.EMAIL')}
                  className={styles.emailInput}
                  onChange={(e) => handleEmailChange(e)}
                  value={values.email as string}
                  error={errors?.email}
                />

                <ProfileSalonPhone
                  numbers={numbers}
                  setNumbers={setNumbers}
                  setValues={setValues}
                  values={values}
                  errors={errors}
                />
              </div>

              <UITextInput
                text={t('MANAGER.FACEBOOK_URL')}
                placeholder={t('COMMON.HTTPS')}
                className={styles.linkInput}
                onChange={(e) =>
                  handleUrlChange(e.target.value, 'facebook_link')
                }
                value={values.facebook_link as string}
                error={errors?.facebook_link}
                prefix={<FacebookSVG />}
                name="facebook_link"
              />

              <UITextInput
                text={t('MANAGER.INSTAGRAM_URL')}
                placeholder={t('COMMON.HTTPS')}
                className={styles.linkInput}
                onChange={(e) =>
                  handleUrlChange(e.target.value, 'instagram_link')
                }
                value={values.instagram_link as string}
                error={errors?.instagram_link}
                prefix={<InstagramSVG />}
                name="instagram_link"
              />
            </div>

            <div>
              <div className={styles.uploadLogo}>
                {t('MANAGER.PROFILE_SALON.UPLOAD_LOGO')}
              </div>

              <UIUpload
                className={styles.uploadAvatar}
                setValues={setValues}
                values={values}
                fileData={fileData}
                isLoading={isMediaLoading}
                pathImg={profile?.logo?.path}
                isCircle={true}
                widthRatio={1}
                heightRatio={1}
              />
            </div>
          </div>
        </UICustomCard>

        <UICustomCard
          title={
            <>
              <span>2. </span>
              {t('MANAGER.PROFILE_SALON.ITEM_2.0')}
            </>
          }
        >
          <UIGoogleMap
            error={errors.address}
            setValues={setValues}
            values={values}
          />
        </UICustomCard>

        <ProfileSalonImages
          setValues={setValues}
          values={values}
          isMediaLoading={isMediaLoading}
          profile={profile}
          images={images}
          setImageList={setImageList}
        />

        <ProfileSalonBusinessHours
          values={values}
          setValues={setValues}
          setIsHourError={setIsHourError}
          customize={customize}
          setCustomize={setCustomize}
        />

        <ProfileSalonAbout
          setValues={setValues}
          values={values}
          error={errors?.about}
        />

        <UIButton
          className={styles.save}
          handler={handleSave}
          label={t('MANAGER.BUTTON.PUBLIC')}
        />
      </Spin>
    </div>
  )
}

export default ViewProfileSalon
