import { FC } from 'react'

import cn from 'classnames'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const UICustomCard: FC<PropsType> = ({
  title,
  children,
  titleRight,
  className,
}) => {
  return (
    <div className={cn(styles.borderBlock, className)}>
      <div className={styles.wrappTitle}>
        <b>{title}</b>
        {titleRight}
      </div>
      <div>{children}</div>
    </div>
  )
}

export default UICustomCard
