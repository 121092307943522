export const GET_ADMIN_CATEGORIES_REQUEST =
  '@categories/GET_ADMIN_CATEGORIES_REQUEST'
export const GET_ADMIN_CATEGORIES_FAILURE =
  '@categories/GET_ADMIN_CATEGORIES_FAILURE'
export const SET_ADMIN_CATEGORIES = '@categories/SET_ADMIN_CATEGORIES'

export const GET_ADMIN_SUBCATEGORIES_REQUEST =
  '@categories/GET_ADMIN_SUBCATEGORIES_REQUEST'
export const SET_ADMIN_SUBCATEGORIES = '@categories/SET_ADMIN_SUBCATEGORIES'

export const SET_ADMIN_NEW_CATEGORY = '@categories/SET_ADMIN_NEW_CATEGORY'
export const SET_ADMIN_NEW_CATEGORY_REQUEST =
  '@categories/SET_ADMIN_NEW_CATEGORY_REQUEST'
export const SET_ADMIN_NEW_CATEGORY_FAILURE =
  '@categories/SET_ADMIN_NEW_CATEGORY_FAILURE'
export const SET_ADMIN_NEW_SUBCATEGORY = '@categories/SET_ADMIN_NEW_SUBCATEGORY'
export const SET_ADMIN_NEW_SUBCATEGORY_REQUEST =
  '@categories/SET_ADMIN_NEW_SUBCATEGORY_REQUEST'
export const SET_ADMIN_NEW_SUBCATEGORY_FAILURE =
  '@categories/SET_ADMIN_NEW_SUBCATEGORY_FAILURE'

export const SORT_ADMIN_CATEGORIES_REQUEST =
  '@categories/SORT_ADMIN_CATEGORIES_REQUEST'
export const SORT_ADMIN_CATEGORIES_FAILURE =
  '@categories/SORT_ADMIN_CATEGORIES_FAILURE'

export const SORT_ADMIN_SUBCATEGORIES_REQUEST =
  '@categories/SORT_ADMIN_SUBCATEGORIES_REQUEST'
export const SORT_ADMIN_SUBCATEGORIES_FAILURE =
  '@categories/SORT_ADMIN_SUBCATEGORIES_FAILURE'

export const EDIT_ADMIN_CATEGORY_REQUEST =
  '@categories/EDIT_ADMIN_CATEGORY_REQUEST'
export const EDIT_ADMIN_CATEGORY_SUCCESS =
  '@categories/EDIT_ADMIN_CATEGORY_SUCCESS'
export const EDIT_ADMIN_CATEGORY_FAILURE =
  '@categories/EDIT_ADMIN_CATEGORY_FAILURE'

export const ADD_IMAGE_CATEGORY_FAILURE =
  '@categories/ADD_IMAGE_CATEGORY_FAILURE'

export const EDIT_ADMIN_SUBCATEGORY = '@categories/EDIT_ADMIN_SUBCATEGORY'
export const EDIT_ADMIN_SUBCATEGORY_FAILURE =
  '@categories/EDIT_ADMIN_SUBCATEGORY_FAILURE'

export const ADMIN_ACTIVE_CATEGORY = '@categories/ADMIN_ACTIVE_CATEGORY'

export const CLEAR_ERRORS = '@categories/CLEAR_ERRORS'
