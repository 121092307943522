import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  SyntheticEvent,
  useMemo,
} from 'react'

import { useTranslation } from 'react-i18next'

import { UITooltip } from 'ui'
import UITextInput from 'ui/TextInput'
import TooltipUIPassword from 'ui/Tooltip/ui/Password'

import style from './style.module.scss'

type PasswordState = {
  password: string
  password_confirmation: string
  password_old: string
}

type PropsType = {
  errors: { [key: string]: string[] }
  passwordState: PasswordState
  setPasswordState: Dispatch<SetStateAction<PasswordState>>
}

const AdminSettingsPassword: FC<PropsType> = ({
  errors,
  passwordState,
  setPasswordState,
}) => {
  const { t } = useTranslation()

  const handleChange = (event: SyntheticEvent) => {
    const { name, value } = event.target as HTMLInputElement

    setPasswordState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const inputs = useMemo(
    () => [
      { text: t('MANAGER.CURRENT_PASSWORD'), name: 'password_old' },
      { text: t('MANAGER.NEW_PASSWORD'), name: 'password' },
      { text: t('MANAGER.CONFIRM_PASSWORD'), name: 'password_confirmation' },
    ],
    [t],
  )

  return (
    <div className={style.passwordWrapp}>
      {inputs.map(({ name, text }, index) => (
        <UITextInput
          key={index}
          name={name}
          text={text}
          isShowPassword
          type="password"
          placeholder={text}
          className={style.input}
          onChange={handleChange}
          error={errors[name]?.[0]}
          value={passwordState[name as keyof PasswordState]}
          iconLabel={<UITooltip content={<TooltipUIPassword />} />}
        />
      ))}
    </div>
  )
}

export default memo(AdminSettingsPassword)
