import {
  FC,
  memo,
  useState,
  SyntheticEvent,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
} from 'react'

import { Form } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

// import { ReactComponent as CancelRemovingIcon } from 'assets/images/common/cancelRemoving.svg'
import { ReactComponent as EditIcon } from 'assets/images/common/edit.svg'
import { ReactComponent as CloseIcon } from 'assets/images/common/redesign/close.svg'
import { ReactComponent as RemoveIcon } from 'assets/images/common/redesign/delete.svg'
import { ReactComponent as PlusIcon } from 'assets/images/common/redesign/plusCircle.svg'
import { ReactComponent as SuccessIcon } from 'assets/images/common/successIcon.svg'
import { UIButton, UITextArea } from 'ui'
import UITextInput from 'ui/TextInput'
import { VALIDATION_CLASS } from 'utils'
import { AdminSettingsBlockSubscriptionsType } from 'views/admin/Settings/index.type'

import style from './style.module.scss'

type PropsType = {
  errors?: { [field: string]: string[] }
  isRemoved?: boolean
  isTrial?: boolean
  onChange: (value: AdminSettingsBlockSubscriptionsType) => void
  // onRemove?: () => void
} & AdminSettingsBlockSubscriptionsType

// Commented for future (many subs)
const AddSubscriptionBlock: FC<PropsType> = ({
  description,
  details,
  price,
  active,
  name,
  price_type,
  onChange,
  // onRemove,
  errors,
  isTrial = false,
  isRemoved = false,
  id,
}) => {
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState(false)
  const [priceInput, setPriceInput] = useState(String(price) || '')
  const [priceSelect, setPriceSelect] = useState(String(price_type) || '0')
  const [elementListInput, setElementListInput] = useState('')
  const [elementsList, setElementsList] = useState(details || [])
  const [titleInput, setTitleInput] = useState(name || '')
  const [descriptionInput, setDescriptionInput] = useState(description || '')

  const handleEditClick = () => {
    setIsEdit((prevState) => {
      if (prevState) {
        onChange({
          ...(id && { id }),
          ...(isRemoved && { isRemoved }),
          description: descriptionInput,
          details: elementsList,
          price: Number(priceInput),
          price_type: Number(priceSelect),
          name: titleInput,
          active,
        })
      }

      return !prevState
    })
  }

  const handleChangeText =
    (setter: Dispatch<SetStateAction<string>>) => (event: SyntheticEvent) => {
      setter((event.target as HTMLInputElement).value)
    }

  const handleChangePrice = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement

    setPriceInput(Number(value) < 0 ? '0' : value)
  }

  // const handleCheck = (value: boolean) => {
  //   onChange({
  //     description: descriptionInput,
  //     details: elementsList,
  //     price: Number(priceInput),
  //     price_type: Number(priceSelect),
  //     name: titleInput,
  //     active: Number(value),
  //   })
  // }

  const handleCancel = () => {
    setIsEdit(false)
    setPriceInput(String(price) || '')
    setPriceSelect(String(price_type) || '0')
    setElementListInput('')
    setElementsList(details || [])
    setTitleInput(name || '')
    setDescriptionInput(description || '')
  }

  const handleAddElementAtList = () => {
    if (!elementListInput.trim()) return

    setElementsList((prevState) => [...prevState, elementListInput])
    setElementListInput('')
  }

  const handleRemoveFromList = (elementIndex: number) => () => {
    setElementsList((prevState) =>
      prevState.filter((_, filterIndex) => filterIndex !== elementIndex),
    )
  }

  // const handleSelect = (value: string) => {
  //   setPriceSelect(value)
  // }

  // const options = useMemo(
  //   () => [
  //     { label: 'per month', value: '0' },
  //     { label: 'per year', value: '1' },
  //   ],
  //   [],
  // )

  const classes = useMemo(
    () => ({
      subBlock: classNames(style.subBlock, {
        [style.isRemoved]: isRemoved,
        [VALIDATION_CLASS]: errors,
      }),
      cardWrapper: classNames(style.cardWrapper, { [style.isEdit]: isEdit }),
      infoWrapper: classNames(style.infoWrapper, {
        [style.active]: isEdit,
        [style.isError]: !!errors,
        [style.isRemoved]: isRemoved,
      }),
      editBtn: classNames({
        [style.editButton]: !isEdit,
        [style.acceptButton]: isEdit,
      }),
      removeBtn: classNames(style.deleteButton, { [style.isActive]: !active }),
      list: classNames(style.list, { 'mb-0': isTrial }),
    }),
    [active, errors, isEdit, isRemoved, isTrial],
  )

  useEffect(() => {
    setElementsList(details)
  }, [details])

  useEffect(() => {
    setTitleInput(name)
  }, [name])

  useEffect(() => {
    setDescriptionInput(description)
  }, [description])

  return (
    <div className={classes.subBlock}>
      {/* {!isTrial && <UISwitch checked={!!active} onChange={handleCheck} />} */}

      <div className={classes.cardWrapper}>
        <button
          className={classes.editBtn}
          onClick={handleEditClick}
          disabled={isRemoved}
        >
          {isEdit && <SuccessIcon />}
          {!isEdit && <EditIcon />}
        </button>

        {isEdit && (
          <button className={style.closeBtn} onClick={handleCancel}>
            <CloseIcon />
          </button>
        )}

        {/* {!isTrial && !isEdit && (
          <button
            className={classes.removeBtn}
            onClick={onRemove}
            disabled={!!active}
          >
            {!isRemoved && <RemoveIcon />}
            {isRemoved && <CancelRemovingIcon />}
          </button>
        )} */}

        <div className={classes.infoWrapper}>
          {isEdit && (
            <Form>
              <UITextInput
                value={titleInput}
                error={errors?.name}
                onChange={handleChangeText(setTitleInput)}
                className={classNames(style.titleInput, style.input)}
              />

              <UITextArea
                rows={4}
                value={descriptionInput}
                error={errors?.description}
                className={style.descriptionInput}
                onChange={handleChangeText(setDescriptionInput)}
              />
            </Form>
          )}

          {!isEdit && (
            <>
              <h2>{titleInput}</h2>
              <p>{descriptionInput}</p>
            </>
          )}

          <div className={classes.list}>
            {isEdit && (
              <div className={style.listAdd}>
                <UITextInput
                  error={errors?.details}
                  value={elementListInput}
                  onChange={handleChangeText(setElementListInput)}
                  className={classNames(style.listAddInput, style.input)}
                />

                <UIButton
                  handler={handleAddElementAtList}
                  label={t('COMMON.ADD')}
                  icon={<PlusIcon />}
                  type="text"
                />
              </div>
            )}

            <ul>
              {elementsList?.map((item, elementIndex) => (
                <li key={elementIndex}>
                  <SuccessIcon className={style.successIcon} />
                  <p title={item}>{item}</p>

                  {isEdit && (
                    <RemoveIcon
                      className={style.removeIcon}
                      onClick={handleRemoveFromList(elementIndex)}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>

          {!isTrial && (
            <div className={style.price}>
              <UITextInput
                type="number"
                prefix={<b>$</b>}
                value={priceInput}
                onChange={handleChangePrice}
                className={style.priceInput}
                disabled={!isEdit}
              />

              <p className="d-flex align-items-center mb-0">
                {price_type
                  ? t('COMMON.PER_YEAR').toLowerCase()
                  : t('COMMON.PER_MONTH').toLowerCase()}
              </p>

              {/* <UISelect
                options={options}
                value={String(priceSelect)}
                onChange={handleSelect}
                className={style.priceSelect}
                disabled={!isEdit}
              /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(AddSubscriptionBlock)
