import { ResponseFailureType } from 'store/Auth/Auth.type'
import {
  CLEAR_BASKET_ACTION_SUCCESS,
  CLEAR_BASKET_STORE,
  CREATE_BASKET_SERVICE_FAILURE,
  CREATE_BASKET_SERVICE_REQUEST,
  CREATE_BASKET_SERVICE_SUCCESS,
  CREATE_SALON_ORDER_FAILURE,
  CREATE_SALON_ORDER_REQUEST,
  CREATE_SALON_ORDER_SUCCESS,
  DELETE_BASKET_SERVICE_FAILURE,
  DELETE_BASKET_SERVICE_REQUEST,
  DELETE_BASKET_SERVICE_SUCCESS,
  GET_BASKET_CALCULATE_TIME_FAILURE,
  GET_BASKET_CALCULATE_TIME_REQUEST,
  GET_BASKET_CALCULATE_TIME_SUCCESS,
  GET_BASKET_FAILURE,
  GET_BASKET_REQUEST,
  GET_BASKET_SUCCESS,
  GET_BASKET_TIME_FAILURE,
  GET_BASKET_TIME_REQUEST,
  GET_BASKET_TIME_SUCCESS,
  GET_BASKET_TOTAL_FAILURE,
  GET_BASKET_TOTAL_REQUEST,
  GET_BASKET_TOTAL_SUCCESS,
  UPDATE_BASKET_SERVICE_FAILURE,
  UPDATE_BASKET_SERVICE_REQUEST,
  UPDATE_BASKET_SERVICE_SUCCESS,
  SET_IS_TIME_REQUEST,
} from './Basket.constant'
import {
  IBasketResponse,
  IBasketResponseItem,
  IBasketTotal,
  ICreateSalonOrderDataRequest,
  IGetBasketCalculateTimeBodyRequest,
} from './Basket.type'

export const getBasketRequest = () => ({
  type: GET_BASKET_REQUEST,
})

export const getBasketSuccess = (payload: IBasketResponse) => ({
  type: GET_BASKET_SUCCESS,
  payload,
})

export const getBasketFailure = (payload: ResponseFailureType) => ({
  payload,
  type: GET_BASKET_FAILURE,
})

export const getBasketTimeRequest = (query = '') => ({
  type: GET_BASKET_TIME_REQUEST,
  payload: query,
})

export const getBasketTimeSuccess = (payload: { [key: string]: string[] }) => ({
  type: GET_BASKET_TIME_SUCCESS,
  payload,
})

export const getBasketTimeFailure = (payload: ResponseFailureType) => ({
  payload,
  type: GET_BASKET_TIME_FAILURE,
})

export const getBasketCalculateTimeRequest = (
  payload: IGetBasketCalculateTimeBodyRequest,
) => ({
  payload,
  type: GET_BASKET_CALCULATE_TIME_REQUEST,
})

export const getBasketCalculateTimeSuccess = ({
  data,
  isTimeRequest,
}: {
  data: IBasketResponse
  isTimeRequest: boolean
}) => ({
  type: GET_BASKET_CALCULATE_TIME_SUCCESS,
  payload: { data, isTimeRequest },
})

export const getBasketCalculateTimeFailure = (
  payload: ResponseFailureType,
) => ({
  payload,
  type: GET_BASKET_CALCULATE_TIME_FAILURE,
})

export const createBasketServiceRequest = (payload: {
  service_id: number
  staff_id: number
}) => ({
  payload,
  type: CREATE_BASKET_SERVICE_REQUEST,
})

export const createBasketServiceSuccess = (payload: IBasketResponseItem) => ({
  type: CREATE_BASKET_SERVICE_SUCCESS,
  payload,
})

export const createBasketServiceFailure = (payload: ResponseFailureType) => ({
  payload,
  type: CREATE_BASKET_SERVICE_FAILURE,
})

export const updateBasketServiceRequest = (payload: {
  id: number
  service_id: number
  staff_id: number
}) => ({
  payload,
  type: UPDATE_BASKET_SERVICE_REQUEST,
})

export const updateBasketServiceSuccess = (payload: IBasketResponseItem) => ({
  type: UPDATE_BASKET_SERVICE_SUCCESS,
  payload,
})

export const updateBasketServiceFailure = (payload: ResponseFailureType) => ({
  payload,
  type: UPDATE_BASKET_SERVICE_FAILURE,
})

export const deleteBasketServiceRequest = (payload: number) => ({
  payload,
  type: DELETE_BASKET_SERVICE_REQUEST,
})

export const deleteBasketServiceSuccess = () => ({
  type: DELETE_BASKET_SERVICE_SUCCESS,
})

export const deleteBasketServiceFailure = (payload: ResponseFailureType) => ({
  payload,
  type: DELETE_BASKET_SERVICE_FAILURE,
})

export const getBasketTotalRequest = () => ({
  type: GET_BASKET_TOTAL_REQUEST,
})

export const getBasketTotalSuccess = (payload: IBasketTotal) => ({
  type: GET_BASKET_TOTAL_SUCCESS,
  payload,
})

export const getBasketTotalFailure = (payload: ResponseFailureType) => ({
  payload,
  type: GET_BASKET_TOTAL_FAILURE,
})

export const createSalonOrderRequest = (
  payload: ICreateSalonOrderDataRequest,
) => ({
  payload,
  type: CREATE_SALON_ORDER_REQUEST,
})

export const createSalonOrderSuccess = () => ({
  type: CREATE_SALON_ORDER_SUCCESS,
})

export const createSalonOrderFailure = (payload: ResponseFailureType) => ({
  payload,
  type: CREATE_SALON_ORDER_FAILURE,
})

export const clearBasketActionSuccess = () => ({
  type: CLEAR_BASKET_ACTION_SUCCESS,
})

export const clearBasketStore = () => ({
  type: CLEAR_BASKET_STORE,
})

export const setIsTimeRequest = (payload: boolean) => ({
  payload,
  type: SET_IS_TIME_REQUEST,
})
