import { enUS, es } from 'date-fns/locale'

export const getI18nLocale = (language: string) => {
  const dateLocales: { [key: string]: Locale } = { es, en: enUS }

  switch (language) {
    case 'es':
      return dateLocales.es
    case 'en':
      return dateLocales.en

    default:
      return dateLocales.es
  }
}
