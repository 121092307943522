import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_CUSTOMER_ERROR,
  GET_ADMIN_CURRENT_CUSTOMER_REQUEST,
  GET_ADMIN_CURRENT_CUSTOMER_FAILURE,
  GET_ADMIN_CURRENT_CUSTOMER_SUCCESS,
  GET_ADMIN_CUSTOMERS_REQUEST,
  GET_ADMIN_CUSTOMERS_FAILURE,
  GET_ADMIN_CUSTOMERS_SUCCESS,
  SET_ADMIN_CURRENT_CUSTOMER_REQUEST,
  SET_ADMIN_CURRENT_CUSTOMER_FAILURE,
  SET_ADMIN_CURRENT_CUSTOMER_SUCCESS,
} from './Customer.constant'
import {
  IAdminCustomerResponse,
  IAdminCustomerResponseData,
} from './Customer.type'

export const getAdminCustomersRequest = (query: string) => ({
  type: GET_ADMIN_CUSTOMERS_REQUEST,
  payload: query,
})

export const getAdminCustomersSuccess = (body: IAdminCustomerResponse) => ({
  type: GET_ADMIN_CUSTOMERS_SUCCESS,
  payload: body,
})

export const getAdminCustomersFailure = (error: ResponseFailureType) => ({
  type: GET_ADMIN_CUSTOMERS_FAILURE,
  payload: error,
})

export const getAdminCurrentCustomerRequest = (id: number) => ({
  type: GET_ADMIN_CURRENT_CUSTOMER_REQUEST,
  payload: id,
})

export const getAdminCurrentCustomerSuccess = (
  body: IAdminCustomerResponseData,
) => ({
  type: GET_ADMIN_CURRENT_CUSTOMER_SUCCESS,
  payload: body,
})

export const getAdminCurrentCustomerFailure = (error: ResponseFailureType) => ({
  type: GET_ADMIN_CURRENT_CUSTOMER_FAILURE,
  payload: error,
})

export const setAdminCurrentCustomerRequest = (id: number, active: 1 | 0) => ({
  type: SET_ADMIN_CURRENT_CUSTOMER_REQUEST,
  payload: { id, active },
})

export const setAdminCurrentCustomerSuccess = (body: 1 | 0) => ({
  type: SET_ADMIN_CURRENT_CUSTOMER_SUCCESS,
  payload: body,
})

export const setAdminCurrentCustomerFailure = (error: ResponseFailureType) => ({
  type: SET_ADMIN_CURRENT_CUSTOMER_FAILURE,
  payload: error,
})

export const clearCustomerError = () => ({
  type: CLEAR_CUSTOMER_ERROR,
})
