import { useState, SyntheticEvent, useEffect } from 'react'

import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as AvatarIcon } from 'assets/images/common/avatar.svg'
import useDebounce from 'hooks/useDebounce'
import {
  clearCustomerError,
  getAdminCustomersRequest,
} from 'store/Admin/Customer/Customer.action'
import { addAlert } from 'store/Alert'
import { IRootState } from 'store/Root.reducer'
import { UICustomTable } from 'ui'
import UIAdminTableHeader from 'ui/AdminTableHeader'
import { ADMIN_CUSTOMERS, ADMIN_CUSTOMER_PROFILE, QUERY_PARAMS } from 'utils'
import { getFromQueryString, getQueryString } from 'utils/helpers/query'

import style from './style.module.scss'

const ViewAdminCustomer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [block, setBlock] = useState(1)
  const [search, setSearch] = useState('')

  const {
    data,
    errorMessage,
    isLoading,
    meta: { perPage, total },
  } = useSelector((store: IRootState) => store.adminCustomer)
  const debouncedValue = useDebounce(search, 500)

  const handleChange = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement

    setSearch(value)

    if (value.length === 0 || value.length === 1) {
      navigate(
        `${ADMIN_CUSTOMERS}${getQueryString({
          page: 1,
        })}`,
      )
    }
  }

  const handleBlockClick = (value: 0 | 1) => () => {
    setBlock(value)
  }

  const handleDataClick = (id: number) => () => {
    navigate(ADMIN_CUSTOMER_PROFILE.replace(':id', String(id)))
  }

  const columns = [
    { title: t('COMMON.NAME') },
    { title: t('COMMON.EMAIL_ADDRESS') },
    { title: t('COMMON.MOBILE_PHONE') },
  ]

  useEffect(() => {
    const { [QUERY_PARAMS.PAGE]: page } = getFromQueryString(location.search)

    let isSearch = false

    const renderObjToRequest = () => {
      if (search) {
        if (+page > 1) {
          isSearch = true
        }

        return getQueryString({
          [QUERY_PARAMS.ADMIN_ACTIVE_FILTER]: String(block),
          ...(debouncedValue && { [QUERY_PARAMS.SEARCH]: debouncedValue }),
          ...(isSearch && { [QUERY_PARAMS.PAGE]: +page || 1 }),
        })
      }

      isSearch = false

      return getQueryString({
        [QUERY_PARAMS.ADMIN_ACTIVE_FILTER]: String(block),
        ...(debouncedValue && { [QUERY_PARAMS.SEARCH]: debouncedValue }),
        [QUERY_PARAMS.PAGE]: Number(page) || 1,
      })
    }

    dispatch(getAdminCustomersRequest(renderObjToRequest()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block, dispatch, location.search, debouncedValue])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
        }),
      )
    }

    return () => {
      dispatch(clearCustomerError())
    }
  }, [dispatch, errorMessage])

  return (
    <Content className={style.adminCustomerPage}>
      <h1>{t('COMMON.CLIENTS')}</h1>

      <UICustomTable
        columns={columns}
        headerChildren={
          <UIAdminTableHeader
            block={block}
            handleBlockClick={handleBlockClick}
            firstBtnName={t('COMMON.ACTIVE')}
            secondBtnName={t('COMMON.BLOCKED')}
            handleSearchChange={handleChange}
            searchValue={search}
          />
        }
        isLoading={isLoading}
        perPage={perPage}
        total={total}
        className={style.table}
      >
        {data.map(({ email, id, name, profile: { avatar, phone } }) => (
          <tr key={id} onClick={handleDataClick(Number(id))}>
            <td>
              {avatar?.path && (
                <img src={avatar?.path} alt={t('COMMON.AVATAR')} />
              )}
              {!avatar?.path && <AvatarIcon />}

              {name || '-'}
            </td>
            <td>{email || '-'}</td>
            <td>{phone || '-'}</td>
          </tr>
        ))}
      </UICustomTable>
    </Content>
  )
}

export default ViewAdminCustomer
