import { basketWatchers } from './Manager/Basket/Basket.saga'
import { all } from 'redux-saga/effects'

import { adminCategoriesWatchers } from './Admin/Categories/Categories.saga'
import { adminCustomerWatchers } from './Admin/Customer/Customer.saga'
import { adminFaqWatchers } from './Admin/Faq/Faq.saga'
import { adminNotificationsWatchers } from './Admin/Notifications/Notifications.saga'
import { adminPagesWatchers } from './Admin/Pages/Pages.saga'
import { adminSalonWatchers } from './Admin/Salons/Salons.saga'
import { adminSeoWatchers } from './Admin/Seo/Seo.saga'
import { adminSettingWatchers } from './Admin/Settings/Settings.saga'
import { authWatchers } from './Auth/Auth.saga'
import { appointmentsWatchers } from './Manager/Appointments/Appointments.saga'
import { ManagerClientsWatchers } from './Manager/Clients/Clients.saga'
import { documentWatchers } from './Manager/Documents/Documents.saga'
import { feedbackWatchers } from './Manager/Feedbacks/Feedbacks.saga'
import { notificationWatchers } from './Manager/Notifications/Notifications.saga'
import { orderHistoryWatchers } from './Manager/Orders/Orders.saga'
import { salonWatchers } from './Manager/Salon/Salon.saga'
import { servicesWatchers } from './Manager/Services'
import { settingsWatchers } from './Manager/Settings/Settings.saga'
import { shiftWatchers } from './Manager/Shift'
import { staffWatchers } from './Manager/Staff/Staff.saga'
import { managerSubscriptionWatchers } from './Manager/Subscription/Subscription.saga'
import { managerSupportWatchers } from './Manager/Support/Support.saga'
import { mediaWatchers } from './Media/Media.saga'
import { sortWatchers } from './Sort/Sort.saga'

export function* rootSagas() {
  yield all([
    ...authWatchers,
    ...adminCategoriesWatchers,
    ...mediaWatchers,
    ...servicesWatchers,
    ...salonWatchers,
    ...sortWatchers,
    ...staffWatchers,
    ...shiftWatchers,
    ...appointmentsWatchers,
    ...feedbackWatchers,
    ...adminPagesWatchers,
    ...adminSettingWatchers,
    ...adminFaqWatchers,
    ...adminCustomerWatchers,
    ...adminSalonWatchers,
    ...adminNotificationsWatchers,
    ...documentWatchers,
    ...settingsWatchers,
    ...orderHistoryWatchers,
    ...ManagerClientsWatchers,
    ...managerSupportWatchers,
    ...managerSubscriptionWatchers,
    ...adminSeoWatchers,
    ...notificationWatchers,
    ...basketWatchers,
  ])
}
