import { FC, useMemo } from 'react'

import useResponsive from 'hooks/useResponsive'
import {
  FORMAT_DATE_WITH_YEAR,
  FORMAT_DATE_CUSTOM,
  FORMAT_DATE_MONTH_WITH_YEAR,
  FORMAT_DATE_MIN_MONTH_WITH_YEAR,
  FORMAT_DATE_MONTH_DAY,
} from 'utils'
import { DateHelper } from 'utils/helpers'

import styles from '../style.module.scss'

type PropsType = {
  date: Date
  type: 'week' | 'day' | 'month'
}

const UICalendarHeaderDateTitle: FC<PropsType> = ({ date, type }) => {
  const { isTablet, isMobile, isIpad } = useResponsive()

  const dateFormat =
    (isMobile && FORMAT_DATE_MONTH_DAY) ||
    (isTablet || isIpad ? FORMAT_DATE_CUSTOM : FORMAT_DATE_WITH_YEAR)

  const renderTitle = useMemo(() => {
    switch (type) {
      case 'week':
        return (
          <span>
            {DateHelper.toFormat(DateHelper.startOfWeek(date), dateFormat)}
            <br />
            {DateHelper.toFormat(DateHelper.endOfWeek(date), dateFormat)}
          </span>
        )
      case 'month':
        return (
          <>
            {DateHelper.toFormat(
              DateHelper.startMonth(date),
              isTablet || isMobile
                ? FORMAT_DATE_MIN_MONTH_WITH_YEAR
                : FORMAT_DATE_MONTH_WITH_YEAR,
            )}
          </>
        )
      default:
        return <>{DateHelper.toFormat(date, dateFormat)}</>
    }
  }, [dateFormat, isMobile, isTablet, date, type])

  return (
    <div className={styles.dateTitle}>
      <span className={styles.weekTitle}>{renderTitle}</span>
    </div>
  )
}

export default UICalendarHeaderDateTitle
