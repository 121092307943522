import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import LayoutManagerSidebar from 'layouts/Manager/Sidebar'
import ManagerSetting from 'modules/manager/setting'
import { addAlert } from 'store/Alert'
import {
  getSettingsRequest,
  resetError,
} from 'store/Manager/Settings/Settings.action'
import { IRootState } from 'store/Root.reducer'

import styles from './style.module.scss'
import { getProfileRequest } from 'store/Manager/Salon'

const ViewManagerSetting = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { errorMessage } = useSelector((state: IRootState) => state.settings)

  useEffect(() => {
    dispatch(getSettingsRequest())
    dispatch(getProfileRequest())
  }, [dispatch])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(resetError())
    }
  }, [errorMessage, dispatch])

  return (
    <LayoutManagerSidebar
      titleHeader={t('MANAGER.SETTING.SETTING')}
      className={'bg-white p-0'}
      isMobileContentVisible
    >
      <div className={styles.wrapper}>
        <ManagerSetting />
      </div>
    </LayoutManagerSidebar>
  )
}

export default ViewManagerSetting
