import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  ADD_ADMIN_FAQ_REQUEST,
  ADD_ADMIN_FAQ_FAILURE,
  ADD_ADMIN_FAQ_SUCCESS,
  CLEAR_ADMIN_FAQ_ERRORS,
  DELETE_ADMIN_FAQ_REQUEST,
  DELETE_ADMIN_FAQ_FAILURE,
  DELETE_ADMIN_FAQ_SUCCESS,
  EDIT_ADMIN_FAQ_REQUEST,
  EDIT_ADMIN_FAQ_FAILURE,
  EDIT_ADMIN_FAQ_SUCCESS,
  GET_ADMIN_CURRENT_FAQ_REQUEST,
  GET_ADMIN_CURRENT_FAQ_FAILURE,
  GET_ADMIN_CURRENT_FAQ_SUCCESS,
  GET_ADMIN_FAQ_REQUEST,
  GET_ADMIN_FAQ_FAILURE,
  GET_ADMIN_FAQ_SUCCESS,
} from './Faq.constant'
import {
  AdminFaqActionsTypes,
  AdminFaqState,
  IAdminFaqResponseData,
} from './Faq.type'

const initialState: AdminFaqState = {
  errors: {},
  errorMessage: null,
  isLoading: false,
  isSaved: false,
  data: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  currentData: {} as IAdminFaqResponseData,
}

export default function adminFaqReducer(
  state = initialState,
  action: AdminFaqActionsTypes,
): AdminFaqState {
  switch (action.type) {
    case GET_ADMIN_FAQ_REQUEST:
    case GET_ADMIN_CURRENT_FAQ_REQUEST:
    case ADD_ADMIN_FAQ_REQUEST:
    case EDIT_ADMIN_FAQ_REQUEST:
    case DELETE_ADMIN_FAQ_REQUEST:
      return { ...state, isLoading: true, isSaved: false }
    case GET_ADMIN_FAQ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload?.data,
        meta: {
          page: action.payload?.meta?.current_page,
          perPage: action.payload?.meta?.per_page,
          total: action.payload?.meta?.total,
        },
      }
    case GET_ADMIN_CURRENT_FAQ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentData: action.payload,
      }
    case ADD_ADMIN_FAQ_SUCCESS:
    case EDIT_ADMIN_FAQ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSaved: true,
      }
    case DELETE_ADMIN_FAQ_SUCCESS:
      return { ...state, isLoading: false, isSaved: true }
    case GET_ADMIN_FAQ_FAILURE:
    case GET_ADMIN_CURRENT_FAQ_FAILURE:
    case ADD_ADMIN_FAQ_FAILURE:
    case EDIT_ADMIN_FAQ_FAILURE:
    case DELETE_ADMIN_FAQ_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case CLEAR_ADMIN_FAQ_ERRORS:
      return {
        ...state,
        errors: {},
        errorMessage: '',
        isSaved: false,
        currentData: {} as IAdminFaqResponseData,
      }
    default:
      return { ...state }
  }
}
