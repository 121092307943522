export const SET_MOBILE_TITLE_OPEN = '@orders/SET_MOBILE_TITLE_OPEN'

export const GET_ORDER_HISTORY_REQUEST = '@orders/GET_ORDER_HISTORY_REQUEST'
export const GET_ORDER_HISTORY_SUCCESS = '@orders/GET_ORDER_HISTORY_SUCCESS'
export const GET_ORDER_HISTORY_FAILURE = '@orders/GET_ORDER_HISTORY_FAILURE'

export const GET_CANCELLED_ORDER_HISTORY_REQUEST =
  '@orders/GET_CANCELLED_ORDER_HISTORY_REQUEST'
export const GET_CANCELLED_ORDER_HISTORY_SUCCESS =
  '@orders/GET_CANCELLED_ORDER_HISTORY_SUCCESS'
export const GET_CANCELLED_ORDER_HISTORY_FAILURE =
  '@orders/GET_CANCELLED_ORDER_HISTORY_FAILURE'

export const CLEAR_ORDERS_ERROR = '@orders/CLEAR_ORDERS_ERROR'
