import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import AdminCeoTableRow from 'modules/admin/Seo/TableRow'
import { UIButton, UICustomTable } from 'ui'
import { ADMIN_SEO } from 'utils'

import style from './style.module.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoriesRequest } from 'store/Admin'
import { IRootState } from 'store/Root.reducer'

const ViewAdminSeo = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { categories } = useSelector(
    (state: IRootState) => state.adminCategories,
  )

  const [openId, setOpenId] = useState(0)

  const handleClick = () => {
    navigate(`${ADMIN_SEO}/common`)
  }

  const columns = [
    { title: t('COMMON.NAME'), className: style['th-name'] },
    { title: t('COMMON.ACTIVITY') },
    { title: t('COMMON.URL'), className: 'text-uppercase' },
  ]

  useEffect(() => {
    dispatch(getCategoriesRequest())
  }, [dispatch])

  return (
    <Content className={style['seo-page']}>
      <div className={style['seo-header']}>
        <h1>{t('ADMIN.SEO.TITLE')}</h1>

        <UIButton
          label={t('ADMIN.SEO.COMMON_TEMPLATE')}
          handler={handleClick}
        />
      </div>

      <UICustomTable
        columns={columns}
        className={style['seo-page_table']}
        isLoading={false}
      >
        {categories.map((row) => (
          <AdminCeoTableRow
            key={row.id}
            {...row}
            setOpenId={setOpenId}
            openId={openId}
            url={row?.url}
          />
        ))}
      </UICustomTable>
    </Content>
  )
}

export default ViewAdminSeo
