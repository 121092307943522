export const GET_MANAGER_NOTIFICATIONS_REQUEST =
  '@notifications/GET_MANAGER_NOTIFICATIONS_REQUEST'
export const GET_MANAGER_NOTIFICATIONS_SUCCESS =
  '@notifications/GET_MANAGER_NOTIFICATIONS_SUCCESS'
export const GET_MANAGER_NOTIFICATIONS_FAILURE =
  '@notifications/GET_MANAGER_NOTIFICATIONS_FAILURE'

export const SET_MANAGER_NOTIFICATIONS =
  '@notifications/SET_MANAGER_NOTIFICATIONS'

export const CLEAR_MANAGER_NOTIFICATION =
  '@notifications/CLEAR_MANAGER_NOTIFICATION'

export const CLEAR_MANAGER_NOTIFICATION_ERROR =
  '@notifications/CLEAR_MANAGER_NOTIFICATION_ERROR'
