import { POST } from 'utils/http'

export const personalSort = (idx: number[], name: string) =>
  POST(`/personal/${name}/sort`, { ids: idx })

export const sort = (idx: number[], name: string) =>
  POST(`/${name}/sort`, { ids: idx })

export const sortService = (
  ids: number[],
  name: string,
  staffId: number,
  sectionId?: number,
) =>
  POST(`${name}`, {
    ids,
    staff_id: staffId,
    ...(sectionId && { section_id: sectionId }),
  })
