export const GET_PLAN_SUBSCRIPTIONS_REQUEST =
  '@settings/GET_PLAN_SUBSCRIPTIONS_REQUEST'
export const GET_PLAN_SUBSCRIPTIONS_SUCCESS =
  '@settings/GET_PLAN_SUBSCRIPTIONS_SUCCESS'
export const GET_PLAN_SUBSCRIPTIONS_FAILURE =
  '@settings/GET_PLAN_SUBSCRIPTIONS_FAILURE'

export const UPDATE_SETTINGS_REQUEST = '@settings/UPDATE_SETTINGS_REQUEST'
export const UPDATE_SETTINGS_SUCCESS = '@settings/UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_FAILURE = '@settings/UPDATE_SETTINGS_FAILURE'

export const CLEAR_ERRORS = '@settings/CLEAR_ERRORS'
