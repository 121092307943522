import { useState } from 'react'

import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'assets/images/common/arrowLeft.svg'
import { AdminBlogTabs } from 'modules/admin/Blog'
import { UIButton, UISelect } from 'ui'
import UITextInput from 'ui/TextInput'
import { ADMIN_BLOG } from 'utils'

import { INITIAL_VALUES, tabs } from './constant'
import { ValuesType } from './index.type'
import styles from './style.module.scss'

const ViewAdminBlogArticle = () => {
  const [values, setValues] = useState<ValuesType>(INITIAL_VALUES)

  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Content className={styles.adminSalonProfilePage}>
      <section className={styles.header}>
        <ArrowIcon
          className={styles.back}
          onClick={() => navigate(ADMIN_BLOG)}
        />

        <h1>{t('ADMIN.BLOG.ADD_NEW_ARTICLE')}</h1>

        <UIButton type="text" />
      </section>
      <div className={styles.inputWrapp}>
        <div>
          <UITextInput
            text={t('COMMON.NAME')}
            placeholder={t('COMMON.NAME')}
            necessaryLabel={`(${t('COMMON.NECESSARY')})`}
            onChange={(e) =>
              setValues((prevState) => ({ ...prevState, name: e.target.value }))
            }
            value={values.name}
          />
        </div>
        <UISelect
          label={t('ADMIN.BLOG.CATEGORY')}
          necessaryLabel={` (${t('COMMON.NECESSARY')})`}
          placeholder={t('ADMIN.BLOG.CATEGORY')}
        />
        <div>
          <UITextInput
            text={t('ADMIN.BLOG.CNC')}
            placeholder={t('ADMIN.BLOG.CNC')}
            necessaryLabel={`(${t('COMMON.NECESSARY')})`}
            onChange={(e) => setValues({ ...values, cnc: e.target.value })}
            value={values.cnc}
          />
        </div>
      </div>
      <AdminBlogTabs tabs={tabs(values, setValues)} />
      <UIButton className={styles.btn} label={t('COMMON.SAVE')} />
    </Content>
  )
}

export default ViewAdminBlogArticle
