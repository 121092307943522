// CLIENT
export const CLIENT_HOME = '/'
export const NOT_FOUND = '/404'

// MANAGER
export const MANAGER_HOME = '/manager/home'
export const MANAGER_LOGIN = '/manager/home#login'
export const MANAGER_PROFILE_SALON = '/profile-settings/profile-salon'
export const MANAGER_SERVICES = '/profile-settings/services'
export const MANAGER_SERVICES_EDIT = '/profile-settings/services/edit/:id'
export const MANAGER_SERVICES_ADD = '/profile-settings/services/add'
export const MANAGER_STAFF = '/profile-settings/staff'
export const MANAGER_STAFF_SERVICES = '/profile-settings/staff/services/:id'
export const MANAGER_STAFF_ADD = '/profile-settings/staff/add'
export const MANAGER_STAFF_EDIT = '/profile-settings/staff/edit/:id'
export const MANAGER_APPOINTMENTS = '/profile-settings/appointments'
export const MANAGER_CLIENTS = '/profile-settings/clients'
export const MANAGER_SETTING = '/profile-settings/setting'
export const MANAGER_SUPPORT = '/profile-settings/support'
export const MANAGER_FEEDBACKS = '/profile-settings/feedbacks'
export const MANAGER_SUBSCRIPTION = '/profile-settings/subscription'
export const MANAGER_ORDERS = '/profile-settings/orders'
export const MANAGER_DOCUMENT = '/profile-settings/document'
export const MANAGER_REPORTS = '/profile-settings/reports'
export const MANAGER_SHIFTS = '/profile-settings/shifts'

// ADMIN
export const ADMIN_CATEGORIES = '/admin/categories'
export const ADMIN_CUSTOMERS = '/admin/customers'
export const ADMIN_CUSTOMER_PROFILE = '/admin/customer/profile/:id'
export const ADMIN_SALONS = '/admin/salons'
export const ADMIN_SALONS_PROFILE = '/admin/salon/profile/:id'
export const ADMIN_PAGES = '/admin/pages'
export const ADMIN_PAGES_EDIT = '/admin/pages/:id/:type'
export const ADMIN_SETTINGS = '/admin/settings'
export const ADMIN_HELP_FOR_BUSINESS = '/admin/help-for-business'
export const ADMIN_HELP_FOR_BUSINESS_ANSWER_PAGE =
  '/admin/help-for-business/:id'
export const ADMIN_HELP_FOR_CUSTOMER = '/admin/help-for-customer'
export const ADMIN_HELP_FOR_CUSTOMER_ANSWER_PAGE =
  '/admin/help-for-customer/:id'
export const ADMIN_SEO = '/admin/seo'
export const ADMIN_SEO_EDIT_CATEGORY = '/admin/seo/:category'
export const ADMIN_SEO_EDIT_SUBCATEGORY = '/admin/seo/:category/:subcategory'
export const ADMIN_NOTIFICATION = '/admin/notification'
export const ADMIN_BLOG = '/admin/blog'
export const ADMIN_BLOG_ADD = '/admin/blog/add'
export const ADMIN_BLOG_EDIT = '/admin/blog/:id'
export const ADMIN_BLOG_ADD_CATEGORY = '/admin/blog/add/category'
export const ADMIN_BLOG_EDIT_CATEGORY = '/admin/blog/category/:id'

export const CLIENT_LINK = process.env.REACT_APP_CLIENT_LINK as string
export const ADMIN_LINK = process.env.REACT_APP_ADMIN_LINK as string
export const MANAGER_LINK = process.env.REACT_APP_MANAGER_LINK as string
