import { FC, memo } from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as ReplyIcon } from 'assets/images/common/redesign/reply.svg'
import { IFeedbacksResponseData } from 'store/Manager/Feedbacks/Feedbakcs.type'
import { UIButton, UIRating, UITag } from 'ui'
import { FORMAT_DATE_WITH_YEAR } from 'utils'

import style from './style.module.scss'
import { DateHelper } from 'utils/helpers'
import classNames from 'classnames'

type PropsType = Omit<IFeedbacksResponseData, 'comment' | 'id'> & {
  message: string
  onReply: () => void
}

const FeedbackTabletRows: FC<PropsType> = ({
  answer,
  message,
  onReply,
  rating,
  created_at,
  user,
  is_new,
}) => {
  const { t } = useTranslation()

  return (
    <div className={style.tabletRow} onClick={onReply}>
      <div className={classNames(style.rowWrapper, style.rowDateWrapper)}>
        <div className={style.dateWrapper}>
          <span className={style.label}>{t('COMMON.DATE')}</span>
          <time className={style.item}>
            {DateHelper.toFormat(new Date(created_at), FORMAT_DATE_WITH_YEAR)}
          </time>
        </div>
        <div className={style.new}>{is_new && <UITag />}</div>
      </div>

      <div className={style.rowWrapper}>
        <span className={style.label}>{t('COMMON.CLIENTS')}</span>
        <p className={style.item}>{user?.name || ''}</p>
      </div>

      <div className={style.rowWrapper}>
        <span className={style.label}>{t('COMMON.RATING')}</span>
        <UIRating rating={rating} />
      </div>

      <div className={style.rowWrapper}>
        <span className={style.label}>{t('COMMON.MESSAGE')}</span>
        <p className={style.item}>{message}</p>
      </div>

      <div className={style.rowWrapper}>
        <span className={style.label}>{t('COMMON.ANSWER')}</span>
        {!!answer && (
          <>
            <time className={style.item}>
              {DateHelper.toFormat(
                new Date(answer?.created_at),
                FORMAT_DATE_WITH_YEAR,
              )}
            </time>
            <p className={style.item}>{answer?.comment}</p>
          </>
        )}

        {!answer && (
          <div className={style.answer}>
            <p className={style.item}>{t('COMMON.NO_ANSWER')}</p>

            <UIButton
              handler={() => {}}
              label={t('COMMON.REPLY')}
              icon={<ReplyIcon />}
              type="text"
              isGreen
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(FeedbackTabletRows)
