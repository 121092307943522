export enum ModalType {
  ADMIN_DEACTIVATE_CATEGORY = 'ADMIN_DEACTIVATE_CATEGORY',
  MANAGER_ADD_SERVICE = 'MANAGER_ADD_SERVICE',
  MANAGER_ADD_TIME_OFF = 'MANAGER_ADD_TIME_OFF',
  MANAGER_COPY_SHIFT = 'MANAGER_COPY_SHIFT',
  MANAGER_DELETE_SERVICE = 'MANAGER_DELETE_SERVICE',
  MANAGER_DELETE_STAFF = 'MANAGER_DELETE_STAFF',
  MANAGER_EDIT_SHIFT = 'MANAGER_EDIT_SHIFT',
  MANAGER_IMAGE_EDITOR = 'MANAGER_IMAGE_EDITOR',
  MANAGER_STAFF_EDIT_HOUR = 'MANAGER_STAFF_EDIT_HOUR',
  NOTIFY = 'NOTIFY',
  NOTIFY_CLIENTS = 'NOTIFY_CLIENTS',
}
