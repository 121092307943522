import { FC, memo } from 'react'

import { ReactComponent as ClientSVG } from 'assets/images/common/redesign/client.svg'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as SearchSVG } from 'assets/images/common/redesign/search.svg'
import { UIButton } from 'ui'
import UITextInput from 'ui/TextInput'
import { MANAGER_CLIENTS, QUERY_PARAMS } from 'utils'
import { getFromQueryString, getQueryString } from 'utils/helpers/query'
import { Mode } from 'views/manager/Client/index.type'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const ClientHeader: FC<PropsType> = ({
  activeMode,
  search,
  onChange,
  setIsDrawer,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const renderTitle = () => {
    switch (activeMode) {
      case Mode.CLIENT:
        return t('MANAGER.CLIENTS')
      case Mode.USERS:
        return t('MANAGER.CLIENTS.USERS')
      default:
        return t('MANAGER.CLIENTS')
    }
  }

  const handleChange = (mode: Mode) => () => {
    const { [QUERY_PARAMS.PAGE]: page, ...queries } = getFromQueryString(
      location.search,
    )

    const query = getQueryString({
      ...queries,
      tab: mode,
      [QUERY_PARAMS.PAGE]: 1,
    })

    navigate(MANAGER_CLIENTS + query)
  }

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        {t('MANAGER.CLIENTS.LIST')} <span>{renderTitle()}</span>
      </p>

      <UITextInput
        isSearch
        value={search}
        onChange={onChange}
        icon={<SearchSVG />}
        className={styles.search}
        placeholder={t('MANAGER.ORDERS.SEARCH')}
      />

      <div className={styles.btn}>
        <UIButton
          type={activeMode === Mode.CLIENT ? 'primary' : 'outline'}
          label={t('COMMON.CLIENTS')}
          handler={handleChange(Mode.CLIENT)}
        />
        <UIButton
          type={activeMode === Mode.USERS ? 'primary' : 'outline'}
          label={t('MANAGER.CLIENTS.USERS')}
          handler={handleChange(Mode.USERS)}
        />
        <UIButton
          type="text"
          icon={<ClientSVG />}
          label={t('MANAGER.CLIENTS.BASE')}
          className={styles.clientBtn}
          handler={() => setIsDrawer(true)}
        />
      </div>
    </div>
  )
}

export default memo(ClientHeader)
