import { FC } from 'react'

import { RightOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import { ReactComponent as MoveSVG } from 'assets/images/common/move.svg'
import { ReactComponent as NotifySVG } from 'assets/images/common/notify.svg'
import { MANAGER_STAFF } from 'utils'

import styles from './style.module.scss'

type PropsType = {
  color?: string
  index?: number
  isActive?: boolean
  isWithoutCategory?: boolean
  name: string
  onClick?: () => void
}
const CategoryCard: FC<PropsType> = ({
  name,
  isActive,
  isWithoutCategory,
  onClick,
  color,
}) => {
  const { pathname } = useLocation()

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.activeBorder]: isActive,
        [styles.border]: !isActive,
        [styles.wrapperStaff]: pathname === MANAGER_STAFF,
      })}
      onClick={onClick}
    >
      <div className={styles.icon}>
        {isWithoutCategory ? <NotifySVG /> : <MoveSVG />}
      </div>
      <div className={styles.block}>
        {color && (
          <div className={styles.color} style={{ backgroundColor: color }} />
        )}
        <div className={styles.wrapperName}>
          {isActive ? name : <div className={styles.name}>{name}</div>}
        </div>
      </div>
      <RightOutlined />
    </div>
  )
}

export default CategoryCard
