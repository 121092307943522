import { FC, memo, useState, SyntheticEvent } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  editFeedbacksRequest,
  setFeedbacksRequest,
} from 'store/Manager/Feedbacks/Feedbacks.action'
import { UIButton, UICustomModal, UITextArea } from 'ui'

import style from './style.module.scss'

type PropsType = {
  errors: { [key: string]: string[] }
  isModalLoading: boolean
  onClose: () => void
  state: {
    [key: string]: string | number | undefined
  }
}

const FeedbackModal: FC<PropsType> = ({
  onClose,
  state,
  isModalLoading,
  errors,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [value, setValue] = useState<string>((state?.comment as string) || '')

  const handleChange = (event: SyntheticEvent) => {
    setValue((event.target as HTMLInputElement).value)
  }

  const handleSave = () => {
    const { id, answerId } = state

    dispatch(
      answerId
        ? editFeedbacksRequest(Number(id), Number(answerId), value)
        : setFeedbacksRequest(Number(id), value),
    )
  }

  return (
    <UICustomModal
      title={t('COMMON.ANSWER')}
      handlerClose={onClose}
      className={style.feedbackModal}
      footer={
        <UIButton
          className={style.button}
          label={t('COMMON.REPLY')}
          handler={handleSave}
          disabled={isModalLoading}
        />
      }
    >
      <div className={style.modalBody}>
        <p className={style.modalQuestion}>{state.message}</p>

        <UITextArea
          onChange={handleChange}
          rows={8}
          value={value}
          maxLength={200}
          showCount={true}
          placeholder={t('COMMON.ADD_ANSWER')}
          disabled={isModalLoading}
          error={errors.comment}
        />
      </div>
    </UICustomModal>
  )
}

export default memo(FeedbackModal)
