/* eslint-disable guard-for-in */
export function getCookie(name: string) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\\.$?*|{}()[]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ),
  )

  return matches ? decodeURIComponent(matches[1]) : undefined
}

type CookieOptions = {
  domain?: string
  expires?: Date | number | string
  path?: string
  samesite?: boolean
  secure?: boolean
}

export function setCookie(
  name: string,
  value: string,
  options: CookieOptions = {},
) {
  const newOptions = {
    path: '/',
    ...options,
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  for (const optionKey in newOptions) {
    updatedCookie += `; ${optionKey}`
    const optionValue = newOptions[optionKey as keyof CookieOptions]

    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`
    }
  }

  document.cookie = updatedCookie
}

export function deleteCookie(name: string, options: CookieOptions = {}) {
  setCookie(name, '', {
    expires: -1,
    ...options,
  })
}
