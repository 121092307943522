import { StaffTimeType } from 'entities/manager/staff'
import { RequestEditShiftType } from 'modules/manager/staff/Shifts/index.type'
import { GET, POST } from 'utils/http'
import { ValuesTimeOfType } from 'widgets/Modal/AddTimeOff/index.type'
import { RequestCopyType } from 'widgets/Modal/CopyShift/index.type'

const basePath = '/schedule'

export const getSchedule = (query?: string) =>
  GET(query ? `${basePath}?${query}` : `${basePath}`)

export const getOneSchedule = (id: number) => GET(`${basePath}/${id}`)

export const updateSchedule = (body: StaffTimeType, id: number) =>
  POST(`${basePath}/${id}`, body)

export const updateShift = (payload: RequestEditShiftType) =>
  POST('/shift', payload)

export const addTimeShift = (payload: ValuesTimeOfType) =>
  POST('/shift/add-break', payload)

export const copyShift = (payload: RequestCopyType) =>
  POST('/shift/copy', payload)
