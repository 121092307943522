import { FC, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import notFoundImage from 'assets/images/common/look.jpg'
import { redirectFromType } from 'entities/auth'
import { IUserInfo } from 'entities/unauthorized/Auth'
import { UIButton } from 'ui'
import { USER } from 'utils'
import { getCookie } from 'utils/helpers/cookie'

import styles from './style.module.scss'

const ViewNotFound: FC = () => {
  const { t } = useTranslation()

  const handleClick = () => {
    const user = JSON.parse(getCookie(USER) || 'null')

    if (user) {
      redirectFromType((user as IUserInfo)?.type)
    }
  }

  useEffect(() => {
    document.title = t('NOT_FOUND_PAGE.HEAD_TITLE')
  }, [t])

  return (
    <main className={styles['not-found-page']}>
      <div className={styles['not-found-page_wrapper']}>
        <img draggable={false} src={notFoundImage} alt={t('COMMON.404')} />

        <div className={styles['not-found-page_desc']}>
          <div className={styles['not-found-page_title-wrapper']}>
            <h2>{t('NOT_FOUND_PAGE.TITLE_1')}</h2>
            <h2>{t('NOT_FOUND_PAGE.TITLE_2')}</h2>
          </div>

          <p>{t('NOT_FOUND_PAGE.TEXT')}</p>

          <UIButton
            handler={handleClick}
            type="primary"
            label={t('COMMON.TO_MAIN_PAGE')}
          />
        </div>
      </div>
    </main>
  )
}

export default ViewNotFound
