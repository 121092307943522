import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'

/* eslint-disable */ // disable for order of imports
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import FullCalendar, {
  DatesSetArg,
  EventClickArg,
  EventSourceInput,
  FormatterInput,
} from '@fullcalendar/react'
import { ResourceSourceInput } from '@fullcalendar/resource-common'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import timeGridPlugin from '@fullcalendar/timegrid'
import timelinePlugin from '@fullcalendar/timeline'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'antd/dist/antd.css'

import { useDispatch, useSelector } from 'react-redux'

import { ShiftType } from 'entities/manager/shift'
import {
  getScheduleRequest,
  resetStore,
} from 'store/Manager/Shift/Shift.action'
import { IRootState } from 'store/Root.reducer'
import { FORMAT_DATE } from 'utils'
import { DateHelper } from 'utils/helpers'

import { EventsType, PropsType, ExtendedPropsType } from './index.type'
import styles from './style.module.scss'

import { getStaffCalendarWeek } from 'entities/manager/shift/getStaffCalendarWeek'
import { getStaffCalendarStartTime } from 'entities/manager/shift/getStaffCalendarStartTime'
import StaffCalendarEvent from './Event'
import StaffCalendarPopover from './Popover'
import esLocale from '@fullcalendar/core/locales/es'
import { addDays } from 'date-fns'

const use24HoursFormat: FormatterInput = {
  hour: 'numeric',
  minute: '2-digit',
  hour12: false,
}

const StaffCalendar: FC<PropsType> = ({
  openEditShift,
  openAddTimeOff,
  openEditWorkingHours,
  setStaffId,
  calendarRef,
}) => {
  const { shifts, isShiftAction } = useSelector(
    (state: IRootState) => state.shift,
  )

  const [calendarDate, setCalendarDate] = useState<string>('')
  const [dateQueryDate, setDateQuery] = useState<string>('')
  const dispatch = useDispatch()

  const resourceData: Omit<ShiftType, 'schedule'>[] = useMemo(
    () =>
      shifts.map((item) => {
        const { schedule, ...val } = item

        return val
      }),
    [shifts],
  )

  const TURQUOISE_COLOR = '#dbf6f6'

  const events: EventsType[] = useMemo(() => {
    let newEvents: EventsType[] = []

    shifts.forEach((shift) => {
      newEvents = [
        ...getStaffCalendarWeek(shift, calendarDate),
        ...newEvents,
        ...shift.schedule.map((day) => ({
          title: day.work.label,
          start: `${day.date}T${day.work.from}`,
          end: `${day.date}T${day.work.to}`,
          resourceId: shift.id,
          className: styles.event,
          backgroundColor: TURQUOISE_COLOR,
          extendedProps: {
            shiftId: shift.id,
            work: day.work,
            date: day.date,
            staffName: shift.name,
            week: shift.duration.week.work,
            month: shift.duration.month.work,
            duration: {
              break: day.duration.break,
              work: day.duration.work,
            },
            break: [
              ...day.break.map((time) => ({
                ...time,
                date: day.date,
                work: day.work,
              })),
            ],
          },
        })),
      ]
    })

    return newEvents
  }, [shifts, calendarDate])

  const handleEventClick = useCallback((e: EventClickArg) => {
    const valueEvent = e?.event?.extendedProps as ExtendedPropsType

    if (openEditShift) {
      openEditShift({
        ...valueEvent,
        break: valueEvent?.break?.filter((item) => item?.from !== null) || [],
      })
    }
  }, [])

  const handleArrowClick = useCallback(
    (e: DatesSetArg) => {
      let query = `from=${DateHelper.toFormat(e?.start, FORMAT_DATE)}&`

      const endDate = addDays(new Date(e?.end), -1)

      if (e?.view.type === 'resourceTimelineDay') {
        query = `${query}to=${DateHelper.toFormat(e?.start, FORMAT_DATE)}`
      } else if (e?.view.type === 'customWeek') {
        query = `${query}to=${DateHelper.toFormat(endDate, FORMAT_DATE)}`
      }

      if (e.startStr !== calendarDate) {
        dispatch(getScheduleRequest(query))
        setDateQuery(query)
        setCalendarDate(e.startStr)
      }
    },
    [calendarDate],
  )

  useEffect(() => {
    if (isShiftAction) {
      dispatch(getScheduleRequest(dateQueryDate))
      dispatch(resetStore())
    }
  }, [dispatch, isShiftAction, dateQueryDate])

  return (
    <div className={styles.calendar}>
      <FullCalendar
        ref={calendarRef}
        themeSystem="bootstrap5"
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrap5Plugin,
          timelinePlugin,
          resourceTimelinePlugin,
        ]}
        headerToolbar={{
          left: '',
          right: '',
          center: '',
        }}
        initialView="resourceTimelineDay"
        views={{
          customWeek: {
            type: 'resourceTimeline',
            viewClassNames: 'calendarWeek',
            duration: { weeks: 1 },
            slotDuration: { days: 1 },
            buttonText: 'week',
            slotLabelFormat: {
              day: 'numeric',
              weekday: 'long',
            },
            eventMinWidth: 200,
            eventResizableFromStart: true,
          },
          resourceTimelineDay: {
            viewClassNames: 'calendarDay',
          },
        }}
        displayEventTime={false}
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        resourceAreaWidth={200}
        resourceAreaHeaderContent={() => null}
        resources={resourceData as ResourceSourceInput}
        datesSet={(e: DatesSetArg) => handleArrowClick(e)}
        eventContent={(e) => <StaffCalendarEvent eventInfo={e} />}
        slotMinTime={getStaffCalendarStartTime(shifts, calendarDate)}
        resourceLabelContent={(e) => (
          <StaffCalendarPopover
            eventInfo={e}
            openEditWorkingHours={openEditWorkingHours}
            setStaffId={setStaffId}
            openAddTimeOff={openAddTimeOff}
          />
        )}
        locale={esLocale}
        editable={false}
        firstDay={1}
        selectMirror={true}
        dayMaxEvents={true}
        eventClick={(e) => handleEventClick(e)}
        events={events as EventSourceInput}
        slotLabelFormat={use24HoursFormat}
      />
    </div>
  )
}

export default memo(StaffCalendar)
