export const downloadFile = (file: string, type: string) => {
  const fileName = 'contacts'
  const link = document.createElement('a')

  link.href = window.URL.createObjectURL(
    new Blob([file] as BlobPart[], {
      type,
    }),
  )
  link.download = fileName
  link.click()
}
