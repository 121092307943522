import { FC, memo, Dispatch, SetStateAction, SyntheticEvent } from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as PlusIcon } from 'assets/images/common/redesign/plusCircle.svg'
import { UIButton, UIUploadFile } from 'ui'
import { OnUploadFileChange } from 'ui/UploadFile/index.type'

import style from './style.module.scss'
import { DYNAMICAL_ROW, INITIAL_STATE_SECTION_THREE } from '../../index.type'
import TextTemplateRows from '../TextTemplateRows'

type PropsType = {
  setter: Dispatch<SetStateAction<typeof INITIAL_STATE_SECTION_THREE>>
  state: typeof INITIAL_STATE_SECTION_THREE
}

const BusinessTemplateSectionThree: FC<PropsType> = ({ state, setter }) => {
  const { t } = useTranslation()

  const handleChange = (index: number, event: SyntheticEvent) => {
    const { name, value } = event.target as HTMLInputElement

    setter((prevState) => {
      const newState = [...prevState.section_3_text]

      newState[index] = { ...prevState.section_3_text[index], [name]: value }

      return {
        ...prevState,
        section_3_text: newState,
      }
    })
  }

  const handleFileChange: OnUploadFileChange = (name, value) => {
    setter((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAddNewRow = () => {
    setter((prevState) => ({
      ...prevState,
      section_3_text: [...prevState.section_3_text, DYNAMICAL_ROW],
    }))
  }

  const handleRemoveRow = (index: number) => {
    setter((prevState) => ({
      ...prevState,
      section_3_text: prevState.section_3_text.filter(
        (_, stateIndex) => stateIndex !== index,
      ),
    }))
  }

  return (
    <div className={style.sectionThree}>
      <h2>{t('COMMON.SECTION_NUMBER', { number: 3 })}</h2>

      <UIUploadFile
        name={'section_3_image'}
        onChange={handleFileChange}
        selectedName={state.section_3_image?.path?.split('/')?.slice(-1)?.[0]}
      />

      <TextTemplateRows
        state={state.section_3_text}
        onChange={handleChange}
        onRowRemove={handleRemoveRow}
      />

      <UIButton
        handler={handleAddNewRow}
        label={t('ADMIN.PAGES.TEMPLATE.TEXT.ADD_TEXT')}
        icon={<PlusIcon />}
        type="text"
      />
    </div>
  )
}

export default memo(BusinessTemplateSectionThree)
