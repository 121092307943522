import {
  ADMIN_ACTIVE_CATEGORY,
  SET_ADMIN_CATEGORIES,
  SET_ADMIN_NEW_CATEGORY,
  SET_ADMIN_NEW_SUBCATEGORY,
  SET_ADMIN_SUBCATEGORIES,
  EDIT_ADMIN_CATEGORY_SUCCESS,
  EDIT_ADMIN_CATEGORY_REQUEST,
  SET_ADMIN_NEW_CATEGORY_REQUEST,
  SET_ADMIN_NEW_CATEGORY_FAILURE,
  ADD_IMAGE_CATEGORY_FAILURE,
  SET_ADMIN_NEW_SUBCATEGORY_FAILURE,
  CLEAR_ERRORS,
  EDIT_ADMIN_CATEGORY_FAILURE,
  EDIT_ADMIN_SUBCATEGORY_FAILURE,
  SORT_ADMIN_CATEGORIES_FAILURE,
  SORT_ADMIN_SUBCATEGORIES_FAILURE,
  GET_ADMIN_SUBCATEGORIES_REQUEST,
  GET_ADMIN_CATEGORIES_FAILURE,
} from 'store/Admin/Categories/Categories.constant'
import {
  CategoriesActionsTypes,
  CategoriesState,
} from 'store/Admin/Categories/Categories.type'
import { ResponseFailureType } from 'store/Auth/Auth.type'

const initialState: CategoriesState = {
  subcategories: [],
  categories: [],
  errors: {},
  errorMessage: null,
  activeCategory: 0,
  isLoading: false,
  isAddCategoryLoading: false,
}

export default function adminCategoriesReducer(
  state = initialState,
  action: CategoriesActionsTypes,
): CategoriesState {
  switch (action.type) {
    case GET_ADMIN_SUBCATEGORIES_REQUEST:
      return { ...state, isLoading: true }

    case EDIT_ADMIN_CATEGORY_REQUEST:
    case SET_ADMIN_NEW_CATEGORY_REQUEST:
      return { ...state, isAddCategoryLoading: true }

    case SET_ADMIN_SUBCATEGORIES:
      return { ...state, subcategories: action.payload, isLoading: false }
    case SET_ADMIN_CATEGORIES:
      return { ...state, categories: action.payload }
    case ADMIN_ACTIVE_CATEGORY:
      return { ...state, activeCategory: action.payload }
    case SET_ADMIN_NEW_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      }
    case SET_ADMIN_NEW_SUBCATEGORY:
      return {
        ...state,
        subcategories: [...state.subcategories, action.payload],
        isAddCategoryLoading: false,
      }
    case EDIT_ADMIN_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.map((i) => {
          if (i.id === action.payload.id) {
            return action.payload
          }

          return i
        }),
        isLoading: false,
      }
    case ADD_IMAGE_CATEGORY_FAILURE: {
      return {
        ...state,
        isAddCategoryLoading: false,
        isLoading: false,
      }
    }
    case SET_ADMIN_NEW_CATEGORY_FAILURE:
    case SET_ADMIN_NEW_SUBCATEGORY_FAILURE:
    case EDIT_ADMIN_CATEGORY_FAILURE:
    case EDIT_ADMIN_SUBCATEGORY_FAILURE:
    case SORT_ADMIN_CATEGORIES_FAILURE:
    case SORT_ADMIN_SUBCATEGORIES_FAILURE:
    case GET_ADMIN_CATEGORIES_FAILURE:
      return {
        ...state,
        isAddCategoryLoading: false,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    default:
      return state
  }
}
