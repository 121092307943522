import { ResponseFailureType } from 'store/Auth/Auth.type'
import {
  CLEAR_SEO_ERRORS,
  CLEAR_SEO_STORE,
  GET_ADMIN_SEO_CATEGORY_FAILURE,
  GET_ADMIN_SEO_CATEGORY_REQUEST,
  GET_ADMIN_SEO_CATEGORY_SUCCESS,
  GET_ADMIN_SEO_SETTINGS_FAILURE,
  GET_ADMIN_SEO_SETTINGS_REQUEST,
  GET_ADMIN_SEO_SETTINGS_SUCCESS,
  GET_ADMIN_SEO_SUBCATEGORY_FAILURE,
  GET_ADMIN_SEO_SUBCATEGORY_REQUEST,
  GET_ADMIN_SEO_SUBCATEGORY_SUCCESS,
  UPDATE_ADMIN_SEO_SETTINGS_REQUEST,
  UPDATE_ADMIN_SEO_SETTINGS_SUCCESS,
  UPDATE_ADMIN_SEO_SETTINGS_FAILURE,
} from './Seo.constant'
import { SeoState, SeoActionsTypes } from './Seo.type'

const initialState: SeoState = {
  category: null,
  subCategory: null,
  isLoading: false,
  errorMessage: null,
  errors: {},
  setting: null,
}

export default function adminSeoReducer(
  state = initialState,
  action: SeoActionsTypes,
): SeoState {
  switch (action.type) {
    case GET_ADMIN_SEO_CATEGORY_REQUEST:
    case GET_ADMIN_SEO_SUBCATEGORY_REQUEST:
    case GET_ADMIN_SEO_SETTINGS_REQUEST:
    case UPDATE_ADMIN_SEO_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case GET_ADMIN_SEO_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload,
        isLoading: false,
      }
    case GET_ADMIN_SEO_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subCategory: action.payload,
        isLoading: false,
      }
    case GET_ADMIN_SEO_SETTINGS_SUCCESS:
      return {
        ...state,
        setting: action.payload,
        isLoading: false,
      }
    case UPDATE_ADMIN_SEO_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case UPDATE_ADMIN_SEO_SETTINGS_FAILURE:
    case GET_ADMIN_SEO_SETTINGS_FAILURE:
    case GET_ADMIN_SEO_SUBCATEGORY_FAILURE:
    case GET_ADMIN_SEO_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }

    case CLEAR_SEO_STORE:
      return {
        ...initialState,
      }

    case CLEAR_SEO_ERRORS:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    default:
      return state
  }
}
