import { GET } from 'utils/http'
import axios, { CancelTokenSource } from 'axios'

let axiosSource: CancelTokenSource | null = null

export const getOrderHistoryApi = async (query: string) => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(`order${query}`, {
    ...(axiosSource && { cancelToken: axiosSource.token }),
  })

  return data
}

export const getCancelledOrderHistoryApi = async (query: string) => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(`order/deleted-order-items${query}`, {
    ...(axiosSource && { cancelToken: axiosSource.token }),
  })

  return data
}
