import adminEng from './admin.json'
import commonEng from './common.json'
import error from './error.json'
import exceptionEng from './exception.json'
import mainEng from './main.json'
import managerEng from './manager.json'
import notFoundEng from './notFound.json'
import validationEng from './validation.json'

export default {
  ...commonEng,
  ...mainEng,
  ...adminEng,
  ...managerEng,
  ...validationEng,
  ...exceptionEng,
  ...error,
  ...notFoundEng,
}
