import { GET, POST } from 'utils/http'

import axios, { CancelTokenSource } from 'axios'
import { FileName, UploadMedia } from 'entities/common'

let axiosSource: CancelTokenSource | null = null

const basePath = '/user'

export const getManagerClientsApi = async (query = '') => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(`${basePath}/clients${query}`, {
    ...(axiosSource && { cancelToken: axiosSource.token }),
  })

  return data
}

export const getManagerUsersApi = async (query = '') => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(`${basePath}/contacts${query}`, {
    ...(axiosSource && { cancelToken: axiosSource.token }),
  })

  return data
}

export const uploadDocumentApi = async (
  fileName: FileName,
  nameUpload: string,
  file: UploadMedia,
) => {
  const formData = new FormData()

  formData.append(fileName, file as unknown as Blob)

  const data = await POST('/import/contacts', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}

export const getExportContactsApi = (query: string) =>
  GET(`/export/contacts?type=${query}`, { responseType: 'arraybuffer' })

export const getErrorImportApi = () =>
  GET('/import/download-report', { responseType: 'arraybuffer' })
