import { Dispatch, FC, memo, SetStateAction, useCallback } from 'react'

import FullCalendar from '@fullcalendar/react'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'

import { StaffType } from 'entities/manager/staff'

import { modeShiftTab, modeTab } from '../constant'
import { ModeShiftType, ModeType } from '../index.type'
import styles from '../style.module.scss'
import { useDispatch } from 'react-redux'
import { getScheduleRequest } from 'store/Manager/Shift/Shift.action'
import { DateHelper } from 'utils/helpers'
import { FORMAT_DATE } from 'utils'

type PropsType = {
  calendarStartDate: Date
  currentMode: string
  isShift?: boolean
  refCurrent?: FullCalendar
  setCurrentMode: Dispatch<SetStateAction<ModeType | ModeShiftType>>
  setMode?: Dispatch<SetStateAction<ModeType>>
  setRangeDate: Dispatch<
    SetStateAction<{
      end: Date
      start: Date
    }>
  >
  setSelectValue: Dispatch<SetStateAction<number>>
  staffs: StaffType[] | null
  stateMode?: ModeType
}

const UICalendarHeaderMode: FC<PropsType> = ({
  isShift,
  currentMode,
  setMode,
  refCurrent,
  setCurrentMode,
  setRangeDate,
  setSelectValue,
  staffs,
  stateMode,
  calendarStartDate,
}) => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()

  const getApi = refCurrent?.getApi()

  const query = `from=${DateHelper.toFormat(
    DateHelper.startOfWeek(calendarStartDate),
    FORMAT_DATE,
  )}&to=${DateHelper.toFormat(
    DateHelper.endOfWeek(calendarStartDate),
    FORMAT_DATE,
  )}`

  const handlerModeClick = useCallback(
    (id: ModeType | ModeShiftType) => {
      if (setMode) {
        setMode(id as ModeType)
      }

      if (query && id === ModeShiftType.WEEK) {
        dispatch(getScheduleRequest(query))
      }

      if (refCurrent) {
        refCurrent?.getApi().changeView(id)
        setCurrentMode(id)
        setRangeDate({ start: new Date(), end: new Date() })
        getApi?.gotoDate(new Date())
      }
      if (!isShift && staffs && id !== ModeType.DAY && id !== ModeType.MONTH) {
        if (searchParams.get('staffId')) {
          setSelectValue(searchParams.get('staffId') as unknown as number)
        } else {
          setSelectValue(staffs?.[0].id)
        }
      } else if (searchParams.get('staffId')) {
        setSelectValue(searchParams.get('staffId') as unknown as number)
      } else if (stateMode !== ModeType.MONTH) {
        setSelectValue(0)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getApi,
      isShift,
      refCurrent,
      searchParams,
      setCurrentMode,
      setMode,
      setRangeDate,
      setSelectValue,
      staffs,
      stateMode,
    ],
  )

  return (
    <div className={styles.mode}>
      {(isShift ? modeShiftTab : modeTab).map(({ id, name }) => (
        <div
          key={id}
          className={classNames({ [styles.active]: currentMode === id })}
          onClick={() => handlerModeClick(id)}
        >
          {name}
        </div>
      ))}
    </div>
  )
}

export default memo(UICalendarHeaderMode)
