import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { IRootState } from 'store/Root.reducer'
import { clearValidationError } from 'store/Validation/Validation.action'
import { VALIDATION_CLASS } from 'utils'

const ValidationListener = () => {
  const dispatch = useDispatch()
  const { isError } = useSelector((store: IRootState) => store.validation)

  useEffect(() => {
    if (isError) {
      const validationFields = document.body.querySelectorAll(
        `.${VALIDATION_CLASS}`,
      )

      validationFields[0]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }

    return () => {
      dispatch(clearValidationError())
    }
  }, [dispatch, isError])

  return null
}

export default ValidationListener
