import { FC, memo } from 'react'

import { useTranslation } from 'react-i18next'

import { FullMedia } from 'entities/common'

import style from './style.module.scss'

type PropsType = { images?: FullMedia[] }

const AdminSalonSectionFour: FC<PropsType> = ({ images }) => {
  const { t } = useTranslation()

  return (
    <section className={style.four}>
      <h2>4. {t('ADMIN.PROFILE_SALON.SECTION_4.TITLE')}</h2>

      <div className={style.picWrapper}>
        {images?.map(({ id, path }) => (
          <img key={id} src={path} />
        ))}
      </div>
    </section>
  )
}

export default memo(AdminSalonSectionFour)
