/* eslint-disable @typescript-eslint/naming-convention */
import { FC, memo, SyntheticEvent, Dispatch, SetStateAction } from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as PlusIcon } from 'assets/images/common/redesign/plusCircle.svg'
import { ReactComponent as TrashIcon } from 'assets/images/common/trash.svg'
import { FullMedia } from 'entities/common'
import { UIButton, UIUploadFile } from 'ui'
import { OnUploadFileChange } from 'ui/UploadFile/index.type'

import style from './style.module.scss'
import { CustomerTemplateStateType } from '../../CustomerTemplate/index.type'
import TemplateField from '../TemplateField'
import { TYPE_INPUT } from '../TemplateField/index.type'

type PropsType = {
  setter: Dispatch<SetStateAction<CustomerTemplateStateType>>
  state: CustomerTemplateStateType
}

const CustomerTemplateSectionSix: FC<PropsType> = ({ state, setter }) => {
  const { t } = useTranslation()
  const {
    section_6_description,
    section_6_title,
    section_6_subtitle,
    section_6_image,
  } = state

  const handleRemove = (index: number) => () => {
    setter((prevState) => ({
      ...prevState,
      section_6_description: (
        prevState.section_6_description as string[]
      ).filter((_, stateIndex) => stateIndex !== index),
    }))
  }

  const handleChange = (index: number) => (event: SyntheticEvent) => {
    setter((prevState) => {
      const newState = [...(prevState.section_6_description as string[])]

      newState[index] = (event.target as HTMLInputElement).value

      return { ...prevState, section_6_description: newState }
    })
  }

  const handleFileChange: OnUploadFileChange = (name, value) => {
    setter((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAddNew = () => {
    setter((prevState) => ({
      ...prevState,
      section_6_description: [
        ...(prevState.section_6_description as string[]),
        '',
      ],
    }))
  }

  const handleInputChange = (event: SyntheticEvent) => {
    const { name, value } = event.target as HTMLInputElement

    setter((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <div className={style.customerTemplateSectionSix}>
      <h2>{t('COMMON.SECTION_NUMBER', { number: 6 })}</h2>

      <TemplateField
        type={TYPE_INPUT}
        onChange={handleInputChange}
        label={t('COMMON.TITLE')}
        name={'section_6_title'}
        value={section_6_title as string}
      />

      <TemplateField
        type={TYPE_INPUT}
        onChange={handleInputChange}
        label={t('COMMON.SUBTITLE')}
        name={'section_6_subtitle'}
        value={section_6_subtitle as string}
      />

      <UIUploadFile
        name={'section_6_image'}
        onChange={handleFileChange}
        selectedName={
          (section_6_image as FullMedia)?.path?.split('/')?.slice(-1)?.[0]
        }
        widthRatio={3.71}
        heightRatio={1}
      />

      <div className={style.listWrapper}>
        <h3>
          {t('COMMON.SECTION_NUMBER', { number: 6 })} {t('COMMON.LIST')}
        </h3>

        {(section_6_description as string[])?.map((item, index) => (
          <div className={style.item} key={index}>
            <TemplateField
              type={TYPE_INPUT}
              onChange={handleChange(index)}
              name={'field'}
              label={t('COMMON.LIST_ITEM', { number: index + 1 })}
              value={item}
            />

            <UIButton
              icon={<TrashIcon />}
              handler={handleRemove(index)}
              type="text"
              isRed
            />
          </div>
        ))}

        <UIButton
          handler={handleAddNew}
          label={t('ADMIN.PAGES.TEMPLATE.TEXT.ADD_TEXT')}
          icon={<PlusIcon />}
          type="text"
        />
      </div>
    </div>
  )
}

export default memo(CustomerTemplateSectionSix)
