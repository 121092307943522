import {
  ADD_VALIDATION_ERROR,
  CLEAR_VALIDATION_ERROR,
} from './Validation.constant'

export const addValidationError = () => ({
  type: ADD_VALIDATION_ERROR,
})

export const clearValidationError = () => ({
  type: CLEAR_VALIDATION_ERROR,
})
