import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  GET_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  RESET_ERROR,
  UPADATE_SETTINGS_FAILURE,
  UPADATE_SETTINGS_REQUEST,
  UPADATE_SETTINGS_SUCCESS,
  SYNC_GOOGLE_CALENDAR_REQUEST,
  SYNC_GOOGLE_CALENDAR_SUCCESS,
  SYNC_GOOGLE_CALENDAR_FAILURE,
  DESYNC_GOOGLE_CALENDAR_REQUEST,
  DESYNC_GOOGLE_CALENDAR_SUCCESS,
  DESYNC_GOOGLE_CALENDAR_FAILURE,
} from './Settings.constant'
import { SettingsActionsTypes, SettingsState } from './Settings.type'

const initialState: SettingsState = {
  settings: null,
  isLoading: false,
  errors: {},
  errorMessage: null,
}

export default function settingsReducer(
  state = initialState,
  action: SettingsActionsTypes,
): SettingsState {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
    case UPADATE_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: action.payload,
      }
    case UPADATE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case GET_SETTINGS_FAILURE:
    case UPADATE_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }

    case SYNC_GOOGLE_CALENDAR_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case SYNC_GOOGLE_CALENDAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case SYNC_GOOGLE_CALENDAR_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }

    case DESYNC_GOOGLE_CALENDAR_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case DESYNC_GOOGLE_CALENDAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DESYNC_GOOGLE_CALENDAR_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }

    case RESET_ERROR:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }

    default:
      return state
  }
}
