import React, { FC, useEffect } from 'react'

import 'antd/dist/antd.css'

import { Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { addAlert } from 'store/Alert'
import {
  clearStaffs,
  clearErrors,
  getOneStaffRequest,
  getStaffRequest,
  resetStore,
  setStaffStep,
} from 'store/Manager/Staff'
import {
  clearMediaStore,
  clearErrors as mediaClearErrors,
} from 'store/Media/Media.action'
import { IRootState } from 'store/Root.reducer'
import { MANAGER_STAFF_SERVICES } from 'utils'

import ViewAddStaffStep1 from './Step1'

const ViewAddStaff: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    staff,
    staffs,
    errorMessage,
    addStaffStep,
    isLoading,
    isStaffAdd,
    isStaffs,
    mediaErrorMessage,
  } = useSelector((state: IRootState) => ({
    ...state.staff,
    mediaErrorMessage: state.media.errorMessage,
  }))

  useEffect(() => {
    if (addStaffStep === 2 && staff?.id) {
      navigate(MANAGER_STAFF_SERVICES.replace(':id', String(staff.id)))
    }
  }, [addStaffStep, dispatch, navigate, staff])

  useEffect(() => {
    if (!errorMessage && isStaffAdd) {
      dispatch(getStaffRequest())
      dispatch(resetStore())
    }
  }, [dispatch, errorMessage, isStaffAdd])

  useEffect(() => {
    if (errorMessage || mediaErrorMessage) {
      dispatch(
        addAlert({
          message: errorMessage || mediaErrorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(clearErrors())
      dispatch(mediaClearErrors())
    }
  }, [errorMessage, dispatch, mediaErrorMessage])

  useEffect(() => {
    return () => {
      dispatch(setStaffStep(1))
      dispatch(clearMediaStore())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (staffs?.length && isStaffs) {
      dispatch(getOneStaffRequest(staffs[staffs.length - 1].id))
      dispatch(clearStaffs())
    }
  }, [dispatch, staffs, isStaffs])

  return (
    <Spin spinning={isLoading}>
      <ViewAddStaffStep1 />
    </Spin>
  )
}

export default ViewAddStaff
