import { memo, FC, SyntheticEvent } from 'react'

import classNames from 'classnames'

import { UITextArea } from 'ui'

import style from './style.module.scss'

type PropsType = {
  className?: string
  disabled?: boolean
  error?: string
  name?: string
  onChange: (event: SyntheticEvent) => void
  placeholder?: string
  rows?: number
  title: string
  value: string
}

const TextBlock: FC<PropsType> = ({
  onChange,
  placeholder,
  title,
  value,
  className,
  name,
  rows,
  error,
  disabled,
}) => (
  <div className={classNames(style.block, className)}>
    <h2>{title}</h2>

    <UITextArea
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      rows={rows}
      disabled={disabled}
      error={error}
    />
  </div>
)

export default memo(TextBlock)
