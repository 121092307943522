import { FC, memo } from 'react'

import { useTranslation } from 'react-i18next'
import { FORMAT_DATE_DAY_YEAR } from 'utils'
import { DateHelper } from 'utils/helpers'

import style from './style.module.scss'

type PropsType = { date?: string; isFreeTrial: boolean }

const AdminSalonSectionTwo: FC<PropsType> = ({ date, isFreeTrial }) => {
  const { t } = useTranslation()

  const renderTitle = () => {
    if (isFreeTrial) {
      return t('COMMON.MANUAL')
    }

    if (date) {
      return (
        <>
          {t('COMMON.PAID_UNTIL')}{' '}
          {DateHelper.toFormat(date, FORMAT_DATE_DAY_YEAR)}
        </>
      )
    }

    if (!isFreeTrial && !date) {
      return t('COMMON.NOT_ISSUED')
    }

    return t('COMMON.NOT_ISSUED')
  }

  return (
    <section className={style.second}>
      <h2>2. {t('ADMIN.PROFILE_SALON.SECTION_2.TITLE')}</h2>

      <p>{renderTitle()}</p>
    </section>
  )
}

export default memo(AdminSalonSectionTwo)
