export const GET_ADMIN_FAQ_REQUEST = '@faq/GET_ADMIN_FAQ_REQUEST'
export const GET_ADMIN_FAQ_SUCCESS = '@faq/GET_ADMIN_FAQ_SUCCESS'
export const GET_ADMIN_FAQ_FAILURE = '@faq/GET_ADMIN_FAQ_FAILURE'

export const GET_ADMIN_CURRENT_FAQ_REQUEST =
  '@faq/GET_ADMIN_CURRENT_FAQ_REQUEST'
export const GET_ADMIN_CURRENT_FAQ_SUCCESS =
  '@faq/GET_ADMIN_CURRENT_FAQ_SUCCESS'
export const GET_ADMIN_CURRENT_FAQ_FAILURE =
  '@faq/GET_ADMIN_CURRENT_FAQ_FAILURE'

export const ADD_ADMIN_FAQ_REQUEST = '@faq/ADD_ADMIN_FAQ_REQUEST'
export const ADD_ADMIN_FAQ_SUCCESS = '@faq/ADD_ADMIN_FAQ_SUCCESS'
export const ADD_ADMIN_FAQ_FAILURE = '@faq/ADD_ADMIN_FAQ_FAILURE'

export const EDIT_ADMIN_FAQ_REQUEST = '@faq/EDIT_ADMIN_FAQ_REQUEST'
export const EDIT_ADMIN_FAQ_SUCCESS = '@faq/EDIT_ADMIN_FAQ_SUCCESS'
export const EDIT_ADMIN_FAQ_FAILURE = '@faq/EDIT_ADMIN_FAQ_FAILURE'

export const DELETE_ADMIN_FAQ_REQUEST = '@faq/DELETE_ADMIN_FAQ_REQUEST'
export const DELETE_ADMIN_FAQ_SUCCESS = '@faq/DELETE_ADMIN_FAQ_SUCCESS'
export const DELETE_ADMIN_FAQ_FAILURE = '@faq/DELETE_ADMIN_FAQ_FAILURE'

export const CLEAR_ADMIN_FAQ_ERRORS = '@faq/CLEAR_ADMIN_FAQ_ERRORS'
