import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { DocumentsType } from 'store/Manager/Documents/Documents.type'
import { UIDragUpload } from 'ui'

import styles from './style.module.scss'
import { PropsType } from './index.type'

const ClientSidebarImport: FC<PropsType> = ({ setFileList, fileList }) => {
  const { t } = useTranslation()

  const handleRequest = (file: DocumentsType) => {
    if (file && fileList.length < 1) {
      setFileList([...fileList, file])
    }
  }

  return (
    <div className={styles.wrapper}>
      <p>{t('MANAGER.CLIENTS.IMPORT_INFO')}</p>
      <UIDragUpload
        setFileList={setFileList}
        fileList={fileList}
        handleRequest={handleRequest as () => void}
        isLoading={false}
        className={styles.sidebarUpload}
      />
    </div>
  )
}

export default ClientSidebarImport
