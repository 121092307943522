import {
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

import 'antd/dist/antd.css'

import { RightOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as NotifySVG } from 'assets/images/common/notify.svg'
import { ServiceType } from 'entities/manager/service'
import { ModalType } from 'entities/modal'
import { CategoryType } from 'entities/view'
import useResponsive from 'hooks/useResponsive'
import LayoutManagerSidebar from 'layouts/Manager/Sidebar'
import { addAlert } from 'store/Alert'
import {
  getCategoriesRequest,
  getOneServiceRequest,
  getServicesByCategoryRequest,
  getServiceWithoutCategoryRequest,
  resetError,
  setActiveCategory,
  setMobileTitleOpen,
} from 'store/Manager/Services'
import { clearMediaStore } from 'store/Media/Media.action'
import { showModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { personalSortRequest, sortRequest } from 'store/Sort/Sort.action'
import { UICategoriesCards, UIServicesCards } from 'ui'
import UIBack from 'ui/Back'
import { MANAGER_SERVICES_ADD, MANAGER_SERVICES_EDIT } from 'utils'

import styles from './style.module.scss'

const ViewServices: FC = () => {
  const {
    categories: listCategories,
    servicesByCategory,
    isCategoryLoading,
    isOneServiceLoading,
    activeCategory,
    errorMessage,
    isWithoutCategory,
    isMobileTitleOpen,
  } = useSelector((state: IRootState) => state.service)

  const { t } = useTranslation()
  const [active, setActive] = useState(0)
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [services, setServices] = useState<ServiceType[]>([])
  const [isNotCategory, setIsNotCategory] = useState(false)
  const [isMobileOpen, setIsMobileOpen] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile, isTablet } = useResponsive()

  useEffect(() => {
    dispatch(getCategoriesRequest('profile'))
  }, [dispatch])

  useEffect(() => {
    if (listCategories) {
      setCategories(listCategories)
    }
  }, [listCategories])

  useEffect(() => {
    if (servicesByCategory) {
      setServices(servicesByCategory)
    }
  }, [servicesByCategory])

  useLayoutEffect(() => {
    dispatch(clearMediaStore())
  }, [dispatch])

  useEffect(() => {
    if (categories && categories.length && !activeCategory && !isNotCategory) {
      dispatch(getServicesByCategoryRequest(categories[0].id))
      dispatch(setActiveCategory(categories[0].id))
    }
  }, [categories, dispatch, activeCategory, isNotCategory])

  useEffect(() => {
    if (activeCategory) {
      setIsNotCategory(false)
      dispatch(getServicesByCategoryRequest(activeCategory))
    }
  }, [activeCategory, dispatch])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(resetError())
    }
  }, [errorMessage, dispatch])

  useEffect(() => {
    if (isWithoutCategory) {
      dispatch(
        showModal({
          modalType: ModalType.NOTIFY,
          modalProps: {
            notifyTitle: t('MANAGER.SERVICES.WITHOUT.NOTIFICATION'),
          },
        }),
      )
    }
  }, [dispatch, isWithoutCategory, t])

  const handlerOpenMenuMobile = useCallback(() => {
    if (isMobile || isTablet) {
      setIsMobileOpen(true)
    }
  }, [isMobile, isTablet])

  const handlerGetOneService = useCallback(
    (id: number) => {
      dispatch(setActiveCategory(id))
      handlerOpenMenuMobile()
    },
    [dispatch, handlerOpenMenuMobile],
  )

  const handlerGetService = (id: number) => {
    dispatch(getOneServiceRequest(id))
    navigate(MANAGER_SERVICES_EDIT.replace(':id', String(id)))
  }

  const handlerServiceSort = (array: number[]) => {
    dispatch(sortRequest({ idx: array, name: 'service' }))
  }

  const handlerCategorySort = (array: number[]) => {
    dispatch(
      personalSortRequest({
        idx: array,
        name: 'category',
      }),
    )
  }

  const handlerWithoutCategory = () => {
    setIsNotCategory(true)
    dispatch(setActiveCategory(null))
    dispatch(getServiceWithoutCategoryRequest())
    handlerOpenMenuMobile()
  }

  const handleHeader = () => {
    navigate(MANAGER_SERVICES_ADD)
  }

  return (
    <LayoutManagerSidebar
      titleHeader={t('MANAGER.SERVICES.TITLE')}
      titleSidebar={t('MANAGER.SERVICES.CATEGORIES')}
      titleBtnHeader={t('MANAGER.BUTTON.ADD_SERVICES')}
      handleHeader={handleHeader}
      mobileActive={isMobileOpen || isMobileTitleOpen}
      sidebarContent={
        <>
          <Spin spinning={isCategoryLoading}>
            <div
              className={cn(styles.categoriesCards, {
                [styles.disableActive]: isNotCategory,
              })}
            >
              <UICategoriesCards
                active={active}
                setActive={setActive}
                categories={categories}
                setCategories={setCategories}
                handler={handlerGetOneService}
                activeId={activeCategory}
                handlerSort={handlerCategorySort}
              />
            </div>
          </Spin>

          {isWithoutCategory && (
            <div className={styles.iconWrapper}>
              <div
                className={cn(styles.category, {
                  [styles.activeBorder]: isNotCategory === true,
                })}
                onClick={handlerWithoutCategory}
              >
                <div className={styles.icon}>
                  <NotifySVG />
                </div>

                <div className={styles.withoutCategory}>
                  {t('MANAGER.SERVICES.WITHOUT.CATEGORY')}
                </div>
                <RightOutlined />
              </div>
            </div>
          )}
        </>
      }
    >
      {(isMobileOpen || isMobileTitleOpen) && (
        <UIBack
          type="title"
          title={
            categories?.find((item) => item.id === activeCategory)?.name ||
            t('MANAGER.SERVICES.WITHOUT.CATEGORIES')
          }
          setIsMobileOpen={() => {
            setIsMobileOpen(false)
            dispatch(setMobileTitleOpen(false))
          }}
        />
      )}

      <div className={styles.servicesCards}>
        <Spin spinning={isOneServiceLoading}>
          <UIServicesCards
            isNotMove={isNotCategory}
            services={services}
            setServices={setServices}
            handler={handlerGetService}
            handlerSort={handlerServiceSort}
          />
        </Spin>
      </div>
    </LayoutManagerSidebar>
  )
}

export default memo(ViewServices)
