/* eslint-disable @typescript-eslint/naming-convention */
import { FC, memo, useMemo } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { ReactComponent as AvatarIcon } from 'assets/images/common/avatar.svg'
import { DocumentStatus } from 'entities/common'
import { IAdminSalonResponseData } from 'store/Admin/Salons/Salons.type'

import { SalonStatus, SalonTypes } from '../index.type'
import { DateHelper } from 'utils/helpers'
import { FORMAT_DATE_DAY_YEAR } from 'utils'

type PropTypes = IAdminSalonResponseData & {
  dateEnd?: string
  freePlanName?: string
  onDataClick: (id: number) => () => void
  typeSubscription?: string
}

const SalonsTableRow: FC<PropTypes> = ({
  id,
  profile,
  type,
  onDataClick,
  typeSubscription,
  freePlanName,
  dateEnd,
}) => {
  const { t } = useTranslation()

  const { logo, is_confirmed, name, manual_free_trial, subscribe } =
    profile || {}

  const isConfirmed = is_confirmed === DocumentStatus.ACCEPT

  const plan = useMemo(() => {
    if (manual_free_trial && !subscribe) {
      return t('COMMON.MANUAL')
    }

    if (subscribe && !subscribe.plan) {
      return freePlanName
    }

    return typeSubscription
  }, [manual_free_trial, subscribe, typeSubscription, t, freePlanName])

  return (
    <tr onClick={onDataClick(Number(id))}>
      <td>
        {logo?.path && <img src={logo?.path} alt={t('COMMON.AVATAR')} />}
        {!logo?.path && <AvatarIcon />}

        {name || '-'}
      </td>
      <td>
        <span
          className={classNames({
            'text-error': type === SalonTypes.NOT_PAID,
          })}
        >
          {plan || '-'}
        </span>
      </td>
      <td>
        <span
          className={classNames({
            'text-error': !isConfirmed && SalonStatus.REJECTED,
            'text-info': isConfirmed && SalonStatus.CONFIRMED,
          })}
        >
          {isConfirmed ? SalonStatus.CONFIRMED : SalonStatus.REJECTED}
        </span>
      </td>
      <td>
        {dateEnd ? DateHelper.toFormat(dateEnd, FORMAT_DATE_DAY_YEAR) : '-'}{' '}
      </td>
    </tr>
  )
}

export default memo(SalonsTableRow)
