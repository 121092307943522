import { FC, useEffect, useState } from 'react'

import { Avatar, Spin, Upload, UploadFile } from 'antd'
import { RcFile } from 'antd/lib/upload'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { ReactComponent as Camera64SVG } from 'assets/images/admin/camera_64.svg'
import { ReactComponent as PlusIcon } from 'assets/images/common/redesign/plusCircle.svg'
import { reorder } from 'entities/common'
import { CategoryType } from 'entities/view'
import {
  setCategories,
  setNewCategoryRequest,
  sortCategories,
} from 'store/Admin'
import { clearMediaStore, resetLinkType } from 'store/Media/Media.action'
import { UPLOAD_LINK_SUBCATEGORY } from 'store/Media/Media.constant'
import { IRootState } from 'store/Root.reducer'
import { UIButton } from 'ui'
import UITextInput from 'ui/TextInput'
import WidgetModalImageEditor from 'widgets/Modal/ImageEditor'

import style from './style.module.scss'
import AdminUICategory from '../Category'

const getSrcFromFile = (file: UploadFile<any>) => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    if (file.originFileObj) {
      reader.readAsDataURL(file.originFileObj)
    }

    reader.onload = () => resolve(reader.result)
  })
}

const AdminUICategories: FC = () => {
  const { categories, errors } = useSelector(
    (state: IRootState) => state.adminCategories,
  )
  const { fileData, isloading, linkType } = useSelector(
    (state: IRootState) => state.media,
  )

  const dispatch = useDispatch()

  const [value, setValue] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isEdit, setIsEdit] = useState(-1)
  const { t } = useTranslation()

  const handleDrag = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      categories,
      result.source.index,
      result.destination.index,
    ) as CategoryType[]

    dispatch(sortCategories(items.map((e) => e.id)))
    dispatch(setCategories(items))
  }

  const handleUpload = async (file: UploadFile<any>) => {
    const src = file.url || ((await getSrcFromFile(file)) as string)
    const imgWindow = window.open(src)

    if (imgWindow) {
      const image = new Image()

      image.src = src
      imgWindow.document.write(image.outerHTML)
    } else {
      window.location.href = src
    }
  }

  const handleAddCategories = () => {
    dispatch(
      setNewCategoryRequest({
        name: value,
        image: fileData?.id,
      }),
    )
    dispatch(clearMediaStore())
    setValue('')
    setFileList([])
    dispatch(resetLinkType())
  }

  const onRemove = () => {
    setFileList([])
    dispatch(clearMediaStore())
  }

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      setFileList([])
    }
  }, [errors])

  useEffect(() => {
    if (fileData && linkType === UPLOAD_LINK_SUBCATEGORY) {
      setFileList([
        { url: fileData.path, id: fileData.id } as unknown as UploadFile,
      ])
    }
  }, [fileData, linkType])

  const uploadButton = (
    <Spin spinning={isloading}>
      <Avatar size={64} icon={<Camera64SVG />} className={style.uploadBtn} />
    </Spin>
  )

  return (
    <div className={style.wrapper}>
      <h2>{t('ADMIN.CATEGORY.NAME')}</h2>

      <div className={style.wrapper_upload}>
        <WidgetModalImageEditor
          widthRatio={1}
          heightRatio={1}
          isCircle={true}
          linkType={UPLOAD_LINK_SUBCATEGORY}
        >
          <Upload
            name="file"
            customRequest={(file) => handleUpload(file.file as RcFile)}
            fileList={fileList}
            listType="picture-card"
            onRemove={onRemove}
          >
            {fileList && fileList.length > 0 ? null : uploadButton}
          </Upload>
        </WidgetModalImageEditor>

        <UITextInput
          value={value}
          placeholder={t('ADMIN.CATEGORY.NAME')}
          onChange={(e) => setValue(e.target.value)}
          maxLength={149}
          className={style.uploadName}
        />

        <UIButton
          handler={handleAddCategories}
          label={t('ADMIN.CATEGORIES.ADD_BUTTON')}
          icon={<PlusIcon />}
          disabled={!value.trim()}
          type="text"
        />
      </div>

      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {categories.map(({ name, active, id, image }, index) => (
                <Draggable key={id} draggableId={String(id)} index={index}>
                  {(prov) => (
                    <div
                      ref={prov.innerRef}
                      {...prov.draggableProps}
                      {...prov.dragHandleProps}
                    >
                      <AdminUICategory
                        id={id}
                        name={name}
                        active={Boolean(active)}
                        key={id}
                        pathImg={image?.path}
                        setIsEdit={(bol: boolean) =>
                          setIsEdit(bol ? index : -1)
                        }
                        isEdit={isEdit === index}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default AdminUICategories
