import { call, fork, put, takeLatest } from 'redux-saga/effects'
import { ManagerNotificationHttp } from 'services/http'
import { getRequestErrors } from 'utils/helpers/requests'
import {
  getManagerNotificationsFailure,
  getManagerNotificationsSuccess,
} from './Notifications.action'
import { GET_MANAGER_NOTIFICATIONS_REQUEST } from './Notifications.constant'
import { IGetManagerNotificationsResponse } from './Notifications.type'

function* workerGetNotifications() {
  try {
    const { data }: { data: IGetManagerNotificationsResponse } = yield call(
      ManagerNotificationHttp.getNotification,
    )

    yield put(getManagerNotificationsSuccess(data.data))
  } catch (error) {
    yield getRequestErrors(getManagerNotificationsFailure, error as string)
  }
}

function* watchGetNotifications() {
  yield takeLatest(GET_MANAGER_NOTIFICATIONS_REQUEST, workerGetNotifications)
}

export const notificationWatchers = [fork(watchGetNotifications)]
