import { FC } from 'react'

import { ReactComponent as BackSVG } from 'assets/images/common/back.svg'

import styles from './style.module.scss'

type PropsType = {
  onClick?: () => void
  setIsMobileOpen?: (arg: boolean) => void
  title?: string
  type?: 'title'
}

const UIBack: FC<PropsType> = ({ onClick, type, setIsMobileOpen, title }) => {
  return (
    <>
      {type === 'title' ? (
        <div className={styles.headerMobile}>
          <div>
            <BackSVG
              onClick={() => {
                if (setIsMobileOpen) {
                  setIsMobileOpen(false)
                }
              }}
            />
          </div>
          <p>{title}</p>
        </div>
      ) : (
        <BackSVG className={styles.wrapper} onClick={onClick} />
      )}
    </>
  )
}

export default UIBack
