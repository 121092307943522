import {
  LoginType,
  RegisterType,
  RegisterRequestType,
  ResetPasswordType,
  SocialLoginType,
  ILoginResponse,
} from 'entities/unauthorized/Auth'

import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_FAILURE,
  AUTH_VERIFY_REQUEST,
  AUTH_VERIFY_SUCCESS,
  AUTH_VERIFY_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  CLEAR_REGESTERED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_FORGOT_STATE,
  AUTH_SOCIAL_LOGIN_REQUEST,
  CLEAR_ERRORS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from './Auth.constant'
import { ResponseFailureRegisterType, ResponseFailureType } from './Auth.type'

export const authLoginRequest = (body: LoginType) => ({
  type: AUTH_LOGIN_REQUEST,
  payload: body,
})

export const authLoginSuccess = (response: ILoginResponse) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: response,
})

export const authLoginFailure = (error: ResponseFailureType) => ({
  type: AUTH_LOGIN_FAILURE,
  payload: error,
})

export const authSocialLoginRequest = (
  body: Omit<SocialLoginType, 'type'>,
) => ({
  type: AUTH_SOCIAL_LOGIN_REQUEST,
  payload: {
    ...body,
    type: window.location.pathname === '/manager/home' ? 'salon' : 'customer',
  },
})

export const authRegisterRequest = (
  type: RegisterRequestType,
  body: RegisterType,
) => ({
  type: AUTH_REGISTER_REQUEST,
  payload: { type, body },
})

export const authRegisterSuccess = () => ({
  type: AUTH_REGISTER_SUCCESS,
})

export const authRegisterFailure = (error: ResponseFailureRegisterType) => ({
  type: AUTH_REGISTER_FAILURE,
  payload: error,
})

export const authVerifyRequest = (token: string) => ({
  type: AUTH_VERIFY_REQUEST,
  payload: token,
})

export const authVerifySuccess = () => ({
  type: AUTH_VERIFY_SUCCESS,
})

export const authVerifyFailure = (error: ResponseFailureType) => ({
  type: AUTH_VERIFY_FAILURE,
  payload: error,
})

export const forgotPasswordRequest = (email: string) => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload: email,
})

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
})

export const forgotPasswordFailure = (error: ResponseFailureType) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
})

export const resetPasswordRequest = (payload: ResetPasswordType) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
})

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
})

export const resetPasswordFailure = (error: ResponseFailureType) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
})

export const resetForgotState = () => ({
  type: RESET_FORGOT_STATE,
})

export const clearRegestered = () => ({
  type: CLEAR_REGESTERED,
})

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
})

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
})

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
})

export const logoutFailure = (error: ResponseFailureType) => ({
  type: LOGOUT_FAILURE,
  payload: error,
})
