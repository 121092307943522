import { GET, POST } from 'utils/http'
import axios, { CancelTokenSource } from 'axios'

const basePath = '/review'

let axiosSource: CancelTokenSource | null = null

export const getFeedbacksApi = async (queryString = '') => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(`${basePath}${queryString}`, {
    ...(axiosSource && { cancelToken: axiosSource.token }),
  })

  return data
}

export const setFeedbackApi = (payload: {
  comment: string
  review_id: number
}) => POST(basePath, payload)

export const editFeedbackApi = (payload: {
  answer_id: number
  comment: string
}) => POST(`${basePath}/${payload.answer_id}`, { comment: payload.comment })
