import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  ADD_DOCUMENT_FAILURE,
  CLEAR_DOCUMENT_ERRORS,
  CLEAR_DOCUMENT_STOR,
  DELETE_DOCUMENT_FAILURE,
  FILTER_DOCUMENT_LIST,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_NOTIFICATION_SUCCESS,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  SET_LOADING,
  UPLOAD_DOCUMENT_FAILURE,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
} from './Documents.constant'
import { DocumentActionsTypes, IDocumentsState } from './Documents.type'

const initialState: IDocumentsState = {
  fileData: null,
  fileName: '',
  isLoading: false,
  errors: {},
  errorMessage: null,
  documents: [],
  profile: null,
  notification: null,
}

export default function documentReducer(
  state = initialState,
  action: DocumentActionsTypes,
): IDocumentsState {
  switch (action.type) {
    case UPLOAD_DOCUMENT_REQUEST: {
      return {
        ...state,
        fileData: null,
        fileName: '',
        isLoading: true,
      }
    }

    case UPLOAD_DOCUMENT_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        documents: [...state.documents, data],
      }
    }
    case UPLOAD_DOCUMENT_FAILURE:
    case DELETE_DOCUMENT_FAILURE:
    case ADD_DOCUMENT_FAILURE:
    case GET_DOCUMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case GET_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DOCUMENT_SUCCESS:
      return { ...state, profile: action.payload, isLoading: false }
    case GET_DOCUMENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.payload,
      }
    case CLEAR_DOCUMENT_STOR:
      return {
        ...state,
        fileData: null,
        notification: null,
        documents: [],
      }
    case CLEAR_DOCUMENT_ERRORS:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case FILTER_DOCUMENT_LIST:
      return {
        ...state,
        documents: state.documents.filter((item) => item.id !== action.payload),
      }
    default:
      return state
  }
}
