import { FC, memo } from 'react'

import { useTranslation } from 'react-i18next'

import { UICustomCard, UIUploadMove } from 'ui'
import { GeneralValueType } from 'ui/UploadMove/index.type'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const ProfileSalonImages: FC<PropsType> = ({
  setValues,
  values,
  isMediaLoading,
  profile,
  images,
  setImageList,
}) => {
  const { t } = useTranslation()

  return (
    <UICustomCard
      title={
        <>
          <span>3. </span>
          {t('MANAGER.PROFILE_SALON.ITEM_3.0')}
        </>
      }
    >
      <>
        <div className={styles.photos}>
          {t('MANAGER.PROFILE_SALON.WORKPLACE')}
        </div>
        <div className={styles.giveClients}>
          {t('MANAGER.PROFILE_SALON.GIVE_CLIENTS')}
        </div>
        <UIUploadMove
          startImage={profile?.images}
          images={images}
          setValues={
            setValues as (value: React.SetStateAction<GeneralValueType>) => void
          }
          values={values as GeneralValueType}
          isLoading={isMediaLoading}
          setImageList={setImageList}
          className={styles.uploadMoveWrapp}
          widthRatio={1.47}
          heightRatio={1}
        />
      </>
    </UICustomCard>
  )
}

export default memo(ProfileSalonImages)
