import { ChangeEvent, FC, useCallback, useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ReactComponent as SaveSVG } from 'assets/images/common/redesign/add.svg'
import { ReactComponent as DeleteSVG } from 'assets/images/common/redesign/delete.svg'
import { ReactComponent as EditSVG } from 'assets/images/common/redesign/edit.svg'
import { updatePhoneRequest } from 'store/Manager/Salon'
import { UIButton, UIPhoneInput } from 'ui'

import styles from './style.module.scss'

type PropsType = {
  id: number
  index: number
  number: string
  numbers: { number: string }[]
  onDelete: (value: number) => void
  setNumbers: (numbers: { number: string }[]) => void
}
const ProfileSalonPhoneInput: FC<PropsType> = ({
  number,
  setNumbers,
  numbers,
  index,
  id,
  onDelete,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const [isEdit, setIsEdit] = useState(false)

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  const handleDelete = () => {
    onDelete(id)

    setNumbers(
      numbers.filter((e, i) => {
        return i !== index
      }),
    )
  }

  const handleSave = () => {
    if (value.replace(/[+_-]/g, '').length === 10 && value) {
      dispatch(
        updatePhoneRequest({
          id,
          number: `52-${value}`,
        }),
      )
      setIsEdit(false)
    } else if (value.replace(/[+_-]/g, '').length !== 10) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
  }

  useEffect(() => {
    setValue(number)
  }, [number])

  return (
    <div>
      <div>
        {isEdit ? (
          <UIPhoneInput
            value={value}
            onChange={handleChange}
            className={styles.phoneInput}
          />
        ) : (
          <div className={styles.number}>+52-{value}</div>
        )}
      </div>
      <div className={styles.wrappBtn}>
        {!isEdit ? (
          <UIButton
            handler={() => setIsEdit(true)}
            label={t('MANAGER.BUTTON.EDIT')}
            icon={<EditSVG />}
            type="text"
            isGreen
          />
        ) : (
          <UIButton
            handler={handleSave}
            label={t('MANAGER.BUTTON.SAVE')}
            icon={<SaveSVG />}
            type="text"
            isGreen
          />
        )}

        <UIButton
          handler={handleDelete}
          label={t('MANAGER.BUTTON.DELETE')}
          icon={<DeleteSVG />}
          type="text"
          isRed
        />
      </div>
    </div>
  )
}

export default ProfileSalonPhoneInput
