import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  GET_ADMIN_FAQ_REQUEST,
  GET_ADMIN_FAQ_SUCCESS,
  GET_ADMIN_FAQ_FAILURE,
  ADD_ADMIN_FAQ_SUCCESS,
  ADD_ADMIN_FAQ_REQUEST,
  ADD_ADMIN_FAQ_FAILURE,
  CLEAR_ADMIN_FAQ_ERRORS,
  GET_ADMIN_CURRENT_FAQ_REQUEST,
  GET_ADMIN_CURRENT_FAQ_SUCCESS,
  GET_ADMIN_CURRENT_FAQ_FAILURE,
  EDIT_ADMIN_FAQ_REQUEST,
  EDIT_ADMIN_FAQ_SUCCESS,
  EDIT_ADMIN_FAQ_FAILURE,
  DELETE_ADMIN_FAQ_REQUEST,
  DELETE_ADMIN_FAQ_SUCCESS,
  DELETE_ADMIN_FAQ_FAILURE,
} from './Faq.constant'
import {
  AdminFaqBodyType,
  IAdminFaqResponse,
  IAdminFaqResponseData,
} from './Faq.type'

export const getAdminFaqRequest = (
  type: 'business' | 'customer',
  page: number,
) => ({
  type: GET_ADMIN_FAQ_REQUEST,
  payload: { type, page },
})

export const getAdminFaqSuccess = (data: IAdminFaqResponse) => ({
  type: GET_ADMIN_FAQ_SUCCESS,
  payload: data,
})

export const getAdminFaqFailure = (error: ResponseFailureType) => ({
  type: GET_ADMIN_FAQ_FAILURE,
  payload: error,
})

export const getAdminCurrentFaqRequest = (id: number) => ({
  type: GET_ADMIN_CURRENT_FAQ_REQUEST,
  payload: id,
})

export const getAdminCurrentFaqSuccess = (data: IAdminFaqResponseData) => ({
  type: GET_ADMIN_CURRENT_FAQ_SUCCESS,
  payload: data,
})

export const getAdminCurrentFaqFailure = (error: ResponseFailureType) => ({
  type: GET_ADMIN_CURRENT_FAQ_FAILURE,
  payload: error,
})

export const addAdminFaqRequest = (body: AdminFaqBodyType) => ({
  type: ADD_ADMIN_FAQ_REQUEST,
  payload: body,
})

export const addAdminFaqSuccess = () => ({
  type: ADD_ADMIN_FAQ_SUCCESS,
})

export const addAdminFaqFailure = (error: ResponseFailureType) => ({
  type: ADD_ADMIN_FAQ_FAILURE,
  payload: error,
})

export const editAdminFaqRequest = (body: AdminFaqBodyType, id: number) => ({
  type: EDIT_ADMIN_FAQ_REQUEST,
  payload: { body, id },
})

export const editAdminFaqSuccess = () => ({
  type: EDIT_ADMIN_FAQ_SUCCESS,
})

export const editAdminFaqFailure = (error: ResponseFailureType) => ({
  type: EDIT_ADMIN_FAQ_FAILURE,
  payload: error,
})

export const deleteAdminFaqRequest = (ids: number[]) => ({
  type: DELETE_ADMIN_FAQ_REQUEST,
  payload: ids,
})

export const deleteAdminFaqSuccess = () => ({
  type: DELETE_ADMIN_FAQ_SUCCESS,
})

export const deleteAdminFaqFailure = (error: ResponseFailureType) => ({
  type: DELETE_ADMIN_FAQ_FAILURE,
  payload: error,
})

export const clearAdminFaqErrors = () => ({
  type: CLEAR_ADMIN_FAQ_ERRORS,
})
