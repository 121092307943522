import { FC, memo, useEffect, useState } from 'react'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { sendSupportRequest } from 'store/Manager/Support/Support.action'
import { UIButton, UITextArea } from 'ui'

import styles from './style.module.scss'

type PropTypes = {
  errors: { [key: string]: string[] } | null
  isLoading: boolean
  isSended: boolean
}

const ManagerSupport: FC<PropTypes> = ({ isLoading, errors, isSended }) => {
  const [value, setValue] = useState('')
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(sendSupportRequest(value))
  }

  useEffect(() => {
    if (isSended) setValue('')
  }, [isSended])

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('MANAGER.SUPPORT.WELCOME')}</div>
      <div className={styles.subTitle}>{t('MANAGER.SUPPORT.THANK')}</div>

      <UITextArea
        value={value}
        error={errors?.message}
        className={styles.texarea}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t('MANAGER.SUPPORT.INPUT_PLACEHOLDER')}
      />

      <Spin spinning={isLoading} wrapperClassName={'w-fit-content'}>
        <UIButton
          type="primary"
          handler={handleClick}
          label={t('COMMON.SEND')}
        />
      </Spin>
    </div>
  )
}

export default memo(ManagerSupport)
