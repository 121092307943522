import {
  ChangeEvent,
  FC,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useState,
} from 'react'

import { Input } from 'antd'
import classNames from 'classnames'

import { ReactComponent as HidePassSVG } from 'assets/images/common/redesign/hidePassword.svg'
import { ReactComponent as ShowPassSVG } from 'assets/images/common/redesign/showPassword.svg'
import { VALIDATION_CLASS } from 'utils'

import styles from './style.module.scss'
import { StringHelper } from 'utils/helpers'

const { Search } = Input

type PropsType = {
  className?: string
  disabled?: boolean
  error?: string | string[] | null
  icon?: ReactNode
  iconLabel?: ReactNode
  isSearch?: boolean
  isShowPassword?: boolean
  maxLength?: number
  min?: number
  name?: string
  necessaryLabel?: string
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onSearch?: () => void
  pattern?: string
  placeholder?: string
  prefix?: ReactNode
  status?: '' | 'error' | 'warning'
  text?: string | ReactNode
  type?: 'text' | 'number' | 'email' | 'password'
  value?: string
}

const UITextInput: FC<PropsType> = ({
  text,
  placeholder,
  className,
  onChange,
  value,
  error,
  isSearch,
  prefix,
  necessaryLabel,
  onSearch,
  disabled,
  icon,
  type,
  maxLength,
  name,
  status,
  isShowPassword,
  iconLabel,
  pattern,
  min,
}) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false)

  const Component = (isSearch && Search) || Input

  return (
    <div
      className={classNames('wrapperInput', styles.wrapper, className, {
        [VALIDATION_CLASS]: error,
      })}
    >
      {text && (
        <div className={styles.label}>
          {text}{' '}
          {necessaryLabel && (
            <span className={styles.necessaryLabel}>{necessaryLabel}</span>
          )}
          {iconLabel && iconLabel}
        </div>
      )}
      <Component
        {...(isSearch && { onSearch })}
        prefix={
          prefix as ReactElement<any, string | JSXElementConstructor<any>> &
            string
        }
        pattern={pattern}
        onChange={onChange}
        placeholder={placeholder}
        className={styles.input}
        value={value}
        disabled={disabled}
        type={isVisiblePassword ? 'text' : type}
        name={name}
        maxLength={maxLength}
        status={error ? 'error' : status || undefined}
        min={min}
        suffix={
          (isShowPassword && (
            <div onClick={() => setIsVisiblePassword(!isVisiblePassword)}>
              {isVisiblePassword ? <ShowPassSVG /> : <HidePassSVG />}
            </div>
          )) ||
          (icon && <div className={styles.icon}>{icon}</div>)
        }
      />
      {error && (
        <div className={classNames('wrapperInputError', styles.error)}>
          {StringHelper.getFisrtMessage(error)}
        </div>
      )}

      {error && (
        <div className={'wrapperInputErrorTooltip'}>
          {StringHelper.getFisrtMessage(error)}
        </div>
      )}
    </div>
  )
}

export default UITextInput
