import { FileName, UploadMedia } from 'entities/common'
import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  ADD_IMPORT_CONTACTS_FAILURE,
  ADD_IMPORT_CONTACTS_REQUEST,
  ADD_IMPORT_CONTACTS_SUCCESS,
  CLEAR_CLIENTS_ERROR,
  GET_EXPORT_CONTACTS_FAILURE,
  GET_EXPORT_CONTACTS_REQUEST,
  GET_EXPORT_CONTACTS_SUCCESS,
  GET_MANAGER_CLIENTS,
  GET_MANAGER_CLIENTS_FAILURE,
  GET_MANAGER_CLIENTS_SUCCESS,
  GET_MANAGER_USERS,
  GET_MANAGER_USERS_FAILURE,
  GET_MANAGER_USERS_SUCCESS,
  GET_ERROR_IMPORT_REQUEST,
  GET_ERROR_IMPORT_SUCCESS,
  GET_ERROR_IMPORT_FAILURE,
  CLEAR_CLIENTS_BASE,
} from './Clients.constant'
import { ResponseFileType } from './Clients.type'

export const getManagerClientsAction = (query?: string) => ({
  type: GET_MANAGER_CLIENTS,
  payload: query,
})

export const getManagerClientsSuccessAction = (data: unknown) => ({
  type: GET_MANAGER_CLIENTS_SUCCESS,
  payload: data,
})

export const getManagerClientsFailureAction = (error: ResponseFailureType) => ({
  type: GET_MANAGER_CLIENTS_FAILURE,
  payload: error,
})

export const getManagerUsersAction = (query?: string) => ({
  type: GET_MANAGER_USERS,
  payload: query,
})

export const getManagerUsersSuccessAction = (data: unknown) => ({
  type: GET_MANAGER_USERS_SUCCESS,
  payload: data,
})

export const getManagerUsersFailureAction = (error: ResponseFailureType) => ({
  type: GET_MANAGER_USERS_FAILURE,
  payload: error,
})

export const getExportContactsRequest = (query: string) => ({
  type: GET_EXPORT_CONTACTS_REQUEST,
  payload: query,
})

export const getExportContactsSuccess = (payload: ResponseFileType) => ({
  type: GET_EXPORT_CONTACTS_SUCCESS,
  payload,
})

export const getExportContactsFailure = (error: ResponseFailureType) => ({
  type: GET_EXPORT_CONTACTS_FAILURE,
  payload: error,
})

export const addImportContactsRequest = (payload: {
  file: UploadMedia
  fileName: FileName
  nameUpload: string
}) => ({
  type: ADD_IMPORT_CONTACTS_REQUEST,
  payload,
})

export const addImportContactsSuccess = (data: { [key: string]: string }) => ({
  type: ADD_IMPORT_CONTACTS_SUCCESS,
  payload: data,
})

export const addImportContactsFailure = (error: ResponseFailureType) => ({
  type: ADD_IMPORT_CONTACTS_FAILURE,
  payload: error,
})

export const getErrorImportRequest = () => ({
  type: GET_ERROR_IMPORT_REQUEST,
})

export const getErrorImportSuccess = (payload: ResponseFileType) => ({
  type: GET_ERROR_IMPORT_SUCCESS,
  payload,
})

export const getErrorImportFailure = (error: ResponseFailureType) => ({
  type: GET_ERROR_IMPORT_FAILURE,
  payload: error,
})

export const clearClientsError = () => ({
  type: CLEAR_CLIENTS_ERROR,
})

export const clearClientsBase = () => ({
  type: CLEAR_CLIENTS_BASE,
})
