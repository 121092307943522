import { FullMedia } from 'entities/common'
import { ResponseFailureType } from 'store/Auth/Auth.type'
import {
  SalonDataRequestType,
  SalonDataType,
  SalonPhoneType,
} from 'views/manager/ProfileSalon/index.type'

import {
  GET_MANAGER_PROFILE_REQUEST,
  GET_MANAGER_PROFILE_SUCCESS,
  UPDATE_MANAGER_PROFILE_REQUEST,
  UPDATE_MANAGER_PROFILE_SUCCESS,
  GET_MANAGER_PROFILE_FAILURE,
  GET_MANAGER_PHONE_REQUEST,
  GET_MANAGER_PHONE_SUCCESS,
  GET_MANAGER_PHONE_FAILURE,
  CREATE_MANAGER_PHONE_REQUEST,
  CREATE_MANAGER_PHONE_SUCCESS,
  CREATE_MANAGER_PHONE_FAILURE,
  DELETE_MANAGER_PHONE_REQUEST,
  DELETE_MANAGER_PHONE_SUCCESS,
  DELETE_MANAGER_PHONE_FAILURE,
  UPDATE_MANAGER_PHONE_REQUEST,
  UPDATE_MANAGER_PHONE_SUCCESS,
  UPDATE_MANAGER_PHONE_FAILURE,
  RESET_PHONE,
  RESET_ERROR,
  SET_IMAGE_LIST,
  UPDATE_MANAGER_PROFILE_FAILURE,
  CLEAR_FIRST_MESSAGE,
} from './Salon.constant'
import { SalonPhoneRequestType } from './Salon.type'

export const updateProfileRequest = (payload: SalonDataRequestType) => ({
  type: UPDATE_MANAGER_PROFILE_REQUEST,
  payload,
})

export const updateProfileSuccess = () => ({
  type: UPDATE_MANAGER_PROFILE_SUCCESS,
})

export const updateProfileFailure = (payload: ResponseFailureType) => ({
  payload,
  type: UPDATE_MANAGER_PROFILE_FAILURE,
})

export const getProfileRequest = () => ({
  type: GET_MANAGER_PROFILE_REQUEST,
})

export const getProfileSuccess = (payload: {
  data: SalonDataType
  message?: string
}) => ({
  payload,
  type: GET_MANAGER_PROFILE_SUCCESS,
})

export const getProfileFailure = () => ({
  type: GET_MANAGER_PROFILE_FAILURE,
})

export const getPhoneRequest = () => ({
  type: GET_MANAGER_PHONE_REQUEST,
})

export const getPhoneSuccess = () => ({
  type: GET_MANAGER_PHONE_SUCCESS,
})

export const getPhoneFailure = () => ({
  type: GET_MANAGER_PHONE_FAILURE,
})

export const createPhoneRequest = (payload: SalonPhoneRequestType) => ({
  type: CREATE_MANAGER_PHONE_REQUEST,
  payload,
})

export const createPhoneSuccess = (payload: SalonPhoneType) => ({
  payload,
  type: CREATE_MANAGER_PHONE_SUCCESS,
})

export const createPhoneFailure = (payload: ResponseFailureType) => ({
  payload,
  type: CREATE_MANAGER_PHONE_FAILURE,
})

export const deletePhoneRequest = (payload: number) => ({
  type: DELETE_MANAGER_PHONE_REQUEST,
  payload,
})

export const deletePhoneSuccess = (payload: number) => ({
  payload,
  type: DELETE_MANAGER_PHONE_SUCCESS,
})

export const deletePhoneFailure = (payload: ResponseFailureType) => ({
  payload,
  type: DELETE_MANAGER_PHONE_FAILURE,
})

export const updatePhoneRequest = (payload: SalonPhoneType) => ({
  type: UPDATE_MANAGER_PHONE_REQUEST,
  payload,
})

export const updatePhoneSuccess = (payload: SalonPhoneType) => ({
  payload,
  type: UPDATE_MANAGER_PHONE_SUCCESS,
})

export const updatePhoneFailure = (payload: ResponseFailureType) => ({
  payload,
  type: UPDATE_MANAGER_PHONE_FAILURE,
})

export const resetPhone = () => ({
  type: RESET_PHONE,
})

export const resetError = () => ({
  type: RESET_ERROR,
})

export const setImageList = (payload: FullMedia[] | null) => ({
  payload,
  type: SET_IMAGE_LIST,
})

export const clearFirstMessage = () => ({
  type: CLEAR_FIRST_MESSAGE,
})
