import { memo, FC, SyntheticEvent } from 'react'

import { Form } from 'antd'

import { UITextArea, UIUploadFile } from 'ui'
import UITextInput from 'ui/TextInput'
import { OnUploadFileChange } from 'ui/UploadFile/index.type'

import {
  TemplateFieldsPropsType,
  TYPE_EDITOR,
  TYPE_FILE,
  TYPE_INPUT,
  TYPE_TEXTAREA,
} from './index.type'
import { Editor, EditorState } from 'react-draft-wysiwyg'

const TemplateField: FC<TemplateFieldsPropsType> = ({
  label,
  name,
  value,
  onChange,
  selectedName,
  type = TYPE_INPUT,
  widthRatio,
  heightRatio,
}) => {
  const onEditorStateChange = (data: EditorState, fieldName: string) => {
    onChange({
      target: {
        name: fieldName,
        value: data,
      },
    } as unknown as SyntheticEvent)
  }

  return (
    <Form.Item label={label}>
      {type === TYPE_INPUT && (
        <UITextInput value={value} name={name} onChange={onChange} />
      )}

      {type === TYPE_FILE && (
        <UIUploadFile
          name={name}
          selectedName={selectedName}
          onChange={onChange as unknown as OnUploadFileChange}
          widthRatio={widthRatio}
          heightRatio={heightRatio}
        />
      )}

      {type === TYPE_TEXTAREA && (
        <UITextArea value={value} name={name} onChange={onChange} rows={6} />
      )}

      {type === TYPE_EDITOR && (
        <Editor
          editorState={value as unknown as EditorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(data) => onEditorStateChange(data, name)}
        />
      )}
    </Form.Item>
  )
}

export default memo(TemplateField)
