import { SeoSettingType } from 'store/Admin/Seo/Seo.type'
import { GET, POST } from 'utils/http'

const basePath = '/admin/category'

export const getSeoCategory = (id: number) => GET(`${basePath}/${id}`)

export const getSeoSubCategory = (id: number) =>
  GET(`${basePath}/section/${id}`)

export const getSeoSettings = () => GET('admin/settings')

export const updateSeoSettings = (data: SeoSettingType) =>
  POST('admin/settings', data)
