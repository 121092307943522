import { call, fork, put, takeLatest } from 'redux-saga/effects'

import {
  editAdminCurrentPageApi,
  getAdminCurrentPageApi,
  getAdminPagesApi,
} from 'services/http'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  getAdminCurrentPageFailure,
  getAdminCurrentPageSuccess,
  getAdminPagesFailure,
  getAdminPagesSuccess,
  setAdminCurrentPageFailure,
  setAdminCurrentPageSuccess,
} from './Pages.action'
import {
  GET_ADMIN_CURRENT_PAGE_REQUEST,
  GET_ADMIN_PAGES_REQUEST,
  SET_ADMIN_CURRENT_PAGE_REQUEST,
} from './Pages.constant'
import {
  IAdminPagesResponse,
  IGetAdminCurrentPageRequest,
  IGetAdminPagesRequest,
  ISetAdminCurrentPageRequest,
} from './Pages.type'

function* workerGetAdminPages({ payload }: IGetAdminPagesRequest) {
  try {
    const response: { data: IAdminPagesResponse[] } = yield call(
      getAdminPagesApi,
      payload,
    )

    yield put(getAdminPagesSuccess(response.data))
  } catch (error) {
    yield getRequestErrors(getAdminPagesFailure, error as string)
  }
}

function* watchGetAdminPages() {
  yield takeLatest(GET_ADMIN_PAGES_REQUEST, workerGetAdminPages)
}

function* workerGetAdminCurrentPage({ payload }: IGetAdminCurrentPageRequest) {
  try {
    const response: { data: { data: IAdminPagesResponse } } = yield call(
      getAdminCurrentPageApi,
      payload,
    )

    yield put(getAdminCurrentPageSuccess(response.data?.data))
  } catch (error) {
    yield
    getRequestErrors(getAdminCurrentPageFailure, error as string)
  }
}

function* watchGetAdminCurrentPage() {
  yield takeLatest(GET_ADMIN_CURRENT_PAGE_REQUEST, workerGetAdminCurrentPage)
}

function* workerSetAdminCurrentPage({ payload }: ISetAdminCurrentPageRequest) {
  try {
    yield call(editAdminCurrentPageApi, payload)

    yield put(setAdminCurrentPageSuccess(payload.data))
  } catch (error) {
    yield
    getRequestErrors(setAdminCurrentPageFailure, error as string)
  }
}

function* watchSetAdminCurrentPage() {
  yield takeLatest(SET_ADMIN_CURRENT_PAGE_REQUEST, workerSetAdminCurrentPage)
}

export const adminPagesWatchers = [
  fork(watchGetAdminPages),
  fork(watchGetAdminCurrentPage),
  fork(watchSetAdminCurrentPage),
]
