/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react'

import { Spin } from 'antd'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { updateSettingsRequest } from 'store/Manager/Settings/Settings.action'
import { IRootState } from 'store/Root.reducer'
import {
  UIButton,
  UISelect,
  UISwitch,
  UITextArea,
  UITimePicker,
  UITooltip,
} from 'ui'
import UITextInput from 'ui/TextInput'

import { INITIAL_VALUES } from './constant'
import { ValuesType } from './index.type'
import styles from './style.module.scss'
import SettingPassword from 'views/manager/Setting/Password'
import SettingGoogleCalendar from './GoogleCalendar'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_APP_ID } from 'utils'
import { getProfileRequest } from 'store/Manager/Salon'

const ManagerSetting = () => {
  const { isLoading, settings } = useSelector(
    (state: IRootState) => state.settings,
  )

  const [values, setValues] = useState<ValuesType>(INITIAL_VALUES)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSave = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {
      status,
      answer,
      url,
      notification,
      notification_duration,
      timeslot_duration,
    } = values

    dispatch(
      updateSettingsRequest({
        reminder_notification_status: notification || false,
        reminder_notification_time: notification_duration
          ? format(notification_duration, 'HH:mm')
          : null,
        health_status: status as 1 | 0,
        health_answer: answer,
        health_url: url,
        timeslot_duration,
      }),
    )
  }

  const handleChangeSwitch = () => {
    setValues((prevState) => ({
      ...prevState,
      notification: !prevState.notification,
    }))
  }

  const handleTimeSlotChange = (val: number) => {
    setValues((prevState) => ({
      ...prevState,
      timeslot_duration: val,
    }))
  }

  const timeSalonOptions = [
    { value: 5, label: `5 ${t('COMMON.MINUTS')}` },
    { value: 10, label: `10 ${t('COMMON.MINUTS')}` },
    { value: 15, label: `15 ${t('COMMON.MINUTS')}` },
    { value: 20, label: `20 ${t('COMMON.MINUTS')}` },
    { value: 30, label: `30 ${t('COMMON.MINUTS')}` },
    { value: 45, label: `45 ${t('COMMON.MINUTS')}` },
    { value: 60, label: `1 ${t('COMMON.HOUR')}` },
    { value: 90, label: `1.5 ${t('COMMON.HOURS')}` },
    { value: 120, label: `2 ${t('COMMON.HOURS')}` },
  ]

  useEffect(() => {
    if (settings) {
      setValues(() => {
        const [hour, minute] = (
          settings.reminder_notification?.time || ':'
        ).split(':')

        return {
          ...settings.health_form,
          timeslot_duration: settings?.timeslot_duration,
          notification: settings?.reminder_notification?.status || false,
          notification_duration: settings.reminder_notification?.time
            ? new Date(2000, 1, 1, Number(hour), Number(minute))
            : null,
        } as ValuesType
      })
    }
  }, [settings])

  useEffect(() => {
    dispatch(getProfileRequest())
  }, [dispatch])

  return (
    <Spin spinning={isLoading}>
      <div className={styles['border-wrapper']}>
        <div className={styles.item}>
          <div className={styles.switchWrap}>
            <UISwitch
              onChange={handleChangeSwitch}
              checked={values.notification}
            />
            <span>{t('MANAGER.SETTING.NOTIFICATION')}</span>
          </div>

          {values.notification && (
            <>
              <div className={styles.description}>
                {t('MANAGER.SETTING.NOTIFICATION_DESCRIPTION')}
              </div>
              <p className={styles.subDescription}>
                {t('MANAGER.SETTING.SET_TIME')}
              </p>
              <div className={styles.timePicker}>
                <UITimePicker
                  placeholder={t('COMMON.SELECT')}
                  format="HH:mm"
                  onSelect={(time: Date | null) =>
                    setValues({ ...values, notification_duration: time })
                  }
                  value={values.notification_duration}
                />
              </div>
            </>
          )}
        </div>
        {/* Backlog */}
        {/* <div className={styles.item}>
        <div className={styles.switchWrap}>
          <UISwitch
            defaultChecked
            onChange={() =>
              setValues({ ...values, feedback: !values.feedback })
            }
            checked={values.feedback}
            disabled
          />
          <span>{t('MANAGER.SETTING.FEEDBACK')}</span>
        </div>
        <div className={styles.description}>
          {t('MANAGER.SETTING.FEEDBACK_DESCRIPTION')}
        </div>
        <div className={styles.timePicker}>
          <UITimePicker
            disabled
            placeholder={t('COMMON.SELECT')}
            label={t('MANAGER.SETTING.SET_TIME')}
            format="HH:mm"
            onChange={(time: Date | null) =>
              setValues({ ...values, feedback_duration: time })
            }
          />
        </div>
      </div> */}
        <div>
          <div className={styles.switchWrap}>
            <UISwitch
              onChange={(checked) =>
                setValues({ ...values, status: checked ? 1 : 0 })
              }
              checked={!!values.status}
            />
            <span>{t('MANAGER.SETTING.HEALTH')}</span>
          </div>

          <div className={styles.description}>
            {t('MANAGER.SETTING.HEALTH_DESCRIPTION')}
          </div>

          <UITextArea
            className={styles.textarea}
            placeholder={t('MANAGER.SETTING.ANSWER_PLACEHOLDER')}
            value={values.answer}
            onChange={(e) => setValues({ ...values, answer: e.target.value })}
          />

          <UITextInput
            text={
              <>
                {t('MANAGER.SETTING.URL_GOOGLE')}{' '}
                <UITooltip content={t('MANAGER.SETTING.FILL_URL_GOOGLE')} />
              </>
            }
            placeholder={t('MANAGER.SETTING.ENTER_URL')}
            className={styles.urlInput}
            value={values.url}
            onChange={(e) => setValues({ ...values, url: e.target.value })}
          />

          <UISelect
            options={timeSalonOptions}
            label={t('COMMON.TIME_SALON_INTERVAL')}
            onChange={handleTimeSlotChange}
            placeholder={t('COMMON.TIME')}
            value={values.timeslot_duration}
          />
        </div>

        <UIButton
          type="primary"
          label={t('MANAGER.BUTTON.SAVE')}
          className={styles.btn}
          handler={handleSave}
        />
      </div>

      <GoogleOAuthProvider clientId={GOOGLE_APP_ID as string}>
        <SettingGoogleCalendar />
      </GoogleOAuthProvider>
      <SettingPassword />
    </Spin>
  )
}

export default ManagerSetting
