import { FC, memo, ChangeEvent } from 'react'

import TextArea from 'antd/lib/input/TextArea'
import classNames from 'classnames'

import { VALIDATION_CLASS } from 'utils'

import styles from './style.module.scss'
import { StringHelper } from 'utils/helpers'

type PropsType = {
  className?: string
  disabled?: boolean
  error?: string | string[]
  label?: string
  maxLength?: number
  name?: string
  necessaryLabel?: string
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  placeholder?: string
  rows?: number
  showCount?: boolean
  value?: string | number
}

const UITextArea: FC<PropsType> = ({
  className,
  label,
  necessaryLabel,
  value,
  placeholder,
  onChange,
  rows,
  name,
  error,
  ...props
}) => {
  return (
    <div
      className={classNames(styles.wrapper, className, {
        [VALIDATION_CLASS]: error,
      })}
    >
      {label && (
        <div className={styles.label}>
          {label}{' '}
          {necessaryLabel && (
            <span className={styles.necessaryLabel}>{necessaryLabel}</span>
          )}
        </div>
      )}
      <TextArea
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        rows={rows}
        name={name}
        {...props}
      />
      {error && (
        <p className="text-error desc-1">
          {StringHelper.getFisrtMessage(error)}
        </p>
      )}
    </div>
  )
}

export default memo(UITextArea)
