import { FC, useState, useEffect, memo } from 'react'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { TimeWeekType } from 'entities/manager/staff'
import {
  updateScheduleRequest,
  resetStore as resetShiftStore,
} from 'store/Manager/Shift/Shift.action'
import {
  getOneStaffRequest,
  resetStaffTime,
  resetStore,
} from 'store/Manager/Staff'
import { hideModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UICustomModal, UIDatePicker, UISelect } from 'ui'
import { FORMAT_DATE } from 'utils'

import { HourType, INITIAL_VALUES } from './constant'
import { DayTextType, PropsType, ValuesType } from './index.type'
import WidgetModalStaffEditHourList from './List'
import styles from './style.module.scss'
import Switch from 'ui/Switch'

const WidgetModalStaffEditHour: FC<PropsType> = ({ staffId, isShift }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    staffSchedule: stafTime,
    staff,
    isStaffAction,
    errorMessage,
    isShiftAction,
    isLoading,
    isRepeatRequest,
  } = useSelector((state: IRootState) => ({
    ...state.staff,
    isLoading: state.shift.isLoading,
    isShiftAction: state.shift.isShiftAction,
    isRepeatRequest: state.appointments.isRepeatRequest,
  }))
  const [values, setValues] = useState<ValuesType>(INITIAL_VALUES)
  const [isDisable, setIsDisable] = useState(true)
  const [isCustomize, setIsCustomize] = useState(false)

  const handleCancel = () => {
    dispatch(hideModal())
  }

  const handleChange = (type: HourType) => {
    setValues({ ...values, type })
  }

  const handleOk = () => {
    if (staffId) {
      dispatch(updateScheduleRequest({ body: values, id: staffId }))
      dispatch(getOneStaffRequest(staffId))
    }

    setValues({ ...values, type: HourType.IMMEDIATELY })
  }

  const disabledDate = (date: Date) =>
    date && date.valueOf() < new Date().valueOf()

  useEffect(() => {
    if (isRepeatRequest && staffId) {
      dispatch(updateScheduleRequest({ body: values, id: staffId }))
    }
  }, [dispatch, isRepeatRequest, staffId, values])

  useEffect(() => {
    if ((isStaffAction || isShiftAction) && staffId) {
      dispatch(getOneStaffRequest(staffId))
    }
  }, [dispatch, isShiftAction, isStaffAction, staffId])

  useEffect(() => {
    if ((isStaffAction || isShiftAction) && !errorMessage) {
      dispatch(resetStore())
      dispatch(resetShiftStore())
      setValues({ ...values, type: HourType.IMMEDIATELY })
      dispatch(hideModal())
    }
  }, [dispatch, errorMessage, isStaffAction, values, isShift, isShiftAction])

  useEffect(() => {
    if (stafTime) {
      const setInitilTime = (week: DayTextType) => ({
        ...((stafTime as unknown as TimeWeekType)?.[week]?.work?.from && {
          work: {
            from: (stafTime as unknown as TimeWeekType)?.[week].work.from,
            to: (stafTime as unknown as TimeWeekType)?.[week].work.to,
          },
        }),
        ...((stafTime as unknown as TimeWeekType)?.[week]?.break?.from && {
          break: {
            from: (stafTime as unknown as TimeWeekType)?.[week].break.from,
            to: (stafTime as unknown as TimeWeekType)?.[week].break.to,
          },
        }),
      })

      setValues({
        ...values,
        schedule: {
          friday: setInitilTime('friday'),
          monday: setInitilTime('monday'),
          saturday: setInitilTime('saturday'),
          sunday: setInitilTime('sunday'),
          thursday: setInitilTime('thursday'),
          tuesday: setInitilTime('tuesday'),
          wednesday: setInitilTime('wednesday'),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stafTime])

  useEffect(() => {
    const staffModalTime = stafTime as TimeWeekType
    let isFind = true

    const sortObj = {
      timeStart: [] as any[],
      timeEnd: [] as any[],
      breakStart: [] as any[],
      breakEnd: [] as any[],
    }

    Object.keys(staffModalTime).forEach((item) => {
      if (
        item === 'monday' ||
        item === 'thursday' ||
        item === 'tuesday' ||
        item === 'wednesday' ||
        item === 'friday'
      ) {
        if (staffModalTime[item].work) {
          sortObj.timeStart = [
            ...sortObj.timeStart,
            staffModalTime[item].work?.from,
          ]
          sortObj.timeEnd = [...sortObj.timeEnd, staffModalTime[item].work?.to]
        }
        if (staffModalTime[item].break) {
          sortObj.breakStart = [
            ...sortObj.breakStart,
            staffModalTime[item].break?.from,
          ]
          sortObj.breakEnd = [
            ...sortObj.breakEnd,
            staffModalTime[item].break?.to,
          ]
        }
      }
    })

    let firstEl = ''

    const getDisable = (arr: string[]) => {
      const [first] = arr

      firstEl = first

      if (arr.length <= 5) {
        arr.forEach((item) => {
          if (isFind) {
            isFind = item === firstEl
          }
        })
      } else {
        isFind = false
      }
    }

    getDisable(sortObj.timeStart as string[])
    getDisable(sortObj.timeEnd as string[])
    getDisable(sortObj.breakStart as string[])
    getDisable(sortObj.breakEnd as string[])

    setIsCustomize(!isFind || Object.keys(staffModalTime).length <= 6)
  }, [stafTime])

  const handleSwitchChange = (isCheck: boolean) => {
    setIsCustomize(isCheck)

    const weekName: DayTextType[] = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
    ]
    const newValues: ValuesType = {
      ...values,
      schedule: { ...values?.schedule },
    }

    if (!isCheck) {
      weekName.forEach((index) => {
        newValues.schedule[index] = {}
      })

      setValues(newValues)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetStaffTime())
    }
  }, [dispatch])

  return (
    <UICustomModal
      title={
        <div className={styles.headerWrapper}>
          <div>
            {t('MANAGER.STAFF.EDIT_HOURS')} - <span>{staff?.name}</span>
          </div>
          <div className={styles.wrapperSwitch}>
            <Switch
              checked={isCustomize}
              className={styles.switch}
              onChange={(e) => handleSwitchChange(e)}
            />
            <div className={styles.customizeTitle}>
              {t('MANAGER.PROFILE_SALON.CUSTOMIZE')}{' '}
            </div>
          </div>
        </div>
      }
    >
      <div className={styles.wrapper}>
        <Spin spinning={isLoading}>
          <WidgetModalStaffEditHourList
            setIsDisable={setIsDisable}
            isCustomize={isCustomize}
            setValues={setValues}
            values={values}
          />
          <div className={styles.line}></div>

          <div className={styles.footer}>
            <div className={styles.select}>
              <p className={styles.selectTitle}>
                {t('MANAGER.STAFF.EFFECTIVE')}
              </p>

              <div>
                <UISelect
                  defaultValue={HourType.IMMEDIATELY}
                  value={values.type}
                  onChange={handleChange}
                  className={styles.type}
                  options={[
                    {
                      value: HourType.IMMEDIATELY,
                      label: t('MANAGER.STAFF.IMMEDIATELY'),
                    },
                    {
                      value: HourType.NEXT_WEEK,
                      label: t('MANAGER.STAFF.NEXT_WEEK'),
                    },
                    {
                      value: HourType.CUSTOM,
                      label: t('MANAGER.STAFF.CUSTOM'),
                    },
                  ]}
                />

                {values.type === HourType.CUSTOM && (
                  <UIDatePicker
                    format={FORMAT_DATE}
                    onChange={(_, dateString) => {
                      setValues({
                        ...values,
                        started_at: dateString,
                      })
                    }}
                    disabledDate={disabledDate}
                  />
                )}
              </div>
            </div>

            <div className={styles.btn}>
              <UIButton
                label={t('COMMON.CANCEL')}
                handler={handleCancel}
                type="outline"
              />

              <UIButton
                label={t('COMMON.SAVE')}
                disabled={isDisable}
                handler={handleOk}
              />
            </div>
          </div>
        </Spin>
      </div>
    </UICustomModal>
  )
}

export default memo(WidgetModalStaffEditHour)
