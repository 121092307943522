export const GET_STAFF_REQUEST = '@staff/GET_STAFF_REQUEST'
export const GET_STAFF_SUCCESS = '@staff/GET_STAFF_SUCCESS'
export const GET_STAFF_FAILURE = '@staff/GET_STAFF_FAILURE'

export const GET_ONE_STAFF_REQUEST = '@staff/GET_ONE_STAFF_REQUEST'
export const GET_ONE_STAFF_SUCCESS = '@staff/GET_ONE_STAFF_SUCCESS'
export const GET_ONE_STAFF_TIME_SUCCESS = '@staff/GET_ONE_STAFF_TIME_SUCCESS'
export const GET_ONE_STAFF_FAILURE = '@staff/GET_ONE_STAFF_FAILURE'

export const GET_STAFF_TIME_REQUEST = '@staff/GET_STAFF_TIME_REQUEST'
export const GET_STAFF_TIME_SUCCESS = '@staff/GET_STAFF_TIME_SUCCESS'
export const GET_STAFF_TIME_FAILURE = '@staff/GET_STAFF_TIME_FAILURE'

export const DELETE_STAFF_REQUEST = '@staff/DELETE_STAFF_REQUEST'
export const DELETE_STAFF_SUCCESS = '@staff/DELETE_STAFF_SUCCESS'
export const DELETE_STAFF_FAILURE = '@staff/DELETE_STAFF_FAILURE'

export const UPDATE_STAFF_REQUEST = '@staff/UPDATE_STAFF_REQUEST'
export const UPDATE_STAFF_SUCCESS = '@staff/UPDATE_STAFF_SUCCESS'
export const UPDATE_STAFF_FAILURE = '@staff/UPDATE_STAFF_FAILURE'

export const ADD_STAFF_REQUEST = '@staff/ADD_STAFF_REQUEST'
export const ADD_STAFF_SUCCESS = '@staff/ADD_STAFF_SUCCESS'
export const ADD_STAFF_FAILURE = '@staff/ADD_STAFF_FAILURE'

export const GET_SERVICES_REQUEST = '@staff/GET_SERVICES_REQUEST'
export const GET_SERVICES_SUCCESS = '@staff/GET_SERVICES_SUCCESS'
export const GET_SERVICES_FAILURE = '@staff/GET_SERVICES_FAILURE'

export const UPDATE_STAFF_TIME_REQUEST = '@staff/UPDATE_STAFF_TIME_REQUEST'
export const UPDATE_STAFF_TIME_SUCCESS = '@staff/UPDATE_STAFF_TIME_SUCCESS'
export const UPDATE_STAFF_TIME_FAILURE = '@staff/UPDATE_STAFF_TIME_FAILURE'

export const GET_STAFF_SUBCATEGORY_REQUEST =
  '@staff/GET_STAFF_SUBCATEGORY_REQUEST'
export const GET_STAFF_SUBCATEGORY_SUCCESS =
  '@staff/GET_STAFF_SUBCATEGORY_SUCCESS'
export const GET_STAFF_SUBCATEGORY_FAILURE =
  '@staff/GET_STAFF_SUBCATEGORY_FAILURE'

export const GET_STAFF_SERVICES_BY_SUBCATEGORY_REQUEST =
  '@staff/GET_STAFF_SERVICES_BY_SUBCATEGORY_REQUEST'
export const GET_STAFF_SERVICES_BY_SUBCATEGORY_SUCCESS =
  '@staff/GET_STAFF_SERVICES_BY_SUBCATEGORY_SUCCESS'
export const GET_STAFF_SERVICES_BY_SUBCATEGORY_FAILURE =
  '@staff/GET_STAFF_SERVICES_BY_SUBCATEGORY_FAILURE'

export const RESET_STORE = '@staff/RESET_STORE'

export const SET_IMAGE_LIST = '@staff/SET_IMAGE_LIST'

export const SET_ACTIVE_STAFF = '@staff/SET_ACTIVE_STAFF'

export const SET_STAFF_STEP = '@staff/SET_STAFF_STEP'

export const CLEAR_ERRORS = '@staff/CLEAR_ERRORS'

export const RESET_STAFF = '@staff/RESET_STAFF'

export const CLEAR_STAFFS = '@staff/CLEAR_STAFFS'

export const SET_IS_START_STAFF = '@staff/SET_IS_START_STAFF'

export const RESET_STAFF_TIME = '@staff/RESET_STAFF_TIME'

export const SET_MOBILE_TITLE_OPEN = '@staff/SET_MOBILE_TITLE_OPEN'
