import { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { setImageList, updateStaffRequest } from 'store/Manager/Staff'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UIUploadMove } from 'ui'
import { GeneralValueType } from 'ui/UploadMove/index.type'
import { StaffValuesType } from 'views/manager/Staff/EditStaff/index.type'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const ServicesUIPortfolio: FC<PropsType> = ({ staff }) => {
  const { images, isloading } = useSelector((state: IRootState) => state.media)

  const [values, setValues] = useState<StaffValuesType>({
    name: '',
    description: '',
    position: '',
    phone: '',
    avatar: null,
    color: '',
    images: [],
  })
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const handlerClick = () => {
    if (staff) {
      dispatch(updateStaffRequest({ body: values, id: staff?.id }))
    }
  }

  useEffect(() => {
    if (staff) {
      setValues({
        name: staff?.name,
        description: staff?.description,
        position: staff?.position,
        phone: staff?.phone,
        avatar: staff?.avatar?.id,
        color: staff?.color,
        images: staff?.images?.map((i) => i.id),
      })
    }
  }, [staff])

  return (
    <div>
      <UIUploadMove
        startImage={staff?.images}
        images={images}
        setValues={
          setValues as (value: React.SetStateAction<GeneralValueType>) => void
        }
        values={values as GeneralValueType}
        setImageList={setImageList}
        isLoading={isloading}
        className={styles.uploadMoveWrapp}
        widthRatio={1.14}
        heightRatio={1}
      />

      <div className={styles.btn}>
        <UIButton handler={handlerClick} label={t('MANAGER.STAFF.SAVE')} />
      </div>
    </div>
  )
}

export default ServicesUIPortfolio
