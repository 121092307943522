import { FC, memo } from 'react'

import { ICustomer } from 'entities/common'
import { IManagerClientsUsersData } from 'store/Manager/Clients/Clients.type'
import { UIAvatar } from 'ui'

import styles from './style.module.scss'
import plugAvatar from '../../../../assets/images/common/redesign/plugAvatarDefault.svg'

type PropsType = (ICustomer | IManagerClientsUsersData) & {
  setIsDrawer?: (arg: boolean) => void
}

const ClientTableRow: FC<PropsType> = ({
  setIsDrawer,
  id,
  name,
  phone,
  ...props
}) => {
  return (
    <tr className={styles.clientRow}>
      <td className={styles.avatar}>
        <div>
          <UIAvatar
            size={40}
            src={(props as ICustomer)?.profile?.avatar?.path || plugAvatar}
          />
          <p>{name}</p>
        </div>
      </td>
      <td>{(props as ICustomer)?.email || '---'}</td>
      <td>{phone || (props as ICustomer)?.profile?.phone || '---'}</td>
    </tr>
  )
}

export default memo(ClientTableRow)
