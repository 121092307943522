import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { UIButton, UICustomModal } from 'ui'

import styles from './style.module.scss'

type PropsType = {
  handler: () => void
  handlerClose: () => void
  title: string
}

const WidgetModalDeactivateCategory: FC<PropsType> = ({
  handler,
  title,
  handlerClose,
}) => {
  const { t } = useTranslation()

  return (
    <UICustomModal handlerClose={handlerClose} isBack={false}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <UIButton handler={handler} label={t('COMMON.DEACTIVATE')} />
      </div>
    </UICustomModal>
  )
}

export default WidgetModalDeactivateCategory
