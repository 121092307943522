import { SHOW_MODAL, HIDE_MODAL } from './Modal.constant'
import { IModalState, ModalActionsTypes } from './Modal.type'

const initialState: IModalState = {
  modalType: null,
  modalProps: {},
  active: false,
}

export default function modalReducer(
  state = initialState,
  action: ModalActionsTypes,
): IModalState {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        ...state,
        ...action.payload,
        active: true,
      }
    }
    case HIDE_MODAL:
      return {
        ...state,
        modalType: null,
        modalProps: {},
        active: false,
      }
    default:
      return state
  }
}
