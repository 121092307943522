import { useSocket } from 'hooks/useSocket'
import { MenuNotificationName } from 'modules/manager/constants/sidebar'
import { useDispatch } from 'react-redux'
import { setManagerNotifications } from 'store/Manager/Notifications/Notifications.action'
import { ManagerSocketEvent } from '..'

export const ManagerNewFeedbackSocket = () => {
  const dispatch = useDispatch()

  useSocket({
    type: ManagerSocketEvent.NEW_FEED_BACK,
    callBack: () => {
      dispatch(setManagerNotifications(MenuNotificationName.FEEDBACKS))
    },
  })
}
