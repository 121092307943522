import {
  ARRAY_HOUR,
  ARRAY_MIN,
  FORMAT_TIME_HOUR,
  FORMAT_TIME_MIN,
  STANDART_DATE,
} from 'utils'

// eslint-disable-next-line import/no-cycle
import DateHelper from './date'

export default (() => {
  const timeFormat = (time: string | Date, format = FORMAT_TIME_HOUR) => {
    return (
      time && +DateHelper.toFormat(new Date(`${STANDART_DATE} ${time}`), format)
    )
  }

  const disabledTimeStart = (
    timeEnd: Date | string,
    timeStart?: Date | string,
    isMin?: boolean,
  ) => {
    const hours = [] as number[]
    const min = [] as number[]

    const formatTimeEnd =
      timeEnd &&
      +DateHelper.toFormat(
        new Date(`${STANDART_DATE} ${timeEnd}`),
        FORMAT_TIME_HOUR,
      )

    const formatTimeStart =
      timeStart &&
      +DateHelper.toFormat(
        new Date(`${STANDART_DATE} ${timeStart}`),
        FORMAT_TIME_HOUR,
      )

    const formatMinTimeEnd =
      timeEnd &&
      +DateHelper.toFormat(
        new Date(`${STANDART_DATE} ${timeEnd}`),
        FORMAT_TIME_MIN,
      )

    ARRAY_HOUR.forEach((time) => {
      if (formatTimeEnd && time > formatTimeEnd) {
        hours.push(+time)
      }
    })

    ARRAY_MIN.forEach((time) => {
      if (formatTimeStart === formatTimeEnd && time >= formatMinTimeEnd) {
        min.push(time)
      }
    })

    return isMin ? min : hours
  }

  const disabledTimeEnd = (
    timeStart: Date | string,
    timeEnd?: Date | string,
    isMin?: boolean,
  ) => {
    const hours = [] as number[]
    const min = [] as number[]

    const formatTimeStart =
      timeStart &&
      +DateHelper.toFormat(
        new Date(`${STANDART_DATE} ${timeStart}`),
        FORMAT_TIME_HOUR,
      )

    const formatTimeEnd =
      timeEnd &&
      +DateHelper.toFormat(
        new Date(`${STANDART_DATE} ${timeEnd}`),
        FORMAT_TIME_HOUR,
      )

    const formatMinTimeStart =
      timeStart &&
      +DateHelper.toFormat(
        new Date(`${STANDART_DATE} ${timeStart}`),
        FORMAT_TIME_MIN,
      )

    if (timeStart) {
      ARRAY_HOUR.forEach((time) => {
        if (formatTimeStart && time < formatTimeStart) {
          hours.push(+time)
        }
      })
    }

    ARRAY_MIN.forEach((time) => {
      if (formatTimeStart === formatTimeEnd && time <= formatMinTimeStart) {
        min.push(time)
      }
    })

    return isMin ? min : hours
  }

  const disabledBreakStart = (
    timeStart: Date | string,
    timeEnd: Date | string,
    breakEnd: Date | string,
    breakStart: Date | string,
    isMin?: boolean,
  ) => {
    const hours = [] as number[]
    const min = [] as number[]

    const formatTimeStart = timeFormat(timeStart)

    const formatTimeEnd = timeFormat(timeEnd)

    const formatBreakEnd = timeFormat(breakEnd)

    const formatBreakStart = timeFormat(breakStart)

    const formatMinTimeStart = timeFormat(timeStart, FORMAT_TIME_MIN)

    const formatMinBreakEnd = timeFormat(breakEnd, FORMAT_TIME_MIN)

    if (isMin) {
      ARRAY_MIN.forEach((time) => {
        if (formatBreakStart === formatBreakEnd && time >= formatMinBreakEnd) {
          min.push(time)
        }
        if (
          formatBreakStart === formatTimeStart &&
          time <= formatMinTimeStart
        ) {
          min.push(time)
        }
      })

      return min
    }

    ARRAY_HOUR.forEach((time) => {
      if (
        time < formatTimeStart ||
        time >= formatTimeEnd ||
        time > formatBreakEnd
      ) {
        hours.push(+time)
      }
    })

    return hours
  }

  const disabledBreakEnd = (
    timeStart: Date | string,
    timeEnd: Date | string,
    breakStart: Date | string,
    breakEnd: Date | string,
    isMin?: boolean,
  ) => {
    const hours = [] as number[]
    const min = [] as number[]
    const formatTimeStart = timeFormat(timeStart)
    const formatTimeEnd = timeFormat(timeEnd)
    const formatBreakStart = timeFormat(breakStart)
    const formatBreakEnd = timeFormat(breakEnd)
    const formatMinBreakStart = timeFormat(breakStart, FORMAT_TIME_MIN)
    const formatMinTimeEnd = timeFormat(timeEnd, FORMAT_TIME_MIN)

    if (formatTimeStart && formatTimeEnd && formatBreakStart) {
      ARRAY_HOUR.forEach((time) => {
        if (
          time < formatTimeStart ||
          time > formatTimeEnd ||
          time < formatBreakStart
        ) {
          hours.push(+time)
        }
      })
    }

    ARRAY_MIN.forEach((time) => {
      if (formatBreakStart === formatBreakEnd && time <= formatMinBreakStart) {
        min.push(time)
      }
      if (formatBreakEnd === formatTimeEnd && time >= formatMinTimeEnd) {
        min.push(time)
      }
    })

    return isMin ? min : hours
  }

  const onDisabledTime = (timeHour?: number[], timeMin?: number[]) => {
    return {
      disabledHours: () => timeHour || [],
      disabledMinutes: () => timeMin || [],
    }
  }

  return {
    disabledTimeStart,
    disabledBreakEnd,
    disabledBreakStart,
    disabledTimeEnd,
    onDisabledTime,
  }
})()
