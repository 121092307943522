import { FC } from 'react'

import { Spin, Tabs, TabsProps } from 'antd'
import { useTranslation } from 'react-i18next'

import { PropsType } from './index.type'
import styles from './style.module.scss'
import { StaffHours, StaffPortfolio, StaffServices } from '..'

const ServicesUITab: FC<PropsType> = ({
  setActiveTab,
  staff,
  services,
  setServices,
  isTimeLoading,
}) => {
  const { t } = useTranslation()

  const handlerChange = (e: string) => {
    setActiveTab(Number(e))
  }

  const menuList: TabsProps['items'] = [
    {
      key: '1',
      label: `${t('MANAGER.STAFF.SERVICES')} (${services.length})`,
      children: (
        <StaffServices
          services={services}
          setServices={setServices}
          staffId={staff?.id}
        />
      ),
    },
    {
      key: '2',
      label: t('MANAGER.STAFF.WORKING_HOURS'),
      children: (
        <Spin spinning={isTimeLoading}>
          <StaffHours staffId={staff?.id} />
        </Spin>
      ),
    },
    {
      key: '3',
      label: t('MANAGER.STAFF.PORTFOLIO'),
      children: <StaffPortfolio staff={staff} />,
    },
  ]

  return (
    <div className={styles.wrapper}>
      <Tabs
        onChange={(e: string) => handlerChange(e)}
        items={menuList}
        defaultActiveKey="0"
      />
    </div>
  )
}

export default ServicesUITab
