import {
  CategoryType,
  CreateCategoryType,
  EditCategoryType,
  SetCategoryType,
  SetSubCategoryType,
  SubCategoryType,
} from 'entities/view'
import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  ADMIN_ACTIVE_CATEGORY,
  EDIT_ADMIN_CATEGORY_REQUEST,
  EDIT_ADMIN_SUBCATEGORY,
  GET_ADMIN_CATEGORIES_REQUEST,
  GET_ADMIN_SUBCATEGORIES_REQUEST,
  SET_ADMIN_CATEGORIES,
  SET_ADMIN_NEW_CATEGORY,
  SET_ADMIN_NEW_CATEGORY_REQUEST,
  SET_ADMIN_NEW_SUBCATEGORY,
  SET_ADMIN_NEW_SUBCATEGORY_REQUEST,
  SET_ADMIN_SUBCATEGORIES,
  SORT_ADMIN_CATEGORIES_REQUEST,
  SORT_ADMIN_SUBCATEGORIES_REQUEST,
  EDIT_ADMIN_CATEGORY_SUCCESS,
  SET_ADMIN_NEW_CATEGORY_FAILURE,
  ADD_IMAGE_CATEGORY_FAILURE,
  SET_ADMIN_NEW_SUBCATEGORY_FAILURE,
  CLEAR_ERRORS,
  EDIT_ADMIN_CATEGORY_FAILURE,
  EDIT_ADMIN_SUBCATEGORY_FAILURE,
  SORT_ADMIN_CATEGORIES_FAILURE,
  SORT_ADMIN_SUBCATEGORIES_FAILURE,
  GET_ADMIN_CATEGORIES_FAILURE,
} from './Categories.constant'

export const getCategoriesRequest = (id?: number) => ({
  payload: id,
  type: GET_ADMIN_CATEGORIES_REQUEST,
})

export const getCategoriesFailure = (payload: ResponseFailureType) => ({
  payload,
  type: GET_ADMIN_CATEGORIES_FAILURE,
})

export const setCategories = (payload: CategoryType[]) => ({
  type: SET_ADMIN_CATEGORIES,
  payload,
})

export const setNewCategory = (payload: CategoryType) => ({
  type: SET_ADMIN_NEW_CATEGORY,
  payload,
})

export const setNewCategoryRequest = (payload: CreateCategoryType) => ({
  type: SET_ADMIN_NEW_CATEGORY_REQUEST,
  payload,
})

export const setNewCategoryFailure = (error: ResponseFailureType) => ({
  type: SET_ADMIN_NEW_CATEGORY_FAILURE,
  payload: error,
})

export const editCategoryRequest = (payload: EditCategoryType) => ({
  type: EDIT_ADMIN_CATEGORY_REQUEST,
  payload,
})

export const editCategorySuccess = (payload: SetCategoryType) => ({
  type: EDIT_ADMIN_CATEGORY_SUCCESS,
  payload,
})

export const editCategoryFailure = (payload: ResponseFailureType) => ({
  type: EDIT_ADMIN_CATEGORY_FAILURE,
  payload,
})

export const sortCategories = (payload: number[]) => ({
  type: SORT_ADMIN_CATEGORIES_REQUEST,
  payload,
})

export const sortCategoriesFailure = (payload: ResponseFailureType) => ({
  type: SORT_ADMIN_CATEGORIES_FAILURE,
  payload,
})

export const editSubcategory = (payload: SetSubCategoryType) => ({
  type: EDIT_ADMIN_SUBCATEGORY,
  payload,
})

export const editSubcategoryFailure = (payload: ResponseFailureType) => ({
  type: EDIT_ADMIN_SUBCATEGORY_FAILURE,
  payload,
})

export const setActiveCategory = (payload: number) => ({
  type: ADMIN_ACTIVE_CATEGORY,
  payload,
})

export const getSubcategoriesRequest = (payload: number) => ({
  type: GET_ADMIN_SUBCATEGORIES_REQUEST,
  payload,
})

export const setSubcategories = (payload: CategoryType[]) => ({
  type: SET_ADMIN_SUBCATEGORIES,
  payload,
})

export const setNewSubcategory = (payload: SubCategoryType) => ({
  type: SET_ADMIN_NEW_SUBCATEGORY,
  payload,
})

export const setNewSubcategoryRequest = (payload: SubCategoryType) => ({
  type: SET_ADMIN_NEW_SUBCATEGORY_REQUEST,
  payload,
})

export const setNewSubcategoryRequestFailure = (
  payload: ResponseFailureType,
) => ({
  type: SET_ADMIN_NEW_SUBCATEGORY_FAILURE,
  payload,
})

export const sortSubcategoriesRequest = (payload: number[]) => ({
  type: SORT_ADMIN_SUBCATEGORIES_REQUEST,
  payload,
})

export const sortSubcategoriesFailure = (payload: ResponseFailureType) => ({
  type: SORT_ADMIN_SUBCATEGORIES_FAILURE,
  payload,
})

export const addImageCategoryFailure = () => ({
  type: ADD_IMAGE_CATEGORY_FAILURE,
})

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
})
