import { FC, ReactNode } from 'react'

import cn from 'classnames'

import { ReactComponent as PlusSVG } from 'assets/images/common/redesign/plusCircle.svg'
import { UIButton } from 'ui'
import UIBack from 'ui/Back'

import styles from './style.module.scss'

type PropsType = {
  children: ReactNode
  className?: string
  contentCenterClassName?: string
  handleBack?: () => void
  handleHeader?: () => void
  headerClassName?: string
  headerContentCenter?: string | ReactNode
  headerRightContent?: string | ReactNode
  isBack?: boolean
  isMobileContentVisible?: boolean
  mobileActive?: boolean
  sidebarContent?: ReactNode
  titleBtnHeader?: string
  titleHeader?: ReactNode | string
  titleSidebar?: string
}

const LayoutManagerSidebar: FC<PropsType> = ({
  titleHeader,
  titleBtnHeader,
  titleSidebar,
  handleHeader,
  handleBack,
  sidebarContent,
  children,
  headerContentCenter,
  isBack,
  mobileActive,
  contentCenterClassName,
  headerClassName,
  className,
  headerRightContent,
  isMobileContentVisible,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.header, headerClassName)}>
        {isBack && handleBack ? (
          <UIBack onClick={handleBack} />
        ) : (
          <div className={styles.titleHeader}>{titleHeader}</div>
        )}

        {headerContentCenter && (
          <div className={cn(styles.contentCenter, contentCenterClassName)}>
            {headerContentCenter}
          </div>
        )}

        {!headerRightContent && titleBtnHeader && (
          <UIButton
            type="text"
            handler={handleHeader}
            icon={<PlusSVG />}
            label={titleBtnHeader}
          />
        )}

        {headerRightContent && headerRightContent}
      </div>

      <div className={styles.contentWrapp}>
        {!!sidebarContent && (
          <div
            className={cn(styles.sidebar, {
              [styles.hideScroll]: mobileActive,
            })}
          >
            {titleSidebar && (
              <div className={styles.sidebarTitle}>{titleSidebar}</div>
            )}

            {sidebarContent}
          </div>
        )}

        <div
          className={cn(styles.content, className, {
            [styles.active]: mobileActive,
            [styles.mobileInitial]: isMobileContentVisible,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default LayoutManagerSidebar
