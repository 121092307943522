import { put, takeLatest, fork, call, ForkEffect } from 'redux-saga/effects'

import { FullMedia } from 'entities/common'
import { MediaHttp } from 'services/http'
import { StringHelper } from 'utils/helpers'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  uploadMediaFailure,
  uploadMediaSuccess,
  uploadArrayMediaSuccess,
  deleteMediaFailure,
} from './Media.action'
import { UPLOAD_MEDIA_REQUEST, DELETE_MEDIA_REQUEST } from './Media.constant'
import { IUploadMediaRequest, IDeleteMediaRequest } from './Media.type'

function* workerUploadMedia({ payload }: IUploadMediaRequest) {
  const { file, fileName, nameUpload, isArray } = payload

  try {
    const { data }: { data: FullMedia } = yield call(
      MediaHttp.uploadFile,
      fileName,
      nameUpload,
      file,
    )

    if (!isArray) {
      yield put(uploadMediaSuccess({ data, name: nameUpload }))
    } else {
      yield put(uploadArrayMediaSuccess(data))
    }
  } catch (error) {
    yield getRequestErrors(
      uploadMediaFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchUploadMedia() {
  yield takeLatest(UPLOAD_MEDIA_REQUEST, workerUploadMedia)
}

function* workerDeleteMedia({ payload }: IDeleteMediaRequest) {
  try {
    yield call(MediaHttp.deleteFile, payload)
  } catch (error) {
    yield put(deleteMediaFailure())
  }
}

function* watchDeleteMedia() {
  yield takeLatest(DELETE_MEDIA_REQUEST, workerDeleteMedia)
}

export const mediaWatchers: ForkEffect[] = [
  fork(watchUploadMedia),
  fork(watchDeleteMedia),
]
