export const GET_SETTINGS_REQUEST = '@settings/GET_SETTINGS_REQUEST'
export const GET_SETTINGS_SUCCESS = '@settings/GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAILURE = '@settings/GET_SETTINGS_FAILURE'

export const UPADATE_SETTINGS_REQUEST = '@settings/UPADATE_SETTINGS_REQUEST'
export const UPADATE_SETTINGS_SUCCESS = '@settings/UPADATE_SETTINGS_SUCCESS'
export const UPADATE_SETTINGS_FAILURE = '@settings/UPADATE_SETTINGS_FAILURE'

export const SYNC_GOOGLE_CALENDAR_REQUEST =
  '@settings/SYNC_GOOGLE_CALENDAR_REQUEST'
export const SYNC_GOOGLE_CALENDAR_SUCCESS =
  '@settings/SYNC_GOOGLE_CALENDAR_SUCCESS'
export const SYNC_GOOGLE_CALENDAR_FAILURE =
  '@settings/SYNC_GOOGLE_CALENDAR_FAILURE'

export const DESYNC_GOOGLE_CALENDAR_REQUEST =
  '@settings/DESYNC_GOOGLE_CALENDAR_REQUEST'
export const DESYNC_GOOGLE_CALENDAR_SUCCESS =
  '@settings/DESYNC_GOOGLE_CALENDAR_SUCCESS'
export const DESYNC_GOOGLE_CALENDAR_FAILURE =
  '@settings/DESYNC_GOOGLE_CALENDAR_FAILURE'

export const RESET_ERROR = '@settings/RESET_ERROR'
