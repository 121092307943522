import cn from 'classnames'
import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { hideModal } from 'store/Modal'
import { UIButton, UICustomModal } from 'ui'

import styles from './style.module.scss'

type PropsType = {
  className?: string
  handler?: () => void
  isHtml?: boolean
  notifyTitle: string
}

const WidgetsModalNotify: FC<PropsType> = ({
  notifyTitle,
  handler,
  className,
  isHtml,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClick = () => {
    if (handler) {
      handler()
    }

    dispatch(hideModal())
  }

  return (
    <UICustomModal isBack={false} isClose={false}>
      <div className={cn(styles.notifyWrapper, className)}>
        {isHtml ? (
          <div
            className={styles.notifyTitle}
            dangerouslySetInnerHTML={{
              __html: notifyTitle,
            }}
          />
        ) : (
          <div className={styles.notifyTitle}>{notifyTitle}</div>
        )}

        <UIButton handler={handleClick} label={t('COMMON.OK')} />
      </div>
    </UICustomModal>
  )
}

export default WidgetsModalNotify
