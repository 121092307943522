import { FC, memo, SyntheticEvent } from 'react'

import { useTranslation } from 'react-i18next'

import { UIButton } from 'ui'
import UITextInput from 'ui/TextInput'

import style from './style.module.scss'

type PropsType = {
  block: number
  disabled?: boolean
  firstBtnName: string
  handleBlockClick: (arg: 0 | 1) => () => void
  handleSearchChange?: (arg: SyntheticEvent) => void
  searchValue?: string
  secondBtnName: string
}

const UIAdminTableHeader: FC<PropsType> = ({
  block,
  firstBtnName,
  secondBtnName,
  searchValue,
  handleSearchChange,
  handleBlockClick,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <div className={style.headerWrapper}>
      <div className={style.buttonWrapper}>
        <UIButton
          type={block ? 'primary' : 'outline'}
          handler={handleBlockClick(1)}
          label={firstBtnName}
          disabled={disabled}
        />

        <UIButton
          type={!block ? 'primary' : 'outline'}
          handler={handleBlockClick(0)}
          label={secondBtnName}
          disabled={disabled}
        />
      </div>

      <div className={style.input}>
        <UITextInput
          isSearch={true}
          value={searchValue}
          placeholder={t('COMMON.SEARCH')}
          onChange={handleSearchChange}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default memo(UIAdminTableHeader)
