import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import i18n from 'lib/i18n/i18n'
import { ShiftHttp } from 'services/http'
import { addAlert } from 'store/Alert'
import { AlertHelper } from 'utils/helpers'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  addTimeShiftFailure,
  addTimeShiftSuccess,
  copyShiftFailure,
  copyShiftSuccess,
  getOneScheduleFailure,
  getOneScheduleSuccess,
  getScheduleFailure,
  getScheduleSuccess,
  updateScheduleFailure,
  updateScheduleSuccess,
  updateShiftFailure,
  updateShiftSuccess,
} from './Shift.action'
import {
  ADD_TIME_SHIFT_REQUEST,
  COPY_SHIFT_REQUEST,
  GET_ONE_SCHEDULE_REQUEST,
  GET_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SHIFT_REQUEST,
} from './Shift.constant'
import {
  IAddTimeShiftRequest,
  ICopyShiftRequest,
  IGetOneScheduleRequest,
  IGetOneScheduleResponse,
  IGetScheduleRequest,
  IGetScheduleResponse,
  IUpdateScheduleRequest,
  IUpdateShiftRequest,
} from './Shifts.type'

function* workerGetSchedule({ payload }: IGetScheduleRequest) {
  try {
    const { data }: { data: IGetScheduleResponse } = yield call(
      ShiftHttp.getSchedule,
      payload,
    )

    yield put(getScheduleSuccess(data.data))
  } catch (error) {
    yield put(getScheduleFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetSchedule() {
  yield takeLatest(GET_SCHEDULE_REQUEST, workerGetSchedule)
}

function* workerGetOneSchedule({ payload }: IGetOneScheduleRequest) {
  try {
    const { data }: { data: IGetOneScheduleResponse } = yield call(
      ShiftHttp.getOneSchedule,
      payload,
    )

    yield put(getOneScheduleSuccess(data.data))
  } catch (error) {
    yield put(getOneScheduleFailure())
    yield put(AlertHelper.getError())
  }
}

function* watchGetOneSchedule() {
  yield takeLatest(GET_ONE_SCHEDULE_REQUEST, workerGetOneSchedule)
}

function* workerUpdateSchedule({ payload }: IUpdateScheduleRequest) {
  try {
    const { id, body } = payload

    yield call(ShiftHttp.updateSchedule, body, id)

    yield put(updateScheduleSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(updateScheduleFailure, error as string)
  }
}

function* watchUpdateSchedule() {
  yield takeLatest(UPDATE_SCHEDULE_REQUEST, workerUpdateSchedule)
}

function* workerUpdateShift({ payload }: IUpdateShiftRequest) {
  try {
    yield call(ShiftHttp.updateShift, payload)

    yield put(updateShiftSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(updateShiftFailure, error as string)
  }
}

function* watchUpdateShift() {
  yield takeLatest(UPDATE_SHIFT_REQUEST, workerUpdateShift)
}

function* workerAddTimeShift({ payload }: IAddTimeShiftRequest) {
  try {
    yield call(ShiftHttp.addTimeShift, payload)

    yield put(addTimeShiftSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_SAVED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(
      addTimeShiftFailure,
      // StringHelper.getError(error as { [key: string]: string[] }),
      error as string,
    )
  }
}

function* watchAddTimeShift() {
  yield takeLatest(ADD_TIME_SHIFT_REQUEST, workerAddTimeShift)
}

function* workerCopyShift({ payload }: ICopyShiftRequest) {
  try {
    yield call(ShiftHttp.copyShift, payload)

    yield put(copyShiftSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_SAVED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(copyShiftFailure, error as string)
  }
}

function* watchCopyShift() {
  yield takeLatest(COPY_SHIFT_REQUEST, workerCopyShift)
}

export const shiftWatchers: ForkEffect[] = [
  fork(watchGetSchedule),
  fork(watchGetOneSchedule),
  fork(watchUpdateSchedule),
  fork(watchUpdateShift),
  fork(watchAddTimeShift),
  fork(watchCopyShift),
]
