/* eslint-disable @typescript-eslint/naming-convention */
import { useState, SyntheticEvent, useEffect } from 'react'

import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import useDebounce from 'hooks/useDebounce'
import {
  clearSalonsError,
  getAdminSalonsRequest,
} from 'store/Admin/Salons/Salons.action'
import { addAlert } from 'store/Alert'
import { IRootState } from 'store/Root.reducer'
import { UICustomTable } from 'ui'
import UIAdminTableHeader from 'ui/AdminTableHeader'
import { ADMIN_SALONS, ADMIN_SALONS_PROFILE, QUERY_PARAMS } from 'utils'
import { getFromQueryString, getQueryString } from 'utils/helpers/query'

import style from './style.module.scss'
import SalonsTableRow from './TableRow'
import { getPlanSubscriptionsRequest } from 'store/Admin/Settings/Settings.action'

const ViewAdminSalons = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [block, setBlock] = useState(1)
  const [search, setSearch] = useState('')
  const {
    data,
    errorMessage,
    isLoading,
    meta: { perPage, total },
  } = useSelector((store: IRootState) => store.adminSalons)

  const {
    settings: { subscription_plan_free_trial_name },
  } = useSelector((state: IRootState) => state.adminSettings)
  const debouncedValue = useDebounce(search, 500)

  const handleChange = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement

    setSearch(value)

    if (value.length === 0 || value.length === 1) {
      navigate(
        `${ADMIN_SALONS}${getQueryString({
          page: 1,
        })}`,
      )
    }
  }

  const handleBlockClick = (value: 0 | 1) => () => {
    setBlock(value)

    navigate(`${location.pathname}?page=1`)
  }

  const handleDataClick = (id: number) => () => {
    navigate(ADMIN_SALONS_PROFILE.replace(':id', String(id)))
  }

  const columns = [
    { title: t('COMMON.SALONS') },
    { title: t('COMMON.TYPE_OF_SUBSCRIPTIONS') },
    { title: t('COMMON.SALON_STATUS') },
    { title: t('COMMON.EXPIRING_SUBSCRIPTION_DATE') },
  ]

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
        }),
      )
    }

    return () => {
      dispatch(clearSalonsError())
    }
  }, [dispatch, errorMessage])

  useEffect(() => {
    const { [QUERY_PARAMS.PAGE]: page } = getFromQueryString(location.search)

    let isSearch = false

    const renderObjToRequest = () => {
      if (search) {
        if (+page > 1) {
          isSearch = true
        }

        return getQueryString({
          [QUERY_PARAMS.ADMIN_ACTIVE_FILTER]: String(block),
          ...(debouncedValue && { [QUERY_PARAMS.SEARCH]: debouncedValue }),
          ...(isSearch && { [QUERY_PARAMS.PAGE]: +page || 1 }),
        })
      }

      isSearch = false

      return getQueryString({
        [QUERY_PARAMS.ADMIN_ACTIVE_FILTER]: String(block),
        ...(debouncedValue && { [QUERY_PARAMS.SEARCH]: debouncedValue }),
        [QUERY_PARAMS.PAGE]: Number(page) || 1,
      })
    }

    dispatch(getAdminSalonsRequest(renderObjToRequest()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block, dispatch, location.search, debouncedValue])

  useEffect(() => {
    dispatch(getPlanSubscriptionsRequest())
  }, [dispatch])

  return (
    <Content className={style.adminSalonsPage}>
      <h1>{t('COMMON.SALONS')}</h1>

      <UICustomTable
        columns={columns}
        headerChildren={
          <UIAdminTableHeader
            block={block}
            handleBlockClick={handleBlockClick}
            firstBtnName={t('COMMON.ACTIVE')}
            secondBtnName={t('COMMON.BLOCKED')}
            handleSearchChange={handleChange}
            searchValue={search}
          />
        }
        isLoading={isLoading}
        className={style.table}
        perPage={perPage}
        total={total}
      >
        {data?.map((item) => (
          <SalonsTableRow
            key={item.id}
            typeSubscription={item?.profile?.subscribe?.plan?.name}
            dateEnd={item?.profile?.subscribe?.finished_at}
            onDataClick={handleDataClick}
            freePlanName={subscription_plan_free_trial_name}
            {...item}
          />
        ))}
      </UICustomTable>
    </Content>
  )
}

export default ViewAdminSalons
