import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { Spin } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as CalendarSVG } from 'assets/images/common/redesign/calendar.svg'
import { ReactComponent as EditSVG } from 'assets/images/common/redesign/edit.svg'
import { ReactComponent as SearchSVG } from 'assets/images/common/redesign/search.svg'
import { ServiceType } from 'entities/manager/service'
import { StaffType } from 'entities/manager/staff'
import { CategoryType } from 'entities/view'
import useDebounce from 'hooks/useDebounce'
import useResponsive from 'hooks/useResponsive'
import ServicesUITab from 'modules/manager/staff/Tab/index'
import {
  getStaffRequest,
  setActiveStaff,
  setIsStartStaff,
  setMobileTitleOpen,
} from 'store/Manager/Staff'
import { sortRequest } from 'store/Sort/Sort.action'
import { UIAvatar, UIButton, UICategoriesCards } from 'ui'
import UIBack from 'ui/Back'
import UITextInput from 'ui/TextInput'
import { MANAGER_APPOINTMENTS, MANAGER_STAFF_EDIT } from 'utils'

import styles from './style.module.scss'

type PropsType = {
  active: number
  activeStaff?: number | null
  categories: CategoryType[]
  idxStaff?: number
  isMobileTitleOpen?: boolean
  isServiceLoading: boolean
  isStaffAction: boolean
  isTimeLoading: boolean
  services: ServiceType[]
  setActive: (value: number) => void
  setCategories: Dispatch<SetStateAction<CategoryType[]>>
  setServices: (value: ServiceType[]) => void
  staff: StaffType | null
}

const ServicesUIMembers: FC<PropsType> = ({
  services,
  categories,
  setActive,
  setCategories,
  active,
  setServices,
  staff,
  isServiceLoading,
  isTimeLoading,
  activeStaff,
  idxStaff,
  isStaffAction,
  isMobileTitleOpen,
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [search, setSearch] = useState('')
  const debouncedValue = useDebounce(search, 500)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isMobile, isTablet } = useResponsive()

  const handlerOpenMenuMobile = useCallback(() => {
    if (isMobile || isTablet) {
      setIsMobileOpen(true)
    }
  }, [isMobile, isTablet])

  const handleCategorySort = (idx: number[]) => {
    dispatch(sortRequest({ name: 'staff', idx }))
  }

  const handleCategoryClick = (id: number, index: number) => {
    dispatch(setActiveStaff({ id, idx: index }))
    dispatch(setIsStartStaff(false))
    handlerOpenMenuMobile()
  }

  const handleEditClick = () => {
    navigate(MANAGER_STAFF_EDIT.replace(':id', String(staff?.id)))
  }

  const handleSearch = (event: SyntheticEvent) => {
    setSearch((event.target as HTMLInputElement).value)
  }

  useEffect(() => {
    dispatch(getStaffRequest(debouncedValue))
  }, [debouncedValue, dispatch])

  return (
    <div className={styles.wrapper}>
      <div
        className={cn(styles.sidebar, {
          [styles.hideScroll]: isMobileOpen || isMobileTitleOpen,
        })}
      >
        <div className={styles.sidebarTitle}>
          {t('MANAGER.STAFF.STAFF_MEMBERS')}
        </div>

        <div className={styles.search}>
          <UITextInput
            isSearch
            value={search}
            onChange={handleSearch}
            icon={<SearchSVG />}
            placeholder={t('MANAGER.STAFF.SEARCH')}
          />
        </div>

        <div>
          <UICategoriesCards
            active={active}
            setActive={setActive}
            categories={categories}
            setCategories={setCategories}
            activeId={activeStaff}
            idxStaff={idxStaff}
            handlerSort={handleCategorySort}
            handler={handleCategoryClick}
          />
        </div>
      </div>

      {staff && (
        <div
          className={cn(styles.mainContent, {
            [styles.active]: isMobileOpen || isMobileTitleOpen,
          })}
        >
          {(isMobileOpen || isMobileTitleOpen) && (
            <UIBack
              type="title"
              setIsMobileOpen={() => {
                setIsMobileOpen(false)
                dispatch(setMobileTitleOpen(false))
              }}
              title={t('MANAGER.STAFF.STAFF_MEMBERS')}
            />
          )}

          <Spin spinning={isServiceLoading}>
            <div className={styles.header}>
              <div className={styles.headerAvatar}>
                <UIAvatar
                  className={styles.avatarWrapp}
                  src={staff?.avatar?.path}
                />
                <div className={styles.headerInfo}>
                  <div className={styles.headerName}>{staff?.name}</div>
                  <div className={styles.headerDescription}>
                    {staff?.position}
                  </div>
                  <div className={styles.headerPhone}>+{staff?.phone}</div>
                </div>
              </div>

              <div className={styles.headerBtn}>
                <UIButton
                  type="text"
                  icon={<EditSVG />}
                  handler={handleEditClick}
                  label={t('MANAGER.STAFF.EDIT_MASTER')}
                />

                <UIButton
                  type="text"
                  icon={<CalendarSVG />}
                  handler={() => {
                    localStorage.setItem('staff', String(true))
                    navigate(`${MANAGER_APPOINTMENTS}?staffId=${staff.id}`)
                  }}
                  label={t('MANAGER.STAFF.CALENDAR')}
                />
              </div>
            </div>

            <ServicesUITab
              isServiceLoading={isServiceLoading}
              isTimeLoading={isTimeLoading}
              services={services}
              setActiveTab={setActiveTab}
              setServices={setServices}
              staff={staff}
              activeTab={activeTab}
              isStaffAction={isStaffAction}
            />
          </Spin>
        </div>
      )}
    </div>
  )
}

export default memo(ServicesUIMembers)
