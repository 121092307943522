import { FC, ReactNode } from 'react'

import { Tabs } from 'antd'

import styles from './style.module.scss'

const { TabPane } = Tabs

type PropsType = {
  tabs: { element: ReactNode; name: string }[]
}

const BlogTabs: FC<PropsType> = ({ tabs }) => {
  return (
    <div className={styles.wrapper}>
      <Tabs defaultActiveKey="0">
        {tabs.map(({ name, element }, i) => {
          return (
            <TabPane key={i} tab={<span>{name}</span>}>
              <div className={styles.element}>{element}</div>
            </TabPane>
          )
        })}
      </Tabs>
    </div>
  )
}

export default BlogTabs
