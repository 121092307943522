import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { getRequestErrors } from 'utils/helpers/requests'

import {
  getAdminNotificationsFailure,
  getAdminNotificationsSuccess,
  getAdminSidebarNotificationsFailure,
  getAdminSidebarNotificationsSuccess,
} from './Notifications.action'
import {
  GET_ADMIN_NOTIFICATIONS_REQUEST,
  GET_ADMIN_SIDEBAR_NOTIFICATIONS_REQUEST,
} from './Notifications.constant'
import {
  AdminNotificationsResponseType,
  IAdminNotificationsType,
  IGetAdminNotificationsRequest,
} from './Notifications.type'
import {
  getAdminNotificationApi,
  getAdminSidebarNotificationApi,
} from '../../../services/http/admin/Notifications'

function* workerGetNotifications({ payload }: IGetAdminNotificationsRequest) {
  try {
    const response: { data: AdminNotificationsResponseType } = yield call(
      getAdminNotificationApi,
      String(payload),
    )

    yield put(getAdminNotificationsSuccess(response.data))
  } catch (error) {
    yield getRequestErrors(getAdminNotificationsFailure, error as string)
  }
}

function* watchGetNotifications() {
  yield takeLatest(GET_ADMIN_NOTIFICATIONS_REQUEST, workerGetNotifications)
}

function* workerGetSidebarNotifications() {
  try {
    const { data }: { data: IAdminNotificationsType } = yield call(
      getAdminSidebarNotificationApi,
    )

    yield put(getAdminSidebarNotificationsSuccess(data))
  } catch (error) {
    yield getRequestErrors(getAdminSidebarNotificationsFailure, error as string)
  }
}

function* watchGetSidebarNotifications() {
  yield takeLatest(
    GET_ADMIN_SIDEBAR_NOTIFICATIONS_REQUEST,
    workerGetSidebarNotifications,
  )
}

export const adminNotificationsWatchers = [
  fork(watchGetNotifications),
  fork(watchGetSidebarNotifications),
]
