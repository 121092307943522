import { createContext } from 'react'

import { ManagerSidebarContextPropsType } from './index.type'

export const ManagerSidebarContext =
  createContext<ManagerSidebarContextPropsType>({
    onToggleMenu: () => {},
    onOpenMenu: () => {},
    onCloseMenu: () => {},
    width: 0,
    height: 0,
    isShowHeader: false,
    isMenuOpen: false,
  })
export const ManagerSidebarContextProvider = ManagerSidebarContext.Provider
