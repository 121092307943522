import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'

const SubscriptionHeader = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p>{t('MANAGER.SUBSCRIPTION.PLAN')}</p>
        <p>{t('MANAGER.SUBSCRIPTION.STATUS')}</p>
        <p>{t('MANAGER.SUBSCRIPTION.PRICE')}</p>
        <p>{t('MANAGER.SUBSCRIPTION.DURATION')}</p>
        <p>{t('MANAGER.SUBSCRIPTION.ACTIONS')}</p>
      </div>
    </div>
  )
}

export default SubscriptionHeader
