import React, { FC } from 'react'

import StaffAddService from '../../../../modules/manager/staff/AddService'

import 'antd/dist/antd.css'

const ViewStaffServices: FC = () => {
  return <StaffAddService />
}

export default ViewStaffServices
