import { useSocket } from 'hooks/useSocket'
import { MenuNotificationName } from 'modules/manager/constants/sidebar'
import { useDispatch } from 'react-redux'
import { setManagerNotifications } from 'store/Manager/Notifications/Notifications.action'
import { ManagerSocketEvent } from '..'

export const ManagerSubscriptionExpiredSocket = () => {
  const dispatch = useDispatch()

  useSocket({
    type: ManagerSocketEvent.SUBSCRIPTION_EXPORED,
    callBack: () => {
      dispatch(setManagerNotifications(MenuNotificationName.SUBSCRIPTION))
    },
  })
}
