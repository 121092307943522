import { AdminFaqBodyType } from 'store/Admin/Faq/Faq.type'
import { DELETE, GET, POST } from 'utils/http'

const basePath = '/faq'

export const getAdminFaqApi = ({
  type,
  page,
}: {
  page: number
  type: 'business' | 'customer'
}) => GET(`${basePath}/${type}?page=${page}`)

export const getAdminCurrentFaqApi = (id: number) => GET(`${basePath}/${id}`)

export const addAdminFaqApi = (body: AdminFaqBodyType) =>
  POST(`${basePath}`, body)

export const editAdminFaqApi = ({
  body,
  id,
}: {
  body: AdminFaqBodyType
  id: number
}) => POST(`${basePath}/${id}`, body)

export const deleteAdminFaqApi = (ids: number[]) =>
  DELETE(
    `${basePath}${ids
      .reduce((prev, curr) => `${prev}ids[]=${curr}&`, '?')
      .slice(0, -1)}`,
  )
