import { put, all } from 'redux-saga/effects'

import { ValidationErrorType } from 'entities/manager/appointments'
// import i18n from 'lib/i18n/i18n'
import { ResponseFailureType } from 'store/Auth/Auth.type'
import { setValidationError } from 'store/Manager/Appointments/Appointments.action'
import { addValidationError } from 'store/Validation/Validation.action'

import getType from './getType'

export function getRequestErrors(
  action: (error: ResponseFailureType) => {
    payload: ResponseFailureType
    type: string
  },
  error:
    | string
    | { [key: string]: string[] }
    | { appointments: ValidationErrorType[] },
) {
  if (Object.prototype.hasOwnProperty.call(error, 'appointments')) {
    return all([
      // put(action({ errorMessage: i18n.t('COMMON.PLEASE_RESOLVE_CONFLICT') })),
      put(action({ errorMessage: '' })),
      put(
        setValidationError(
          (error as { appointments: ValidationErrorType[] }).appointments,
        ),
      ),
    ])
  }

  if (getType(error) === 'string') {
    return put(action({ errorMessage: error as string }))
  }

  return all([
    put(action({ errors: error as { [key: string]: string[] } })),
    put(addValidationError()),
  ])
}
