import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { UICheckbox } from 'ui'

import { PropsType } from './index.type'
import styles from '../style.module.scss'

const WidgetModalCopyShiftCheckbox: FC<PropsType> = ({
  staffs,
  setValues,
  values,
}) => {
  const { t } = useTranslation()

  const [isCheckAll, setIsCheckAll] = useState(false)

  const handleChange = (id: number) => {
    setIsCheckAll(false)

    if (values?.staff_ids?.find((idx) => idx === id)) {
      setValues({
        ...values,
        staff_ids: values?.staff_ids.filter((idx) => idx !== id),
      })
    } else {
      setValues({ ...values, staff_ids: [...values.staff_ids, id] })
    }
  }

  const handlerChangeAll = () => {
    setIsCheckAll(true)
    if (staffs) {
      setValues({ ...values, staff_ids: staffs?.map((item) => item.id) })
    }
  }

  return (
    <div className={styles.checkboxWrapp}>
      <p>{t('MANAGER.STAFF.FOR_SELECTED')}</p>

      <UICheckbox
        onChange={handlerChangeAll}
        checked={isCheckAll || staffs?.length === values?.staff_ids.length}
      >
        {t('MANAGER.STAFF.ALL')}
      </UICheckbox>
      <div className={styles.listCheckbox}>
        {staffs?.map((item) => {
          return (
            <UICheckbox
              checked={!!values.staff_ids?.find((idx) => idx === item.id)}
              onChange={() => handleChange(item.id)}
              key={item.id}
            >
              {item.name}
            </UICheckbox>
          )
        })}
      </div>
    </div>
  )
}

export default WidgetModalCopyShiftCheckbox
