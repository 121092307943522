/* eslint-disable import/no-cycle */
import { IManagerSubscribe } from '../../../store/Manager/Subscription/Subscription.type'

export type AdminSettingsBlockType = {
  isChecked: boolean
  value: string
}

export type AdminSettingsBlockSubscriptionsType = {
  active?: number
  description: string
  details: string[]
  finished_at?: string | null
  freeDuration?: { end: string; start: string }
  freeSubscribe?: IManagerSubscribe | null
  id?: number
  isHideAction?: boolean
  isHideSubscribe?: boolean
  isRemoved?: boolean
  name: string
  price?: number | string
  price_type?: number
  subscribe?: IManagerSubscribe | null
}

export type AdminSettingsBlockSubscriptionsSubsType = {
  expired?: boolean
  finished_at: string | null
  id: number
  manual: boolean
  subscribe_at: string | null
}

export const SETTINGS_PASSWORDS_INITIAL_STATE = {
  password: '',
  password_confirmation: '',
  password_old: '',
}

export const SETTINGS_SUBSCRIPTIONS_INITIAL_STATE: AdminSettingsBlockSubscriptionsType =
  {
    description: '',
    active: 0,
    details: [],
    price: 0,
    price_type: 0,
    name: '',
  }
