import { FC } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { UICheckbox, UIDatePicker, UITimePicker } from 'ui'
import { FORMAT_TIME_SHORT, STANDART_DATE } from 'utils'
import { DateHelper, WeekTimeHelper } from 'utils/helpers'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const WidgetModalAddTimeOffPicker: FC<PropsType> = ({
  setValues,
  values,
  isAll,
  isRepeat,
  setIsAll,
  setIsRepeat,
}) => {
  const { t } = useTranslation()

  const handleTimeChange = (date: Date | null, name: 'from' | 'to') => {
    if (date) {
      setValues({
        ...values,

        break: {
          ...values.break,
          [name]: DateHelper.toFormat(date, FORMAT_TIME_SHORT),
        },
      })
    }
  }

  const handleDateChange = (time: Date | null) => {
    setValues({
      ...values,
      to: time as Date,
    })
  }

  const disabledDateFrom = (date: Date) => {
    const ONE_DAY = 86300000

    return date && date.valueOf() < new Date().valueOf() - ONE_DAY
  }

  const disabledDateTo = (date: Date) => {
    return (values.from && date.valueOf() <= values?.from.valueOf()) || false
  }

  const timeStart = values?.break?.from as Date
  const timeEnd = values?.break?.to as Date

  return (
    <div className={styles.wrapper}>
      <div className={styles.fullTime}>
        <UICheckbox
          checked={isAll}
          onChange={(e) => setIsAll(e.target.checked)}
        >
          {t('MANAGER.STAFF.ALL_DAY')}
        </UICheckbox>
      </div>
      <div className={styles.pickerWrapper}>
        <div className={styles.datePicker}>
          <UIDatePicker
            format="dd-MM-yyyy"
            label={t('MANAGER.STAFF.SELECT_DATE')}
            value={values.from as Date}
            onChange={(time) => {
              if (!isRepeat) {
                setValues({ ...values, from: time as Date, to: time as Date })
              } else {
                setValues({ ...values, from: time as Date })
              }
            }}
            placement="bottomLeft"
            disabledDate={disabledDateFrom}
            {...(values.from &&
              values.to &&
              values.from > values.to && { status: 'error' })}
          />
        </div>

        {!isAll && (
          <div className={styles.timepickWrapp}>
            <UITimePicker
              placeholder={t('COMMON.SELECT')}
              format="HH:mm"
              value={timeStart && new Date(`${STANDART_DATE} ${timeStart}`)}
              onSelect={(date: Date | null) => handleTimeChange(date, 'from')}
              disabledTime={() =>
                WeekTimeHelper.onDisabledTime(
                  WeekTimeHelper.disabledTimeStart(timeEnd),
                  WeekTimeHelper.disabledTimeStart(
                    timeEnd || '00:00:00',
                    timeStart,
                    true,
                  ),
                )
              }
              {...(timeStart &&
                timeEnd &&
                timeStart >= timeEnd && { status: 'error' })}
            />
            <span className={styles.dash}></span>
            <UITimePicker
              placeholder={t('COMMON.SELECT')}
              format="HH:mm"
              value={timeEnd && new Date(`${STANDART_DATE} ${timeEnd}`)}
              onSelect={(date: Date | null) => handleTimeChange(date, 'to')}
              disabledTime={() =>
                WeekTimeHelper.onDisabledTime(
                  WeekTimeHelper.disabledTimeEnd(timeStart),
                  WeekTimeHelper.disabledTimeEnd(timeStart, timeEnd, true),
                )
              }
              {...(timeStart &&
                timeEnd &&
                timeStart >= timeEnd && { status: 'error' })}
            />
          </div>
        )}
        <UICheckbox
          onChange={(e) => setIsRepeat(e.target.checked)}
          className={styles.repeat}
          checked={isRepeat}
        >
          {t('MANAGER.STAFF.REPEAT')}
        </UICheckbox>
        {isRepeat && (
          <div className={cn(styles.datePicker, styles.repeatPicker)}>
            <UIDatePicker
              format="dd-MM-yyyy"
              label={t('MANAGER.STAFF.REPEAT_DAY')}
              value={values.to as Date}
              onChange={handleDateChange}
              disabledDate={disabledDateTo}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default WidgetModalAddTimeOffPicker
