import { SyntheticEvent, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import useDebounce from 'hooks/useDebounce'
import useResponsive from 'hooks/useResponsive'
import ClientTableRow from 'modules/manager/client/ClientTableRow'
import ClientHeader from 'modules/manager/client/Header'
import ClientSidebar from 'modules/manager/client/Sidebar'
import ClientTableMobile from 'modules/manager/client/TableMobile'
import { addAlert } from 'store/Alert'
import {
  clearClientsError,
  getManagerClientsAction,
  getManagerUsersAction,
} from 'store/Manager/Clients/Clients.action'
import { IRootState } from 'store/Root.reducer'
import { UICustomPagination, UIManagerTable } from 'ui'
import { MANAGER_CLIENTS, QUERY_PARAMS } from 'utils'
import { getFromQueryString, getQueryString } from 'utils/helpers/query'

import { columns } from './constant'
import { Mode } from './index.type'
import styles from './style.module.scss'

const ViewManagerClient = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { data, errorMessage, isLoading, meta } = useSelector(
    (store: IRootState) => store.managerClients,
  )
  const [activeMode, setActiveMode] = useState(Mode.CLIENT)
  const [isDrawer, setIsDrawer] = useState(false)
  const [search, setSearch] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const debouncedValue = useDebounce(search, 500)
  const { isMobile } = useResponsive()

  const handleSearch = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement

    setSearch(value)

    if (value.length === 0 || value.length === 1) {
      navigate(
        `${MANAGER_CLIENTS}${getQueryString({
          ...getFromQueryString(location.search),
          page: 1,
        })}`,
      )
    }
  }

  useEffect(() => {
    const { [QUERY_PARAMS.SEARCH]: querySearch, [QUERY_PARAMS.TAB]: tab } =
      getFromQueryString(location.search)

    setActiveMode((tab as Mode) || Mode.CLIENT)
    setSearch(querySearch || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const queries = getFromQueryString(location.search)

    const query = getQueryString({
      ...queries,
      [QUERY_PARAMS.SEARCH]: debouncedValue,
    })

    navigate(MANAGER_CLIENTS + query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  useEffect(() => {
    const {
      [QUERY_PARAMS.PAGE]: page,
      [QUERY_PARAMS.SEARCH]: querySearch,
      [QUERY_PARAMS.TAB]: tab,
    } = getFromQueryString(location.search)

    const query = getQueryString({
      [QUERY_PARAMS.SEARCH]: querySearch || '',
      [QUERY_PARAMS.PAGE]: page || 1,
    })

    const renderObjToRequest = () => {
      if (search) {
        if (+page > 1) {
          setIsSearch(true)
        }

        return getQueryString({
          [QUERY_PARAMS.SEARCH]: querySearch || '',
          ...(isSearch && { [QUERY_PARAMS.PAGE]: page || 1 }),
        })
      }

      setIsSearch(false)

      return query
    }

    if (tab) {
      const isClient = tab === Mode.CLIENT

      setActiveMode(isClient ? Mode.CLIENT : Mode.USERS)
      dispatch(
        (isClient ? getManagerUsersAction : getManagerClientsAction)(
          renderObjToRequest(),
        ),
      )
    } else {
      navigate(
        MANAGER_CLIENTS +
          getQueryString({
            [QUERY_PARAMS.SEARCH]: querySearch || '',
            [QUERY_PARAMS.TAB]: tab || Mode.CLIENT,
            [QUERY_PARAMS.PAGE]: page || 1,
          }),
        { replace: true },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
        }),
      )
    }

    return () => {
      dispatch(clearClientsError())
    }
  }, [dispatch, errorMessage])

  return (
    <>
      <ClientHeader
        search={search}
        activeMode={activeMode}
        onChange={handleSearch}
        setIsDrawer={setIsDrawer}
      />

      <div className={styles.wrapper}>
        {isMobile && (
          <div>
            {data.map((item, index) => (
              <ClientTableMobile
                key={index}
                setIsDrawer={setIsDrawer}
                {...item}
              />
            ))}

            {!!meta?.total && (
              <UICustomPagination
                perPage={meta?.perPage}
                total={meta?.total}
                isLoading={isLoading}
              />
            )}
          </div>
        )}

        {!isMobile && (
          <UIManagerTable
            columns={columns}
            isLoading={isLoading}
            perPage={meta.perPage}
            total={meta.total}
          >
            {data.map((item, index) => (
              <ClientTableRow key={index} {...item} />
            ))}
          </UIManagerTable>
        )}
      </div>
      {isDrawer && <ClientSidebar setIsDrawer={setIsDrawer} />}
    </>
  )
}

export default ViewManagerClient
