/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { call, fork, put, takeLatest } from 'redux-saga/effects'

import {
  getAdminCurrentUserApi,
  getAdminCustomersApi,
  setAdminCurrentUserDisabledApi,
} from 'services/http/admin/Customers'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  getAdminCurrentCustomerFailure,
  getAdminCurrentCustomerSuccess,
  getAdminCustomersFailure,
  getAdminCustomersSuccess,
  setAdminCurrentCustomerFailure,
  setAdminCurrentCustomerSuccess,
} from './Customer.action'
import {
  GET_ADMIN_CURRENT_CUSTOMER_REQUEST,
  GET_ADMIN_CUSTOMERS_REQUEST,
  SET_ADMIN_CURRENT_CUSTOMER_REQUEST,
} from './Customer.constant'
import {
  IAdminCustomerResponse,
  IAdminCustomerResponseData,
  IGetAdminCurrentCustomerRequest,
  IGetAdminCustomersRequest,
  ISetAdminCurrentCustomerRequest,
} from './Customer.type'

function* workerGetAdminCustomers({ payload }: IGetAdminCustomersRequest) {
  try {
    const response: { data: IAdminCustomerResponse } = yield call(
      getAdminCustomersApi,
      payload,
    )

    yield put(getAdminCustomersSuccess(response.data))
  } catch (error) {
    yield getRequestErrors(getAdminCustomersFailure, error as string)
  }
}

function* watchGetAdminCustomers() {
  yield takeLatest(GET_ADMIN_CUSTOMERS_REQUEST, workerGetAdminCustomers)
}

function* workerGetAdminCurrentCustomer({
  payload,
}: IGetAdminCurrentCustomerRequest) {
  try {
    const response: { data: { data: IAdminCustomerResponseData } } = yield call(
      getAdminCurrentUserApi,
      payload,
    )

    yield put(getAdminCurrentCustomerSuccess(response.data?.data))
  } catch (error) {
    yield getRequestErrors(getAdminCurrentCustomerFailure, error as string)
  }
}

function* watchGetAdminCurrentCustomer() {
  yield takeLatest(
    GET_ADMIN_CURRENT_CUSTOMER_REQUEST,
    workerGetAdminCurrentCustomer,
  )
}

function* workerSetAdminCurrentCustomer({
  payload,
}: ISetAdminCurrentCustomerRequest) {
  try {
    yield call(setAdminCurrentUserDisabledApi, payload)

    yield put(setAdminCurrentCustomerSuccess(payload.active))
  } catch (error) {
    yield getRequestErrors(setAdminCurrentCustomerFailure, error as string)
  }
}

function* watchSetAdminCurrentCustomer() {
  yield takeLatest(
    SET_ADMIN_CURRENT_CUSTOMER_REQUEST,
    workerSetAdminCurrentCustomer,
  )
}

export const adminCustomerWatchers = [
  fork(watchGetAdminCustomers),
  fork(watchGetAdminCurrentCustomer),
  fork(watchSetAdminCurrentCustomer),
]
