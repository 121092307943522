import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_ADMIN_CURRENT_TEMPLATE,
  GET_ADMIN_CURRENT_PAGE_REQUEST,
  GET_ADMIN_CURRENT_PAGE_FAILURE,
  GET_ADMIN_CURRENT_PAGE_SUCCESS,
  GET_ADMIN_PAGES_REQUEST,
  GET_ADMIN_PAGES_FAILURE,
  GET_ADMIN_PAGES_SUCCESS,
  SET_ADMIN_CURRENT_PAGE_REQUEST,
  SET_ADMIN_CURRENT_PAGE_FAILURE,
  SET_ADMIN_CURRENT_PAGE_SUCCESS,
} from './Pages.constant'
import { CurrentPageBodyRequest, IAdminPagesResponse } from './Pages.type'

export const getAdminPagesRequest = (query: string) => ({
  type: GET_ADMIN_PAGES_REQUEST,
  payload: query,
})

export const getAdminPagesSuccess = (payload: IAdminPagesResponse[]) => ({
  type: GET_ADMIN_PAGES_SUCCESS,
  payload,
})

export const getAdminPagesFailure = (payload: ResponseFailureType) => ({
  type: GET_ADMIN_PAGES_FAILURE,
  payload,
})

export const getAdminCurrentPageRequest = (payload: number) => ({
  type: GET_ADMIN_CURRENT_PAGE_REQUEST,
  payload,
})

export const getAdminCurrentPageSuccess = (payload: IAdminPagesResponse) => ({
  type: GET_ADMIN_CURRENT_PAGE_SUCCESS,
  payload,
})

export const getAdminCurrentPageFailure = (payload: ResponseFailureType) => ({
  type: GET_ADMIN_CURRENT_PAGE_FAILURE,
  payload,
})

export const setAdminCurrentPageRequest = (
  id: number,
  data: CurrentPageBodyRequest,
) => ({
  type: SET_ADMIN_CURRENT_PAGE_REQUEST,
  payload: { id, data },
})

export const setAdminCurrentPageSuccess = (
  payload: CurrentPageBodyRequest,
) => ({
  type: SET_ADMIN_CURRENT_PAGE_SUCCESS,
  payload,
})

export const setAdminCurrentPageFailure = (payload: ResponseFailureType) => ({
  type: SET_ADMIN_CURRENT_PAGE_FAILURE,
  payload,
})

export const clearAdminCurrentTemplate = () => ({
  type: CLEAR_ADMIN_CURRENT_TEMPLATE,
})
