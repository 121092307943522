/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  legacy_createStore as createStore,
  compose,
  applyMiddleware,
} from 'redux'
import createSagaMiddleware from 'redux-saga'

import { rootReducer } from './Root.reducer'
import { rootSagas } from './Root.saga'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers =
  typeof window !== 'undefined' &&
  // process.env.REACT_APP_DEVELOPMENT === 'development' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const bindMiddleware = composeEnhancers(applyMiddleware(sagaMiddleware))

const initStore = createStore(rootReducer, bindMiddleware)

sagaMiddleware.run(rootSagas)

export default initStore
