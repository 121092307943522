export const UPLOAD_DOCUMENT_REQUEST = '@document/UPLOAD_DOCUMENT_REQUEST'
export const UPLOAD_DOCUMENT_SUCCESS = '@document/UPLOAD_DOCUMENT_SUCCESS'
export const UPLOAD_DOCUMENT_FAILURE = '@document/UPLOAD_DOCUMENT_FAILURE'

export const DELETE_DOCUMENT_REQUEST = '@document/DELETE_DOCUMENT_REQUEST'
export const DELETE_DOCUMENT_SUCCESS = '@document/DELETE_DOCUMENT_SUCCESS'
export const DELETE_DOCUMENT_FAILURE = '@document/DELETE_DOCUMENT_FAILURE'

export const ADD_DOCUMENT_REQUEST = '@document/ADD_DOCUMENT_REQUEST'
export const ADD_DOCUMENT_SUCCESS = '@document/ADD_DOCUMENT_SUCCESS'
export const ADD_DOCUMENT_FAILURE = '@document/ADD_DOCUMENT_FAILURE'

export const GET_DOCUMENT_REQUEST = '@document/GET_DOCUMENT_REQUEST'
export const GET_DOCUMENT_SUCCESS = '@document/GET_DOCUMENT_SUCCESS'
export const GET_DOCUMENT_NOTIFICATION_SUCCESS =
  '@document/GET_DOCUMENT_NOTIFICATION_SUCCESS'
export const GET_DOCUMENT_FAILURE = '@document/GET_DOCUMENT_FAILURE'

export const CLEAR_DOCUMENT_STOR = '@document/CLEAR_DOCUMENT_STOR'

export const CLEAR_DOCUMENT_ERRORS = '@document/CLEAR_DOCUMENT_ERRORS'

export const FILTER_DOCUMENT_LIST = '@document/FILTER_DOCUMENT_LIST'

export const SET_LOADING = '@document/SET_LOADING'
