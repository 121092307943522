import { ReactComponent as NotificationSVG } from 'assets/images/common/newSidebar/notification.svg'
// backlog
// import { ReactComponent as BlogSVG } from 'assets/images/common/sidebar/blog.svg'
import { ReactComponent as SettingsSVG } from 'assets/images/common/newSidebar/settings.svg'
import { ReactComponent as BusinessSVG } from 'assets/images/common/sidebar/business.svg'
import { ReactComponent as CategoriesSVG } from 'assets/images/common/sidebar/categories.svg'
import { ReactComponent as CeoSVG } from 'assets/images/common/sidebar/ceoIcon.svg'
import { ReactComponent as ClientsSVG } from 'assets/images/common/sidebar/clients.svg'
import { ReactComponent as CustomersSVG } from 'assets/images/common/sidebar/customers.svg'
import { ReactComponent as PagesSVG } from 'assets/images/common/sidebar/pages.svg'
import { ReactComponent as UserSVG } from 'assets/images/common/sidebar/user.svg'
import { SidebarType } from 'entities/admin'
import { MenuNotificationName } from 'modules/manager/constants/sidebar'
import {
  ADMIN_CATEGORIES,
  ADMIN_CUSTOMERS,
  ADMIN_PAGES,
  ADMIN_SALONS,
  ADMIN_SETTINGS,
  ADMIN_HELP_FOR_BUSINESS,
  ADMIN_HELP_FOR_CUSTOMER,
  ADMIN_SEO,
  ADMIN_NOTIFICATION,
} from 'utils'

export const sidebar: SidebarType[] = [
  {
    label: 'ADMIN.SIDEBAR.CUSTOMERS',
    Icon: UserSVG,
    link: ADMIN_CUSTOMERS,
  },
  {
    label: 'ADMIN.SIDEBAR.SALONS',
    Icon: ClientsSVG,
    link: ADMIN_SALONS,
  },
  {
    label: 'ADMIN.SIDEBAR.CATEGORIES',
    Icon: CategoriesSVG,
    link: ADMIN_CATEGORIES,
  },
  {
    label: 'ADMIN.SIDEBAR.PAGES',
    Icon: PagesSVG,
    link: ADMIN_PAGES,
  },
  {
    label: 'ADMIN.SIDEBAR.SETTINGS',
    Icon: SettingsSVG,
    link: ADMIN_SETTINGS,
  },
  {
    label: 'ADMIN.SIDEBAR.HELP_FOR_BUSINESS',
    Icon: BusinessSVG,
    link: ADMIN_HELP_FOR_BUSINESS,
  },
  {
    label: 'ADMIN.SIDEBAR.HELP_FOR_CUSTOMERS',
    Icon: CustomersSVG,
    link: ADMIN_HELP_FOR_CUSTOMER,
  },
  {
    label: 'ADMIN.SIDEBAR.SEO',
    Icon: CeoSVG,
    link: ADMIN_SEO,
  },
  // backlog
  // {
  //   label: 'ADMIN.SIDEBAR.BLOG',
  //   Icon: BlogSVG,
  //   link: ADMIN_BLOG,
  //   className: 'adminIconSeo',
  // },
  {
    label: 'ADMIN.SIDEBAR.NOTIFICATION',
    Icon: NotificationSVG,
    link: ADMIN_NOTIFICATION,
    notification: false,
    notificationName: MenuNotificationName.NOTIFICATION,
  },
]
