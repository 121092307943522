import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import { sendSupportMessageApi } from 'services/http/manager/Support'
import { getRequestErrors } from 'utils/helpers/requests'

import { sendSupportFailure, sendSupportSuccess } from './Support.action'
import { SEND_SUPPORT_MANAGER_MESSAGE_REQUEST } from './Support.constant'
import { ISendManagerSupportRequest } from './Support.type'

function* workerSendManagerSupport({ payload }: ISendManagerSupportRequest) {
  try {
    yield call(sendSupportMessageApi, payload)

    yield put(sendSupportSuccess())
  } catch (error) {
    yield getRequestErrors(sendSupportFailure, error as string)
  }
}

function* watchSendManagerSupport() {
  yield takeLatest(
    SEND_SUPPORT_MANAGER_MESSAGE_REQUEST,
    workerSendManagerSupport,
  )
}

export const managerSupportWatchers: ForkEffect[] = [
  fork(watchSendManagerSupport),
]
