import { IUpdateManagerHistorySubscriptionPayload } from 'store/Manager/Subscription/Subscription.type'
import { POST, GET } from 'utils/http'

const basePath = '/subscription-plans'

export const managerPlanSubscribe = (id: number) =>
  POST(`${basePath}/${id}/subscribe`)

export const managerPlanCancel = (id: number) =>
  POST(`${basePath}/${id}/cancel`)

export const managerHistory = (query: string | null) =>
  GET(query ? `/payment-history?${query}` : '/payment-history')

export const updateSubscription = (
  body: IUpdateManagerHistorySubscriptionPayload,
) => POST(`${basePath}/callback`, body)
