import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_ADMIN_CURRENT_TEMPLATE,
  GET_ADMIN_CURRENT_PAGE_REQUEST,
  GET_ADMIN_CURRENT_PAGE_FAILURE,
  GET_ADMIN_CURRENT_PAGE_SUCCESS,
  GET_ADMIN_PAGES_REQUEST,
  GET_ADMIN_PAGES_FAILURE,
  GET_ADMIN_PAGES_SUCCESS,
  SET_ADMIN_CURRENT_PAGE_REQUEST,
  SET_ADMIN_CURRENT_PAGE_FAILURE,
  SET_ADMIN_CURRENT_PAGE_SUCCESS,
} from './Pages.constant'
import {
  AdminPagesState,
  AdminPagesActionsTypes,
  IAdminPagesResponse,
} from './Pages.type'

const initialState: AdminPagesState = {
  errors: {},
  errorMessage: null,
  isLoading: false,
  data: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isCurrentPageSaved: false,
  currentPage: {} as IAdminPagesResponse,
}

export default function adminPagesReducer(
  state = initialState,
  action: AdminPagesActionsTypes,
): AdminPagesState {
  switch (action.type) {
    case GET_ADMIN_PAGES_REQUEST:
      return { ...state, isLoading: true }
    case GET_ADMIN_PAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        // meta: {
        //   page: action.payload.meta?.current_page,
        //   perPage: action.payload.meta?.per_page,
        //   total: action.payload.meta?.total,
        // },
      }
    case GET_ADMIN_PAGES_FAILURE:
    case GET_ADMIN_CURRENT_PAGE_FAILURE:
    case SET_ADMIN_CURRENT_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case GET_ADMIN_CURRENT_PAGE_REQUEST:
    case SET_ADMIN_CURRENT_PAGE_REQUEST:
      return { ...state, isLoading: true }
    case GET_ADMIN_CURRENT_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentPage: action.payload,
      }
    case SET_ADMIN_CURRENT_PAGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        currentPage: {
          ...state.currentPage,
          content: action.payload.content,
          seo: action.payload.seo,
        },
        isCurrentPageSaved: true,
      }
    }

    case CLEAR_ADMIN_CURRENT_TEMPLATE:
      return {
        ...state,
        errors: {},
        errorMessage: null,
        currentPage: {} as IAdminPagesResponse,
        isCurrentPageSaved: false,
      }
    default:
      return { ...state }
  }
}
