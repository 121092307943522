export const INITIAL_VALUES = {
  name: '',
  address: null,
  phones: [],
  email: null,
  about: '',
  times: {},
  logo: null,
  timezone: null,
}
