export enum HourType {
  CUSTOM = 'custom',
  IMMEDIATELY = 'immediately',
  NEXT_WEEK = 'next_week',
}

export const INITIAL_VALUES = {
  schedule: {
    friday: {},
    monday: {},
    saturday: {},
    sunday: {},
    thursday: {},
    tuesday: {},
    wednesday: {},
  },
  type: HourType.IMMEDIATELY,
}
