import { FC, ReactNode } from 'react'

import { Tooltip } from 'antd'

import { ReactComponent as InfoSVG } from 'assets/images/common/information.svg'

type PropsType = {
  content: ReactNode
  placement?:
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
}

const UITooltip: FC<PropsType> = ({ content, placement = 'right' }) => {
  return (
    <Tooltip
      title={content}
      placement={placement}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
    >
      <span>
        <InfoSVG />
      </span>
    </Tooltip>
  )
}

export default UITooltip
