import { COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN, USER } from 'utils'
import { getCookie } from 'utils/helpers/cookie'

import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_REGISTER_FAILURE,
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS,
  AUTH_VERIFY_FAILURE,
  AUTH_VERIFY_REQUEST,
  AUTH_VERIFY_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_REGESTERED,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  RESET_FORGOT_STATE,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
} from './Auth.constant'
import { AuthActionsTypes, IAuthState, ResponseFailureType } from './Auth.type'

const initialState: IAuthState = {
  user: JSON.parse(localStorage.getItem(USER) || 'null'),
  errors: {},
  registerErrors: {},
  errorMessage: null,
  isLoading: false,
  isLogoutLoading: false,
  isForgotPasswordLoading: false,
  isForgotEmailConfirmed: false,
  isRegistered: false,
  isPasswordReseted: false,
  accessToken: { token: getCookie(COOKIE_ACCESS_TOKEN) || '', expires: 0 },
  refreshToken: { token: getCookie(COOKIE_REFRESH_TOKEN) || '', expires: 0 },
}

export default function authReducer(
  state = initialState,
  action: AuthActionsTypes,
): IAuthState {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {},
        registerErrors: {},
        errorMessage: null,
      }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accessToken: action.payload.tokens.access_token,
        refreshToken: action.payload.tokens.refresh_token,
        user: action.payload.user,
      }
    case AUTH_REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {},
        registerErrors: {},
        errorMessage: null,
        isRegistered: false,
      }
    case AUTH_REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRegistered: true,
      }
    case AUTH_VERIFY_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {},
        registerErrors: {},
        errorMessage: null,
      }
    case AUTH_VERIFY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isPasswordReseted: false,
        isForgotPasswordLoading: true,
        errors: {},
        registerErrors: {},
        errorMessage: null,
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPasswordLoading: false,
        isForgotEmailConfirmed: true,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPasswordLoading: false,
        isPasswordReseted: true,
      }
    case AUTH_LOGIN_FAILURE:
    case AUTH_REGISTER_FAILURE:
    case AUTH_VERIFY_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case FORGOT_PASSWORD_FAILURE:
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isForgotPasswordLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case RESET_FORGOT_STATE:
      return {
        ...state,
        isForgotPasswordLoading: false,
        isForgotEmailConfirmed: false,
        isPasswordReseted: false,
      }
    case LOGOUT_REQUEST:
      return { ...state, isLogoutLoading: true }
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLogoutLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        accessToken: { token: '', expires: 0 },
        refreshToken: { token: '', expires: 0 },
        user: null,
      }
    case CLEAR_REGESTERED:
      return {
        ...state,
        isRegistered: false,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
        registerErrors: {},
        errorMessage: null,
      }
    default:
      return { ...state }
  }
}
