import { useState, useRef, FC, useEffect } from 'react'

import cn from 'classnames'

import { ReactComponent as ArrowSVG } from 'assets/images/common/redesign/arrowBottom.svg'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const SubscriptionUIAccordion: FC<PropsType> = ({
  topHeader,
  content,
  isDefaultOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [contentHeight, setContentHeight] = useState<number>()
  const [isFirstOpen, setFirstOpen] = useState(false)

  const contentRef = useRef<HTMLDivElement>(null)

  const handlerOpen = () => {
    if (isFirstOpen) {
      setFirstOpen(false)
      setIsOpen(false)
    } else {
      setIsOpen(!isOpen)
    }
  }

  useEffect(() => {
    if (contentRef.current?.offsetHeight) {
      setContentHeight(contentRef.current?.offsetHeight)
    }
  }, [contentRef.current?.offsetHeight])

  useEffect(() => {
    if (isDefaultOpen) {
      setFirstOpen(isDefaultOpen)
    }
  }, [isDefaultOpen])

  return (
    <div className={styles.wrapper}>
      <div
        className={cn(styles.header, {
          [styles.active]: isOpen || isFirstOpen,
        })}
        onClick={handlerOpen}
      >
        <>
          {topHeader}
          <span className={styles.arrow}>
            <ArrowSVG />
          </span>
        </>
      </div>
      <div
        className={cn(styles.contentWrapp, {
          [styles.open]: isOpen || isFirstOpen,
        })}
        {...((isOpen || isFirstOpen) && {
          style: {
            maxHeight: `${contentHeight}px`,
          },
        })}
      >
        <div ref={contentRef} className={styles.content}>
          {content}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionUIAccordion
