import { FC, useEffect, useRef, useState } from 'react'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as CopySVG } from 'assets/images/common/redesign/copy.svg'
import { ModalType } from 'entities/modal'
import StaffCalendar from 'modules/manager/staff/Calendar'
import { addAlert } from 'store/Alert'
import { clearErrors } from 'store/Manager/Shift/Shift.action'
import { getOneStaffRequest } from 'store/Manager/Staff'
import { hideModal, showModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import UICalendarHeader from 'ui/CalendarHeader'

import styles from './style.module.scss'
import { ExtendedPropsType } from '../Calendar/index.type'

const StaffUIShifts: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const calendarRef = useRef(null)
  const { isLoading, isShiftAction, errorMessage } = useSelector(
    (state: IRootState) => state.shift,
  )
  const [staffId, setStaffId] = useState<number | null>(null)

  const openAddTimeOff = (id: number | null) => {
    dispatch(
      showModal({
        modalType: ModalType.MANAGER_ADD_TIME_OFF,
        modalProps: { staffId: id, isClickStaff: true },
      }),
    )
  }

  const openEditWorkingHours = (id: number | null) => {
    dispatch(
      showModal({
        modalType: ModalType.MANAGER_STAFF_EDIT_HOUR,
        modalProps: {
          staffId: id,
          isLoading,
        },
      }),
    )
  }

  const openEditShift = (value: ExtendedPropsType) => {
    dispatch(
      showModal({
        modalType: ModalType.MANAGER_EDIT_SHIFT,
        modalProps: {
          valueEvent: value,
          isLoading,
        },
      }),
    )
  }

  const openCopyShift = () => {
    dispatch(
      showModal({
        modalType: ModalType.MANAGER_COPY_SHIFT,
      }),
    )
  }

  useEffect(() => {
    if (isShiftAction) {
      dispatch(hideModal())
    }
  }, [dispatch, isShiftAction])

  useEffect(() => {
    if (staffId) {
      dispatch(getOneStaffRequest(staffId))
    }
  }, [dispatch, staffId])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
          placement: 'right',
        }),
      )
    }

    return () => {
      dispatch(clearErrors())
    }
  }, [errorMessage, dispatch])

  return (
    <div className={styles.wrapper}>
      <Spin spinning={isLoading}>
        <UICalendarHeader
          calendarRef={calendarRef}
          isShift
          btnLabel={t('MANAGER.STAFF.COPY_SHIFTS')}
          btnIcon={<CopySVG />}
          btnHandler={openCopyShift}
        />

        <div className={styles.wrapperCalendar}>
          <StaffCalendar
            calendarRef={calendarRef}
            openEditWorkingHours={openEditWorkingHours}
            openAddTimeOff={openAddTimeOff}
            openEditShift={openEditShift}
            setStaffId={setStaffId}
          />
        </div>
      </Spin>
    </div>
  )
}

export default StaffUIShifts
