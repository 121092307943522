import { ChangeEvent, FC, useContext, useState } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { ReactComponent as LogoSVG } from 'assets/images/common/newSidebar/logo.svg'
import { ReactComponent as LogoutSVG } from 'assets/images/common/newSidebar/logout.svg'
import { ManagerSidebarContext } from 'context'
import { SidebarType } from 'entities/admin'
import { logoutRequest } from 'store/Auth'
import { CLIENT_LINK } from 'utils'

import styles from './style.module.scss'

type PropsType = {
  defaultSelectedKeys: string[]
  isManager?: boolean
  items: SidebarType[]
}

const WidgetSidebar: FC<PropsType> = ({
  items,
  defaultSelectedKeys,
  isManager,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { onCloseMenu, isShowHeader, width, isMenuOpen } = useContext(
    ManagerSidebarContext,
  )
  const [menuItemTop, setMenuItemTop] = useState(0)
  const [menuScroll, setMenuScroll] = useState(0)

  const handleLogout = () => {
    dispatch(logoutRequest())
  }

  const handleCloseMenu = () => {
    if (isShowHeader) {
      onCloseMenu()
    }
  }

  const handleLink = () => {
    window.location.href = CLIENT_LINK
  }

  const handleLinkClick = (e: ChangeEvent<HTMLInputElement | EventTarget>) => {
    e.stopPropagation()

    if ((e?.target as HTMLElement)?.offsetTop) {
      setMenuItemTop((e.target as HTMLElement).offsetTop)
    }
  }

  return (
    <div
      className={cn(styles.wrapper, { [styles.managerWrapper]: isManager })}
      onScroll={(e) => setMenuScroll((e?.target as HTMLElement)?.scrollTop)}
    >
      <div className={styles.menu}>
        <div className={styles.logo}>
          <a onClick={handleLink}>
            <LogoSVG />
          </a>
        </div>

        {items.map(
          (
            { link, label, Icon, className, notification, notificationCount },
            index,
          ) => {
            return (
              <Link
                key={index}
                className={cn(styles.menuItem, className, {
                  [styles.menuActive]: defaultSelectedKeys?.find(
                    (item) => item === label,
                  ),
                  [styles.hideStroke]: label === 'MANAGER.SIDEBAR.CLIENTS',
                })}
                to={link || ''}
                onClick={(e) => {
                  handleCloseMenu()
                  handleLinkClick(e)
                }}
                onMouseEnter={handleLinkClick}
                onTouchStart={handleLinkClick}
                onTouchEnd={handleLinkClick}
              >
                <div
                  className={cn(styles.menuNotificationWrapper, {
                    [styles.menuNotification]:
                      notification && !notificationCount,
                  })}
                >
                  <Icon />
                  {notificationCount ? (
                    <span className={styles.menuNotificationCount}>
                      {notificationCount}
                    </span>
                  ) : null}
                </div>

                <div
                  className={cn(styles.menuTitle, {
                    [styles.menuTitleMobile]: isMenuOpen,
                  })}
                  {...(width > 1024 && {
                    style: { top: `${+menuItemTop - +menuScroll}px` },
                  })}
                >
                  {t(label)}
                </div>
              </Link>
            )
          },
        )}

        <div className={styles.logOut} onClick={handleLogout}>
          <LogoutSVG />
          <div className={styles.menuTitle}>{t('COMMON.LOG_OUT')}</div>
        </div>
      </div>
    </div>
  )
}

export default WidgetSidebar
