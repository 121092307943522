import {
  FC,
  memo,
  useMemo,
  SyntheticEvent,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'

import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as MinusIcon } from 'assets/images/common/redesign/minusCircle.svg'
import { ReactComponent as PlusIcon } from 'assets/images/common/redesign/plusCircle.svg'
import { ADMIN_SEO } from 'utils'

import style from './style.module.scss'
import { CategoryType } from 'entities/view'
import { IRootState } from 'store/Root.reducer'
import { useDispatch, useSelector } from 'react-redux'
import { getSubcategoriesRequest } from 'store/Admin'

import { useTranslation } from 'react-i18next'
import { Spin } from 'antd'

type PropsType = {
  openId: number
  setOpenId: Dispatch<SetStateAction<number>>
}

const AdminCeoTableRow: FC<CategoryType & PropsType> = ({
  active,
  id,
  name,
  setOpenId,
  openId,
  url,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { subcategories, isLoading } = useSelector(
    (state: IRootState) => state.adminCategories,
  )

  const handleNavigate = (itemUrl: string) => {
    navigate(`${ADMIN_SEO}/${itemUrl}`)
  }

  const handleShowSubcategories = useCallback(
    (event: SyntheticEvent, categoryId: number) => {
      event.stopPropagation()

      setOpenId(categoryId)
    },
    [setOpenId],
  )

  const handleClick = useCallback(() => {
    dispatch(getSubcategoriesRequest(id))
  }, [dispatch, id])

  const icon = useMemo(
    () =>
      openId === id ? (
        <MinusIcon
          className={style.icon}
          onClick={(e) => handleShowSubcategories(e, 0)}
        />
      ) : (
        <PlusIcon
          className={style.icon}
          onClick={(e) => {
            handleShowSubcategories(e, id)
            handleClick()
          }}
        />
      ),
    [handleClick, handleShowSubcategories, id, openId],
  )

  return (
    <>
      <tr
        key={id}
        onClick={() => handleNavigate(`category/${id}`)}
        className={classNames('pointer', {
          [style['main-row-open']]: openId === id,
        })}
      >
        <td className={style.name}>
          {icon}

          <p>{name}</p>
        </td>
        <td>{active ? t('COMMON.YES') : t('COMMON.NO')}</td>
        <td>{url}</td>
      </tr>

      {isLoading && openId === id && (
        <tr>
          <td>
            <Spin></Spin>
          </td>
        </tr>
      )}

      {openId === id && subcategories.length
        ? subcategories?.map((item, index) => (
            <tr
              key={item.id}
              className={classNames(style['child-row'], {
                [style['last-row']]: index + 1 === subcategories.length,
              })}
              onClick={() => {
                handleNavigate(`subcategory/${item.id}`)
              }}
            >
              <td>{item?.name}</td>
              <td>{item.active ? t('COMMON.YES') : t('COMMON.NO')}</td>
              <td>{item?.url}</td>
            </tr>
          ))
        : null}

      {openId === id && !subcategories.length && (
        <tr>
          <td>{t('NOT_FOUND_PAGE.TITLE_2')}</td>
        </tr>
      )}
    </>
  )
}

export default memo(AdminCeoTableRow)
