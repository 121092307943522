import { FC } from 'react'

import cn from 'classnames'

import { ReactComponent as DeleteSVG } from 'assets/images/common/redesign/delete.svg'
import { ReactComponent as FileSVG } from 'assets/images/common/redesign/file.svg'
import { ReactComponent as UploadSVG } from 'assets/images/common/redesign/upload.svg'

import styles from './style.module.scss'

type PropsType = {
  handlerDelete?: () => void
  isDownload?: boolean
  name?: string
  path?: string
}

const DocumentCard: FC<PropsType> = ({
  name,
  handlerDelete,
  isDownload,
  path,
}) => {
  const handleClick = () => {
    if (handlerDelete) {
      handlerDelete()
    }
  }

  return (
    <div className={styles.itemWrapp}>
      <div className={styles.itemIcon}>
        <FileSVG className={styles.show} />

        {isDownload ? (
          <a href={path || ''} download target="_blank">
            <UploadSVG
              className={cn(styles.hide, styles.upload)}
              onClick={handleClick}
            />
          </a>
        ) : (
          <DeleteSVG className={styles.hide} onClick={handleClick} />
        )}
      </div>
      <p className={styles.itemTitle}>{name}</p>
    </div>
  )
}

export default DocumentCard
