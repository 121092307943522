/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useRef, useState } from 'react'

import { Tooltip, UploadFile } from 'antd'
import classNames from 'classnames'
import { useDrag, useDrop } from 'react-dnd'

const type = 'DragableUploadList'

interface DragableUploadListItemProps {
  file: UploadFile
  fileList: UploadFile[]
  moveRow: (dragIndex: any, hoverIndex: any) => void
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

const UploadMoveItem = ({
  originNode,
  moveRow,
  file,
  fileList,
}: DragableUploadListItemProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const index = fileList.indexOf(file)
  const [isTouch, setIsTouch] = useState(false)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}

      if (dragIndex === index) {
        return {}
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item: any) => {
      moveRow(item.index, index)
    },
  })
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drop(drag(ref))
  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  )

  return (
    <div
      ref={ref}
      className={classNames(
        `ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`,
        { 'active-move': isTouch },
      )}
      style={{ cursor: 'move' }}
      onTouchStart={() => setIsTouch(true)}
      onTouchEnd={() => setIsTouch(false)}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  )
}

export default UploadMoveItem
