import { ShiftType } from 'entities/manager/shift'
import { StaffTimeType } from 'entities/manager/staff'
import { RequestEditShiftType } from 'modules/manager/staff/Shifts/index.type'
import { ResponseFailureType } from 'store/Auth/Auth.type'
import { ValuesTimeOfType } from 'widgets/Modal/AddTimeOff/index.type'
import { RequestCopyType } from 'widgets/Modal/CopyShift/index.type'

import {
  GET_ONE_SCHEDULE_FAILURE,
  GET_ONE_SCHEDULE_REQUEST,
  GET_ONE_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAILURE,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SHIFT_FAILURE,
  UPDATE_SHIFT_REQUEST,
  UPDATE_SHIFT_SUCCESS,
  RESET_STORE,
  ADD_TIME_SHIFT_REQUEST,
  ADD_TIME_SHIFT_SUCCESS,
  ADD_TIME_SHIFT_FAILURE,
  COPY_SHIFT_REQUEST,
  COPY_SHIFT_SUCCESS,
  COPY_SHIFT_FAILURE,
  CLEAR_ERRORS,
} from './Shift.constant'

export const getScheduleRequest = (payload?: string) => ({
  type: GET_SCHEDULE_REQUEST,
  payload,
})

export const getScheduleSuccess = (payload: ShiftType[]) => ({
  payload,
  type: GET_SCHEDULE_SUCCESS,
})

export const getScheduleFailure = () => ({
  type: GET_SCHEDULE_FAILURE,
})

export const getOneScheduleRequest = (payload: number) => ({
  payload,
  type: GET_ONE_SCHEDULE_REQUEST,
})

export const getOneScheduleSuccess = (payload: ShiftType) => ({
  payload,
  type: GET_ONE_SCHEDULE_SUCCESS,
})

export const getOneScheduleFailure = () => ({
  type: GET_ONE_SCHEDULE_FAILURE,
})

export const updateScheduleRequest = (payload: {
  body: StaffTimeType
  id: number
}) => ({
  payload,
  type: UPDATE_SCHEDULE_REQUEST,
})

export const updateScheduleSuccess = () => ({
  type: UPDATE_SCHEDULE_SUCCESS,
})

export const updateScheduleFailure = (payload: ResponseFailureType) => ({
  payload,
  type: UPDATE_SCHEDULE_FAILURE,
})

export const updateShiftRequest = (payload: RequestEditShiftType) => ({
  payload,
  type: UPDATE_SHIFT_REQUEST,
})

export const updateShiftSuccess = () => ({
  type: UPDATE_SHIFT_SUCCESS,
})

export const updateShiftFailure = (payload: ResponseFailureType) => ({
  payload,
  type: UPDATE_SHIFT_FAILURE,
})

export const addTimeShiftRequest = (payload: ValuesTimeOfType) => ({
  payload,
  type: ADD_TIME_SHIFT_REQUEST,
})

export const addTimeShiftSuccess = () => ({
  type: ADD_TIME_SHIFT_SUCCESS,
})

export const addTimeShiftFailure = (payload: ResponseFailureType) => ({
  payload,
  type: ADD_TIME_SHIFT_FAILURE,
})

export const copyShiftRequest = (payload: RequestCopyType) => ({
  payload,
  type: COPY_SHIFT_REQUEST,
})

export const copyShiftSuccess = () => ({
  type: COPY_SHIFT_SUCCESS,
})

export const copyShiftFailure = (payload: ResponseFailureType) => ({
  payload,
  type: COPY_SHIFT_FAILURE,
})

export const resetStore = () => ({
  type: RESET_STORE,
})

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
})
