import {
  PERSONAL_SORT_REQUEST,
  PERSONAL_SORT_SUCCESS,
  PERSONAL_SORT_FAILURE,
  SORT_REQUEST,
  SORT_SUCCESS,
  SORT_FAILURE,
  SORT_STAFF_SERVICE_REQUEST,
  SORT_STAFF_SERVICE_SUCCESS,
  SORT_STAFF_SERVICE_FAILURE,
} from './Sort.constant'

export const personalSortRequest = (payload: {
  idx: number[]
  name: string
}) => ({
  payload,
  type: PERSONAL_SORT_REQUEST,
})

export const personalSortSuccess = () => ({
  type: PERSONAL_SORT_SUCCESS,
})

export const personalSortFailure = () => ({
  type: PERSONAL_SORT_FAILURE,
})

export const sortRequest = (payload: { idx: number[]; name: string }) => ({
  payload,
  type: SORT_REQUEST,
})

export const sortSuccess = () => ({
  type: SORT_SUCCESS,
})

export const sortFailure = () => ({
  type: SORT_FAILURE,
})

export const sortStaffServiceRequest = (payload: {
  ids: number[]
  name: string
  sectionId?: number
  staffId: number
}) => ({
  payload,
  type: SORT_STAFF_SERVICE_REQUEST,
})

export const sortStaffServiceSuccess = () => ({
  type: SORT_STAFF_SERVICE_SUCCESS,
})

export const sortStaffServiceFailure = () => ({
  type: SORT_STAFF_SERVICE_FAILURE,
})
