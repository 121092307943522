import { CurrentPageBodyRequest } from 'store/Admin/Pages/Pages.type'
import { GET, POST } from 'utils/http'

const basePath = '/page'

export const getAdminPagesApi = (query = '') => GET(`${basePath}${query}`)

export const getAdminCurrentPageApi = (id: number) => GET(`${basePath}/${id}`)

export const editAdminCurrentPageApi = ({
  id,
  data,
}: {
  data: CurrentPageBodyRequest
  id: number
}) => POST(`${basePath}/${id}`, data)
