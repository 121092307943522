export const GET_ADMIN_SEO_CATEGORY_REQUEST =
  '@seo/GET_ADMIN_SEO_CATEGORY_REQUEST'
export const GET_ADMIN_SEO_CATEGORY_SUCCESS =
  '@seo/GET_ADMIN_SEO_CATEGORY_SUCCESS'
export const GET_ADMIN_SEO_CATEGORY_FAILURE =
  '@seo/GET_ADMIN_SEO_CATEGORY_FAILURE'

export const GET_ADMIN_SEO_SUBCATEGORY_REQUEST =
  '@seo/GET_ADMIN_SEO_SUBCATEGORY_REQUEST'
export const GET_ADMIN_SEO_SUBCATEGORY_SUCCESS =
  '@seo/GET_ADMIN_SEO_SUBCATEGORY_SUCCESS'
export const GET_ADMIN_SEO_SUBCATEGORY_FAILURE =
  '@seo/GET_ADMIN_SEO_SUBCATEGORY_FAILURE'

export const GET_ADMIN_SEO_SETTINGS_REQUEST =
  '@seo/GET_ADMIN_SEO_SETTINGS_REQUEST'
export const GET_ADMIN_SEO_SETTINGS_SUCCESS =
  '@seo/GET_ADMIN_SEO_SETTINGS_SUCCESS'
export const GET_ADMIN_SEO_SETTINGS_FAILURE =
  '@seo/GET_ADMIN_SEO_SETTINGS_FAILURE'

export const UPDATE_ADMIN_SEO_SETTINGS_REQUEST =
  '@seo/UPDATE_ADMIN_SEO_SETTINGS_REQUEST'
export const UPDATE_ADMIN_SEO_SETTINGS_SUCCESS =
  '@seo/UPDATE_ADMIN_SEO_SETTINGS_SUCCESS'
export const UPDATE_ADMIN_SEO_SETTINGS_FAILURE =
  '@seo/UPDATE_ADMIN_SEO_SETTINGS_FAILURE'

export const CLEAR_SEO_ERRORS = '@seo/CLEAR_SEO_ERRORS'

export const CLEAR_SEO_STORE = '@seo/CLEAR_SEO_STORE'
