import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { UIButton, UICustomModal } from 'ui'

import styles from './style.module.scss'

type PropsType = {
  handler: () => void
}

const WidgetModalDeleteService: FC<PropsType> = ({ handler }) => {
  const { t } = useTranslation()

  return (
    <UICustomModal isBack={false}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{t('MANAGER.MODAL.NOTIFY')}</div>
        <UIButton handler={handler} label={t('COMMON.DELETE')} />
      </div>
    </UICustomModal>
  )
}

export default WidgetModalDeleteService
