import { FileName, FullMedia, UploadMedia } from 'entities/common'
import { ResponseFailureType } from 'store/Auth/Auth.type'
import { SalonDataType } from 'views/manager/ProfileSalon/index.type'

import {
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_FAILURE,
  CLEAR_DOCUMENT_STOR,
  CLEAR_DOCUMENT_ERRORS,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_REQUEST,
  ADD_DOCUMENT_FAILURE,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_NOTIFICATION_SUCCESS,
  SET_LOADING,
  FILTER_DOCUMENT_LIST,
} from './Documents.constant'
import {
  DocumentActionsTypes,
  DocumentsNotificatonType,
} from './Documents.type'

export function uploadDocumentRequest(payload: {
  file: UploadMedia
  fileName: FileName
  nameUpload: string
}): DocumentActionsTypes {
  return {
    type: UPLOAD_DOCUMENT_REQUEST,
    payload,
  }
}

export function uploadDocumentSuccess(payload: {
  data: FullMedia
}): DocumentActionsTypes {
  return {
    type: UPLOAD_DOCUMENT_SUCCESS,
    payload,
  }
}

export function uploadDocumentFailure(payload: ResponseFailureType) {
  return {
    payload,
    type: UPLOAD_DOCUMENT_FAILURE,
  }
}

export function deleteDocumentRequest(payload: number): DocumentActionsTypes {
  return {
    type: DELETE_DOCUMENT_REQUEST,
    payload,
  }
}

export function deleteDocumentFailure(payload: ResponseFailureType) {
  return {
    payload,
    type: DELETE_DOCUMENT_FAILURE,
  }
}

export function addDocumentRequest(payload: {
  ids: number[]
}): DocumentActionsTypes {
  return {
    type: ADD_DOCUMENT_REQUEST,
    payload,
  }
}
export function addDocumentSuccess() {
  return {
    type: ADD_DOCUMENT_SUCCESS,
  }
}

export function addDocumentFailure(payload: ResponseFailureType) {
  return {
    payload,
    type: ADD_DOCUMENT_FAILURE,
  }
}

export const getDocumentRequest = () => ({
  type: GET_DOCUMENT_REQUEST,
})

export const getDocumentSuccess = (payload: SalonDataType) => ({
  payload,
  type: GET_DOCUMENT_SUCCESS,
})

export const getDocumentNotificationSuccess = (
  payload: DocumentsNotificatonType,
) => ({
  payload,
  type: GET_DOCUMENT_NOTIFICATION_SUCCESS,
})

export const getDocumentFailure = (payload: ResponseFailureType) => ({
  type: GET_DOCUMENT_FAILURE,
  payload,
})

export function clearDocumentStore(): DocumentActionsTypes {
  return {
    type: CLEAR_DOCUMENT_STOR,
  }
}

export const clearDocumentErrors = () => ({
  type: CLEAR_DOCUMENT_ERRORS,
})

export const filterDocumentList = (payload: number) => ({
  payload,
  type: FILTER_DOCUMENT_LIST,
})

export const setLoading = (payload: boolean) => ({
  type: SET_LOADING,
  payload,
})
