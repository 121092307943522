import { FC, useEffect, useState } from 'react'

import { Avatar, Image, Input, Tooltip, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ReactComponent as Camera56SVG } from 'assets/images/admin/camera_56.svg'
import { ReactComponent as CategoryEditSVG } from 'assets/images/admin/category_edit.svg'
import { ReactComponent as OffEditSVG } from 'assets/images/admin/off_edit.svg'
import { ReactComponent as InfoSVG } from 'assets/images/common/information.svg'
import { ReactComponent as MoveSVG } from 'assets/images/common/move.svg'
import { ReactComponent as SaveIcon } from 'assets/images/common/redesign/save.svg'
import { ModalType } from 'entities/modal'
import { hideModal, showModal } from 'store/Modal'
import { UIButton, UISwitch } from 'ui'
import WidgetModalImageEditor from 'widgets/Modal/ImageEditor'

import style from './style.module.scss'
import { PropsType } from './index.type'

const AdminUIInput: FC<PropsType> = ({
  name,
  value,
  onActive,
  isActive,
  onSave,
  onChange,
  isCategory,
  handleClick,
  active,
  isEdit,
  handleSwitch,
  handleUpload,
  pathImg,
  fileList,
  onRemove,
  isloading,
  isCategoryLoading,
  linkType,
}) => {
  const [isCheched, setIsChecked] = useState(active)
  const [isConfirm, setIsConfirm] = useState(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSwitchChange = (checked: boolean) => {
    if (!isCheched && handleSwitch) {
      handleSwitch(true)
      setIsChecked(checked)
      setIsConfirm(true)
    } else {
      setIsChecked(checked)
      setIsConfirm(false)
    }
  }

  const handleRequest = (file: RcFile) => {
    if (handleUpload) {
      handleUpload(file)
    }
  }

  const uploadButton = (
    <Avatar
      size={56}
      {...(pathImg && { src: <Image src={pathImg} /> })}
      icon={<Camera56SVG />}
    />
  )

  useEffect(() => {
    if (!isCheched && !isConfirm) {
      dispatch(
        showModal({
          modalType: ModalType.ADMIN_DEACTIVATE_CATEGORY,
          modalProps: {
            title: t(
              isCategory ? 'ADMIN.MODAL_CATEGORY' : 'ADMIN.MODAL_SUBCATEGORY',
            ),
            handler: () => {
              if (!isCheched && handleSwitch) {
                dispatch(hideModal())
                setIsConfirm(true)
                handleSwitch(false)
              }
            },
            handlerClose: () => {
              setIsChecked(true)
            },
          },
        }),
      )
    }
  }, [dispatch, handleSwitch, isCategory, isCheched, isConfirm, t])

  return (
    <div className={style.wrapper} key={name}>
      <div onClick={handleClick}>
        <MoveSVG className={style.icon} />

        {isCategory && (
          <div className={style.tooltip}>
            <Tooltip
              title={() => (
                <ul>
                  <li>{t('ADMIN.FORMAT.IMAGE')}</li>
                  <li>{t('ADMIN.SIZE.IMAGE')}</li>
                </ul>
              )}
            >
              <InfoSVG />
            </Tooltip>
          </div>
        )}

        {isEdit && <CategoryEditSVG className={style.icon} />}
        {!isEdit && <OffEditSVG onClick={onActive} className={style.icon} />}

        <div
          className={classNames(style.inputWrapper, {
            [style.activeInput]: isActive,
            [style.activeInputSubcategory]: !isCategory && isActive,
          })}
        >
          {isEdit && (
            <div className={style.wrapper_item}>
              <div className={style.wrapper_upload}>
                <WidgetModalImageEditor
                  widthRatio={1}
                  heightRatio={1}
                  isCircle={true}
                  linkType={linkType}
                >
                  <Upload
                    customRequest={(file) => handleRequest(file.file as RcFile)}
                    accept=".jpg, .jpeg"
                    listType="picture-card"
                    disabled={!isEdit}
                    fileList={fileList}
                    onRemove={onRemove}
                    maxCount={1}
                  >
                    {!pathImg &&
                      fileList &&
                      fileList?.length <= 0 &&
                      uploadButton}
                  </Upload>
                </WidgetModalImageEditor>
              </div>
              <Input value={value} maxLength={149} onChange={onChange} />
            </div>
          )}

          {!isEdit && (
            <div className={style.uploadBtnWrapp}>
              {isCategory && uploadButton}
              <div>
                {value.length > (isCategory ? 25 : 35)
                  ? `${value.substring(0, isCategory ? 25 : 35)}...`
                  : value}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={style.switch}>
        <UISwitch
          checked={isCheched}
          onChange={(checked: boolean) => handleSwitchChange(checked)}
        />
      </div>

      {isEdit && (
        <div className={style.switch}>
          <UIButton
            handler={onSave}
            label={t('ADMIN.BUTTON.SAVE')}
            icon={<SaveIcon />}
            disabled={!value.trim()}
            isLoading={!!isloading || !!isCategoryLoading}
            type="text"
            isGreen
          />
        </div>
      )}
    </div>
  )
}

export default AdminUIInput
