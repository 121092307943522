import i18n from 'lib/i18n/i18n'

import { ModeType, ModeShiftType } from './index.type'

export const modeTab = [
  { id: ModeType.DAY, name: i18n.t('COMMON.DAY') },
  { id: ModeType.WEEK, name: i18n.t('COMMON.WEEK') },
  { id: ModeType.MONTH, name: i18n.t('COMMON.MONTH') },
]

export const modeShiftTab = [
  { id: ModeShiftType.DAY, name: i18n.t('COMMON.DAY') },
  { id: ModeShiftType.WEEK, name: i18n.t('COMMON.WEEK') },
]
