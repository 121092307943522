import { put, takeLatest, fork, call, ForkEffect } from 'redux-saga/effects'

import i18n from 'lib/i18n/i18n'
import { SortHttp } from 'services/http'
import { addAlert } from 'store/Alert'

import {
  personalSortFailure,
  personalSortSuccess,
  sortSuccess,
  sortFailure,
  sortStaffServiceFailure,
  sortStaffServiceSuccess,
} from './Sort.action'
import {
  PERSONAL_SORT_REQUEST,
  SORT_REQUEST,
  SORT_STAFF_SERVICE_REQUEST,
} from './Sort.constant'
import {
  IPersonalSortRequest,
  ISortRequest,
  ISortStaffServiceRequest,
} from './Sort.type'

function* workerPersonalSort({ payload }: IPersonalSortRequest) {
  try {
    yield call(SortHttp.personalSort, payload.idx, payload.name)

    yield put(personalSortSuccess())
  } catch (error) {
    yield put(personalSortFailure())
    yield put(
      addAlert({
        message: [i18n.t('ERROR.ALERT_GET_ONE'), i18n.t('ERROR.ALERT_GET_TWO')],
        type: 'error',
        placement: 'right',
      }),
    )
  }
}

function* watchPersonalSort() {
  yield takeLatest(PERSONAL_SORT_REQUEST, workerPersonalSort)
}

function* workerSort({ payload }: ISortRequest) {
  try {
    yield call(SortHttp.sort, payload.idx, payload.name)

    yield put(sortSuccess())
  } catch (error) {
    yield put(sortFailure())
    yield put(
      addAlert({
        message: [i18n.t('ERROR.ALERT_GET_ONE'), i18n.t('ERROR.ALERT_GET_TWO')],
        type: 'error',
        placement: 'right',
      }),
    )
  }
}

function* watchSort() {
  yield takeLatest(SORT_REQUEST, workerSort)
}

function* workerSortStaffService({ payload }: ISortStaffServiceRequest) {
  try {
    const { name, ids, staffId, sectionId } = payload

    yield call(SortHttp.sortService, ids, name, staffId, sectionId)

    yield put(sortStaffServiceSuccess())
  } catch (error) {
    yield put(sortStaffServiceFailure())
    yield put(
      addAlert({
        message: [i18n.t('ERROR.ALERT_GET_ONE'), i18n.t('ERROR.ALERT_GET_TWO')],
        type: 'error',
        placement: 'right',
      }),
    )
  }
}

function* watchSortStaffService() {
  yield takeLatest(SORT_STAFF_SERVICE_REQUEST, workerSortStaffService)
}

export const sortWatchers: ForkEffect[] = [
  fork(watchPersonalSort),
  fork(watchSort),
  fork(watchSortStaffService),
]
