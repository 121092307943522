import { FC, memo, SyntheticEvent } from 'react'

import { Spin } from 'antd'

import { ReactComponent as CheckIcon } from 'assets/images/common/document-check.svg'
import { ReactComponent as DownloadIcon } from 'assets/images/common/download.svg'
import { ReactComponent as TrashIcon } from 'assets/images/common/trash.svg'

import style from './style.module.scss'

type PropsType = {
  id: number
  isLoading: boolean
  name: string
  onDownload: (path: string) => void
  onRemove: (id: number) => void
  path: string
}

const AdminSalonsSectionSixCard: FC<PropsType> = ({
  name,
  path,
  id,
  onRemove,
  isLoading,
  onDownload,
}) => {
  const handleRemove = (event: SyntheticEvent) => {
    event.stopPropagation()

    onRemove(id)
  }

  const handleDownload = (event: SyntheticEvent) => {
    event.stopPropagation()

    onDownload(path)
  }

  return (
    <Spin spinning={isLoading}>
      <div className={style.card}>
        <div>
          <CheckIcon className={style.checkIcon} />

          <DownloadIcon
            className={style.downloadIcon}
            onClick={handleDownload}
          />
        </div>

        <p>{name}</p>

        <TrashIcon className={style.trashIcon} onClick={handleRemove} />
      </div>
    </Spin>
  )
}

export default memo(AdminSalonsSectionSixCard)
