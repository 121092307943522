import { TimeFromTo } from 'entities/common'
import {
  AppointmentType,
  ValidationErrorType,
} from 'entities/manager/appointments'
import { ServiceType } from 'entities/manager/service'
import { StaffType } from 'entities/manager/staff'
import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_STORE,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_REQUEST,
  DELETE_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_FAILURE,
  GET_APPOINTMENT_MOBILE_SUCCESS,
  GET_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_SCHEDULE_SUCCESS,
  GET_APPOINTMENT_SUCCESS,
  RESET_AVAILABLE_TIME,
  RESET_VALIDATION_ERROR,
  SET_QUERY,
  SET_REPEAT_REQUEST,
  SET_VALIDATION_ERROR,
  UPDATE_APPOINTMENT_AVAILABLE_FAILURE,
  UPDATE_APPOINTMENT_AVAILABLE_REQUEST,
  UPDATE_APPOINTMENT_AVAILABLE_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE,
  UPDATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_SEARCH_FAILURE,
  UPDATE_APPOINTMENT_SEARCH_REQUEST,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_SEARCH_CLIENT_SUCCESS,
  UPDATE_SEARCH_CONTACT_SUCCESS,
  UPDATE_SEARCH_SERVICE_SUCCESS,
  UPDATE_SEARCH_STAFF_SUCCESS,
  RESET_ERROR,
  GET_ONE_SERVICE_REQUEST,
  GET_ONE_SERVICE_SUCCESS,
  GET_ONE_SERVICE_FAILURE,
} from './Appointments.constant'
import {
  AppointmentAvailableValues,
  AppointmentCreateValues,
  AppointmentSearchValues,
  AppointmentValues,
  MethodSearch,
  SearchClientType,
} from './Appointments.type'

export const setValidationError = (payload: ValidationErrorType[]) => ({
  payload,
  type: SET_VALIDATION_ERROR,
})

export const resetValidationError = () => ({
  type: RESET_VALIDATION_ERROR,
})

export const resetAvailableTime = () => ({
  type: RESET_AVAILABLE_TIME,
})

export const updateAppointmentSearchRequest = (payload: {
  body: AppointmentSearchValues
  method: MethodSearch
}) => ({
  type: UPDATE_APPOINTMENT_SEARCH_REQUEST,
  payload,
})

export const updateSearchClientSuccess = (payload: SearchClientType[]) => ({
  payload,
  type: UPDATE_SEARCH_CLIENT_SUCCESS,
})

export const updateSearchContactSuccess = (payload: any) => ({
  payload,
  type: UPDATE_SEARCH_CONTACT_SUCCESS,
})

export const updateSearchServiceSuccess = (payload: ServiceType[]) => ({
  payload,
  type: UPDATE_SEARCH_SERVICE_SUCCESS,
})

export const updateSearchStaffSuccess = (payload: StaffType[]) => ({
  payload,
  type: UPDATE_SEARCH_STAFF_SUCCESS,
})

export const updateAppointmentSearchFailure = (
  payload: ResponseFailureType,
) => ({
  payload,
  type: UPDATE_APPOINTMENT_SEARCH_FAILURE,
})

export const updateAppointmentAvailableRequest = (
  payload: AppointmentAvailableValues,
) => ({
  type: UPDATE_APPOINTMENT_AVAILABLE_REQUEST,
  payload,
})

export const updateAppointmentAvailableSuccess = (payload: string[]) => ({
  payload,
  type: UPDATE_APPOINTMENT_AVAILABLE_SUCCESS,
})

export const updateAppointmentAvailableFailure = (
  payload: ResponseFailureType,
) => ({
  payload,
  type: UPDATE_APPOINTMENT_AVAILABLE_FAILURE,
})

export const updateAppointmentRequest = (payload: {
  body: AppointmentValues
  id: number
}) => ({
  type: UPDATE_APPOINTMENT_REQUEST,
  payload,
})

export const updateAppointmentSuccess = (id: number) => ({
  type: UPDATE_APPOINTMENT_SUCCESS,
  payload: id,
})

export const updateAppointmentFailure = (payload: ResponseFailureType) => ({
  payload,
  type: UPDATE_APPOINTMENT_FAILURE,
})

export const getAppointmentRequest = (payload: {
  query: string
  type?: string
}) => ({
  payload,
  type: GET_APPOINTMENT_REQUEST,
})

export const getAppointmentSuccess = (payload: AppointmentType[]) => ({
  type: GET_APPOINTMENT_SUCCESS,
  payload,
})

export const getAppointmentMobileSuccess = (payload: AppointmentType[]) => ({
  type: GET_APPOINTMENT_MOBILE_SUCCESS,
  payload,
})

export const getAppointmentScheduleSuccess = (payload: {
  [key: string]: TimeFromTo
}) => ({
  type: GET_APPOINTMENT_SCHEDULE_SUCCESS,
  payload,
})

export const getAppointmentFailure = (payload: ResponseFailureType) => ({
  payload,
  type: GET_APPOINTMENT_FAILURE,
})

export const createAppointmentRequest = (payload: AppointmentCreateValues) => ({
  payload,
  type: CREATE_APPOINTMENT_REQUEST,
})

export const createAppointmentSuccess = () => ({
  type: CREATE_APPOINTMENT_SUCCESS,
})

export const createAppointmentFailure = (payload: ResponseFailureType) => ({
  payload,
  type: CREATE_APPOINTMENT_FAILURE,
})

export const deleteAppointmentRequest = (payload: number) => ({
  payload,
  type: DELETE_APPOINTMENT_REQUEST,
})

export const deleteAppointmentSuccess = (id: number) => ({
  payload: id,
  type: DELETE_APPOINTMENT_SUCCESS,
})

export const deleteAppointmentFailure = (payload: ResponseFailureType) => ({
  payload,
  type: DELETE_APPOINTMENT_FAILURE,
})

export const getOneServiceRequest = (payload: number) => ({
  type: GET_ONE_SERVICE_REQUEST,
  payload,
})

export const getOneServiceSuccess = (payload: ServiceType) => ({
  type: GET_ONE_SERVICE_SUCCESS,
  payload,
})

export const getOneServiceFailure = (payload: ResponseFailureType) => ({
  payload,
  type: GET_ONE_SERVICE_FAILURE,
})

export const setQuery = (payload: string) => ({
  payload,
  type: SET_QUERY,
})

export const clearStore = () => ({
  type: CLEAR_STORE,
})

export const setRepeatRequest = (payload: boolean) => ({
  payload,
  type: SET_REPEAT_REQUEST,
})

export const resetError = () => ({
  type: RESET_ERROR,
})
