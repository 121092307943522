import { memo, FC } from 'react'

import { Spin } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line import/no-cycle
import { UICustomPagination } from 'ui'

import { PropsType } from './index.type'
import style from './style.module.scss'

const UICustomTable: FC<PropsType> = ({
  columns,
  children,
  isLoading,
  headerChildren,
  className = '',
  perPage,
  total,
}) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(style.customTable, className)}>
      {headerChildren}

      <table>
        <thead>
          <tr>
            {columns?.map(
              ({ title, className: tableClass = '', icon }, index) => (
                <th key={index} className={tableClass}>
                  <p>
                    <>
                      {title}
                      {icon && icon}
                    </>
                  </p>
                </th>
              ),
            )}
          </tr>
        </thead>

        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={columns?.length} className={style.loader}>
                <Spin size="large" />
              </td>
            </tr>
          )}

          {!isLoading && !(children as [])?.length && (
            <tr>
              <td colSpan={columns?.length} className={style.emptyTable}>
                {t('COMMON.EMPTY_TABLE')}
              </td>
            </tr>
          )}

          {!isLoading && children}
        </tbody>
      </table>

      {!!perPage && <UICustomPagination perPage={perPage} total={total} />}
    </div>
  )
}

export default memo(UICustomTable)
