import { QUERY_PARAMS } from 'utils'
import { GET, DELETE, POST } from 'utils/http'
import { StaffValuesType } from 'views/manager/Staff/EditStaff/index.type'
import axios, { CancelTokenSource } from 'axios'

let axiosSource: CancelTokenSource | null = null

const basePath = '/staff'

export const getStaff = async (search: string) => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(
    `${basePath}${search ? `?${QUERY_PARAMS.SEARCH}=${search}` : ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  )

  return data
}

export const getOneStaff = (id: number) => GET(`${basePath}/${id}`)

export const getStaffTime = (id: number) => GET(`schedule/${id}`)

export const updateStaffTime = (payload: any, id: number) =>
  POST(`schedule/${id}`, payload)

export const deleteStaff = (id: number) => DELETE(`${basePath}/${id}`)

export const updateStaff = (body: StaffValuesType, id: number) =>
  POST(`${basePath}/${id}`, body)

export const addStaff = (body: StaffValuesType) => POST(`${basePath}`, body)

export const getAllTime = () => GET(`${basePath}-time`)

export const getStaffSubCategory = (id: number) =>
  GET(`${basePath}/${id}/section`)

export const getStaffServiceBySubCategory = (
  staffId: number,
  sectionId: number,
) => GET(`${basePath}/${staffId}/section/${sectionId}/service`)
