export const GET_MANAGER_SUBSCRIPTION_REQUEST =
  '@subscription/GET_MANAGER_SUBSCRIPTION_REQUEST'
export const GET_MANAGER_SUBSCRIPTION_SUCCESS =
  '@subscription/GET_MANAGER_SUBSCRIPTION_SUCCESS'
export const GET_MANAGER_SUBSCRIPTION_FAILURE =
  '@subscription/GET_MANAGER_SUBSCRIPTION_FAILURE'

export const SUBSCRIBE_MANAGER_PLAN_REQUEST =
  '@subscription/SUBSCRIBE_MANAGER_PLAN_REQUEST'
export const SUBSCRIBE_MANAGER_PLAN_SUCCESS =
  '@subscription/SUBSCRIBE_MANAGER_PLAN_SUCCESS'
export const SUBSCRIBE_MANAGER_PLAN_FAILURE =
  '@subscription/SUBSCRIBE_MANAGER_PLAN_FAILURE'

export const CANCEL_MANAGER_PLAN_REQUEST =
  '@subscription/CANCEL_MANAGER_PLAN_REQUEST'
export const CANCEL_MANAGER_PLAN_SUCCESS =
  '@subscription/CANCEL_MANAGER_PLAN_SUCCESS'
export const CANCEL_MANAGER_PLAN_FAILURE =
  '@subscription/CANCEL_MANAGER_PLAN_FAILURE'

export const GET_MANAGER_HISTORY_SUBSCRIPTION_REQUEST =
  '@subscription/GET_MANAGER_HISTORY_SUBSCRIPTION_REQUEST'
export const GET_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS =
  '@subscription/GET_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS'
export const GET_MANAGER_HISTORY_SUBSCRIPTION_FAILURE =
  '@subscription/GET_MANAGER_HISTORY_SUBSCRIPTION_FAILURE'

export const UPDATE_MANAGER_HISTORY_SUBSCRIPTION_REQUEST =
  '@subscription/UPDATE_MANAGER_HISTORY_SUBSCRIPTION_REQUEST'
export const UPDATE_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS =
  '@subscription/UPDATE_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS'
export const UPDATE_MANAGER_HISTORY_SUBSCRIPTION_FAILURE =
  '@subscription/UPDATE_MANAGER_HISTORY_SUBSCRIPTION_FAILURE'

export const CLEAR_PAYMENT_HISTORY = '@subscription/CLEAR_PAYMENT_HISTORY'

export const CLEAR_MANAGER_SUBSCRIPTION_ERRORS =
  '@subscription/CLEAR_MANAGER_SUBSCRIPTION_ERRORS'
