export const INITIAL_SERVICE = {
  time: {
    from: '',
    to: '',
  },
  service: {},
  staff: {},
}

export const INITIAL_STATIC_VALUES = {
  contact_name: '',
  contact_phone: '',
  client_id: null,
}
