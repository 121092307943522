import { useEffect, useState } from 'react'

import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'assets/images/common/arrowLeft.svg'
import { AdminBlogTabs } from 'modules/admin/Blog'
import { UIButton } from 'ui'
import UITextInput from 'ui/TextInput'
import { ADMIN_BLOG } from 'utils'

import { tabs, INITIAL_VALUES } from './constant'
import { ValuesType } from './index.type'
import styles from './style.module.scss'
import { dataCategories } from '../constant'

const ViewAdminBlogCategory = () => {
  const [values, setValues] = useState<ValuesType>(INITIAL_VALUES)

  const { id } = useParams()

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      const data = dataCategories?.find((item) => item.id === +id)

      setValues((prevState) => ({
        ...prevState,
        name: data?.name,
      }))
    } else {
      setValues(INITIAL_VALUES)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Content className={styles.adminSalonProfilePage}>
      <section className={styles.header}>
        <ArrowIcon
          className={styles.back}
          onClick={() => navigate(ADMIN_BLOG)}
        />

        <h1>{t('ADMIN.BLOG.ADD_CATECORY')}</h1>

        <UIButton type="text" />
      </section>
      <div className={styles.inputWrapp}>
        <UITextInput
          text={t('ADMIN.BLOG.NAME_OF_CATEGORY')}
          placeholder={t('ADMIN.BLOG.NAME_OF_CATEGORY')}
          necessaryLabel={`(${t('COMMON.NECESSARY')})`}
          onChange={(e) =>
            setValues((prevState) => ({ ...prevState, name: e.target.value }))
          }
          value={values.name}
        />
      </div>
      <AdminBlogTabs tabs={tabs(values, setValues)} />
      <UIButton className={styles.btn} label={t('COMMON.SAVE')} />
    </Content>
  )
}

export default ViewAdminBlogCategory
