import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_FEEDBACKS_ERRORS,
  EDIT_FEEDBACKS_REQUEST,
  EDIT_FEEDBACKS_FAILURE,
  EDIT_FEEDBACKS_SUCCESS,
  GET_FEEDBACKS_REQUEST,
  GET_FEEDBACKS_FAILURE,
  GET_FEEDBACKS_SUCCESS,
  SET_FEEDBACKS_REQUEST,
  SET_FEEDBACKS_FAILURE,
  SET_FEEDBACKS_SUCCESS,
} from './Feedbacks.constant'
import {
  EditFeedbackPayloadType,
  IFeedbacksResponse,
  SetFeedbackPayloadType,
} from './Feedbakcs.type'

export const getFeedbacksRequest = (queryString: string) => ({
  type: GET_FEEDBACKS_REQUEST,
  payload: queryString,
})

export const getFeedbacksSuccess = (payload: IFeedbacksResponse[]) => ({
  type: GET_FEEDBACKS_SUCCESS,
  payload,
})

export const getFeedbacksFailure = (payload: ResponseFailureType) => ({
  type: GET_FEEDBACKS_FAILURE,
  payload,
})

export const setFeedbacksRequest = (review_id: number, comment: string) => ({
  type: SET_FEEDBACKS_REQUEST,
  payload: { review_id, comment },
})

export const setFeedbacksSuccess = (
  payload: SetFeedbackPayloadType & { created_at: string },
) => ({
  type: SET_FEEDBACKS_SUCCESS,
  payload,
})

export const setFeedbacksFailure = (payload: ResponseFailureType) => ({
  type: SET_FEEDBACKS_FAILURE,
  payload,
})

export const editFeedbacksRequest = (
  review_id: number,
  answer_id: number,
  comment: string,
) => ({
  type: EDIT_FEEDBACKS_REQUEST,
  payload: { answer_id, comment, review_id },
})

export const editFeedbacksSuccess = (
  payload: EditFeedbackPayloadType & { created_at: string },
) => ({
  type: EDIT_FEEDBACKS_SUCCESS,
  payload,
})

export const editFeedbacksFailure = (payload: ResponseFailureType) => ({
  type: EDIT_FEEDBACKS_FAILURE,
  payload,
})

export const clearFeedbacksErrors = () => ({
  type: CLEAR_FEEDBACKS_ERRORS,
})
