export const COOKIE_ACCESS_TOKEN = 'access_token'
export const COOKIE_REFRESH_TOKEN = 'refresh_token'
export const USER = 'user'

export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY
export const API_URL = process.env.REACT_APP_API_PATH
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID
export const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID
export const APPLE_APP_ID = process.env.REACT_APP_APPLE_APP_ID
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI

export const ALERT_TIMEOUT = 6000

export const VALIDATION_CLASS = 'is-validation-error'

export const FORMAT_TIME_SHORT = 'HH:mm:ss'
export const FORMAT_TIME_HOUR = 'HH'
export const FORMAT_TIME_HOUR_MIN = 'H'
export const FORMAT_TIME_MIN = 'mm'
export const FORMAT_TIME_MIN_SHORT = 'm'
export const FORMAT_TIME_HOUR_WITH_MIN = 'HH:mm'
export const FORMAT_DAY = 'd'
export const FORMAT_DATE_POINT = 'dd.mm.yyyy'
export const FORMAT_DATE_TIME = 'YYYY-MM-DD HH:mm'
export const FORMAT_DATE = 'yyyy-MM-dd'
export const FORMAT_DATE_CUSTOM = 'MMM d, yyyy'
export const FORMAT_DATE_DAY_YEAR = 'd MMM, yyyy'
export const FORMAT_DATE_MONTH_DAY = 'MMM d'
export const FORMAT_DATE_MONTH = 'MMM'
export const FORMAT_DATE_MONTH_WITH_DAY = 'MMM d'
export const FORMAT_DATE_YEAR = 'yyyy'
export const FORMAT_NUMBER_WEEK = 'I'
export const FORMAT_DATE_WITH_YEAR = 'MMMM d, yyyy'
export const FORMAT_DATE_WEEK_DAY_MONTH = 'E, d LLL'
export const FORMAT_WEEK = 'EEEE'
export const FORMAT_DATE_MONTH_WITH_YEAR = 'MMMM, yyyy'
export const FORMAT_DATE_MIN_MONTH_WITH_YEAR = 'MMM, yyyy'

export const PASSWORD_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&№*_\/<>,.\\:"'|;{}[\]()]).{8,}$/gm

export const EMAIL_REGEX = /\S+@\S+\.\S+/gm

export const PHONE_REGEX = /[\d]{3}-[\d]{3}-[\d]{2}-[\d]{2}/gm

export const SORT_VALUES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const ARRAY_HOUR = [...Array(24).fill(1)].map((_, index) => index)

export const ARRAY_MIN = [...Array(60).fill(1)].map((_, index) => index)

export const ARRAY_HOUR_MIN = [...Array(24).fill(1)].map(
  (_, index) => `${index}:00`,
)

export const STANDART_DATE = 'Jan 1 2022'

export const QUERY_PARAMS = {
  ORDER_HISTORY_STATUS: 'filter[status]',
  ADMIN_ACTIVE_FILTER: 'filter[active]',
  SEARCH: 'search',
  PAGE: 'page',
  SORT: 'sort',
  TAB: 'tab',
}

export enum SeoNames {
  DESCRIPTION = 'seo_description',
  KEYWORDS = 'seo_keywords',
  TITLE = 'seo_title',
}

export const SEO_INPUTS = [
  {
    title: 'ADMIN.SEO.PARAM.TITLE',
    name: SeoNames.TITLE,
  },
  {
    title: 'ADMIN.SEO.PARAM.DESCRIPTION',
    name: SeoNames.DESCRIPTION,
  },
  {
    title: 'ADMIN.SEO.PARAM.KEYWORDS',
    name: SeoNames.KEYWORDS,
  },
]

export const TIMEZONES = [
  'GTM-8 Baja California',
  'GTM-7 Baja California Sur',
  'GTM-7 Nayarit',
  'GTM-7 Sinaloa',
  'GTM-7 Sonora',
  'GTM-6 Aguascalientes',
  'GTM-6 Campeche',
  'GTM-6 Coahuila',
  'GTM-6 Colima',
  'GTM-6 Chiapas',
  'GTM-6 Chihuahua',
  'GTM-6 Ciudad de México',
  'GTM-6 Durango',
  'GTM-6 Guanajuato',
  'GTM-6 Guerrero',
  'GTM-6 Hidalgo',
  'GTM-6 Jalisco',
  'GTM-6 Estado de México',
  'GTM-6 Michoacán',
  'GTM-6 Morelos',
  'GTM-6 Nuevo León',
  'GTM-6 Puebla',
  'GTM-6 Querétaro',
  'GTM-6 San Luis Potosí',
  'GTM-6 Tabasco',
  'GTM-6 Tamaulipas',
  'GTM-6 Tlaxcala',
  'GTM-6 Veracruz',
  'GTM-6 Yucatán',
  'GTM-6 Zacatecas',
  'GTM-5 Quintana Roo',
]
