import { useMemo, useState } from 'react'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as RemoveIcon } from 'assets/images/common/redesign/delete.svg'
import { ReactComponent as PlusIcon } from 'assets/images/common/redesign/plusCircle.svg'
import BlogTableContent from 'modules/admin/Blog/TableContent'
import { UIButton, UICustomTable } from 'ui'
import UIAdminTableHeader from 'ui/AdminTableHeader'
import { ADMIN_BLOG_ADD, ADMIN_BLOG_ADD_CATEGORY } from 'utils'

import {
  columnsArticles,
  dataArticles,
  columnsCategories,
  dataCategories,
} from './constant'
import { DataArticlesType, DataCategoriesType } from './index.type'
import style from './style.module.scss'

const ViewAdminBlog = () => {
  const [selected, setSelected] = useState<number[]>([])
  const [block, setBlock] = useState(1)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleChecked = (id: number) => (event: CheckboxChangeEvent) => {
    const { checked } = event.target

    if (checked) setSelected((prevState) => [...prevState, id])
    else setSelected((prevState) => prevState.filter((item) => item !== id))
  }

  const handleBlockClick = (value: 0 | 1) => () => {
    setBlock(value)
    setSelected([])
  }

  const renderTableContent = useMemo(
    () =>
      (block
        ? (dataArticles as DataArticlesType[])
        : (dataCategories as DataCategoriesType[])
      ).map((itemRow: DataArticlesType & DataCategoriesType, index) => {
        return (
          <BlogTableContent
            key={index}
            block={block}
            handleChecked={handleChecked}
            itemRow={itemRow}
            selected={selected}
          />
        )
      }),
    [block, selected],
  )

  return (
    <Content className={style.blogWrapper}>
      <div className={style.titleWrapper}>
        <h1>{t('ADMIN.BLOG.BLOG')}</h1>

        <div className={style.titleActions}>
          <UIButton
            label={
              <>
                {t('COMMON.DELETE')}{' '}
                {!!selected.length && `(${selected.length})`}
              </>
            }
            icon={<RemoveIcon />}
            type="text"
            isRed
          />

          <UIButton
            label={t('COMMON.ADD_NEW')}
            handler={() =>
              navigate(block ? ADMIN_BLOG_ADD : ADMIN_BLOG_ADD_CATEGORY)
            }
            icon={<PlusIcon />}
            type="text"
          />
        </div>
      </div>

      <UICustomTable
        columns={block ? columnsArticles : columnsCategories}
        className={style.table}
        headerChildren={
          <UIAdminTableHeader
            block={block}
            handleBlockClick={handleBlockClick}
            firstBtnName={t('ADMIN.BLOG.ARTICLES')}
            secondBtnName={t('COMMON.CATEGORIES')}
          />
        }
        isLoading={false}
        perPage={1}
        total={100}
      >
        {renderTableContent}
      </UICustomTable>
    </Content>
  )
}

export default ViewAdminBlog
