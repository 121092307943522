import { useState, useMemo, useContext, useEffect } from 'react'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ManagerSidebarContext } from 'context'
import LayoutManagerSidebar from 'layouts/Manager/Sidebar'
import FeedbackModal from 'modules/manager/feedback/Modal'
import FeedbackSearch from 'modules/manager/feedback/Search'
import FeedbackTableRow from 'modules/manager/feedback/TableRow'
import FeedbackTabletRows from 'modules/manager/feedback/TabletRows'
import { addAlert } from 'store/Alert'
import {
  clearFeedbacksErrors,
  getFeedbacksRequest,
} from 'store/Manager/Feedbacks/Feedbacks.action'
import { IRootState } from 'store/Root.reducer'
import { UICustomPagination, UICustomSort, UIManagerTable } from 'ui'
import { QUERY_PARAMS, SORT_VALUES } from 'utils'
import { getFromQueryString, getQueryString } from 'utils/helpers/query'

import style from './style.module.scss'

const getInitialSort = (querySort: string) => {
  return querySort
    ? {
        date: null,
        rating: null,
        client: null,
        ...{ [querySort.split('|')[0]]: querySort.split('|')[1] },
      }
    : {
        date: SORT_VALUES.DESC,
        rating: null,
        client: null,
      }
}

const ViewManagerFeedBack = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { width } = useContext(ManagerSidebarContext)
  const { [QUERY_PARAMS.SORT]: querySort, [QUERY_PARAMS.SEARCH]: querySearch } =
    getFromQueryString(location.search)
  const [search, setSearch] = useState(querySearch || '')
  const [sort, setSort] = useState<{
    [key: string]: null | typeof SORT_VALUES[keyof typeof SORT_VALUES]
  }>(getInitialSort(querySort))
  const [openState, setOpenState] = useState<{
    [key: string]: string | number | undefined
  } | null>(null)
  const {
    data,
    isLoading,
    isModalLoading,
    errors,
    meta: { perPage, total },
  } = useSelector((store: IRootState) => store.feedbacks)

  const handleOpen =
    (id: number, message: string, comment?: string, answerId?: number) =>
    () => {
      setOpenState({ message, id, comment, answerId })
    }

  const handleClose = () => {
    setOpenState(null)
    dispatch(clearFeedbacksErrors())
  }

  const sortValues = useMemo(
    () => [
      { title: t('COMMON.DATE'), key: 'date' },
      { title: t('COMMON.RATING'), key: 'rating' },
      { title: t('COMMON.CLIENT'), key: 'client' },
    ],
    [t],
  )

  const columns = useMemo(
    () => [
      { title: '' },
      { title: t('COMMON.DATE') },
      { title: t('COMMON.CLIENTS') },
      { title: t('COMMON.RATING') },
      { title: t('COMMON.MESSAGE') },
      { title: t('COMMON.ANSWER') },
    ],
    [t],
  )

  const isTablet = width <= 768

  useEffect(() => {
    if (openState) {
      handleClose()

      dispatch(
        addAlert({
          message: t('COMMON.SUCCESSFULLY_SAVED'),
          type: 'success',
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const queries = getFromQueryString(location.search)
    const sortKey = Object.keys(sort).filter((key) => !!sort[key])[0]
    const sortValue = `${sortKey}|${sort[sortKey]}`

    navigate(
      `${location.pathname}${getQueryString({
        ...queries,
        [QUERY_PARAMS.SORT]: sortValue,
        [QUERY_PARAMS.SEARCH]: search,
        [QUERY_PARAMS.PAGE]: 1,
      })}`,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, search])

  useEffect(() => {
    const queries = getFromQueryString(location.search)

    let isSearch = false

    if (location.search) {
      if (search) {
        if (+queries.page > 1) {
          isSearch = true
        }
      }

      const renderObjToRequest = (page: number) => {
        if (search) {
          const newQueries = queries

          delete newQueries.page

          return getQueryString({
            ...newQueries,
            ...(isSearch && { [QUERY_PARAMS.PAGE]: page || 1 }),
          })
        }

        isSearch = false

        return getQueryString({ ...queries })
      }

      dispatch(getFeedbacksRequest(renderObjToRequest(+queries.page)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return (
    <LayoutManagerSidebar
      titleHeader={t('MANAGER.FEEDBACK.FEEDBACK')}
      className={'bg-white p-0'}
      headerContentCenter={
        <FeedbackSearch onSearchChange={setSearch} searchValue={search} />
      }
      headerClassName={style.feedbackHeader}
      contentCenterClassName={style.search}
      headerRightContent={
        <div className={style.feedbackHeaderSort}>
          <UICustomSort
            sortValues={sortValues}
            setState={setSort}
            state={sort}
          />
        </div>
      }
      isMobileContentVisible
    >
      <Spin spinning={isLoading}>
        <div className={style.feedbackPage}>
          {!isTablet && (
            <UIManagerTable
              columns={columns}
              perPage={perPage}
              total={total}
              className={style.feedbackTable}
            >
              {data?.map(({ id, comment, answer, ...props }, index) => (
                <FeedbackTableRow
                  key={index}
                  answer={answer}
                  message={comment}
                  {...props}
                  onReply={handleOpen(
                    Number(id),
                    String(comment),
                    answer?.comment,
                    Number(answer?.id),
                  )}
                />
              ))}
            </UIManagerTable>
          )}

          {isTablet && (
            <>
              <div className={style.listContainer}>
                {data?.map(({ id, comment, answer, ...props }, index) => (
                  <FeedbackTabletRows
                    key={index}
                    answer={answer}
                    message={comment}
                    {...props}
                    onReply={handleOpen(
                      Number(id),
                      String(comment),
                      answer?.comment,
                      Number(answer?.id),
                    )}
                  />
                ))}
              </div>

              {!!total && (
                <UICustomPagination perPage={perPage} total={total} />
              )}
            </>
          )}
        </div>
      </Spin>

      {!!openState && (
        <FeedbackModal
          onClose={handleClose}
          state={openState}
          isModalLoading={isModalLoading}
          errors={errors}
        />
      )}
    </LayoutManagerSidebar>
  )
}

export default ViewManagerFeedBack
