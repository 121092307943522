import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  GET_CATEGORIES_REQUEST,
  GET_SERVICE_BY_CATEGORY_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  GET_ONE_SERVICE_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_SERVICE_BY_CATEGORY_FAILURE,
  GET_SERVICE_BY_CATEGORY_REQUEST,
  GET_SUB_CATEGORY_SUCCESS,
  GET_STAFF_SUCCESS,
  SET_ACTIVE_CATEGORY,
  SET_IMAGE_LIST,
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_SUCCESS,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  CREATE_SERVICE_FAILURE,
  DELETE_SERVICE_FAILURE,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAILURE,
  GET_ONE_SERVICE_REQUEST,
  GET_ONE_SERVICE_FAILURE,
  RESET_STORE,
  RESET_ERROR,
  GET_ALL_SERVICE_REQUEST,
  GET_ALL_SERVICE_SUCCESS,
  GET_ALL_SERVICE_FAILURE,
  GET_FULL_SERVICE_SUCCESS,
  RESET_CATEGORY,
  GET_FULL_CATEGORIES_SUCCESS,
  SET_CATEGORIES_WITHOUT_SUCCESS,
  GET_SERVICE_WITHOUT_CATEGORIES_REQUEST,
  GET_SERVICE_WITHOUT_CATEGORIES_SUCCESS,
  GET_SERVICE_WITHOUT_CATEGORIES_FAILURE,
  SET_MOBILE_TITLE_OPEN,
} from './Services.constant'
import { IServicesState, ServicesActionsTypes } from './Services.type'

const initialState: IServicesState = {
  servicesByCategory: [],
  categories: [],
  fullCategories: [],
  subcategories: [],
  service: null,
  allService: [],
  fullService: [],
  isLoading: false,
  isCategoryLoading: false,
  isOneServiceLoading: false,
  staff: [],
  activeCategory: null,
  errors: {},
  errorMessage: null,
  isImageLoading: false,
  isServiceAction: false,
  isWithoutCategory: false,
  isMobileTitleOpen: false,
}

export default function servicesReducer(
  state = initialState,
  action: ServicesActionsTypes,
): IServicesState {
  switch (action.type) {
    case GET_ALL_SERVICE_REQUEST:
      return {
        ...state,
        isOneServiceLoading: true,
      }
    case GET_FULL_SERVICE_SUCCESS:
      return {
        ...state,
        isOneServiceLoading: false,
        fullService: action.payload,
      }
    case GET_ALL_SERVICE_SUCCESS:
      return {
        ...state,
        isOneServiceLoading: false,
        allService: action.payload,
      }
    case GET_ALL_SERVICE_FAILURE:
      return {
        ...state,
        isOneServiceLoading: false,
      }
    case GET_CATEGORIES_REQUEST:
      return {
        ...state,
        isCategoryLoading: true,
      }
    case SET_CATEGORIES_WITHOUT_SUCCESS:
      return {
        ...state,
        isWithoutCategory: action.payload,
      }
    case GET_SERVICE_WITHOUT_CATEGORIES_SUCCESS:
      return {
        ...state,
        servicesByCategory: action.payload,
        isOneServiceLoading: false,
      }

    case GET_SERVICE_BY_CATEGORY_REQUEST:
    case GET_SERVICE_WITHOUT_CATEGORIES_REQUEST:
      return {
        ...state,
        isOneServiceLoading: true,
      }
    case GET_SERVICE_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        servicesByCategory: action.payload,
        isOneServiceLoading: false,
      }
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        isCategoryLoading: false,
      }
    case GET_FULL_CATEGORIES_SUCCESS:
      return {
        ...state,
        fullCategories: action.payload,
        isCategoryLoading: false,
      }

    case GET_ONE_SERVICE_SUCCESS:
      return {
        ...state,
        service: action.payload,
        isLoading: false,
      }
    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        isCategoryLoading: false,
      }
    case GET_SERVICE_BY_CATEGORY_FAILURE:
    case GET_SERVICE_WITHOUT_CATEGORIES_FAILURE:
      return {
        ...state,
        isOneServiceLoading: false,
      }
    case GET_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subcategories: action.payload,
      }
    case GET_STAFF_SUCCESS:
      return {
        ...state,
        staff: action.payload,
      }
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: action.payload,
      }
    case SET_IMAGE_LIST:
      return {
        ...state,
        service: {
          ...state.service,
          images: action.payload,
        },
      }
    case DELETE_SERVICE_REQUEST:
    case CREATE_SERVICE_REQUEST:
    case UPDATE_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isServiceAction: false,
      }
    case UPDATE_SERVICE_SUCCESS:
    case CREATE_SERVICE_SUCCESS:
    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isServiceAction: true,
      }
    case CREATE_SERVICE_FAILURE:
    case DELETE_SERVICE_FAILURE:
    case UPDATE_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isServiceAction: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case GET_ONE_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ONE_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case RESET_STORE:
      return {
        ...state,
        isServiceAction: false,
      }
    case RESET_ERROR:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    case RESET_CATEGORY:
      return {
        ...state,
        activeCategory: null,
        categories: [],
      }
    case SET_MOBILE_TITLE_OPEN:
      return {
        ...state,
        isMobileTitleOpen: action.payload,
      }
    default:
      return state
  }
}
