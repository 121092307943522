import {
  BusinessTemplateStateType,
  INITIAL_STATE_SECTION_THREE,
} from 'modules/admin/Pages/index.type'
import {
  BussinessBody,
  BussinessBodySectionFive,
  BussinessBodySectionFour,
  BussinessBodySectionOne,
  BussinessBodySectionThree,
  BussinessBodySectionTwo,
  CustomerBody,
  CustomerBodySectionFive,
  CustomerBodySectionFour,
  CustomerBodySectionOne,
  CustomerBodySectionSix,
  CustomerBodySectionThree,
  CustomerBodySectionTwo,
} from 'views/admin/Pages/index.type'

export const getAdminTemplateSections = (
  sections: BussinessBody,
): {
  sectionFive: BusinessTemplateStateType
  sectionFour: BusinessTemplateStateType
  sectionOne: BusinessTemplateStateType
  sectionThree: typeof INITIAL_STATE_SECTION_THREE
  sectionTwo: BusinessTemplateStateType
} => {
  const newSections = {
    sectionOne: {} as BusinessTemplateStateType,
    sectionTwo: {} as BusinessTemplateStateType,
    sectionThree: {} as typeof INITIAL_STATE_SECTION_THREE,
    sectionFour: {} as BusinessTemplateStateType,
    sectionFive: {} as BusinessTemplateStateType,
  }

  for (const section in sections) {
    if (Object.prototype.hasOwnProperty.call(sections, section)) {
      const sectionNumber = section.split('_')[1]

      switch (sectionNumber) {
        case '1':
          newSections.sectionOne = {
            ...(newSections.sectionOne || {}),
            [section]: sections[section as keyof BussinessBodySectionOne],
          }
          break
        case '2':
          newSections.sectionTwo = {
            ...(newSections.sectionTwo || {}),
            [section]: sections[section as keyof BussinessBodySectionTwo],
          }
          break
        case '3':
          newSections.sectionThree = {
            ...(newSections.sectionThree || {}),
            [section]: sections[section as keyof BussinessBodySectionThree],
          }
          break
        case '4':
          newSections.sectionFour = {
            ...(newSections.sectionFour || {}),
            [section]: sections[section as keyof BussinessBodySectionFour],
          }
          break
        case '5':
          newSections.sectionFive = {
            ...(newSections.sectionFive || {}),
            [section]: sections[section as keyof BussinessBodySectionFive],
          }
          break
        default:
          break
      }
    }
  }

  return newSections
}

export const getAdminCustomerTemplate = (sections: CustomerBody) => {
  const newSections = {
    sectionOne: {},
    sectionTwo: {},
    sectionThree: {},
    sectionFour: {},
    sectionFive: {},
    sectionSix: {},
    seo: {},
  }

  for (const section in sections) {
    if (Object.prototype.hasOwnProperty.call(sections, section)) {
      const [seo, sectionNumber] = section.split('_')

      if (seo === 'description' || seo === 'title' || seo === 'keywords') {
        newSections.seo = {
          ...(newSections.seo || {}),
          [`seo_${section}`]: sections[section],
        }
      }

      switch (sectionNumber) {
        case '1':
          newSections.sectionOne = {
            ...(newSections.sectionOne || {}),
            [section]: sections[section as keyof CustomerBodySectionOne],
          }
          break
        case '2':
          newSections.sectionTwo = {
            ...(newSections.sectionTwo || {}),
            [section]: sections[section as keyof CustomerBodySectionTwo],
          }
          break
        case '3':
          newSections.sectionThree = {
            ...(newSections.sectionThree || {}),
            [section]: sections[section as keyof CustomerBodySectionThree],
          }
          break
        case '4':
          newSections.sectionFour = {
            ...(newSections.sectionFour || {}),
            [section]: sections[section as keyof CustomerBodySectionFour],
          }
          break
        case '5':
          newSections.sectionFive = {
            ...(newSections.sectionFive || {}),
            [section]: sections[section as keyof CustomerBodySectionFive],
          }
          break
        case '6':
          newSections.sectionSix = {
            ...(newSections.sectionSix || {}),
            [section]: sections[section as keyof CustomerBodySectionSix],
          }
          break
        default:
          break
      }
    }
  }

  return newSections
}

export const addPrefixSeo = (obj: { [key: string]: string }) => {
  let newObj = {}

  Object.keys(obj)?.forEach((item) => {
    newObj = {
      ...(newObj || {}),
      [`seo_${item}`]: obj[item],
    }
  })

  return newObj
}
