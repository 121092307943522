import { FullMedia } from 'entities/common'

export const PAGES_TEMPLATES_TYPE_ABOUT_US = 'about-us'
export const PAGES_TEMPLATES_TYPE_CONTACT_US = 'contact-us'
export const PAGES_TEMPLATES_TYPE_FOR_CUSTOMER = 'for-customer'
export const PAGES_TEMPLATES_TYPE_PRIVACY_POLICY = 'privacy-policy'
export const PAGES_TEMPLATES_TYPE_TERMS_CONDITIONS = 'terms-conditions'

export type TemplateFileType = undefined | FullMedia

export type TemplateType =
  | typeof PAGES_TEMPLATES_TYPE_ABOUT_US
  | typeof PAGES_TEMPLATES_TYPE_CONTACT_US
  | typeof PAGES_TEMPLATES_TYPE_FOR_CUSTOMER
  | typeof PAGES_TEMPLATES_TYPE_PRIVACY_POLICY
  | typeof PAGES_TEMPLATES_TYPE_TERMS_CONDITIONS

export type ContactUsBody = {
  description: string
  title: string
} & { [key: string]: string }

export type TextPagesData = {
  [key: string]: string | TextBody[]
}

export type TextBody = {
  description: string | any
  title: string
}

export type AboutUsBody = {
  section_1_description: string
  section_1_image_1: TemplateFileType
  section_1_image_2: TemplateFileType
  section_1_title: string
  section_2_description: string
  section_2_image: TemplateFileType
  section_2_title: string
  section_3_description: string
  section_3_image: TemplateFileType
  section_3_title: string
} & Partial<{ [key: string]: string | TemplateFileType }>

export type BussinessBodySectionOne = {
  section_1_description: string
  section_1_image: TemplateFileType
  section_1_title: string
}

export type BussinessBodySectionTwo = {
  section_2_description: string
  section_2_image_1: TemplateFileType
  section_2_image_2: TemplateFileType
  section_2_image_3: TemplateFileType
  section_2_image_4: TemplateFileType
  section_2_title: string
  section_2_title_1: string
  section_2_title_2: string
  section_2_title_3: string
  section_2_title_4: string
}

export type BussinessBodySectionThree = {
  section_3_image: TemplateFileType
  section_3_text: { description: string; title: string }[]
}

export type BussinessBodySectionFour = {
  section_4_description: string
  section_4_image: TemplateFileType
  section_4_title: string
}

export type BussinessBodySectionFive = {
  section_5_description: string
  section_5_image: TemplateFileType
  section_5_title: string
}

export type BussinessBody = BussinessBodySectionOne &
  BussinessBodySectionTwo &
  BussinessBodySectionThree &
  BussinessBodySectionFour &
  BussinessBodySectionFive &
  CustomerBodySectionSeo

export type CustomerBodySectionOne = {
  section_1_description: string
  section_1_image: TemplateFileType
  section_1_title: string
}

export type CustomerBodySectionTwo = {
  section_2_description: string
  section_2_title: string
}

export type CustomerBodySectionThree = {
  section_3_image: TemplateFileType
  section_3_text: { description: string; title: string }[]
}

export type CustomerBodySectionFour = {
  section_4_description: string
  section_4_image_1: TemplateFileType
  section_4_image_2: TemplateFileType
  section_4_title: string
}

export type CustomerBodySectionFive = {
  section_5_description: string
  section_5_image_1: TemplateFileType
  section_5_image_2: TemplateFileType
  section_5_image_3: TemplateFileType
  section_5_title: string
}

export type CustomerBodySectionSix = {
  section_6_description: string[]
  section_6_image: TemplateFileType
  section_6_subtitle: string
  section_6_title: string
}

export type CustomerBodySectionSeo = {
  [key: string]: string
}

export type CustomerBody = CustomerBodySectionOne &
  CustomerBodySectionTwo &
  CustomerBodySectionThree &
  CustomerBodySectionFour &
  CustomerBodySectionFive &
  CustomerBodySectionSix &
  CustomerBodySectionSeo

export type TemplateBodyType =
  | ContactUsBody
  | TextPagesData
  | AboutUsBody
  | BussinessBody
  | CustomerBody

export type ContentType = {
  data?: { description: string; title: string }[]
}
