import { t } from 'i18next'
import { call, fork, put, takeLatest } from 'redux-saga/effects'

import {
  getAdminCurrentUserApi,
  getAdminSalonsApi,
  removeAdminDocument,
  setAdminCurrentUserDisabledApi,
  setAdminCurrentUserDocumentConfirmationApi,
  setAdminCurrentUserToggleSubscriptionApi,
} from 'services/http/admin/Customers'
import { addAlert } from 'store/Alert'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  removeAdminCurrentSalonDocumentSuccess,
  setAdminCurrentSalonDocumentConfirmationSuccess,
  getAdminCurrentSalonFailure,
  getAdminCurrentSalonSuccess,
  getAdminSalonsFailure,
  getAdminSalonsSuccess,
  setAdminCurrentSalonFailure,
  setAdminCurrentSalonSuccess,
  setAdminCurrentSalonDocumentConfirmationFailure,
  removeAdminCurrentSalonDocumentFailure,
  setAdminCurrentSalonToggleSubscriptionFailure,
  setAdminCurrentSalonToggleSubscriptionSuccess,
} from './Salons.action'
import {
  SET_ADMIN_CURRENT_SALON_REQUEST,
  GET_ADMIN_CURRENT_SALON_REQUEST,
  GET_ADMIN_SALONS_REQUEST,
  SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_REQUEST,
  REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_REQUEST,
  SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_REQUEST,
} from './Salons.constant'
import {
  ISetAdminCurrentSalonDocumentConfirmationRequest,
  ISetAdminCurrentSalonRequest,
  IAdminSalonResponse,
  IAdminSalonResponseData,
  IGetAdminCurrentSalonRequest,
  IGetAdminSalonsRequest,
  IRemoveAdminCurrentSalonDocument,
  ISetAdminCurrentSalonToggleSubscriptionRequest,
} from './Salons.type'

function* workerGetAdminSalons({ payload }: IGetAdminSalonsRequest) {
  try {
    const response: { data: IAdminSalonResponse } = yield call(
      getAdminSalonsApi,
      payload,
    )

    yield put(getAdminSalonsSuccess(response.data))
  } catch (error) {
    yield getRequestErrors(getAdminSalonsFailure, error as string)
  }
}

function* watchGetAdminSalons() {
  yield takeLatest(GET_ADMIN_SALONS_REQUEST, workerGetAdminSalons)
}

function* workerGetAdminCurrentSalon({
  payload,
}: IGetAdminCurrentSalonRequest) {
  try {
    const response: { data: { data: IAdminSalonResponseData } } = yield call(
      getAdminCurrentUserApi,
      payload,
    )

    yield put(getAdminCurrentSalonSuccess(response.data?.data))
  } catch (error) {
    yield getRequestErrors(getAdminCurrentSalonFailure, error as string)
  }
}

function* watchGetAdminCurrentSalon() {
  yield takeLatest(GET_ADMIN_CURRENT_SALON_REQUEST, workerGetAdminCurrentSalon)
}

function* workerSetAdminCurrentSalon({
  payload,
}: ISetAdminCurrentSalonRequest) {
  try {
    yield call(setAdminCurrentUserDisabledApi, payload)

    yield put(setAdminCurrentSalonSuccess(payload.active))
  } catch (error) {
    yield getRequestErrors(setAdminCurrentSalonFailure, error as string)
  }
}

function* watchSetAdminCurrentSalon() {
  yield takeLatest(SET_ADMIN_CURRENT_SALON_REQUEST, workerSetAdminCurrentSalon)
}

function* workerSetAdminCurrentSalonDocumentConfirmation({
  payload,
}: ISetAdminCurrentSalonDocumentConfirmationRequest) {
  try {
    yield call(setAdminCurrentUserDocumentConfirmationApi, payload)

    yield put(
      setAdminCurrentSalonDocumentConfirmationSuccess(payload.confirmed),
    )
    yield put(
      addAlert({
        message: t('COMMON.SUCCESSFULLY_SAVED'),
        type: 'success',
      }),
    )
  } catch (error) {
    yield getRequestErrors(
      setAdminCurrentSalonDocumentConfirmationFailure,
      error as string,
    )
  }
}

function* watchSetAdminCurrentSalonDocumentConfirmation() {
  yield takeLatest(
    SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_REQUEST,
    workerSetAdminCurrentSalonDocumentConfirmation,
  )
}

function* workerSetAdminCurrentSalonToggleSubscription({
  payload,
}: ISetAdminCurrentSalonToggleSubscriptionRequest) {
  try {
    yield call(setAdminCurrentUserToggleSubscriptionApi, payload)

    yield put(
      setAdminCurrentSalonToggleSubscriptionSuccess(payload.manual_free_trial),
    )
    yield put(
      addAlert({
        message: t('COMMON.SUCCESSFULLY_SAVED'),
        type: 'success',
      }),
    )
  } catch (error) {
    yield getRequestErrors(
      setAdminCurrentSalonToggleSubscriptionFailure,
      error as string,
    )
  }
}

function* watchSetAdminCurrentSalonToggleSubscription() {
  yield takeLatest(
    SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_REQUEST,
    workerSetAdminCurrentSalonToggleSubscription,
  )
}

function* workerRemoveAdminCurrentSalonDocument({
  payload,
}: IRemoveAdminCurrentSalonDocument) {
  try {
    yield call(removeAdminDocument, payload)

    yield put(removeAdminCurrentSalonDocumentSuccess(payload))
    yield put(
      addAlert({
        message: t('COMMON.SUCCESSFULLY_DELETE'),
        type: 'success',
      }),
    )
  } catch (error) {
    yield getRequestErrors(
      removeAdminCurrentSalonDocumentFailure,
      error as string,
    )
  }
}

function* watchRemoveAdminCurrentSalonDocument() {
  yield takeLatest(
    REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_REQUEST,
    workerRemoveAdminCurrentSalonDocument,
  )
}

export const adminSalonWatchers = [
  fork(watchGetAdminSalons),
  fork(watchGetAdminCurrentSalon),
  fork(watchSetAdminCurrentSalon),
  fork(watchRemoveAdminCurrentSalonDocument),
  fork(watchSetAdminCurrentSalonToggleSubscription),
  fork(watchSetAdminCurrentSalonDocumentConfirmation),
]
