import { call, fork, put, takeLatest } from 'redux-saga/effects'

import i18n from 'lib/i18n/i18n'
import { ManagerSettingsHttp } from 'services/http'
import { addAlert } from 'store/Alert'
import { StringHelper } from 'utils/helpers'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  getSettingsFailure,
  getSettingsSuccess,
  syncGoogleCalendarFailure,
  syncGoogleCalendarSuccess,
  updateSettingsFailure,
  updateSettingsSuccess,
  desyncGoogleCalendarSuccess,
  desyncGoogleCalendarFailure,
} from './Settings.action'
import {
  GET_SETTINGS_REQUEST,
  UPADATE_SETTINGS_REQUEST,
  SYNC_GOOGLE_CALENDAR_REQUEST,
  DESYNC_GOOGLE_CALENDAR_REQUEST,
} from './Settings.constant'
import {
  IGetSettingsResponse,
  IUpdateSettingsRequest,
  ISyncGoogleCalendarRequest,
} from './Settings.type'
import { getProfileRequest } from '../Salon'

function* workerUpdateSettings({ payload }: IUpdateSettingsRequest): unknown {
  try {
    yield call(ManagerSettingsHttp.updateSettings, payload)

    yield put(updateSettingsSuccess())
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    )
  } catch (error) {
    yield getRequestErrors(
      updateSettingsFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchUpdateSettings() {
  yield takeLatest(UPADATE_SETTINGS_REQUEST, workerUpdateSettings)
}

function* workerGetSettings() {
  try {
    const response: { data: { data: IGetSettingsResponse } } = yield call(
      ManagerSettingsHttp.getSettings,
    )

    yield put(getSettingsSuccess(response?.data?.data))
  } catch (error) {
    yield getRequestErrors(
      getSettingsFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchGetSettings() {
  yield takeLatest(GET_SETTINGS_REQUEST, workerGetSettings)
}

function* workerSyncGoogleCalendar({ payload }: ISyncGoogleCalendarRequest) {
  try {
    yield call(ManagerSettingsHttp.syncGoogleCalendar, payload)

    yield put(syncGoogleCalendarSuccess())
    yield put(getProfileRequest())
  } catch (error) {
    yield getRequestErrors(
      syncGoogleCalendarFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchSyncGoogleCalendar() {
  yield takeLatest(SYNC_GOOGLE_CALENDAR_REQUEST, workerSyncGoogleCalendar)
}

function* workerDesyncGoogleCalendar() {
  try {
    yield call(ManagerSettingsHttp.desyncGoogleCalendar)

    yield put(desyncGoogleCalendarSuccess())
    yield put(getProfileRequest())
  } catch (error) {
    yield getRequestErrors(
      desyncGoogleCalendarFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    )
  }
}

function* watchDesyncGoogleCalendar() {
  yield takeLatest(DESYNC_GOOGLE_CALENDAR_REQUEST, workerDesyncGoogleCalendar)
}

export const settingsWatchers = [
  fork(watchUpdateSettings),
  fork(watchGetSettings),
  fork(watchSyncGoogleCalendar),
  fork(watchDesyncGoogleCalendar),
]
