import i18n from 'lib/i18n/i18n'

export const columns = [
  {
    title: i18n.t('MANAGER.CLIENTS.NAME'),
  },
  {
    title: i18n.t('MANAGER.CLIENTS.EMAIL'),
  },
  {
    title: i18n.t('MANAGER.CLIENTS.PHONE'),
  },
]
