import { FC } from 'react'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { UICheckbox } from 'ui'
import {
  FORMAT_TIME_HOUR_MIN,
  FORMAT_TIME_MIN_SHORT,
  STANDART_DATE,
} from 'utils'
import { DateHelper } from 'utils/helpers'

import styles from './style.module.scss'

type PropsType = {
  checked: boolean
  color?: string
  isCheckAll: boolean
  name: string
  oldPrice?: string
  onChange: (e: CheckboxChangeEvent) => void
  price: number
  time: string
}

const StaffUIChecbox: FC<PropsType> = ({
  name,
  time,
  price,
  isCheckAll,
  checked,
  onChange,
  oldPrice,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div>
        <UICheckbox
          checked={isCheckAll || checked}
          onChange={onChange}
          className={styles.checkbox}
        >
          {name}
        </UICheckbox>
      </div>
      <time className={styles.time}>
        {DateHelper.toFormat(
          new Date(`${STANDART_DATE} ${time}`),
          FORMAT_TIME_HOUR_MIN,
        )}
        {t('MANAGER.SERVICES.TIME.HOUR.SHORT')}{' '}
        {DateHelper.toFormat(
          new Date(`${STANDART_DATE} ${time}`),
          FORMAT_TIME_MIN_SHORT,
        )}
        {t('MANAGER.SERVICES.TIME.MIN.SHORT')}
      </time>
      <b className={cn(styles.oldPrice, { [styles.border]: oldPrice })}>
        <span>{oldPrice && `$${oldPrice}`}</span>
      </b>
      <b className={styles.price}>${price}</b>
    </>
  )
}

export default StaffUIChecbox
