/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect } from 'react'

import { createSocketConnection } from 'services/socket'
import { getCookie } from 'utils/helpers/cookie'
import { COOKIE_ACCESS_TOKEN, USER } from 'utils'
import {
  AdminSocketEvent,
  ManagerSocketEvent,
  PublicSocketEvent,
} from 'context/WebSocket'

const userId = getCookie(USER) ? JSON.parse(getCookie(USER) || '')?.id : ''
const managerChannel = `private-manager.${userId}`
const adminChannel = `private-admin.${userId}`

function listen(
  callBack: (payload: any) => void,
  channel: string,
  event: string,
) {
  window.Echo.channel(channel).listen(event, (payload: any) => {
    callBack(payload)
  })

  return function cleanUp() {
    window.Echo.leaveChannel(channel)
  }
}

type Options = {
  callBack: (payload: any) => void
  type: ManagerSocketEvent | AdminSocketEvent | PublicSocketEvent
}

export const useSocket = ({ type, callBack }: Options) => {
  useEffect(() => {
    createSocketConnection(getCookie(COOKIE_ACCESS_TOKEN) || '')

    switch (type) {
      case ManagerSocketEvent.NEW_FEED_BACK: {
        return listen(
          callBack,
          managerChannel,
          ManagerSocketEvent.NEW_FEED_BACK,
        )
      }
      case ManagerSocketEvent.SUBSCRIPTION_EXPORED: {
        return listen(
          callBack,
          managerChannel,
          ManagerSocketEvent.SUBSCRIPTION_EXPORED,
        )
      }
      case ManagerSocketEvent.DOCUMENT_REJECTED: {
        return listen(
          callBack,
          managerChannel,
          ManagerSocketEvent.DOCUMENT_REJECTED,
        )
      }
      case ManagerSocketEvent.DOCUMENT_ACCEPTED: {
        return listen(
          callBack,
          managerChannel,
          ManagerSocketEvent.DOCUMENT_ACCEPTED,
        )
      }
      case ManagerSocketEvent.CATEGORY_DEACTIVATED: {
        return listen(
          callBack,
          managerChannel,
          ManagerSocketEvent.CATEGORY_DEACTIVATED,
        )
      }
      case ManagerSocketEvent.APPOINTMENT_RESCHEDULE_BY_CUSTOMER: {
        return listen(
          callBack,
          managerChannel,
          ManagerSocketEvent.APPOINTMENT_RESCHEDULE_BY_CUSTOMER,
        )
      }
      case ManagerSocketEvent.APPOINTMENT_CANCELED_BY_CUSTOMER: {
        return listen(
          callBack,
          managerChannel,
          ManagerSocketEvent.APPOINTMENT_CANCELED_BY_CUSTOMER,
        )
      }
      case ManagerSocketEvent.APPOINTMENT_BLOKED_BY_CUSTOMER: {
        return listen(
          callBack,
          managerChannel,
          ManagerSocketEvent.APPOINTMENT_BLOKED_BY_CUSTOMER,
        )
      }
      case AdminSocketEvent.DOCUMENT_UPLOADED: {
        return listen(
          callBack,
          adminChannel,
          AdminSocketEvent.DOCUMENT_UPLOADED,
        )
      }
      case PublicSocketEvent.PUBLIC_EVENT: {
        return listen(
          callBack,
          'test-public-channel',
          PublicSocketEvent.PUBLIC_EVENT,
        )
      }

      default:
        return null as unknown as void
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
